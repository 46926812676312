// 관리자 마이페이지 

import React from 'react';
import { Grid, Box } from '@mui/material';
import PageContainer from '../container/PageContainer.js';

// components
import YearlyRes from './components/YearlyRes.js'; // 예약현황
import Wcounselor from './components/Wcounselor.js'; // 승인대기 회원
import MonthlyEarnings from './components/MonthlyEarnings.js'; // 정산
import Oresconfirm from './components/Oresconfirm.js'; // 정산

import { CssBaseline, ThemeProvider } from '@mui/material';
import { baselightTheme } from "../theme/DefaultColors.js"


const adm_Dashboard = () => {
  const theme = baselightTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PageContainer title="관리자 마이페이지">
        <Box
          sx={{
            width: '100%',
            overflowX: 'hidden',
            p: 0,
            '@media (max-width: 1125px)': {
              width: '90vw'
            }
          }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <YearlyRes />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MonthlyEarnings />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={15}>
              <Wcounselor />
            </Grid>
            <Grid item xs={12} lg={15}>
              <Oresconfirm />
            </Grid>
          </Grid>
        </Box>
      </PageContainer>
    </ThemeProvider>
  );
};

export default adm_Dashboard;
