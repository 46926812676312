import {
  IconCopy, IconLayoutDashboard, IconLogin, IconCalendarTime, IconReportMoney, IconCalendarEvent, IconClock
} from '@tabler/icons';
import { Typography } from '@mui/material';

import { uniqueId } from 'lodash';

const Menuitems = [
  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
      sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", 
              fontSize: '1.25rem',  
              marginTop: '24px', 
              marginBottom: '10px' }}>
        홈
      </Typography>
    ),
  },

  {
    id: uniqueId(),
    title: '전문가 페이지',
    icon: IconLayoutDashboard,
    href: '/Mypage/counselor',
  },
  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
      sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '1.25rem',  marginTop: '24px', marginBottom: '10px' }}>
        상담
      </Typography>
    ),
  },
  {
    id: uniqueId(),
    title: '상담 일정 관리',
    icon: IconCalendarEvent,
    href: '/Mypage/counselor/con_calender',
  },
  {
    id: uniqueId(),
    title: '결제 완료 상담 관리',
    icon: IconCalendarTime,
    href: '/Mypage/counselor/con_request',
  },
  {
    id: uniqueId(),
    title: '상담 가능 시간 관리',
    icon: IconClock,
    href: '/Mypage/counselor/con_time_calendar',
  },

  {
    id: uniqueId(),
    title: '정산',
    icon: IconReportMoney,
    href: '/Mypage/counselor/con_calculate',
  },

  {
    id: uniqueId(),
    title: '후기',
    icon: IconCopy,
    href: '/Mypage/counselor/review',
  },

  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
        sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '1.25rem',  marginTop: '24px', marginBottom: '10px' }}>
        메타버스 상담실
      </Typography>
    ),
  },
  {
    id: uniqueId(),
    title: '다운로드 센터',
    icon: IconLogin,
    href: '/Mypage/counselor/meta',
  },

];

export default Menuitems;
