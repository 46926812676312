import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import Button from '@mui/material/Button';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileImg from '../../counselor_img/unknown.png';

const Update_counselor = () => {
  const [userData, setUserData] = useState({}); // 사용자 데이터
  const navigate = useNavigate();
  const [w_counselorData, setW_counselorData] = useState({}); // 사용자 데이터
  const [counsImg, setCounsImg] = useState({}); // 이미지 불러오기

  const location = useLocation();
  const state = location.state || {}; // null 대신 빈 객체로 초기화
  const { check } = state;

  console.log(check);
  if (!check || check !== 'OK') {
    navigate('/Mypage/counselor/Check');
    console.log("잘못된 접근입니다.");
  }

  // 로그인 상태가 아닐 시 로그인 페이지로 전환
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    navigate('/Login');
  }

  const [pw, setPw] = useState("");
  const [checkpw, setCheckpw] = useState("");


  const [updatedUserData, setUpdatedUserData] = useState({
    name: '', // 기본적으로 빈 문자열로 초기화
    loginid: '',
    email: '',
    phonenum: '',
    birthday: '',
    pw: '', // 비밀번호 추가
    checkpw: '', // 비밀번호 확인 추가

  });

  async function fetchCounsImg() {
    try {
      //서버에 상담사 이미지 가져오는 api 호출
      const counsResponse = await axios.post('/api/MypageCouns', {
        userId: w_counselorData.user_id,
        userName: w_counselorData.couns_name,
      }, { withCredentials: true });

      const counsImg = counsResponse.data ? counsResponse.data : null; // response 값이 null인 경우 처리
      if (counsImg === null) {
        //null인 경우 기본 이미지
        setCounsImg("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
        return;
      }
      setCounsImg(counsImg);
    } catch (error) {
      console.log('데이터 요청 오류');
    }
  }


  useEffect(() => {
    // 서버에서 사용자 정보를 가져오는 API 호출
    axios
      .get("/api/Mypage", { withCredentials: true })
      .then((response) => {
        setW_counselorData(response.data);
      })
      .catch((error) => {
        console.error("마이페이지 데이터 요청 오류:", error);
      });
  }, []);

  useEffect(() => {
    if (w_counselorData && w_counselorData.user_id) {
      fetchCounsImg();

    }
  }, [w_counselorData]);

  const isPasswordValid = (pw) => {
    // 비밀번호 유효성 검사 로직을 추가
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,16}$/;
    return passwordRegex.test(pw);
  };

  const handleUpdate = () => {

    if (pw !== checkpw) {
      // 비밀번호 확인 일치
      toast.error("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return;
    }

    if (!isPasswordValid(pw)) {
      toast.error(
        "비밀번호는 (영문자/숫자/특수문자 조합 8자 ~ 16자)여야 합니다."
      );
      return;
    }

    axios.put('/api/updateCounselor', { pw: pw }, { withCredentials: true })
      .then(response => {
        console.log('사용자 정보 업데이트 성공:');
      })
      .catch(error => {
        console.error('사용자 정보 업데이트 오류');
      });

    goToedit();
  };


  const goToedit = () => {
    navigate(`/Mypage/counselor/edit_counselor`, {
      state: {
        check: "OK"
      }
    });
  }





  return (
    <PageContainer title="프로필 수정" >
      <ToastContainer />
      <DashboardCard title="나의 정보" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Paper elevation={10}>
          <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
            <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#5D87FF",
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '11px',
                          top: "-190px",
                          left: "920px",
                          height: '30px',
                        }}
                        onClick={handleUpdate}>
                        저장
                      </Button>

                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#5D87FF",
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '11px',
                          top: "-190px",
                          left: "930px",
                          height: '30px',

                        }}
                        onClick={goToedit}>
                        취소
                      </Button>

                      {w_counselorData.c_image ? (
                        <img
                          src={w_counselorData.c_image}
                          alt="Counselor"
                          style={{
                            width: '110px',
                            height: '110px',
                            marginRight: '50px',
                            marginLeft: '-20px',
                            marginTop: '-280px',
                            borderRadius: '5px',
                          }}
                        />
                      ) : (
                        <img
                          src={ProfileImg}
                          alt="Counselor"
                          style={{
                            width: '110px',
                            height: '110px',
                            marginRight: '30px',
                            marginTop: '-280px',
                            borderRadius: '5px',
                          }}
                        />
                      )}
                      <div>
                        <br /><br /><br />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                          이름 : {w_counselorData.couns_name}
                        </Typography>
                        <br /><br /><br /><br />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                          아이디 : {w_counselorData.couns_loginid}
                        </Typography>
                        <br />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          <TextField
                            label="비밀번호"
                            variant="filled"
                            color="primary"
                            focused
                            onChange={(event) => {
                              setPw(event.target.value);
                            }}

                            sx={{
                              '& .MuiInputBase-root': {
                                height: '45px', // 원하는 높이 값으로 설정
                              },
                            }} />
                        </Typography>
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          <TextField
                            label="비밀번호 확인"
                            variant="filled"
                            color="primary"
                            focused
                            onChange={(event) => {
                              setCheckpw(event.target.value);
                            }}

                            sx={{
                              '& .MuiInputBase-root': {
                                height: '45px', // 원하는 높이 값으로 설정
                              },
                            }} />
                        </Typography>
                        <br /><br /><br /><br />


                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                          이메일 : {w_counselorData.couns_email}
                        </Typography>
                        <br />
                        <br />
                        <br /><br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                          전화번호 : {w_counselorData.couns_phonenum}
                        </Typography>
                        <br />
                        <br />
                        <br /><br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                          생년월일 : {w_counselorData.birthday}
                        </Typography>
                        <br />
                        <br />
                        <br /><br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                          성별 : {w_counselorData.couns_gender === 1 ? '남자' : '여자'}
                        </Typography>
                        <br />
                        <br />



                      </div>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>

          </Box>
        </Paper>

      </DashboardCard>
    </PageContainer>
  );
};

export default Update_counselor;
