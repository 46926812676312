// 내담자 마이페이지 메뉴 

import {
  IconAperture, IconCopy, IconLayoutDashboard, IconLogin, IconMoodHappy, IconTypography, IconUserPlus
} from '@tabler/icons';
import { Typography } from '@mui/material';


import { uniqueId } from 'lodash';

const Menuitems = [


  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
      sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", 
              fontSize: '1.25rem',  
              marginTop: '24px', 
              marginBottom: '10px' }}>
        홈
      </Typography>
    ),
  },


  {
    id: uniqueId(),
    title: '내담자 페이지',
    icon: IconLayoutDashboard,
    href: '/Mypage/client',
  },
  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
      sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '1.25rem',  marginTop: '24px', marginBottom: '10px' }}>
        상담
      </Typography>
    ),
  },
  {
    id: uniqueId(),
    title: '예약 관리', // Typography
    icon: IconTypography,
    href: '/Mypage/client/reservation_manage',
  },
  {
    id: uniqueId(),
    title: '상담 내역', // Shadow
    icon: IconCopy,
    href: '/Mypage/client/con_detail',
  },
  {
    id: uniqueId(),
    title: '상담 결과', // Shadow
    icon: IconCopy,
    href: '/Mypage/client/Cli_consultation_list',
  },
  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
      sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '1.25rem',  marginTop: '24px', marginBottom: '10px' }}>
        결제
      </Typography>
    ),
  },
  {
    id: uniqueId(),
    title: '결제 내역', // Login 
    icon: IconLogin,
    href: '/Mypage/client/payment',
  },
  
  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
        sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '1.25rem',  marginTop: '24px', marginBottom: '10px' }}>
        메타버스
      </Typography>
    ),
  },

  {
    id: uniqueId(),
    title: '다운로드 센터',
    icon: IconAperture,
    href: '/Mypage/client/meta',
  },
];

export default Menuitems;
