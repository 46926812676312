import React, { useState, useEffect } from "react";
import { Main } from "./Home/main";
import { About } from "./Home/about";
import { Services } from "./Home/services";
import { Gallery } from "./Home/gallery";
import { Testimonials } from "./Home/testimonials";
import { Contact } from "./Home/contact";
import JsonData from "./Home/data/data.json";
import SmoothScroll from "smooth-scroll";
import "./Home/home.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const RedirectURL = (props) => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    document.title = "나만";
  }, []);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Main data={landingPageData.Header2} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default RedirectURL;
