import React, { useEffect, useRef, useState } from 'react';
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";
import inforefund from "./info_refund";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './Payment.css';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import { Typography, Grid, CardContent, TableHead, TableRow, TableCell, Button, Paper } from '@mui/material';
import BlankCard from '../../bootstrap/shared/BlankCard';
import PageContainer from '../../bootstrap/container/PageContainer';
import DashboardCard from '../../bootstrap/shared/DashboardCard';

import queryString from 'query-string';

/* 결제위젯 초기화 */
const widgetClientKey = "live_gck_24xLea5zVAljZ6n14Z0x3QAMYNwW";
const customerKey = nanoid();

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [userData, setUserData] = useState({});
  const [isChecked1, setIsChecked1] = useState(false);

  const [paymentWidget, setPaymentWidget] = useState(null);
  const paymentMethodsWidgetRef = useRef(null);

  const {
    counselorName,
    counselorId,
    selectedDate2,
    selectedTime,
    countLife,
    countBusiness,
    countCareer,
    totalAmount,
    reservationType,
    request_1,
    request_2,
    request_3,
    request_4,
    request_5,
    request_6,
    request_7,
    request_8,
  } = state || {};

  // 예약 당시 날짜로 예약 번호 만들기
  const currentDate = new Date();
  const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, "");
  const res_id = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
  const reservationDateTime = currentDate.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    navigate('/Login');
  }
  // 올바른 접근인지 확인
  if (state === null) {
    navigate('/Search');
    toast.error("잘못된 접근입니다.");
  }

  const handleCheckboxChange = (checkboxNum) => {
    if (checkboxNum === 1) {
      setIsChecked1(!isChecked1);
    }
  };

  /* 유효한 날짜인지 확인*/
  useEffect(() => {
    if (!selectedDate2 || isNaN(new Date(selectedDate2).getTime())) {
      navigate('/');
    }
  }, [selectedDate2, navigate]);

  // 예약 선택 날짜
  // 문자열을 Date 객체로 변환
  const selectedDateObj = new Date(selectedDate2);

  // UTC 시간을 계산하고 한국 시간대로 변환
  const selectedDateUTC = Date.UTC(
    selectedDateObj.getFullYear(),
    selectedDateObj.getMonth(),
    selectedDateObj.getDate(),
    selectedDateObj.getHours(),
    selectedDateObj.getMinutes(),
    selectedDateObj.getSeconds()
  );
  const selectedDateSeoul = new Date(selectedDateUTC + 9 * 60 * 60 * 1000);

  // 한국 시간대를 고려하여 MySQL에 저장할 형식으로 변환
  const mysqlDate = selectedDateSeoul.toISOString().slice(0, 10);

  let totalTime;

  if (reservationType === "라이프") {
    totalTime = countLife;
  } else if (reservationType === "비즈니스") {
    totalTime = countBusiness;
  } else if (reservationType === "커리어") {
    totalTime = countCareer;
  }

  // 선택한 시간 및 총 상담 시간을 시간과 분으로 분리
  const [startTimeHour, startTimeMinute] = selectedTime.split(":").map(Number);
  const totalTimeHour = Math.floor(totalTime);
  const totalTimeMinute = Math.round((totalTime - totalTimeHour) * 60);

  // 종료 시간 계산
  let endHour = startTimeHour + totalTimeHour;
  let endMinute = startTimeMinute + totalTimeMinute;

  // 분이 60을 넘어가면 시간에 반영
  if (endMinute >= 60) {
    endHour += Math.floor(endMinute / 60);
    endMinute %= 60;
  }

  // 시간이 24를 넘어가면 다음 날로 넘어감 (예외 처리)
  if (endHour >= 24) {
    endHour %= 24;
  }

  const endTime = `${endHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")}`;

  useEffect(() => {
    // 서버에서 사용자 정보를 가져오는 API 호출
    axios
      .get("/api/Mypage", { withCredentials: true })
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.error("마이페이지 데이터 요청 오류:", error);
      });
  }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행되도록 설정

  const currencyFormat = (amount) => {
    return new Intl.NumberFormat("ko-KR", {
      style: "decimal",
      currency: "KRW",
    }).format(amount);
  };

  /* 결제 관련 UI */
  useEffect(() => {
    const fetchPaymentWidget = async () => {
      try {
        const loadedWidget = await loadPaymentWidget(widgetClientKey, customerKey);
        setPaymentWidget(loadedWidget);
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    };

    fetchPaymentWidget();
  }, []);

  useEffect(() => {
    if (!paymentWidget) return;

    const initializePaymentWidget = async () => {
      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        "#payment-widget",
        { value: totalAmount }, // 여기서는 고정된 값을 사용하거나 totalAmount를 사용합니다.
        { variantKey: "resang" }
      );
      paymentMethodsWidgetRef.current = paymentMethodsWidget;

      // 이용 약관 렌더링
      paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });
    };

    initializePaymentWidget();
  }, [paymentWidget, totalAmount]);

  const onClickPayment = async () => {
    if (!paymentWidget || !paymentMethodsWidgetRef.current) {
      console.error("Payment widget is not initialized.");
      return;
    }

    if (!isChecked1) {
      toast.error("환불규정을 확인해주세요.");
      return;
    }

    const resData = {
      res_id: res_id,
      reservationDateTime: reservationDateTime,
      coun_type: '개인 상담',
      reservationType: reservationType,
      selectedDate: mysqlDate,
      selectedTime: selectedTime,
      totalTime: totalTime,
      counselor_id: counselorId,
      counselor_name: counselorName,
      user_id: userData.user_id,
      user_name: userData.name,
      user_birth: userData.birthday,
      totalAmount: totalAmount,
      deposit_stat: '결제 전',
      deposit_name: userData.name,
      counselor_stat: '상담 전',
      request_1: request_1,
      request_2: request_2,
      request_3: request_3,
      request_4: request_4,
      request_5: request_5,
      request_6: request_6,
      request_7: request_7,
      request_8: request_8,
      start_time: selectedTime,
      end_time: endTime,
      availability_status: "available",
      pay_time: "결제 전"
    };

    const resParams = queryString.stringify(resData);

    try {
      // 전화번호에서 하이픈 제거
      const phonenumber = userData.phonenum.replace(/-/g, '');

      await paymentWidget?.requestPayment({
        orderId: res_id, // 주문 ID 설정
        orderName: "나만 개인 상담 예약", // 주문 이름 설정
        customerName: userData.name,
        customerEmail: userData.email,
        customerMobilePhone: phonenumber,
        successUrl: `${window.location.origin}/Paybank?resData=${encodeURIComponent(resParams)}`,
        failUrl: `${window.location.origin}/PaybankFail`,
      });
    } catch (error) {
      console.error('Error during the payment process:', error);
      alert('결제에 실패하였습니다. 에러 내용: ' + error.message);
    }
  };

  if (!state || !state.selectedDate2) {
    return <div className="payment_p2">유효한 데이터가 없습니다.</div>;
  }

  return (
    <div className="paymentdiv">
      <PageContainer title="예약 결제" className="page-container">
        <ToastContainer />
        <Grid container spacing={3} className="grid-container">
          <Grid item xs={12} sm={10}>
            <Paper elevation={3}>
              <DashboardCard title="결제" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }} >
                <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12} className="left-grid-item">
                    <Paper elevation={10} sx={{ width: '100%' }}>
                      <BlankCard>
                        <CardContent>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography className="card-content" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }} sx={{ fontSize: '17px', fontWeight: 'bold' }}>
                                  예약 정보
                                </Typography>
                                <br /><br />
                                <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                  상담사 : {counselorName} 상담사
                                </Typography>
                                <br /><br />
                                <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                  일정 : {selectedDate2} / {selectedTime}
                                </Typography>
                                <br /><br />
                                <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                  상담유형 : {reservationType === '라이프' && `${reservationType}(${countLife}시간)`}
                                  {reservationType === '비즈니스' && `${reservationType}(${countBusiness}시간)`}
                                  {reservationType === '커리어' && `${reservationType}(${countCareer}시간)`}
                                </Typography>
                                <br />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </CardContent>
                      </BlankCard>
                    </Paper>
                  </Grid>
                  <Grid container spacing={0} sx={{ display: 'flex', marginLeft: '2.1rem', textAlign: 'center', alignItems: 'center', justifyContent: 'center', paddingTop: '2rem' }}>
                    <Grid item xs={12}>
                      <Paper elevation={10}>
                        <BlankCard className="inner-card">
                          <CardContent>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography className="card-content" sx={{ fontWeight: 'bold', fontSize: '17px' }} style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                    최종결제 금액
                                  </Typography>
                                  <br /><br />
                                  <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                    총 금액: {currencyFormat(totalAmount)}원
                                  </Typography>
                                  <br /><br />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {/* 결제 UI, 이용약관 UI*/}
                            <div id="payment-widget" className="payment-widget" />
                            <div id="agreement" className="agreement" />
                          </CardContent>
                        </BlankCard>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="left-grid-item">
                    <Paper elevation={10} sx={{ width: '100%' }}>
                      <BlankCard>
                        <CardContent>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography className="card-content" sx={{ fontWeight: 'bold' }}>
                                  환불규정
                                </Typography>
                                <br /><br />

                                <div className="join_p" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '12px' }}>

                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={isChecked1}
                                      onChange={() => handleCheckboxChange(1)}
                                      style={{ marginRight: '5px' }}
                                    />
                                  </label>
                                  아래 내용을 확인했습니다.
                                </div>

                                <div className="signup-re-agree-box"
                                  style={{
                                    fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
                                    textAlign: 'left', width: '100%', paddingLeft: '10px'
                                  }}
                                >
                                  {inforefund}
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </CardContent>
                      </BlankCard>
                    </Paper>
                  </Grid>


                </Grid>
                <Button
                  variant="contained"
                  className="payment-button"
                  onClick={onClickPayment}
                  sx={{
                    fontSize: '13.5px',
                    marginTop: '2.2rem',
                    width: '185px',
                    height: '35px'
                  }}
                  style={{
                    marginBottom: '20px',
                    fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
                    fontSize: '14px'
                  }}
                >
                  결제하기
                </Button>
              </DashboardCard>
            </Paper>
            <br /><br /><br /><br />
          </Grid>
        </Grid>
      </PageContainer>
    </div>
  );
};

export default Payment;
