import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Box, Card, Stack, Typography } from '@mui/material';


import PageContainer from '../../bootstrap/container/PageContainer';
import Logo from '../../bootstrap/layouts/full/shared/logo/Logo';
import AuthLogin from './AuthLogin';
import naman_logo from "./naman.png";

const Login = () => {


  return (
    <PageContainer title="로그인">
      <Box
        sx={{
          position: 'relative',
          '&:before': {
            content: '""',
            background: 'radial-gradient(#d2f1df, #d3d7fa, #bad8f4)',
            backgroundSize: '400% 400%',
            animation: 'gradient 15s ease infinite',
            position: 'absolute',
            height: '100%',
            width: '50%',
            opacity: '0.3',
          },
        }}
      >
        <Grid container spacing={0} justifyContent="center" sx={{ height: '100vh' }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={4}
            xl={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Card elevation={9} sx={{ p: 4, zIndex: 1, width: '100%', maxWidth: '500px' }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Link to="/">
                  <img
                    src={naman_logo}
                    alt="Logo"
                    style={{
                      height: '32px',
                      width: '140px',
                      overflow: 'hidden',
                      display: 'block',
                      marginBottom: '25px',
                    }}
                  />
                </Link>
              </Box>
              <AuthLogin
                subtext={
                  <Typography variant="subtitle1" textAlign="center" color="textSecondary" mb={1}>

                  </Typography>
                }
                subtitle={
                  <Stack direction="row" spacing={1} justifyContent="center" mt={3}>
                    <Typography color="textSecondary" variant="h6" fontWeight="500"
                      sx={{
                        fontSize: '12px',
                        fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                      }} >
                      나만 회원이 아니신가요?
                    </Typography>
                    <Typography
                      component={Link}
                      to="/Signup"
                      fontWeight="500"
                      sx={{
                        textDecoration: 'none',
                        color: 'primary.main',
                        fontSize: '11px'

                      }}
                    >
                      회원가입
                    </Typography>
                  </Stack>
                }
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Login;
