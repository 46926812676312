// 내담자 마이페이지

import React from 'react';
import { Grid, Box } from '@mui/material';
import PageContainer from '../container/PageContainer';

// components
import Client_profile from './components/Client_profile';
import Cli_community from './components/Cli_community.js';
import ProductPerformance from './components/ProductPerformance';
import Complete_reservation from './components/Complete_reservation';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { baselightTheme } from "../theme/DefaultColors.js"


const Dashboard = () => {
  const theme = baselightTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PageContainer title="내담자 마이페이지">
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Client_profile />
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Cli_community />
                </Grid>

                <Grid item xs={12}>
                  <Complete_reservation />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={15}>
              <ProductPerformance />
            </Grid>

          </Grid>
        </Box>
      </PageContainer>
    </ThemeProvider>
  );
};

export default Dashboard;
