import React from 'react';

function Footer() {
    return (
        <footer>
            <div className='footerdiv'
                style={{
                    backgroundColor: '#fff',
                    color: '#353535',
                    padding: '1.5rem 4rem',
                    boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.07)',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    width: '100%'
                }}>
                <div className='footertitle'
                    style={{
                        textAlign: 'left',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        width: '98%',
                        paddingBottom: '5px',
                        display: 'flex',
                        margin: 'auto'
                    }}>
                    &copy; N A M A N
                </div>
                <div className='footercontentdiv'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '98%',
                        margin: 'auto'
                    }}>
                    <div className='footercontent'
                        style={{
                            textAlign: 'left'
                        }}>
                        <p style={{ margin: '3px 0', fontSize: '12px' }}>상호명 ｜ 리상컴퍼니 Resang Company</p>
                        <p style={{ margin: '3px 0', fontSize: '12px' }}>주소 ｜ 대구광역시 남구 고산 4길9, 프렌즈컴퍼니 2층 </p>
                        <p style={{ margin: '3px 0', fontSize: '12px' }}> 사업자등록번호 ｜ 679-25-01415 </p>
                        <p style={{ margin: '3px 0', fontSize: '12px' }}> 통신판매업번호 ｜ 2024-대구남구-0993 </p>
                    </div>
                    <div className='footercontent2'
                        style={{
                            textAlign: 'right',
                            fontSize: '10px'
                        }}>
                        <p style={{ margin: '3px 0', fontSize: '12px' }}>대표 ｜ 김가영</p>
                        <p style={{ margin: '3px 0', fontSize: '12px' }}>Phone ｜ 010-6566-2379</p>
                        <p style={{ margin: '3px 0', fontSize: '12px' }}>Email ｜ resang79@naver.com</p>
                    </div>
                </div>
            </div>

            <style>
                {`
                @media (max-width: 715px) {
                    .footercontentdiv {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    }
                }
                `}
            </style>
        </footer>
    );
}

export default Footer;