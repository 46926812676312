import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import agree_reservation from './Agree_Reservation';
import './Reservation_request.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button } from '@mui/material'
import axios from 'axios';

export default function ReservationRequest(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const state = location.state || {};



    // 이전 페이지에서 전달받은 state
    const {
        counselorName = '',
        counselorId = '',
        selectedDate2 = '',
        selectedTime = [],
        countLife = 0,
        countBusiness = 0,
        countCareer = 0,
        totalAmount = 0,
        reservationType = ''
    } = state;

    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (!isLoggedIn) {
        navigate('/Login');
    }
    // 올바른 접근인지 확인
    if (useLocation().state === null) {
        navigate('/Search');
    }

    useEffect(() => { // 직접 url 제한
        if (!isLoggedIn) {
            navigate('/Login');
            return; // 로그인되지 않은 경우, 컴포넌트 렌더링 방지
        }

        // 상태가 유효한지 확인하고, 유효하지 않으면 리디렉션
        if (!state || !counselorName || !counselorId) {
            navigate('/Search'); // Search 페이지로 리디렉션
            console.log("잘못된 접근입니다.");
        }
    }, [isLoggedIn, state, counselorName, counselorId, navigate]);

    useEffect(() => {
        document.title = "상담 요청 사항";
    }, []);

    const { id } = useParams(); // URL에서 상담사 ID 추출
    const [cdetail, setcDeatildata] = useState({
        c_name: '',
        c_description: '',
        c_career: '',
        c_education: '',
        c_license: '',
        image: '', // 추가된 부분
    });



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/counselordetail', { params: { id } });
                const cDetailData = response.data.cdetaildata;
                setcDeatildata(cDetailData[0]);
            } catch (error) {
                console.error('데이터 가져오기 오류:', error);
            }
        };

        fetchData();
    }, [id]);

    const handlePayment = () => {
        if (!isChecked5) {
            toast.error("필수 약관 확인 후 동의해 주세요!"); // toast로 오류 메시지 표시
            return;
        }
        else if (!request_1 | !request_2 | !request_3 | !request_4 | !request_5 | !request_6 | !request_7 | !request_8) {
            toast.error("상담 신청서를 모두 작성해 주세요.");
            return;
        }
        else {
            // 이전 페이지에서 전달받은 state를 payment에다 그대로 전달
            navigate(`/Counselor/${cdetail.id}/payment`, {
                state: {
                    counselorName: cdetail.c_name, counselorId, selectedDate2, selectedTime, countLife, countBusiness, countCareer, totalAmount, reservationType,
                    request_1, request_2, request_3, request_4, request_5, request_6, request_7, request_8
                }
            });
        }
    };


    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);
    const [isChecked5, setIsChecked5] = useState(false);

    const [customText, setCustomText] = useState('');

    const [request_1, setRequest_1] = useState('');
    const [request_2, setRequest_2] = useState('');
    const [request_3, setScore] = useState(); // 라디오 버튼(점수)
    const [request_4, setRequest_4] = useState('');
    const [request_5, setRequest_5] = useState('');
    const [request_6, setRequest_6] = useState('');
    const [request_7, setRequest_7] = useState('');
    const [request_8, setRequest_8] = useState('');

    const handleScoreChange = (event) => {
        setScore(event.target.value);
    };

    const handleCheckboxChange = (value) => {
        if (value === '본인이 원해서') {
            setIsChecked1(true);
            setIsChecked2(false);
            setIsChecked3(false);
            setIsChecked4(false);
            setRequest_7('본인이 원해서');
        } else if (value === '가족이 권해서') {
            setIsChecked1(false);
            setIsChecked2(true);
            setIsChecked3(false);
            setIsChecked4(false);
            setRequest_7('가족이 권해서');
        } else if (value === '가족 외 친구나 지인이 권해서') {
            setIsChecked1(false);
            setIsChecked2(false);
            setIsChecked3(true);
            setIsChecked4(false);
            setRequest_7('가족 외 친구나 지인이 권해서');
        } else if (value === '기타') {
            setIsChecked1(false);
            setIsChecked2(false);
            setIsChecked3(false);
            setIsChecked4(true);
            setRequest_7(customText);
        }
    };

    const setCustomTextValue = (value) => {
        setCustomText(value);
        if (isChecked4) {
            setRequest_7(value);
        }
    };

    const handleCheckboxChange2 = (checkboxNum) => {
        if (checkboxNum === 5) {
            setIsChecked5(!isChecked5);
        }
    };

    const handleTextChange1 = (event) => {
        setRequest_1(event.target.value);
    };
    const handleTextChange2 = (event) => {
        setRequest_2(event.target.value);
    };
    const handleTextChange4 = (event) => {
        setRequest_4(event.target.value);
    };
    const handleTextChange5 = (event) => {
        setRequest_5(event.target.value);
    };
    const handleTextChange6 = (event) => {
        setRequest_6(event.target.value);
    };
    const handleTextChange8 = (event) => {
        setRequest_8(event.target.value);
    };

    return (
        <div className='requestdiv'>
            <div className="aragraphStyle">
                <ToastContainer />
                <div className="requestTitle" >
                    <h3>상담 신청서</h3>
                </div>
                <hr className='hr-res' />
                <div />

                <div className="requestfield">
                    <div className="join_p">
                        ● 상담시 불러주었으면 하는 호칭이 있습니까? (최대 10자)
                        <br /><br />
                        <input
                            type="text"
                            value={request_1}
                            onChange={handleTextChange1}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                resize: 'none',
                                //border: '1px solid #ccc',
                                border: request_1.length > 0 ? '1px solid #5D87FF' : '1px solid #ccc', // 길이에 따라 테두리 색상 변경
                                fontSize: '12px',
                                width: '100%',
                                maxWidth: '300px' // 최대 너비 설정
                            }}
                            maxLength={10} // 최대 길이 설정
                        />
                    </div>



                    <div className="join_p">
                        ● 현재 하고 있는 일에 대해 소개해 주시겠어요? (최대 500자) <Span2 />
                        <br /><br />
                        <textarea
                            rows="4"
                            cols="50"
                            value={request_2}
                            onChange={handleTextChange2}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '12px',
                                width: '100%',
                                resize: 'none',
                                border: request_2.length > 0 ? '1px solid #5D87FF' : '1px solid #ccc', // 길이에 따라 테두리 색상 변경
                                maxWidth: '100%', // 최대 너비 설정
                                maxHeight: '60%',

                            }}
                            maxLength={500} // 최대 길이 설정

                        />
                    </div>



                    <div className="join_p">
                        ● 현재 나의 상태를 점수로 표현한다면 1~6점 중 몇 점을 주시겠어요? (1 ~ 3점 : 부정,  4 ~6 : 긍정)
                        <br /><br />
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="score"
                                value="6"
                                checked={request_3 === "6"}
                                onChange={handleScoreChange}
                            />
                            <span className="radio-text">6점</span>
                        </label>
                        &nbsp;

                        <label className="radio-label">
                            <input
                                type="radio"
                                name="score"
                                value="5"
                                checked={request_3 === "5"}
                                onChange={handleScoreChange}
                            />
                            <span className="radio-text">5점</span>
                        </label>
                        &nbsp;

                        <label className="radio-label">
                            <input
                                type="radio"
                                name="score"
                                value="4"
                                checked={request_3 === "4"}
                                onChange={handleScoreChange}
                            />
                            <span className="radio-text">4점</span>
                        </label>
                        &nbsp;

                        <label className="radio-label">
                            <input
                                type="radio"
                                name="score"
                                value="3"
                                checked={request_3 === "3"}
                                onChange={handleScoreChange}
                            />
                            <span className="radio-text">3점</span>
                        </label>
                        &nbsp;

                        <label className="radio-label">
                            <input
                                type="radio"
                                name="score"
                                value="2"
                                checked={request_3 === "2"}
                                onChange={handleScoreChange}
                            />
                            <span className="radio-text">2점</span>
                        </label>
                        &nbsp;

                        <label className="radio-label">
                            <input
                                type="radio"
                                name="score"
                                value="1"
                                checked={request_3 === "1"}
                                onChange={handleScoreChange}
                            />
                            <span className="radio-text">1점</span>
                        </label>
                        &nbsp;
                    </div>



                    <div className="join_p">
                        ● 어떤 주제으로 상담을 원하시는지 구체적으로 알려주시겠어요? (최대 500자)
                        <br /><br />
                        <textarea
                            rows="4"
                            cols="50"
                            value={request_4}
                            onChange={handleTextChange4}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '12px',
                                width: '100%',
                                border: request_4.length > 0 ? '1px solid #5D87FF' : '1px solid #ccc', // 길이에 따라 테두리 색상 변경
                                maxWidth: '100%', // 최대 너비 설정
                                maxHeight: '60%',
                                resize: 'none',
                            }}
                            maxLength={500} // 최대 길이 설정

                        />
                    </div>

                    <div className="join_p">
                        ● 상담을 통해 얻고싶거나 기대하는 사항이 있다면 알려주시겠어요? (최대 500자)
                        <br /><br />
                        <textarea
                            rows="4"
                            cols="50"
                            value={request_5}
                            onChange={handleTextChange5}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '12px',
                                width: '100%',
                                border: request_5.length > 0 ? '1px solid #5D87FF' : '1px solid #ccc', // 길이에 따라 테두리 색상 변경
                                maxWidth: '100%', // 최대 너비 설정
                                maxHeight: '60%',
                                resize: 'none',
                            }}
                            maxLength={500} // 최대 길이 설정

                        />

                    </div>

                    <div className="join_p">
                        ● 상담 전문가에게 추가로 전달하고 싶은 사항이 있다면 편하게 알려주세요~ (최대 500자) <Span />
                        <br /><br />
                        <textarea
                            rows="4"
                            cols="50"
                            value={request_6}
                            onChange={handleTextChange6}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '12px',
                                width: '100%',
                                border: request_6.length > 0 ? '1px solid #5D87FF' : '1px solid #ccc', // 길이에 따라 테두리 색상 변경
                                maxWidth: '100%', // 최대 너비 설정
                                maxHeight: '60%',
                                resize: 'none',
                            }}
                            maxLength={500} // 최대 길이 설정

                        />

                    </div>

                    <div className="join_p">
                        ● 상담을 신청하시게 된 동기는 무엇입니까?
                        <br /><br />
                        <label className="radio-label">
                            <input
                                type="checkbox"
                                checked={isChecked1}
                                onChange={() => handleCheckboxChange('본인이 원해서')}

                            />
                            &nbsp;
                            1. 본인이 원해서

                        </label>
                        &nbsp;


                        <label className="radio-label">
                            <input
                                type="checkbox"
                                checked={isChecked2}
                                onChange={() => handleCheckboxChange('가족이 권해서')}
                            />
                            &nbsp;
                            2. 가족이 권해서
                        </label>
                        &nbsp;

                        <label className="radio-label">
                            <input
                                type="checkbox"
                                checked={isChecked3}
                                onChange={() => handleCheckboxChange('가족 외 친구나 지인이 권해서')}
                            />
                            &nbsp;
                            3. 가족 외 친구나 지인이 권해서
                        </label>
                        &nbsp;

                        <label className="radio-label">
                            <input
                                type="checkbox"
                                checked={isChecked4}
                                onChange={() => handleCheckboxChange('기타')}
                            />
                            &nbsp;
                            4. 기타(직접기재란, 최대 50자) <input value={customText} onChange={(e) => setCustomTextValue(e.target.value)} />
                        </label>
                        &nbsp;
                    </div>

                    <div className="join_p">
                        ● 불편하지 않으시다면, 심리상담이나 이와 비슷한 경험이 있다면, 어떤 방법으로 얼마나 받으셨는지 알려주시겠어요? (최대 500자) <Span />
                        <br /><br />
                        <textarea
                            rows="4"
                            cols="50"
                            value={request_8}
                            onChange={handleTextChange8}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '12px',
                                width: '100%',
                                border: request_8.length > 0 ? '1px solid #5D87FF' : '1px solid #ccc', // 길이에 따라 테두리 색상 변경
                                maxWidth: '100%', // 최대 너비 설정
                                maxHeight: '60%',
                                resize: 'none',
                            }}
                            maxLength={500} // 최대 길이 설정

                        />

                    </div>
                    <hr className='hr-res' />


                    <h5>‘나만’ 상담서비스에 대한 이해와 협조 동의</h5>

                    <div className="re-agree-box">
                        {agree_reservation}

                        <label>


                            <input
                                type="checkbox"
                                checked={isChecked5}
                                onChange={() => handleCheckboxChange2(5)}
                            />
                            &nbsp;
                            동의

                        </label>





                        <br /><br />
                        ※ 동의하시지 않을 경우, 부득이 ‘나만’ 상담 서비스 제공이 어려움을 양해 부탁드립니다.
                        <br /><br />

                    </div>


                    <hr className='hr-res' />
                    <br />
                </div>

                <div className='requestbtn'>
                    <Button
                        className="join_button"
                        color="primary"
                        variant="contained"
                        sx={{ height: 40, width: 180, fontSize: '1.3rem' }}
                        style={{ marginBottom: '20px', fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '14px' }}
                        onClick={handlePayment}>
                        결제하기
                    </Button>
                </div>
            </div>
        </div>
    );
}


function Span({ space = 80 }) {
    return (
        <span style={{ paddingRight: space }}></span>
    );
}


function Span2({ space = 50 }) {
    return (
        <span style={{ paddingRight: space }}></span>
    );
}


function Span4({ space = 85 }) {
    return (
        <span style={{ paddingRight: space }}></span>
    );
}