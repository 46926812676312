import React, { useEffect } from 'react';
import './Paybank.css';
import { useNavigate, useLocation } from 'react-router-dom';

import { Grid, Box, Card, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import naman_logo from "../Login/naman.png";
import PageContainer from '../../bootstrap/container/PageContainer';

export default function O_Reservaion_sus() {
    const location = useLocation();
    const navigate = useNavigate();
    const { status } = location.state || {};

    // 로그인 상태가 아닐 시 로그인 페이지로 전환
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (!isLoggedIn) {
        navigate('/Login');
    }
    // 올바른 접근인지 확인
    if (location.state === null || status !== "success") {
        navigate('/O_Reservation');
    }

    return (

        <div className="login-title">

            <PageContainer title="예약 완료" sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>

                <br /><br /><br /><br /><br /><br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>

                    <Card elevation={20} sx={{ p: 4, zIndex: 1, width: '500px', minHeight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>


                        <Link to="/">
                            <img
                                src={naman_logo}
                                alt="Logo"
                                style={{
                                    height: '32px',
                                    width: '140px',
                                    overflow: 'hidden',
                                    display: 'block',
                                    marginBottom: '25px',
                                }}
                            />

                            <hr style={{
                                backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
                                height: '4px',
                                width: '115px',
                                top: '-20px',
                                left: '3px',
                                border: 'none' // 기본 테두리를 제거합니다
                            }} />
                        </Link>
                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                            예약 완료
                        </Typography>
                        <br />
                        <Typography style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }} sx={{ fontSize: "15px", justifyContent: "left", fontWeight: "400", textAlign: "left" }}>
                            *관리자의 확인 후 예약이 확정됩니다.*
                            <br />

                        </Typography>
                        <br />
                    </Card>
                </div>
                <br /><br /><br /><br />
            </PageContainer>
        </div>
    );
}