import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const Client_Leave = () => {
    const [passwd, setPasswd] = useState(''); // 비밀번호를 문자열로 관리

    const navigate = useNavigate();
    const location = useLocation();
    const { loginid, user_id, authority, check } = location.state || {};

    console.log(location.state);

    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    // 로그인 상태 확인
    if (!isLoggedIn) {
        navigate('/Login');
        return null;
    }

    // 권한 및 체크 확인
    if (!check || check !== "OK") {
        navigate('/Mypage/admin/client_manage');
        console.log("잘못된 접근입니다.");
        return null;
    }

    const nextbuttonhandle = () => {
        if (passwd === '내담자강제회원탈퇴') {
            axios
                .post(
                    '/api/leave',
                    {
                        user_id: user_id,
                        authority: authority,
                        state: "관리자 요청"
                    },
                    {
                        withCredentials: true
                    }
                )
                .then(response => {
                    if (response.status === 200) {
                        navigate('/Mypage/admin/client_manage');
                    } else {
                        toast.error("탈퇴 중 오류가 발생했습니다.");
                    }
                })
                .catch(error => {
                    toast.error("탈퇴 중 오류가 발생했습니다.");
                });
        } else {
            toast.error("탈퇴를 원하시면, '내담자강제회원탈퇴'를 입력해 주세요.");
        }
    };
    return (
        <PageContainer title="회원 탈퇴">
            <ToastContainer />
            <DashboardCard title="회원 탈퇴"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh'
                }}>
                <Paper elevation={10}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: { xs: 2, md: 4 },
                        width: '100%'
                    }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Box sx={{ width: '100%', flexDirection: 'column' }}>
                            <Table sx={{ width: '100%' }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    marginBottom: '1rem',
                                                    lineHeight: 1.2,
                                                }}
                                            >
                                                회원 탈퇴 시, 내담자의 개인 정보를 비롯한 나만 상담을 이용한 내역은 즉시 영구 삭제되어 어떠한 방법으로든 복구가 불가능합니다.
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    marginBottom: '1rem',
                                                    lineHeight: 1.2,
                                                }}
                                            >
                                                단, 공공적 성격의 게시글(커뮤니티, 후기 등)은 삭제되지 않으니 해당 사안은 안내 부탁드립니다.
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    marginBottom: '1rem',
                                                    lineHeight: 1.2,
                                                }}
                                            >
                                                회원 강제 탈퇴 후, 삭제된 데이터들은 되돌릴 수 없으니 신중하게 검토해 주세요.
                                            </Typography>
                                            <br />
                                            <br />
                                            <br />
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    marginBottom: '2rem',
                                                    lineHeight: 1.2,
                                                }}
                                            >
                                                탈퇴를 원하시면 '내담자강제회원탈퇴'를 입력하신 후 탈퇴 버튼을 눌러 주세요.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '1rem',
                                                width: '100%',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    lineHeight: 3,
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                입력란
                                            </Typography>
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                value={passwd}
                                                onChange={(e) => setPasswd(e.target.value)}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={nextbuttonhandle}
                                                sx={{
                                                    width: '100%',
                                                    height: '3.5rem',
                                                    fontSize: '1rem',
                                                    borderRadius: '10px'
                                                }}
                                            >
                                                탈퇴
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Paper>
            </DashboardCard>
        </PageContainer>
    );
};

export default Client_Leave;
