
const infocounselor = `\n [상담전문가용] 서비스 이용약관


[제1장 총칙]
제1조 (목적)

본 약관은 리상컴퍼니(이하 “회사”)가 운영하는 Mental Health Care 플랫폼 ‘나만’(이하 “서비스”)를 이용함에 있어 이용자와 회사 간의 의무, 권리, 책임사항 및 회원의 서비스 이용조건 및 절차 등 기본적인 사항을 규정하여 회원의 권익을 보호함을 목적으로 한다.

이용자는 본 이용약관을 자세히 읽은 후 이용약관에 동의하지 않을 경우, 본 이용약관에 동의하거나, 서비스에 등록 또는 액세스하거나 이를 이용(이하 “이용”)하지 말아야 한다.


제2조 (용어의 정의)

'나만' 서비스(이하 ‘ 서비스') : 회사가 제공하는 모든 서비스를 의미한다.
심리상담 서비스
감정분석 및 심리검사 등의 심리분석/관리 솔루션 서비스
정신건강 관련 커뮤니티 서비스
정신건강을 위한 셀프케어 솔루션 판매 서비스
기타 회사가 추가 개발하거나 다른 회사 혹은 개인과 제휴 및 계약 등을 통해 제공하는 서비스
이용자: 본 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 및 비회원을 의미한다.
이용계약: 회사가 제공하는 서비스의 이용과 관련하여 회사와 이용자 간에 체결하는 계약을 의미한다.
'나만' 계정: 이용자가 서비스를 사용하기 위하여 필요한 이메일 주소 및 아이디·패스워드 기반의 로그인 계정 및 간편 로그인 계정을 의미한다.
회원: 본 약관에 동의함으로써 회사와 이용계약을 체결하고 '나만' 계정을 등록한 자로서, 회사가 제공하는 정보와 서비스를 지속적으로 이용할 수 있는 회원을 포함한다. 회원은 서비스 이용 목적에 따라 상담사 회원과 일반 회원으로 구분되는 경우가 있으며, 상담사 회원과 일반 회원 모두에게 이 약관이 적용된다.
비회원: 회원가입을 하지 않고 ‘서비스’를 이용하는 사용자를 말한다.
상담사 회원: 상담을 제공할 목적으로 회사가 온라인으로 제공하는 양식에 맞추어 회사의 '나만' 서비스에 등록한 회원을 말한다. 회사는 서비스의 원활한 제공을 위해 상담사의 등급을 회사 내부 규정에 따라 나눌 수 있다.
아이디(ID): 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자 또는 숫자의 조합을 의미한다.
비밀번호(Password): 회원이 부여 받은 아이디와 일치하는 회원임을 확인하고 회원의 권익을 보호하기 위하여 회원이 선정한 문자와 숫자의 조합을 의미한다.
전자우편(Email): 인터넷을 통한 우편 혹은 전기적 매체를 이용한 우편을 의미한다.
콘텐츠(Contents): 회사가 지식, 정보 등을 전달하기 위하여 만든 부호 · 문자 · 도형 · 색채 · 음성 · 음향 · 이미지 및 영상 등(이들의 복합체를 포함한다)의 자료 또는 정보 및 회원이 입력 또는 게시하는 모든 정보(게시물)를 의미한다.
해지(또는 탈퇴): 회사 또는 회원이 이용계약을 해약하는 것을 말한다.
상담 상품권: 상담사로부터 상담 서비스를 제공받기 위하여 이용자가 일정한 대가를 지급하고 구매하는 상담 서비스 상품을 말한다.
솔루션: 이용자에게 유상 또는 무상으로 제공하는 유형 또는 무형의 재화를 의미한다.
직거래: '나만' 서비스를 통하지 않고 상담사 회원과 일반 회원이 직접 서비스 대금을 주고받는 행위를 말한다.
포인트: 회원이 일정한 행위를 마친 때에 시스템에 적립되는 서비스 상의 데이터로서 소멸 전까지 서비스상에서 상품의 구매 등에 사용할 수 있는 것을 말한다. 회사에서는 이를 ‘별’라는 명칭으로 사용한다.


제3조 (약관의 효력 및 변경)

본 약관은 서비스를 이용하고자 하는 모든 이용자에게 그 효력이 발생한다.
본 약관의 내용은 회사가 서비스의 인터넷 웹사이트(www.naman.co.kr 이하 “웹사이트”) 또는 서비스 화면에 게시하거나 기타의 방법으로 이용자에게 공지하고, 이용자가 이에 동의하면 효력이 발생한다.
이용자가 약관을 상세히 읽지 않아 발생한 피해에 대해서 회사는 책임을 지지 않는다.
회사는 필요하다고 인정되면 본 약관을 변경할 수 있으며, 회사가 약관을 변경할 때에는 적용 일자와 변경사유를 밝혀 본 조 제 2항과 같은 방법으로 그 적용 일자 7일 전부터 공지한다. 다만, 이용자에게 불리한 약관을 변경할 때에는 그 적용일자 30일 전부터 공지하며, 이메일(E-mail), 문자메시지(SNS)등으로 회원에게 개별 통지한다. (이용자가 연락처를 기재하지 않았거나 변경하여 개별 통지가 어려울 때에는 웹사이트에 변경 약관을 공지하는 것을 개별 통지한 것으로 간주한다.)
회사가 본 조 제2항에 따라 변경 약관을 공지하거나 통지하면서, 이용자가 약관 변경 적용일까지 거부 의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 이용자가 변경된 약관의 효력 발생일까지 약관 변경에 대한 거부 의사를 표시하지 않을 때에는 이용자가 변경 약관에 동의한 것으로 간주한다. 이용자는 변경된 약관에 동의하지 않으면 서비스 이용을 중단하고 이용계약을 해지할 수 있다.
본 약관에 동의하는 것은 회사가 운영하는 서비스의 웹사이트를 정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미한다. 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에 대하여 회사는 책임이 없다.
이 약관은 이용자가 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 한다. 단, 이 약관의 4조, 5조, 6조, 10조, 11조, 12조, 31조, 33조 는 회원이 탈퇴 후에도 유효하게 적용될 수 있다.
회사는 이용자가 요구하면 본 약관의 사본을 이용자에게 교부하며, 이용자가 웹사이트에서 본 약관을 인쇄할 수 있도록 조치한다.
회사는 특정 상담사 회원 및 개인과 개별적으로 이 약관 및 개별약관(이하 “약관 등”)에 규정된 내용과 다른 내용의 계약(이하 “개별계약”)을 체결할 수 있다. 이 경우 개별계약이 약관 등에 우선하여 적용된다. 회사는 개별계약을 체결한 상담사 회원 및 개인에게 계약내용을 서면(전자문서 포함)교부하거나 별도의 화면에서 확인할 수 있도록 한다.


제4조 (약관 외 준칙)

본 약관에 밝히지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령 및 회사가 정한 서비스의 세부이용지침 등의 규정을 따른다.
회사는 필요하면 서비스에 적용될 사항 및 세부 이용지침(이하 “개별약관”)을 정하여 이를 제3조 제2항의 방법에 따라 공지할 수 있다.
본 약관과 관련하여 회사의 정책 변경, 법령의 제·개정 또는 공공기관의 고시나 지침 등에 따라 회사가 웹사이트의 공지사항 게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성한다.


[제2장 서비스 이용계약]
제1조 (서비스 이용계약의 성립 등)

이용자는 회사가 제공하는 다음 각호 또는 이와 유사한 절차에 의하여 서비스 이용을 신청한다.
서비스 정보의 열람 및 선택
서비스 상세 정보 확인
결제하기 클릭
주문 상품 및 결제금액 확인(환불규정 안내)
주문에 필요한 개인정보 입력
결제 수단 확인
결제 금액 재확인
결제
회원가입
서비스 이용계약은 이용자가 회사가 정한 이용약관에 대하여 ‘동의’를 작성하고 회원가입에 필요한 온라인 신청 양식을 작성하여 서비스 이용을 신청한 후 이용 신청에 대하여 회사가 이를 승낙함으로써 성립한다.
회원이 상담 상품권을 구매하여 실제 상담 서비스를 받기 위하여는 상담 전 준수사항 등을 확인하고 회사가 마련한 소정의 동의서에 동의를 하여야 한다.
회원이 솔루션을 구매하여 실제 이용하기 위하여는 구매 전 약관 내용, 청약철회권이 제한되는 서비스, 배송료 등의 비용 부담과 관련한 내용을 확인하여야 한다.
이용자가 유료 서비스를 결제한 후 어떠한 이유(예: 약관 거부)로 회원가입을 하지 않거나 이용신청의 정정, 취소를 원할 경우 회사는 환불 규정 및 소정의 절차에 따라 이를 진행하여야 한다.
다만 이용자가 유료 서비스를 취소하지 않은 상황에서 어떠한 이유(예: 잔액 부족)로 지불 방법에 요금이 청구될 수 없는 경우 미수금에 대한 책임은 이용자에게 있고, 회사는 이용자가 결제 방법 정보를 업데이트할 때 회사의 결제 방법에 요금을 청구하려고 시도하며 이로 인해 후속 유료 서비스의 시작 날짜 등이 변경될 수 있다.
서비스를 이용하고자 하는 상담사 회원은 실제 거래 진행 또는 서비스 판매를 하기 위해서는 회사가 요청하는 추가 정보를 기재해야 한다.
상담사 회원의 필수기재항목
본인 명의의 계좌
본인 명의의 휴대 전화번호
메일(e-mail) 주소
비밀번호
프로필 이미지
학력
본인의 주민등록증 사본
회사는 위의 필수기재항목 외에도 회원에게 다양하고 유익한 정보를 제공하기 위하여 상담 회원에게 상담분야, 경력 및 자격, 일정 등의 정보를 제공할 것을 요청할 수 있다. 다만, 상담사 회원은 그러한 정보의 제공을 거절할 수 있다.
개인의 기기의 성능, 데이터 통신의 한계로 인한 서비스 이용의 제한에 대한 책임은 이용자에게 있다.


제2조 (이용신청의 승낙과 제한)

회사는 이용자가 온라인 신청 양식의 각 사항을 정확히 기재하고 본 약관에 동의할 경우 기술상 지장이 없는 한 서비스의 이용신청을 승낙한다.
회사는 다음 각 호의 경우에는 이용자의 서비스 이용 신청의 승낙을 거절할 수 있다.
온라인 신청 양식의 기재 사항에 허위, 기재누락, 도용, 오기가 있는 경우
신용정보의 이용과 보호에 관한 법률에 의한 PC통신, 인터넷 서비스의 신용불량자로 등록되어 있는 경우
정보통신윤리위원회에 PC통신, 인터넷 서비스의 불량 이용자로 등록되어 있는 경우
이용 신청자가 본 약관에 의하여 이전에 회원자격을 상실한 사실이 있는 경우(다만 회원자격 상실 후 6개월이 경과한 자로서 회사로부터 회원 재가입 승낙을 얻은 경우는 예외로 한다)
기타 회사가 정한 이용 신청의 요건이 만족되지 않은 경우
회사는 다음 각 호의 경우에는 이용자의 서비스 이용 신청의 승낙을 유보할 수 있다. a) 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우b) 기타 회사의 귀책사유로 이용 신청의 승낙이 곤란한 경우
회사가 본조 제2항과 제3항에 따라 회원가입 신청의 승낙을 거절하거나 유보한 경우에는 팝업창을 통하여 즉시 이용 신청자에게 알린다. 단, 회사의 귀책 사유 없이 이용자에게 알릴 수 없는 경우에는 예외로 한다.
상담사 회원에 대해서 필수 기재 항목에 누락이 있거나 허위 사실 기재가 확인 된 경우, 사전 안내없이 이용에 제한이 있을 수 있다.
회사는 상담사 회원에 대해서는 별도 내부 규정에 따라 이용신청의 승낙과 제한을 적용할 수 있으며, 내부 규정은 상담사 회원이 확인 가능한 웹 또는 모바일 어플리케이션 페이지 내에 공개한다.


제3조 (회원정보와 상담정보)

회원은 언제든지 자신의 개인정보를 열람하고 수정할 수 있다.
회원이 전항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지 않는다.
서비스의 제공 및 이용을 위하여 일반 회원의 개인정보와 상담 내용 등은 회사와 상담사 회원이 공유하며, 개인정보의 수집과 공유항목 등 세부적인 사항은 개인정보 처리방침을 확인한다.
효율적인 상담을 위하여 일반 회원은 상담 전 상담 접수지를 작성 제출하며, 상담 접수 시 수집되는 일반 회원의 정보에는 서비스의 특성상 민감정보가 포함될 수 있다는 사실을 인정한다.
효율적이고도 지속적인 상담을 위하여 일반 회원 개인정보, 접수 정보 그리고 회사가 운영하는 앱이나 인터넷 사이트 등 플랫폼 상의 상담 내용, 일반 회원이 게시한 글이나 사진 등 저작물 등 상담 서비스 관련 모든 정보와 자료는 회사가 보관하면서, 상담사 회원과 공유할 수 있다. 더 나아가 상기 상담 서비스 관련 모든 정보와 자료는 익명화하여 서비스의 제공, 더 나은 서비스의 개발과 상담연구, 통계, 홍보, 빅데이터 활용 내지 연구를 위한 제3자에 대한 양도 등을 위하여 활용될 수 있다는 사실을 인정하고 동의한다.
상담사 회원은 개인정보 중 프로필 사진, 학력 정보, 자격 정보, 경력 정보 등 서비스 운영을 위해 확인이 필요한 정보를 변경한 경우, 해당 정보를 입증할 수 있는 자료를 제출해야 한다. 내부 규정에 적합하지 않은 정보 또는 입증이 불가능한 정보인 경우, 상담사 회원의 활동을 중단할 수 있다.


제4조 (회원의 아이디 및 비밀번호의 관리에 대한 의무)

회원은 아이디와 비밀번호에 대한 모든 관리 책임이 있으며, 이를 제3자에게 제공 또는 공유, 공개하여 제3자가 이용하도록 하여서는 안 된다.
회사는 전항의 경우 회원의 자격을 박탈할 수 있다.
회원은 자신의 아이디 및 비밀번호가 유출되어 제3자에 의해 사용되고 있음을 인지한 경우, 즉시 회사에 알려야 한다.
회사는 전항의 경우 회원의 개인정보보호 및 기타 서비스 부정이용행위 방지 등을 위하여 회원에게 비밀번호의 변경 등 필요한 조치를 요구할 수 있으며, 회원은 회사의 요구가 있는 즉시 회사의 요청에 성실히 응해야 한다.
회사는 회원이 본조 제1항 및 제3항에 따른 의무를 성실히 이행하지 않아 발생한 불이익에 대하여 책임지지 않는다.


제5조 (이용계약의 해지)

일반 회원이 이용계약의 해지를 원할 경우에는 서비스 내의 회원 탈퇴 기능을 통하여 언제든지 회원탈퇴를 신청할 수 있다. 이 경우 회사는 불가피한 사정이 없는 한 일반 회원의 탈퇴 요청을 지체 없이 처리한다.
상담사 회원은 해지의사를 통지하기 전 또는 통지한 후라도 진행 중인 모든 판매 절차를 마무리하고 내부 규정에 따라 최종 계약 해지 과정을 진행한다.
회사는 회원이 본 약관 또는 관계법령 등을 위반하는 경우, 이용계약을 해지할 수 있다.
본 항에 따라 해지를 한 회원은 이 약관이 정하는 회원가입 절차와 관련 조항에 따라 회원으로 다시 가입할 수 있다. 다만 회원이 중복 참여가 제한된 판촉 이벤트 중복 참여 등 부정한 목적으로 회원탈퇴 후 재가입 신청하는 경우 회사가 재가입을 일정기간 동안 제한할 수 있다.
회원탈퇴 시 소진되지 않은 포인트 및 혜택은 권리를 포기한 것으로 간주하여 탈퇴와 동시에 전부 소멸된다.


[제3장 상담 서비스의 이용]
제1조 (서비스의 제공 및 변경)

회사는 이용자에게 서비스에 대한 정보를 제공하며, 구매 계약을 체결할 수 있는 기능을 제공한다.
다만, 유료 상담 서비스는 이용자가 상담 상품권을 구매하고 회원가입을 완료한 후에 실제 상담서비스를 제공받을 수 있다.
서비스의 이용은 연중 무휴 1일 24시간을 원칙으로 한다. 다만, 시스템 점검, 증설과 교체 및 고장 등의 이유로 회사가 정한 기간에는 서비스가 일시 중지될 수 있다. 이 경우 회사는 사전에 사이트에 해당 사실을 공지한다.
회사가 통제할 수 없는 사유로 인하여 서비스가 중단됨으로써 사전 공지가 불가능한 경우에는 사후 공지로 갈음한다.
회사는 서비스가 변경되는 경우 변경되는 서비스 내용 및 제공 일자를 사이트를 통하여 공지하거나 회원에게 통지한다.


제2조 (일반 회원의 권한 및 의무)

일반 회원이 회사가 제공하는 상담 서비스를 이용함에 있어서 상담사 회원으로부터 상담을 받기 위하여는 상담 상품권을 구매하여야 하며, 상담 상품권의 환불에 대하여는 회사가 별도로 공지하는 환불규정을 따르기로 한다.
일반 회원은 관계법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지한 사항을 준수해야 하며, 기타 회사의 업무를 방해하는 행위를 하여서는 안 된다.
일반 회원은 심리상담을 받기 위한 목적으로만 서비스를 이용하여야 하며, 학술적 의문의 해소, 광고, 스토킹, 장난 등의 목적으로 서비스를 이용하여서는 안 된다.
일반 회원은 서비스 이용에 필요한 최소한의 구체적이고 진실한 정보를 제공하여야 하며, 정보 부족 시 서비스의 이용에 제한을 받을 수 있다. 단, 일반 회원 자신과 타인의 개인정보 등을 작성하는 방법 등으로 타인의 명예를 훼손하거나 기타 권리를 침해해서는 안 된다.
일반 회원은 상담사 회원으로부터 받은 상담의 내용을 회사의 사전 서면 승낙 없이 제3자에게 공개하거나 복제ㆍ편집ㆍ전시ㆍ전송ㆍ배포ㆍ판매ㆍ방송ㆍ공연하는 등의 행위를 하여서는 안 된다.
일반 회원은 서비스에 등록된 상담사 회원이 회사의 사용자가 아니라는 사실을 인식하고, 상담사 회원의 귀책사유 있는 불법 내지 위법 행위를 알게 된 경우, 회사에게 해당 사실을 통지하여야 한다. 이 경우 회사는 관련 사실관계를 확인하고, 상담사 회원의 회원 자격박탈 등의 필요한 조치를 취한다.
본조 제6항의 경우, 일반 회원은 상담사 회원에 대한 책임 청구 이외에 회사에 대하여는 상담사 회원의 귀책사유 있는 불법 내지 위법행위에 대한 책임을 물을 수 없다.
의도적으로 상담과 무관한 내용으로 상담을 방해하거나 기타 행위로 상담사에게 불쾌감을 유발할 경우 상담사 회원은 '본 행위가 반복될 경우에 상담을 강제종료할 수 있음'을 알릴 수 있으며, 알림 후 동일한 행위가 재반복되면 상담을 강제 종료할 수 있다. 이 경우 상담 상품권은 환불되지 않는다.
상담 외 솔루션을 구매하는 경우 제 4장 셀프케어 서비스 이용정책을 따른다.


제3조 (상담사 회원의 권리와 의무)

상담사 회원은 일반 회원에게 상담 서비스를 제공할 수 있다.
상담사 회원은 판매하는 서비스의 종류와 범위, 판매가격, 거래조건 등을 회사의 등급 별 기준에 따라 스스로 결정하고, 회사는 이에 부당하게 관여하지 않는다.
상담사 회원은 광고, 브로커 제안, 스토킹, 장난 등 '나만' 서비스의 취지에 부합하지 않는 목적으로 상담을 요청하는 일반 회원에 대하여 회사 또는 경찰 등 유관기관에 신고할 수 있다.
상담사 회원은 회사의 요청이 있는 경우, 학력 및 심리 상담 관련 자격증 정보 등의 최소한의 정보를 공개해야 한다.
상담사 회원은 회사에게 전항의 정보를 전달함에 있어 진실에 부합하게 하여야 하고, 회사가 관련 증명 서류를 요청할 경우 이에 응하여야 한다.
상담사 회원은 상담 업무 제공과 서비스 플랫폼 운영을 위하여 이름, 사진 등 개인정보가 회사의 앱이나 인터넷 사이트 등 플랫폼 상에서 공개될 수 있고, 더 나아가 회사의 홍보를 위하여 상담사 회원이 동의한 개인정보 항목에 대해 매체 혹은 외부에 소개될 수 있다.
상담사 회원은 회사가 제공하는 서비스의 지적재산권을 침해하는 행위를 하여서는 아니된다.
상담사 회원은 회원과의 상담 내용에 대하여 비밀준수의무를 지며, 본 항의 의무는 이용계약 해지 이후에도 유지된다.
상담사 회원은 상담 활동을 진행할 때 상담사의 윤리강령을 준수해야만 하며, 윤리강령은 사단법인 한국상담심리학회 윤리강령을 준용한다.
상담사 회원은 상담 업무 이행 과정에서 회사의 앱이나 인터넷 사이트 등 플랫폼 상에서 제공 받는 등 공유하거나 취득하게 된 회사 내지 일반 회원의 모든 정보를 상담 업무 이행을 위해서만 활용하고, 다른 목적으로 사용하지 않는다.
상담사 회원은 서비스 내에서 활동함에 취득한 정보에 대해서는 회사의 서면 동의 없이 제3자에게 누설하거나 타 목적에 이용해서는 아니되며, 본 항의 의무는 이용계약 해지 이후에도 유지된다.
상담사 회원은 상담 진행 시 일반 회원과 미리 정한 예약 시간을 준수하여 약속한 횟수와 시간에 따라 상담을 진행하여야 한다.
상담사 회원은 일반 회원의 상담 신청 시점으로부터 최대 24시간 내에 상담방 내의 메시지 등으로 응해야 하며, 지정된 시간 내 의사표시가 없을 경우 별도의 내규에 따른 처리가 이루어질 수 있다.
상담사 회원은 일반 회원의 개인정보 내지 상담 정보를 무단으로 수집•저장•공개하는 행위, 일반 회원의 명예를 손상하거나 불이익을 주는 행위를 하여서는 안 된다.
상담사 회원은 사전 동의 없이 오프라인 상담이나, 다른 온라인 상담 서비스 등 회사가 제공하는 서비스 이용방법 이외의 방법으로 일반 회원에게 심리상담 및 기타 서비스를 제공하거나 제안하는 행위를 하여서는 안 된다.
상담사 회원은 일반 회원의 요청이 있을 때 회사의 사전 동의를 얻은 후 오프라인 상담이나 기타 기관을 추천해줄 수 있다.
상담사 회원은 이 약관과 회사가 서비스 화면을 통하여 미리 공지하는 약관 등이 정하는 절차에 따라 신원정보의 제공, 개인 프로필 정보의 등록, 판매서비스권 등록, 거래진행과정의 관리, 회원에 대한 상담 이행, CS 등의 사후처리를 수행해야 한다.
회사는 상담사 회원이 등록한 프로필의 판매 촉진 및 신뢰도 상승을 위해 서비스의 메인 이미지 또는 프로필 정보 등에 대한 등록 기준을 마련하여 상담사 회원에게 수정을 요청할 수 있으며, 상담사 회원은 이에 동의해야 한다. 만일 이에 동의하지 않을 경우 회사는 상담사 회원의 서비스 활동을 제한할 수 있다.
상담사 회원은 상담권 판매로 발생한 수익금을 회사와 배분한다. 수익 배분율은 내부 규정을 따르며, 상담사에 따라 개별계약이 있을 수 있다.
회사는 상담사 회원에게 수익금에 대한 정산자료를 제공하며, 정산내역에 대한 정정기간을 충분히 제공한다. 정산자료에 대한 이의제기, 정정 및 취소요청, 입금 계좌 변경 등은 정산자료 통보일에 안내된 수정 가능 기간에 한하여 가능하며, 회사의 과실로 인한 건이라도 해당 수정 가능 기간을 초과한 요청에 대해서는 정정이 불가하거나 다음 정산에 처리될 수 있다. 상담사 회원의 지급정보가 불충분하여 정산이 이뤄지지 않은 경우, 수입이 발생한 시점으로부터 3개월이 경과하면 해당 수입에 대한 회사의 지급의무가 소멸된다. 상담사 회원이 3개월 이내 지급정보를 입력하고 수입에 대한 정산을 청구할 수 있으며 이 경우 정산은 회사의 일정에 따라 지급된다.
본 약관에 명시되지 않은 내용은 관련 법령 및 회사 내규를 따른다.


제4조 (회사의 권리와 의무)

회사가 제공하는 서비스 및 콘텐츠에 대한 지적재산권은 회사에 귀속된다.
회원과 상담사 회원이 회사에 제공한 개인정보, 접수정보, 상담내용, 상담을 위하여 게시한 글과 사진 등 저작물과 기타 자료 등은 모든 자료와 정보를 익명화하여 회사가 서비스 제공, 홍보, 연구, 통계 등의 목적으로 활용할 수 있다, 활용 범위에 관해서는 저작권 정책(5장)의 내용에 따른다.  회사가 취득한 정보와 저작물 기타 자료 등의 이용기간은 특별한 사유가 없는 한 영구적으로 한다.
회사는 서비스의 향상과 신규 서비스 개발, 심리 상담 관련 연구 등을 위하여, 일반 회원의 상담요청 내용, 상담사 회원의 상담 내용 등 서비스 제공 과정에서 작성된 모든 자료와 정보를 수집하고, 익명화하여 통계나 감정 분석 연구 내지는 심리상담 솔루션 개발에 활용할 수 있다.
회사는 회원이 받는 서비스 질의 향상과 사이트의 홍보를 위하여 서비스에서 작성된 이용 리뷰 등의 컨텐츠를 포털사이트 등과의 제휴와 협약 등을 통해 전송하거나 게시할 수 있다.
회사는 본 약관 및 관계법령에 따라 서비스를 제공하여야 하며, 다음 각 호의 사유가 발생한 경우를 제외하고 계속적, 안정적으로 서비스를 제공하기 위하여 최선의 노력을 다한다.
서비스용 설비의 보수, 정기점검 또는 공사로 인한 부득이한 경우
전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우
전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우
설비장애 또는 이용 폭주 등으로 인하여 서비스 이용에 지장이 있는 경우
회사는 상담사 회원이 본 약관 제12조 제3항에 따라 신고하면, 신고 내용을 확인하고 필요한 조치를 취할 수도 있다. 단, 상황에 따라서 회사는 아무런 조치를 취하지 않을 수도 있다.
회사는 각 회원과 관련된 서비스 이용 방법, 서비스 개편에 대한 내용을 제공 및 공지한다.
회사의 구상권 내지 상담사 회원에 대한 채권 발생 우려, 상담사 회원의 채권자의 채권 추심 등 기타 지급을 유예할 정당한 사유가 발생한 경우, 회사는 상담사 회원에게 이러한 사실을 통보하고, 지급을 유예할 수 있다.
회사는 일반 회원 및 상담사 회원의 과실로 서비스 내용의 유출이 있을 경우, 발생한 분쟁을 해결하기 위해 피해자 및 사법기관을 포함한 공공기관에서 일반 회원 및 상담사 회원의 문제 관련 정보 요청 시 즉시 제공한다.


제5조 (회원의 개인정보 보호)

회사는 개인정보보호와 관련된 자세한 사항을 개인정보 처리방침으로 정하고 이를 전자적 표시 형태로 제공하여야 한다.
회사는 회원의 개인정보에 대하여 철저한 보안을 유지하여야 하며 서비스 운영 및 개선에만 사용하여야 하고, 이외의 목적으로 타 기관 및 개인에게 제공하지 않는다. 다만 다음 각 호에 해당하는 경우에는 그렇지 않다.
관계법령에 의해 수사상의 목적으로 관계기관으로부터 요청이 있는 경우
정보통신윤리위원회의 요청이 있는 경우
기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
회사는 회원의 개인정보 관리를 위하여 회원의 개인정보를 제3자에게 위탁할 수 있다.
회사는 회원의 귀책 사유로 인하여 노출된 개인정보에 대하여서는 일체의 책임을 지지 않는다.
회사는 상담사 회원에게 제공 공유된 일반 회원 정보에 관하여 계약 해지 또는 회원자격 박탈 시점에 따라 일반 회원의 모든 개인정보와 상담내용 등 자료를 제출 받거나 폐지하도록 요구한다.


[제4장 저작권 정책]
제1조 (서비스 게시물의 등록 및 관리)

회원은 약관에서 정하는 범위 내에서 자율적으로 게시물을 작성할 수 있다
회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야 한다.
회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있다.
회사는 게시물에 대하여 제3자로부터 명예훼손, 지적재산권 침해 등의 이유로 게시중단 요청을 받은 경우 이를 임시로 게시중단 및 삭제할 수 있다.
회사는 회원이 게시하거나 전달하는 서비스 내의 모든 게시물(텍스트, 이미지, 동영상, 각종 파일, 링크, 댓글, 답글 등을 모두 포함)이 다음 각 호 중 하나에 해당한다고 판단되면 사전 통지 없이 삭제할 수 있으며, 이에 대해 회사는 어떠한 책임도 부담하지 않는다.
음란물, 욕설 등 공공질서와 미풍양속에 위반되는 내용
범죄행위와 관련이 있다고 판단되는 내용
자살을 목적으로 하거나 이를 미화, 방조 또는 권유하여 자살 충동을 일으킬 우려가 있는 내용
회사, 타인의 저작권 등 지적재산권을 침해하는 내용
타인과의 분쟁을 일으킬 수 있는 내용으로, 이러한 분쟁으로 인하여 회사의 업무가 방해되거나 방해되리라고 판단되는 경우
타인의 개인정보, 사생활을 침해하거나 비방, 허위사실 등으로 명예를 손상시키는 내용
동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 내용
불필요하거나 승인되지 않은 광고, 판촉물의 내용
관리자 사칭 등 커뮤니티 서비스를 본 목적에 어긋나게 사용하는 경우
기타 관계법령에 위반된다고 판단되는 내용


제2조 (서비스 게시물 등의 저작권)

'나만' 서비스에서 회원이 작성한 모든 게시물은 법적인 저작물로서 저작권의 보호 대상이 된다.
타인의 지적 재산권에 속하는 대상물을 '나만' 내에서 이용할 경우에는 반드시 해당 권리자의 사전 동의를 받아야 한다.
회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속된다. 다만, 회사는 저작물을 무상으로 영구히 이용할 수 있다.
본조 제1항에도 불구하고, 일반 회원과 상담사 회원이 상담과정에서 작성하는 상담내용과 게시하는 글과 사진 등 저작물의 저작권 내지 저작물의 이용권은 회사에 무상으로 영구히 귀속되고, 모든 자료와 정보를 익명화하여 수집하여 심리상담 및 정신건강 관련 연구, 통계, 솔루션 개발에 활용할 수 있다. 더 나아가 빅데이터 활용 내지 연구를 위해 제3자에게 재이용권을 허락할 수 있다는 사실을 인정하고 동의한다.
회원이 서비스 내에 게시하는 게시물의 내용은 포털사이트 등 인터넷의 검색 결과 내지 서비스 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 편집되어 게시될 수 있다. 또한 약관에 의해 포털사이트 등에서 운영하는 타 서비스 등에 전송되거나 게시될 수 있다.


제3조 (회원 및 제3자의 권리 보호)

회원은 타인의 저작권을 포함한 지적 재산권 및 인격권 등 기타 권리를 침해해서는 안 된다. 만일 이와 같은 내용의 게시물로 인해 발생하는 결과에 대한 모든 책임은 회원 본인에게 있다.
저작권자의 명시적 동의 없이 타인의 저작물의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로 이용하는 것은 저작권자의 권리를 침해하는 행위임으로 복제 등의 방법으로 타인의 저작권을 침해한 게시물을 서비스에 게재 또는 등록하여서는 안 된다.
회원은 공표된 저작물의 보도·비평·교육·연구 등을 위하여 정당한 범위 안에서 공정한 관행에 합치되게 이를 인용할 수 있으며, 반드시 출처를 명시해야 한다. 하지만 인용이 저작자의 저작 인격권을 침해하는 것이 되어서는 안 되며 이러한 경우 출처를 밝히더라도 저작권 침해에 해당될 수 있다.
회원의 게시물에 대하여 제3자로부터 저작권 및 기타 권리의 침해로 소송 및 기타 법률적 이의가 제기된 경우 해당 회원은 상담 비용을 포함하여 권리침해로 야기된 제반 법률 문제를 해결하기 위한 비용을 부담하는 등 기타 조치로 회사를 보호할 의무가 있다.
회원의 게시물에 대하여 제3자로부터 저작권 및 기타 권리의 침해로 이의가 제기된 경우 회사는 당해 게시물을 임시 삭제할 수 있으며, 이의를 제기한 자와 게시물 등록자 간에 소송, 합의 등을 통해 당해 게시물에 관한 법적 문제가 종결된 후 이를 근거로 회사에 신청이 있는 경우에만 상기 임시 삭제된 게시물은 다시 등록될 수 있다.
회원은 서비스를 이용함으로써 얻은 정보 내지 상담 내용을 외부에 공개하거나 회사의 사전 승낙 없이 복제, 송신, 출판, 전송, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 해서는 안 된다.
회사의 서비스에 게재 또는 등록된 회원의 게시물을 제3자가 사용하고자 할 경우에는 반드시 해당 회원의 명시적 동의를 얻은 뒤 사용해야 한다. 단, 약관에 따라 이용 리뷰 등의 콘텐츠가 네이버, 다음 등의 포털사이트 등에 전송, 게시되는 경우에는 그러하지 아니하다.


[제 5장 포인트 정책]
제1조 (포인트 적립 및 이용)

회사는 회사에서 정한 정책에 따라 지정한 솔루션 이용을 완료한 회원에게 포인트를 부여할 수 있다.
포인트 적립 기준은 회사의 내부 방침에 따라 임의로 변경될 수 있으며, 회사는 회원에게 사이트, 모바일 앱을 통하여 변경내용을 별도로 게시하여 통지한다.
회원은 회사의 유상 솔루션 구매 시 다른 결제 수단과 함께 포인트를 사용할 수 있다. 다만, 포인트 사용조건은 유상 솔루션별로 다를 수 있고, 회사의 내부 방침에 따른다.
회원은 본인이 소유한 포인트를 타인에게 양도, 대여 또는 담보의 목적으로 이용할 수 없다.
회원은 적립된 포인트를 금전 등 다른 지급수단의 형태로 교환 또는 환불해 줄 것을 요청할 수 없다.
회원은 다른 회원이 적립한 포인트를 부정하게 이용하여서는 아니 되며, 회사는 포인트에 대한 적법한 권리가 있는 회원으로부터 부정이용 신고 등을 접수한 경우에는 즉시 이를 해결하기 위한 조치를 한다.
부정한 방법으로 포인트를 획득한 사실이 확인될 경우 회사는 회원의 포인트 회수, 계정 삭제 및 형사 고발 등 조치를 취할 수 있다


제2조 (포인트 정정)

적립된 포인트에 오류가 있을 경우 회원은 오류발생 시점부터 7일 이내 회사에 정정 신청을 하여야 하며, 회사는 회원의 정정 신청일로부터 최대 30일 이내에 조치를 한다.
클라이언트 변경, 해킹, 매크로 등 부정한 방법으로 포인트를 적립하신 경우, 부정 적립이 되어 그 포인트는 0으로 초기화 된다.


제3조 (포인트 유효기간 및 소멸)

포인트의 유효기간은 적립된 날로부터 2년이다. 다만, 회사가 별도로 유효기간을 정한 때에는 이에 따른다. 위 유효기간 내에 사용되지 않은 포인트는 적립된 날을 기준으로 차례로 소멸된다.
전항에도 불구하고 회원이 다음 각 호의 사유에 해당하는 경우, 포인트가 각 호에서 정한 바에 따라 소멸된다.
회원탈퇴로 인한 포인트 소멸 : 회원탈퇴 요청일엔 현재까지 적립된 포인트는 사용이 불가하며, 적립된 포인트의 유효기간 만료 이내에 재가입하더라도 복원되지 않는다.
자격 상실로 인한 포인트 소멸 : 적립된 포인트는 복구가 불가한 상태로 즉시 소멸되며, 적립된 포인트의 유효기간 만료 이내에 재가입하더라도 복원되지 않는다.
회사는 유효기간 만료에 따른 소멸예정 포인트, 소멸시기 등 포인트 소멸과 관련된 내용을 회원이 모바일 앱에서 확인할 수 있도록 조치한다.


[제 6장 회원자격 박탈 및 분쟁의 조정]
제1조 (회원자격의 박탈)

회사는 다음 각 호의 경우 회원의 서비스 이용기간을 제한하거나 회원자격을 예고 없이 박탈할 수 있다.
회원의 개인신상정보가 사실과 다를 경우
타인에게 ID를 양도, 대여한 경우
다른 이용자의 ID를 부정 사용한 경우
서비스를 통하여 취득한 다른 회원의 정보를 수집, 저장, 양도하거나 중개에 이용한 경우
불법 정보, 유해 정보 또는 회사로부터 허가 받지 않은 광고, 브로커 제안, 장난글 등을 게시한 경우
서비스를 통해 진행한 상담의 내용을 회사 및 일반 회원 또는 상담사 회원의 사전 승낙없이 복제ㆍ편집ㆍ전시ㆍ전송ㆍ배포ㆍ판매ㆍ방송ㆍ공연하는 등의 행위를 한 경우
회사 직원, 운영자 등을 포함한 타인을 사칭한 경우
서비스의 운영에 지장을 주거나 줄 우려가 있는 행위를 한 경우
본 약관을 포함하여 기타 회사가 정한 규정 또는 이용조건을 위반한 경우
직거래 또는 직거래 유도행위를 시도할 경우
성적인 대화, 협박, 욕설, 스토킹 등 다른 회원을 괴롭히는 행위를 한 경우
컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 컴퓨터 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자 우편으로 배포하는 행위를 한 경우
상담사 회원이 사유를 전달 하지 않은 상태로 2달 이상 미접속 하는 경우
상담사 회원이 회사와 협의없이 이용자에게 타 서비스를 안내한 경우
기타 법령에 위배되는 행위를 한 경우
기타 내규에 위배되는 행위를 한 경우
회사는 회원의 서비스 이용 권한을 일정 기간 제한하거나 이용 권한을 박탈한 경우, 회원에게 시정 또는 소명 기회를 부여할 수 있다. 회원의 이의신청이 정당한 경우 회사는 즉시 회원이 서비스를 이용할 수 있도록 필요한 조치를 취하여야 한다.
회원이 회원자격을 상실할 경우 보유한 포인트, 혜택은 전부 소멸된다.


제2조 (면책조항)

회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없거나 제공이 지연된 경우에는 서비스 제공에 관한 책임이 면제된다.
회사는 회원의 귀책사유로 인하여 발생한 서비스 이용의 장애에 대해서는 책임을 지지 않는다.
회사는 일반 회원이 서비스와 관련하여 게시 또는 제공한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 책임을 지지 않는다.
회사는 상담사 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 상담사 회원이 기존에 회사에 제출한 서면자료가 위조, 변조되거나 기타 사유로 회사가 상담사 회원의 정보가 허위임을 인지하지 못한 경우에 대하여는 책임을 지지 않는다.
회사는 회원에게 플랫폼을 제공하는 역할을 담당하며, 상담사 회원의 상담 내용에 대한 진위 등을 판단할 수 없다. 또한 일반 회원과 상담사 회원 간의 상담을 진행하는 과정에서 발생한 분쟁에 대해서는 개입할 수 없고 이로 인한 손해를 배상할 책임이 없다.
회사는 회원 상호간 또는 회원과 제3자 간에 콘텐츠를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않는다.
본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하는 경우, 해당 위반자는 회사에 발생되는 모든 손해를 배상하여야 하며, 동 손해로부터 회사를 면책 시켜야 한다.


제3조 (분쟁의 해결)

회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 서로 성실하게 노력하여야 한다.
회사가 제공하는 서비스는 합법적인 목적으로만 사용되어야 하며, 대한민국 법령에 반하는 형태의 사용은 금지된다. 회원의 금지된 형태의 사용으로 인하여 손해가 발생할 경우 회사는 이에 대하여 책임을 지지 않는다.
회사와 회원 사이에 발생한 소송의 관할법원은 회사의 본사 소재지 관할 법원으로 한다.
회사와 회원 사이에 발생한 소송에는 대한민국 법령을 적용한다.


제4조 (링크)

회사는 회원에게 타인의 웹사이트 또는 자료에 대한 링크를 제공할 수 있다. 회사는 해당 웹사이트나 자료의 내용 등에 대하여 책임이 없으며, 회원이 자발적으로 이를 이용하거나 신뢰함으로 인하여 야기된 손해에 대하여 책임을 지지 않는다.



공고일자: 2024년 3월 4일

시행일자: 2024년 3월 4일
`;


export default infocounselor;