import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6" style={{display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', marginBottom: '3rem'}}>
            {" "}
            <img src="img/logo.jpg" className="img-responsive" alt=""/>{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>"나만"</h2>
                <p>우리는 일상 속에서 다양한 고민과 스트레스를 받으며 살고 있지만,<br />
                속 이야기까지 털어 놓기는 쉽지 않습니다.</p>

                <h4>Why Choose Us?</h4>
                <p>리상 컴퍼니에서는 <br />
                '나만'의 공간에서<br />
                '나'를 '만'나는 시간<br />
                [나만] 서비스를 제공합니다.</p>

                <h4>What's the difference?</h4>
                <p>대면과 비대면 방식의 강점을 활용하고, 약점을 보완한<br />
                "메타버스 상담실"을 활용합니다.<br />
                사용자는 내가 가장 편안한 시간에 편안한 장소에서<br />
                전문가를 만나 서비스를 이용할 수 있습니다.</p>

                <h4>Objective of Naman</h4>
                <p>[나만] 서비스의 궁극적인 목표는<br />
                일상 속 고민과 스트레스를 슬기롭게 극복하여<br />
                우리가 함께 행복해지는 것입니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
