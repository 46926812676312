import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Post.css';

const PostCreate = () => {
  const navigate = useNavigate();
  const [Titleinput, setTitleinput] = useState('');
  const [Contentinput, setContentinput] = useState('');
  const [Nicknameinput, setNicknameinput] = useState('');

  // 업로드 당시 날짜로 게시글 번호 만들기
  const currentDate = new Date();
  const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
  const postnum = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
  const postDateTime = currentDate.toLocaleString('ko-KR', options);

  useEffect(() => {
    document.title = "게시글 작성";
  }, []);

  const handletitlechange = (value) => {
    setTitleinput(value);
  }

  const handlecontentchange = (value) => {
    setContentinput(value);
  }

  const handlenicknameinputchange = (value) => {
    if (value.length > 10) {
      // toast 메시지를 표시하고 입력 칸을 비움
      toast.error('10글자 이하로 입력해 주세요.');
      setNicknameinput('');
    } else {
      // 상태 업데이트
      setNicknameinput(value);
    }
  }

  const sendPostCommunity = async () => {
    if (!Titleinput | !Contentinput | !Nicknameinput) {
      toast.error("내용을 입력해 주세요."); // toast로 오류 메시지 표시
      return;
    }
    try {
      await axios.post('/api/inputcommunity', {
        postnum: postnum,
        nickname: Nicknameinput,
        title: Titleinput,
        content: Contentinput,
        writedate: postDateTime,
        view: 0
      });
      toast.success('게시물이 성공적으로 작성되었습니다!');
      navigate(`/postView/${postnum}`);
    } catch (error) {
      toast.error('게시물 작성 중 오류가 발생하였습니다.');
    }
  }

  return (
    <div>
      <div className="page_p_bold2">글 작성</div>
      <div className='postviewcontainer'>
        <div className="post-view-wrapper">
          <div className='create-nickname'>
            <label className='createnicknameinputlabel'>닉네임</label>
            &nbsp;
            <textarea type='text' className='createnicknameinput' value={Nicknameinput} onChange={(e) => handlenicknameinputchange(e.target.value)} />
          </div>
          <div className='create-title'>
            <label className='createtitleinputlabel'>제목</label>
            &nbsp;
            <textarea type='text' className='createtitleinput' onChange={(e) => handletitlechange(e.target.value)} />
          </div>
          <textarea className='create-content-input' onChange={(e) => handlecontentchange(e.target.value)} />
          <button className="create-post-button" onClick={sendPostCommunity}>작성 </button>
        </div>
        <br />
        <ToastContainer />
      </div >
      <br /><br /><br /><br /><br /><br /><br /><br /><br />
    </div >
  );
}



export default PostCreate;