import React, {useEffect ,useState } from 'react';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, CardContent, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './review.css';
import BlankCard from '../shared/BlankCard';
import { FaStar, FaRegStar } from 'react-icons/fa';



const Add_review = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  
  const [Contentinput, setContentinput] = useState('');
  

  // 업로드 당시 날짜로 리뷰 번호
  const currentDate = new Date();
  const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
  const postnum = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
  const postDateTime = currentDate.toLocaleString('ko-KR', options);

  const { // 예약 데이터 전달받기
    res_id,
    counselor_name,
    counselor_id,
    time,
    total_time,
    date,
  } = useLocation().state;

   
 // 별점
  const [rating, setRating] = useState(0);
  const handleClick = (value) => {
      setRating(value);
  };
  
// 내용
  const handlecontentchange = (value) => {
    setContentinput(value);
  }

  const createreview = async () => {
    if (rating === 0) {
      toast.error("별점을 입력해주세요.");
      return;
    }

    if (!Contentinput) {
      toast.error("내용을 입력해 주세요."); // toast로 오류 메시지 표시
      return;
    }

    try {
      await axios.post('/api/createreview', {
        num_review: postnum,
        res_id : res_id,
        counselor_id: counselor_id,
        counselor_name : counselor_name,
        rating: rating,
        content: Contentinput,
        writedate: postDateTime,
        date : date,
        time : time,
        total_time : total_time

      });
      toast.success('리뷰가 성공적으로 작성되었습니다!');
      navigate(`/Mypage/client/review`);
    } catch (error) {
      toast.error('리뷰 작성 중 오류가 발생하였습니다.');
    }
  }


  return (
    <div>
      <DashboardCard title="후기 작성">
        <ToastContainer />
          <Grid container spacing={3}>
            <Grid item sm={4} sx={{ textAlign: 'left', marginTop : '10px' }}>
              <Paper elevation={10}>
                <BlankCard>
                  <CardContent>
                    <Typography sx={{ fontSize: "13.5px", marginTop : '5px' }}>
                      <span style={{ fontWeight: 700 }}>예약 ID :</span> {res_id}
                    </Typography>
                    <br/>
                    <Typography sx={{ fontSize: "13.5px", marginTop : '5px' }}>
                      <span style={{ fontWeight: 700 }}>상담사 :</span> {counselor_name} 상담사
                    </Typography>
                    <br/>
                    <Typography sx={{ fontSize: "13.5px", marginTop : '5px' }}>
                      <span style={{ fontWeight: 700 }}>상담일시 :</span> {date}
                    </Typography>
                    <br/>
                    <Typography sx={{ fontSize: "13.5px", marginTop : '5px' }}>
                      <span style={{ fontWeight: 700 }}>상담 시간 :</span> {time}({total_time}시간)
                    </Typography>
                  </CardContent>
                </BlankCard>
              </Paper>
            </Grid>
            <Grid item sm={4} sx={{ textAlign: 'left', marginTop : '10px' }}>
            {[...Array(5)].map((_, index) => (
              <span
                key={index}
                onClick={() => handleClick(index + 1)}
                style={{ cursor: 'pointer', fontSize: '24px', color: index < rating ? 'gold' : 'grey'  }}
              >
                {index < rating ? <FaStar /> : <FaRegStar />} {/* 별 아이콘 */}
              </span>
            ))}
            <br/> &nbsp; <p>별점: {rating}점</p>
          </Grid>
          </Grid>
          <br/><br/>
          <textarea 
          className='review-content-input' 
          onChange={(e) => handlecontentchange(e.target.value)} 
          value={Contentinput}
          style={{
            width: '100%',
            height: '150px',
            padding: '10px',
            fontSize: '14px',
            lineHeight: '1.5',
            border: '1px solid #ddd',
            borderRadius: '4px',
            boxSizing: 'border-box',
            resize: 'vertical',
            whiteSpace: 'pre-wrap'
          }}
        />
          <br/><br/><br/>
          <Button variant="contained" 
                  style={{ 
                          backgroundColor: "#5D87FF", 
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          marginLeft: '5px'
                          }}  
                          onClick={createreview}> 
                    작성
            </Button> 
        </DashboardCard>

      
    </div >
  );
}



export default Add_review;