import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CommonTable from '../Table/CommonTable';
import CommonTableColumn from '../Table/CommonTableColumn';
import CommonTableRow from '../Table/CommonTableRow';
import axios from 'axios';
import './Post.css';
import { useAuth } from '../../../AuthContext.js';
import Pagination from '@mui/material/Pagination';

const PostList = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [communityList, setCommunityList] = useState([]);

  const { isLoggedIn } = useAuth(); // 로그인 확인

  const goTocreate = () => {
    navigate("/PostCreate");
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    document.title = "커뮤니티";
  }, []);

  useEffect(() => {
    const fetchCommunitylist = async () => {
      try {
        const response = await axios.get('/api/communitylist', {
          headers: {
            'Cache-Control': 'no-store'
          }
        });
        let sortedCommunityList = response.data.communitylist.sort((a, b) => b.listnum - a.listnum);
        setCommunityList(prevCommunityList => sortedCommunityList);
      } catch (error) {
        console.error('게시글 정보 가져오기 오류:', error);
      }
    }

    fetchCommunitylist();
  }, []);

  function formatDateString(dateString) {
    const dateParts = dateString.split('. ');
    const year = dateParts[0].slice(2);
    const month = dateParts[1].padStart(2, '0');
    const day = dateParts[2].slice(0, 2).padStart(2, '0');

    return `${year}. ${month}. ${day}`;
  }

  const itemsPerPage = 15;

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentPosts = communityList.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className='postlistbox' style={{ backgroundColor: "#f4f7fa" }}>
      <div className='comunitytitle'>
        <h3>커뮤니티</h3>
      </div>
      <div className='posttablebox'>
        <div className='common-table'>
          <div className='common-table-header'>
            <div className='common-table-column communitylistindex'>글번호</div>
            <div className='common-table-column communitylisttitle'>제목</div>
            <div className='common-table-column communitylistnickname'>작성자</div>
            <div className='common-table-column communitylistdate'>등록일</div>
            <div className='common-table-column communitylistview'>조회수</div>
          </div>
          {
            communityList ? currentPosts.map((item, index) => {
              const adjustedIndex = communityList.length - ((currentPage - 1) * itemsPerPage + index);
              return (
                <div className='common-table-row' key={index}>
                  <div className="common-table-column communitylistindex">{adjustedIndex}</div>
                  <div className="common-table-column communitylisttitle">
                    <Link
                      to={`/postView/${item.postnum}`}
                      onClick={() => {
                        axios.post('/api/communityview', { postnum: item.postnum });
                      }}
                    >
                      {item.title}
                    </Link>
                  </div>
                  <div className="common-table-column communitylistnickname">{item.nickname}</div>
                  <div className="common-table-column communitylistdate">{formatDateString(item.writedate)}</div>
                  <div className="common-table-column communitylistview">{item.view}</div>
                </div>
              );
            }) : ''
          }
        </div>
      </div>
      <div className='communicatebutton'>
        {isLoggedIn && (
          <button className="common-button" onClick={goTocreate}>글 작성</button>
        )}
      </div>
      <div className='pagenationcomponent' style={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(communityList.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    </div>
    
  );
}
export default PostList;
