import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, Paper, Button, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Cou_community = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postResponse = await axios.get('/api/Checkpost');
        const postData = postResponse.data.postdata;
        setPost(postData);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, []);

  const handleButtonClick = (postnum) => {
    navigate(`/postView/${postnum}`);
  };

  return (
    <PageContainer title="커뮤니티 활동">
      <DashboardCard title="커뮤니티 활동"
        action={
          <Typography sx={{ fontSize: "14.5px", fontWeight: "700" }}>
            <Link
              to="/Mypage/counselor/community/comment"
              style={{ fontWeight: 700 }}>댓글</Link>
          </Typography>
        }>
        <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: "left" }}>
          {"<작성 글>"}
        </Typography>
        <br /><br />

        <Grid container spacing={3}>
          {post.map((postItem) => (
            <Grid item xs={12} sm={6} lg={4} key={postItem.postnum}>
              <Paper elevation={5}>
                <DashboardCard>
                  <TableContainer style={{ maxHeight: 300 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                fontSize: "13.5px",
                                fontWeight: "400",
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '100px' // 원하는 최대 너비 설정
                              }}
                            >
                              제목 : {postItem.title}
                            </Typography>
                            <br />

                            <Typography
                              sx={{
                                fontSize: "13.5px",
                                fontWeight: "400",
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '100px' // 원하는 최대 너비 설정
                              }}
                            >
                              내용: {postItem.content.length > 100 ? postItem.content.slice(0, 100) + '...' : postItem.content}
                            </Typography>
                            <br />

                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                              작성일시 : {postItem.writedate}
                            </Typography>
                            <br />

                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                              조회 수  : {postItem.view}
                            </Typography>
                            <br />

                            <Button variant="contained"
                              style={{
                                backgroundColor: "#5D87FF",
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '9px',
                                marginLeft: '-2px',
                                marginTop: '10px'
                              }}
                              onClick={() => handleButtonClick(postItem.postnum)}
                            >
                              자세히 보기
                            </Button> &nbsp;


                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </DashboardCard>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
};

export default Cou_community;
