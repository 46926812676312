
const infoContent = ` \n [전체] 개인정보 처리방침

1. 개인정보 처리방침의 수립과 공개
‘나만’은 리상컴퍼니(이하 ‘회사’라 한다)가 운영하는 정신건강관리 플랫폼입니다. 회사는 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등에 따라 이용자의 개인정보를 보호하고 하고, 개인정보와 관련하여 회사와 이용자간의 권리 및 의무 관계를 규정하기 위해 개인정보 처리방침을 수립/공개합니다.


2. 수집되는 개인정보
회사는 회원 종류에 따라 다음의 개인정보를 필수 및 선택 항목으로 회원의 동의를 받아 수집하고 있습니다. (다만, 가명 정보는 개인정보 보호법의 관련 규정에 따름)

일반 회원 SNS 회원가입 시 제휴사에서 제공하는 정보 수집/이용 항목
구분 제휴사 제공 항목 트로스트 이용 항목 속성
제목 없음 필수 선택	
카카오 회원으로 가입 고유 아이디, 닉네임, 프로필 사진, 전화번호	이메일	고유 아이디이메일전화번호
네이버 회원으로 가입 고유 아이디, 별명, 프로필사진, 성별, 이메일, 연령대	
고유 아이디이메일전화번호
페이스북 회원으로 가입	고유 아이디, 프로필사진, 성명, 성별, 생일(생년제외), 기타 공개 정보, 전화번호	이메일	고유 아이디이메일전화번호
애플 회원으로 가입	고유 아이디, 성명	이메일	고유 아이디이메일전화번호
서비스 이용을 위한 수집/이용 항목
필수 항목 : 이메일 주소, 비밀번호, 닉네임, 휴대전화번호
선택 항목: 성별, 나이, 직업 종류

서비스 구매 시 수집/이용 항목
구매 및 결제 내역 및 결제 방법에 따른 아래 각 항목
신용카드: 카드사명, 카드번호, 유효기간, CVC, 비밀번호 앞 2자리. 생년월일 (또는 사업자 등록번호)
핸드폰 : 전화번호, 통신사
무통장 입금 : 입금자명, 은행명

상담사와의 상담 진행시 상담의 특성상 개인의 건강 및 가치관, 신념 등에 해당하는 개인정보보호법상 민감정보가 수집될 수 있습니다. 원하지 않으실 경우 개별 항목에 대한 응답 여부를 선택할 수 있습니다.
선택항목: 상담경험, 신경정신과 경험, 신체적·정신적 호소 문제, 종교, 학력, 생활 수준, 병역사항, 결혼경력, 가족사항 등
이외에도 서비스 이용 과정에서 서비스 이용 기록, 기기정보(앱 버전, OS 버전), ADID/IDFA(광고식별자) 등의 정보가 수집될 수 있습니다.

상담사 회원
상담사 회원의 회원가입은 웹페이지에서 가능하며, 가입 시에 아래와 같은 정보가 수집됩니다.
선택 항목 : 경력정보, 자격정보, 종교, 현거주지역 등
서비스 가입 이후 상담료 정산을 위해 아래와 같은 정보가 수집됩니다.
필수 항목 : 본인 명의의 계좌 은행명, 계좌번호, 신분증 사본 등
회사는 상담사의 학력, 경력, 자격 등 변동될 수 있는 정보 중 서비스 운영상 필요하다고 판단하는 회원의 정보에 대하여 정기적으로 유효성을 검사하거나 확인할 수 있습니다.

3. 수집된 개인정보의 이용
회사는 회원관리, 온/오프라인 서비스 개발·제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다. 
일반 회원은 가입 의사의 확인, 이용자 식별, 회원탈퇴 의사 확인 등 회원관리를 위하여 개인정보를 이용합니다.
서비스 이용 요금 결제, 상담 및 문의 처리, 공지사항 전달 및 불만처리 등 원활한 의사소통 경로를 확보하기 위하여 개인정보를 이용합니다.
일반 회원이 유료상담 서비스 결제를 위해 카드를 등록한 경우, 회사는 서비스 제공 및 향상, 안전한 인터넷 이용환경 구축을 위하여 위 목적범위 내에서 일반 회원의 카드정보를 이용할 수 있습니다.
또한, 현재 인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 심리 상담 관련 통계나 연구 등을 통해 심리상담 솔루션 개발, 개인정보 및 상담에 기반한 맞춤형 서비스 제공 등의 서비스 개발 및 개선에 개인정보를 익명화하여 이용합니다.
상담사 회원은 상담 서비스 제공에 필요한 유관 경력과 관련하여 필요한 개인정보를 수집하고 이용합니다.
또한 서비스 제공에 관한 계약 체결·유지·이행·관리·개선 및 서비스 제공에 따른 요금 정산 및 컨텐츠 서비스 이용, 프로필 노출, 결제정보 안내 등 현재 제공하고 있는 서비스 제공을 위하여 개인정보를 이용합니다.
서비스 이용요금 결제와 상담 등 서비스의 제공을 법령 및 회사 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
서비스 이용 기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
이벤트 등의 참여자에 한해서 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다. 이 때 수집하는 정보는 이벤트의 목적과 내용 등에 따라 다를 수 있습니다.
보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위하여 개인정보를 이용합니다.



4. 개인정보의 제공
회사는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 단, 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 직접 동의를 한 경우, 그리고 관련 법령에 의거해 회사에 개인정보 제출 의무가 발생한 경우, 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우에 한하여 개인정보를 제공하고 있습니다.
개인정보보호법이나 정보통신망법 등 개인정보보호 관련 법률에 따르면, 통상 이용자로부터 수집한 개인정보를 제3자에게 제공할 때에는 이용자에게 일정한 사항을 알리고 그에 대한 동의를 획득한 후에 이를 제공해야 합니다. 그러나 통상적 고지 및 동의에 의한 방법 외에도 관계법령에 의해 개인정보의 제3자 제공이 발생하는 경우가 있습니다. 가장 대표적인 예가 범죄수사의 필요성에 의해 엄격한 법률 절차에 따라 수사기관이나 정보기관 등에 범죄용의자의 정보가 제공되는 경우입니다.
이용자의 개인정보가 통상의 사전 고지 및 동의 절차를 거치지 않고서 제3자에게 제공되는 경우는 대표적으로 다음과 같은 것들이 있습니다.

형사소송법에 따른 압수수색
통신비밀보호법에 의한 통신사실확인자료
전기통신사업법에 의한 통신자료
기타 세금과 관련하여 부가가치세 징수목적 또는 연말정산 등을 위해 관계법령에 따라 개인정보가 국세청 내지 관세청 등 과세 관련 처분청에 제공될 수 있습니다. 또한, 공정한 선거의 관리를 위해 선관위 요청에 따라 관계 법령에 의하여 제한적으로 개인정보가 선관위에 제출될 수 있습니다. 이러한 경우 관계 기관에서는 법령에서 정하는 바에 의거하여 엄격한 절차에 의해 자료제출을 요청하게 되며, 이를 접수한 사업자도 해당 법령의 규정과 목적에 부합하는지를 살펴 그 제출을 결정하게 됩니다.
이용자에 대한 사전 고지 및 동의 절차에 의하지 않은 개인정보의 제공 사례가 존재할 수 있음은 위에서 확인한 바와 같습니다.


5. 개인정보의 처리 위탁
회사는 편리하고 더 나은 서비스를 제공하기 위해 업무 중 일부를 외부에 위탁할 수 있습니다.

회사는 이용자들이 전문적인 상담서비스를 제공받게 하기 위하여 심리상담 서비스 제공업무를 상담사 회원들에게 위탁하고 있습니다. 그 외에도 회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에 위탁할 수 있으며, 위탁할 경우 위탁 받은 업체가 정보통신망법에 따라 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독을 합니다.

위탁받는 곳	위탁 내용
㈜KG이니시스, Paypal, (주)카카오페이, 네이버(주), (주)비바리퍼블리카	결제 및 환불
(주)피에이치피스쿨닷컴	SMS 발송
Amazon Web Services, Inc.	서비스 제공을 위한 인프라 관리
카페24(주)	상품 주문
(주)채널코퍼레이션	채널톡 운영
비즈톡(주)	카카오 알림톡 발송


6. 개인정보의 파기
회사는 원칙적으로 서비스 이용 기간동안 개인정보를 보유하고, 회원 탈퇴 시 이용자의 개인정보를 지체없이 파기합니다. 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.

이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우는 아래와 같습니다.

부정 가입 및 징계 기록 등의 부정 이용 기록은 부정 가입 및 이용 방지를 위하여 수집 시점으로부터 6개월간 보관하고 파기하고 있습니다. 결제 도용 등 관련 법령 및 이용약관을 위반하는 부정 거래 기록 (아이디, 휴대폰번호, IP주소, 쿠키, 기기정보)은 부정 거래 방지 및 다른 선량한 이용자의 보호, 안전한 거래 환경 보장을 위하여 수집 시점으로부터 3년간 보관하고 파기합니다.

전자상거래 등에서의 소비자 보호에 관한 법률, 전자금융거래법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다. 회사는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.

전자상거래 등에서 소비자 보호에 관한 법률
계약 또는 청약철회 등에 관한 기록: 5년 보관
대금결제 및 재화 등의 공급에 관한 기록: 5년 보관
소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관
표시/광고에 관한 기록: 6개월 보관
전자금융거래법
전자금융에 관한 기록: 5년 보관
통신비밀보호법
로그인 기록: 3개월
회원 탈퇴, 서비스 종료, 이용자에게 동의받은 개인정보 보유기간 경과와 같이 개인정보의 수집 및 이용 목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체없이 재생이 불가능한 방법으로 파기합니다.

전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.



7. 이용자의 권리
이용자는 언제든지 트로스트 사이트를 통하여 이용자의 개인정보를 열람, 정정 처리하실 수 있으며, 전자우편 또는 서면으로 요청하신 경우 열람, 정정, 삭제 처리 해드리겠습니다.



8. 개인정보 보호 책임자 및 담당자 안내
회사는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.

개인정보 보호 책임자
이름: 김가영
소속: 리상컴퍼니
직위: 대표
전화(사무실): 010.6566.2379
이메일: resang79@naver.com
 
개인정보관리담당자
이름: 김영선
소속: 리상컴퍼니/개인정보보호파트
직위: 이사
전화(사무실): 010.6566.2379
이메일: resang79@naver.com


9. 개인정보의 안전성 확보 조치
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.

관리적 조치
내부 관리 계획 수립·시행, 정기적 직원 교육, 점검 등
내부 관리 계획의 수립 및 시행
회사는 개인정보의 안전한 처리를 위하여 내부 관리 계획을 수립하고 시행하고 있습니다.
회사는 사내 개인정보 보호 책임자 등을 통해 개인정보 보호 조치의 이행사항 및 담당자의 준수여부를 확인하여 문제 발견 시 즉시 시정 조치하도록 하고 있습니다.
처리 직원의 최소화 및 교육
회사는 이용자의 개인정보에 대한 접근 권한을 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자, 개인정보 보호 책임자 및 담당자 등 개인정보 관리 업무를 수행하는 자, 기타 업무상 개인정보의 처리가 불가피한 자로 제한 하고 있습니다.
개인정보를 처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 교육을 실시하고 있습니다.
입사시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보보호정책에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.


기술적 조치
개인정보처리시스템 등의 접근 권한 관리, 접근 통제 시스템 설치, 고유 식별정보 등의 암호화, 보안 프로그램 설치
접근통제장치의 설치 및 운영
회사는 침입차단시스템을 이용하여 외부로부터 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위하여 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
개인정보의 암호화
이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 저장, 관리되며, 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
해킹 등에 대비한 대책
회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인 정보가 침해되는 것을 방지하고 있습니다.
회사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.
해킹 등 외부침입에 대비하여 침입차단시스템 및 취약점 분석 시스템 등을 이용하여 보안에 만전을 기하고 있습니다.


10. 개정 전 고지 의무
본 개인정보 처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 3일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.


공고일자: 2024년 3월 4일
시행일자: 2024년 3월 4일
\n
`;


export default infoContent;