import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../AuthContext.js';
import './Post.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PostView = () => {
  const navigate = useNavigate();
  const { no } = useParams();
  const [communityPost, setCommunityPost] = useState([]);
  const [commentPost, setCommentPost] = useState([]);
  const [login_user_id, setUser_id] = useState(null);
  const [login_authority, setlogin_authority] = useState(null);

  const [Contentinput, setContentinput] = useState('');
  const [Nicknameinput, setNicknameinput] = useState('');

  // 삭제 당시 날짜로 게시글 번호 만들기
  const currentDate = new Date();
  const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
  const deletepostnum = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
  const commentnum = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
  const changecommentnum = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
  const deletecommentnum = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
  const postdeleteDateTime = currentDate.toLocaleString('ko-KR', options);

  //댓글 수정 모달
  const [isOpen, setIsOpen] = useState(false);
  const [changecontent, setchangecontent] = useState('');
  const [changenickname, setchangenickname] = useState('');
  const [chcommentnum, setchangecommentnum] = useState('');

  const { isLoggedIn } = useAuth(); // 로그인 확인

  const goToCommunity = () => {
    navigate("/Community");
  }

  const handlecontentchange = (value) => {
    setContentinput(value);
  }

  useEffect(() => {
    document.title = "커뮤니티 게시글";
  }, []);

  const handlenicknameinputchange = (value) => {
    if (value.length > 10) {
      // toast 메시지를 표시하고 입력 칸을 비움
      toast.error('10글자 이하로 입력해 주세요.');
      setNicknameinput('');
    } else {
      // 상태 업데이트
      setNicknameinput(value);
    }
  }

  const fetchComments = async (postNumber) => {
    try {
      const response = await axios.get(`/api/communitycomment?postnum=${postNumber}`, {
        headers: {
          'Cache-Control': 'no-store'
        }
      });
      if (response.data && response.data.commentlist) {
        const sortedCommentList = response.data.commentlist.sort((a, b) => a.listnum - b.listnum);
        setCommentPost(sortedCommentList);
      } else {
        setCommentPost([]);
      }
    } catch (error) {
      console.error('댓글을 불러오는 중 오류가 발생했습니다:', error);
      // 오류 처리 로직 추가
    }
  };

  // 게시글, 댓글 정보와 현재 로그인 중인 회원번호 받아오기
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`/api/communitypost?postnum=${no}`, {
          headers: {
            'Cache-Control': 'no-store'
          }
        });
        setCommunityPost(response.data.postcontent[0]);
        fetchComments(no); // fetchComments 호출을 axios.get 완료 후에 이동

        if (isLoggedIn) {
          try {
            const response = await axios.get('/api/Mypage', { withCredentials: true });
            if (Array.isArray(response.data) && response.data.length > 0) {
              setUser_id(response.data[0].user_id);
              setlogin_authority(response.data[0].authority)
            } else if (!Array.isArray(response.data) && typeof response.data === 'object') {
              setUser_id(response.data.user_id);
              setlogin_authority(response.data.authority)
            }
          } catch (error) {
            console.error('회원번호 요청 오류:', error);
          }
        }
      } catch (error) {
        console.error('게시글 및 회원번호 가져오기 오류:', error);
      }
    };

    fetchPost();
  }, []);

  // 게시글 수정
  const handleEdit = () => {
    navigate(`/PostChange/${no}`);
  }

  // 게시글 삭제
  const deletePost = async () => {
    try {
      await axios.delete(`/api/postdelete?deletepostnum=${deletepostnum}&deletecommentnum=${deletecommentnum}&postnum=${no}&deletedate=${postdeleteDateTime}`);
      toast.success('게시물이 성공적으로 삭제되었습니다!');
      navigate(`/Community`);
    } catch (error) {
      toast.error('게시물 삭제 중 오류가 발생하였습니다.');
    }
  };

  // 댓글 작성
  const sendCommentCommunity = () => {
    if (!Contentinput || !Nicknameinput) {
      toast.error("내용을 입력해 주세요."); // toast로 오류 메시지 표시
      return;
    }

    axios.post('/api/inputcomment', {
      commentnum: commentnum,
      postnum: no,
      nickname: Nicknameinput,
      content: Contentinput,
      writedate: postdeleteDateTime,
    }).then(response => {
      fetchComments(no);
      // 입력 필드 비우기
      setNicknameinput('');
      document.getElementById('contentinput').value = '';
    }).catch(error => {
      toast.error('댓글 작성 중 오류가 발생하였습니다.');
    });
  }

  // 댓글 수정
  const handleCommentEdit = async () => {
    axios.post('/api/Changecomment', {
      changecommentnum: changecommentnum,
      postnum: no,
      commentnum: chcommentnum,
      content: changecontent,
      changedate: postdeleteDateTime
    }).then(response => {
      toast.success('댓글이 성공적으로 수정되었습니다!');
      fetchComments(no);
      closechangeModal();
    }).catch(error => {
      toast.error('댓글 작성 중 오류가 발생하였습니다.');
    });
  };


  // 댓글 수정 Modal
  const openchangeModal = (index, index2, index3) => {
    setchangecommentnum(index);
    setchangecontent(index2);
    setchangenickname(index3);
    setIsOpen(true);
  };

  const closechangeModal = () => {
    setchangecommentnum(null);
    setchangecontent(null);
    setchangenickname(null);
    setIsOpen(false);
  };

  // 댓글 삭제
  const deletecomment = async (commentnum) => {
    try {
      await axios.delete(`/api/commentdelete?deletecommentnum=${deletecommentnum}&commentnum=${commentnum}&deletedate=${postdeleteDateTime}`);
      toast.success('댓글이 성공적으로 삭제되었습니다!');
      fetchComments(no);
    } catch (error) {
      toast.error('댓글 삭제 중 오류가 발생하였습니다.');
    }
  };

  return (
    <>
      <ToastContainer />

      <div className='postviewcontainer'>
        <br />
        <div className="post-view-wrapper">
          {communityPost ? (
            <>
              <div className='firstpost'>
                <div className='posttitle'>
                  <label style={{ fontSize: '1em' }}>{communityPost.title}</label>
                </div>
                <div className='view'>
                  <label>조회수</label>
                  &nbsp;
                  <label>{communityPost.view}</label>
                </div>
              </div>

              <div className='secondpost'>
                <div className='nickname'>
                  <label>작성자</label>
                  &nbsp;
                  <label>{communityPost.nickname}</label>
                  &nbsp;
                  <label>|</label>
                  &nbsp;
                  <label>게시글 번호</label>
                  &nbsp;
                  <label>{communityPost.postnum}</label>
                  {(login_authority === 3) && (
                    <>
                      &nbsp;
                      <label>|</label>
                      &nbsp;
                      <label>회원번호</label>
                      &nbsp;
                      <label>{communityPost.user_id}</label>
                    </>
                  )}
                  &nbsp;
                </div>
                <div className='writedate'>
                  <label>{communityPost.writedate}</label>
                </div>
              </div>
              <hr className='contenthr' />
              <div className='thirdpost'>
                <pre className='contentfield'>{communityPost.content}</pre>
              </div>
            </>
          ) : (
            '해당 게시글을 찾을 수 없습니다.'
          )}
        </div>
        <div>
          <button className="post-view-go-list-btn" onClick={goToCommunity}>
            목록으로 돌아가기
          </button>
          &nbsp;
          {(communityPost.user_id === login_user_id || login_authority === 3) && (
            <>
              <button className="post-view-go-list-btn" onClick={handleEdit}>
                수정
              </button>
              &nbsp;
              <button className="post-view-go-list-btn" onClick={deletePost}>
                삭제
              </button>
            </>
          )}
        </div>

        <hr className='hr2' />

        <div className='commentdiv'>
          {isLoggedIn === true && (
            <div className='commentdiv'>
              <div className='commenttitle'>댓글</div>
              <div className='commentinput'>
                <div className='inputcomment1'>
                  <div className='inputnickname'>닉네임</div>
                  <textarea type='text' className='nicknameinput' id='nicknameinput' value={Nicknameinput} onChange={(e) => handlenicknameinputchange(e.target.value)} />
                </div>
                <div className='inputcomment2'>
                  <div className='inputcontent'>내용</div>
                  <textarea type='text' className='contentinput' id='contentinput' onChange={(e) => handlecontentchange(e.target.value)} />
                </div>
                <button className="post-commentinput" onClick={sendCommentCommunity}>
                  작성
                </button>
              </div>
            </div>
          )}
          {
            commentPost && commentPost.map((item, index) => {
              const adjustedIndex = index + 1; // 인덱스 값을 1 증가시켜서 1부터 시작하도록 함
              return (
                <div key={index} className="comment-item">
                  <div className="comment-info">
                    <div className="comment-number">{adjustedIndex}</div>
                    <div className="comment-writer">{item.nickname}</div>
                    <div className="comment-date">{item.writedate}</div>
                  </div>
                  <div className='comment-content-div'>
                    <div className="comment-content">{item.content}</div>
                    <div className="comment-button">
                      {(commentPost[index].user_id === login_user_id || login_authority === 3) && (
                        <>
                          <button className="commentbtn" onClick={() => openchangeModal(commentPost[index].commentnum, commentPost[index].content, commentPost[index].nickname)}>
                            수정
                          </button>
                          &nbsp;
                          <button className="commentbtn" onClick={() => deletecomment(commentPost[index].commentnum)}>
                            삭제
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          }
          {/* 댓글 수정 모달 */}
          <Modal
            isOpen={isOpen && changecontent !== null}
            onRequestClose={closechangeModal}
            overlayClassName="modal-overlay"
            className="custom-modal"
          >
            {changecontent !== null && (
              <div className="modal-content">
                <h3>댓글 수정</h3>
                {/* 닉네임 */}
                <div style={{ textAlign: 'left' }}> 닉네임  |  {changenickname}</div>
                {/* 수정할 내용 입력 폼 */}
                <textarea type="text" value={changecontent} onChange={(e) => setchangecontent(e.target.value)} />
                {/* 수정 완료 버튼 */}
                <button onClick={handleCommentEdit}>수정 완료</button>
                {/* 모달 닫기 버튼 */}
                <button onClick={closechangeModal}>닫기</button>
              </div>
            )}
          </Modal>

        </div>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    </>
  );
};

export default PostView;