import React, { useEffect, useState } from 'react';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, CardContent, Paper, Button } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './review.css';
import BlankCard from '../shared/BlankCard';
import { FaStar, FaRegStar } from 'react-icons/fa';

const currentDate = new Date();
const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
const changereviewnum = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
const changedate = currentDate.toLocaleString('ko-KR', options);


const ChangeReview = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const { no } = useParams();

    const [Contentinput, setContentinput] = useState('');
    const [review, setReview] = useState({});
    const [rating, setRating] = useState(0);


    const { reviewnum, date, counselorname } = state || {};

    const handleClick = (value) => {
        setRating(value);
    };

    const handleContentChange = (value) => {
        setContentinput(value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const reviewResponse = await axios.get('/api/reviewdetail', {
                    params: { reviewnum: no }
                });
                const reviewData = reviewResponse.data.reviewdetail[0];
                setReview(reviewData);
                setContentinput(reviewData.content); // 기존 내용
                setRating(reviewData.rating); // 기존 별점
            } catch (error) {
                console.error('데이터 가져오기 오류:', error);
            }
        };

        fetchData();
    }, [no]);

    const changereview = async () => {
        try {
            await axios.post('/api/Changereview', {
                changereviewnum: changereviewnum,
                num_review: no,
                content: Contentinput,
                rating : rating,
                changedate: changedate
            });
            toast.success('게시물이 성공적으로 수정되었습니다!');
            navigate(`/Mypage/client/review`);
        } catch (error) {
            toast.error('게시물 수정 중 오류가 발생하였습니다.');
        }
    }

    return (
        <div>
            <DashboardCard title="후기 작성">
                <ToastContainer />
                <Grid container spacing={3}>
                    <Grid item sm={4} sx={{ textAlign: 'left', marginTop: '10px' }}>
                        <Paper elevation={10}>
                            <BlankCard>
                                <CardContent>
                                    <Typography sx={{ fontSize: '13.5px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: 700 }}>예약 ID :</span> {review.res_id}
                                    </Typography>
                                    <br />
                                    <Typography sx={{ fontSize: '13.5px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: 700 }}>상담사 :</span> {counselorname} 상담사
                                    </Typography>
                                    <br />
                                    <Typography sx={{ fontSize: '13.5px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: 700 }}>상담일시 :</span> {date}
                                    </Typography>
                                    <br />
                                    <Typography sx={{ fontSize: '13.5px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: 700 }}>상담 시간 :</span> {review.time}({review.totaltime}시간)
                                    </Typography>
                                </CardContent>
                            </BlankCard>
                        </Paper>
                    </Grid>
                    <Grid item sm={4} sx={{ textAlign: 'left', marginTop: '10px' }}>
                        {[...Array(5)].map((_, index) => (
                            <span
                                key={index}
                                onClick={() => handleClick(index + 1)}
                                style={{ cursor: 'pointer', fontSize: '24px', color: index < rating ? 'gold' : 'grey' }}
                            >
                                {index < rating ? <FaStar /> : <FaRegStar />}
                            </span>
                        ))}
                        <br /> &nbsp; <p>별점: {rating}점</p>
                    </Grid>
                </Grid>
                <br /><br />
                <textarea className='review-content-input' value={Contentinput} onChange={(e) => handleContentChange(e.target.value)} />
                <br /><br /><br />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#5D87FF",
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        marginLeft: '5px'
                    }}
                    onClick = {changereview}>
                    작성
                </Button>
            </DashboardCard>
        </div>
    );
};

export default ChangeReview;
