import React from "react";
import './Find_pw.css';
import { Link } from 'react-router-dom';
import PageContainer from '../../bootstrap/container/PageContainer';
import {Typography, Card, Button} from '@mui/material';

import naman_logo from "./naman.png";

export default function Success_pw() {


  return (
    <PageContainer>
        
        <br/><br/><br/><br/><br/><br/><br/>
        <br/><br/><br/><br/>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card elevation={20} sx={{mt:10, p: 4, zIndex: 1, width: '100%', maxWidth: '480px', minHeight:'200px ' }}>
           
            <Link to="/">
                  <img
                    src={naman_logo}
                    alt="Logo"
                    style={{
                      height: '32px',
                      width: '140px',
                     
                      overflow: 'hidden',
                  
                      marginBottom: '25px',
                    }}
                  />
                  
                  <hr style={{
                            backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
                            height: '4px',
                            width: '115px',
                            top : '-38px',
                            left : '3px',
                            border: 'none' // 기본 테두리를 제거합니다
                        }} />
                </Link>
             
              
                
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  비밀번호가 변경되었습니다.
                </Typography> 
                <br/><br/>
                <Typography>
                  <Link to="/Login">로그인</Link>
                </Typography>
              
                <br/>
            </Card>
            
        </div>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </PageContainer>

  );
}
