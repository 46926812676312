import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, Avatar } from '@mui/material';
import DashboardCard from '../../shared/DashboardCard';
import axios from "axios";
import { IconCurrencyDollar } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

const Cou_calculate = () => {
  // chart color
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const primarylight = '#ecf2ff';
  const successlight = theme.palette.success.light;

  const navigate = useNavigate();
  const today = new Date();
  const selectedMonth = today.getMonth() + 1;
  const [calCounsNum, setCalCounsNum] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [completedReservations, setCompletedReservations] = useState([]);
  const [incompleteReservations, setIncompleteReservations] = useState([]);
  const [adminCalculate, setAdminCalculate] = useState([]);

  async function fetchConCalculate() {
    try {
      const queryParams = new URLSearchParams({
        selectedMonth: selectedMonth,
      }).toString();
      const url = `/api/Con_complete_calculate?${queryParams}`;
      const response = await axios.get(url);
      const { completedReservations, incompleteReservations } = response.data;

      setCompletedReservations(completedReservations);
      setIncompleteReservations(incompleteReservations);
      setCalCounsNum(completedReservations.length + incompleteReservations.length); // 상담 건수

      // 완료된 예약의 totalamount 값을 모두 더하여 정산 금액에 설정
      const totalAmount = completedReservations.reduce((acc, item) => acc + item.totalamount, 0);
      setTotalFee(totalAmount);
    } catch (error) {
      console.error("데이터 가져오기 오류:", error);
    }
  }

  useEffect(() => {
    fetchConCalculate();
  }, [selectedMonth]);

  // chart
  const optionscolumnchart = {
    chart: {
      type: 'donut',
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      foreColor: '#adb0bb',
      toolbar: {
        show: false,
      },
      height: 155,
    },
    colors: [primary, '#bdd3ff', '#F9F9FD'],
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        donut: {
          size: '75%',
          background: 'transparent',
        },
      },
    },
    tooltip: {
      theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
      fillSeriesColor: false,
      x: {
        formatter: function(val, opts) {
          const labels = opts.w.config.labels; // 시리즈의 라벨 가져오기
          return labels[opts.seriesIndex]; // 시리즈의 라벨로 툴팁 내용 설정
        },
      },
      y: {
        formatter: function(val) {
          return val + '건';
        }
      }
     
    },

    labels: ["완료", "미완료", "기타"], 
    
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 991,
        options: {
          chart: {
            width: 120,
          },
        },
      },
    ],
  };
  
  const seriescolumnchart = [completedReservations.length, incompleteReservations.length, 0]; 
  


  
  return (
    <DashboardCard title={`${today.getMonth() + 1}월 예약`}>
      <>
        <Grid container spacing={3}>
          {/* column */}
          <Grid item xs={7} sm={5}>
            <Stack spacing={3} mt={5} direction="row">
              <Stack direction="row" spacing={1} alignItems="center">
                <Avatar
                  sx={{ width: 9, height: 9, bgcolor: primary, svg: { display: 'none' } }}
                ></Avatar>
                <Typography variant="subtitle2" color="textSecondary">
                  완료 예약 : {completedReservations.length}건
                </Typography>
                <Avatar
                  sx={{ width: 9, height: 9, bgcolor: '#bdd3ff', svg: { display: 'none' } }}
                ></Avatar>
                <Typography variant="subtitle2" color="textSecondary">
                  미완료 예약 : {incompleteReservations.length}건
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          {/* column */}
          <Grid item xs={5} sm={5}>
            <Chart
              options={optionscolumnchart}
              series={seriescolumnchart}
              type="donut"
              height="150px"
            />
          </Grid>
        </Grid>

        <Typography fontWeight="700" mt="-20px" sx={{ fontSize: "14.5px", textAlign: 'left' }}>
          상담 건수 : {calCounsNum}건
        </Typography>
        <br />
        <br />
        <br />

        <Typography fontWeight="700" mt="-20px" sx={{ fontSize: "14.5px", textAlign: 'left' }}>
          정산 금액 : {totalFee}원
        </Typography>
      </>
    </DashboardCard>
  );
};

export default Cou_calculate;
