import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './O_Reservation.css';
import { useAuth } from "../../AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sections = [

    {
        type: "교육",
        title: "패러다임 전환과 창의적 사고",
        description: "대상: 청소년, 대학생, 성인, 기업체",
        duration: "시간: 4시간 (평일, 주말)",
        classtime: 4,
        content: [
            {
                title: "고정관념과 선입견",
                content: (
                    <ul>
                        <li>아이스 브레이킹</li>
                        <li>고정관념과 사고의 한계</li>
                        <li>선입견에 따른 감정과 행동</li>
                    </ul>
                )
            },
            {
                title: "패러다임 전환",
                content: (
                    <ul>
                        <li>3D 퍼즐 게임</li>
                        <li>패러다임 전환 이론과 실습</li>
                    </ul>
                )
            },
            {
                title: "창의적 사고",
                content: (
                    <ul>
                        <li>창의적 사고 기법 설명</li>
                        <li>실습 및 발표</li>
                    </ul>
                )
            },
            {
                title: "자기개발 계획",
                content: (
                    <ul>
                        <li>자기개발 계획</li>
                        <li>실행 계획</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/edu_1.jpg'
    },
    {
        type: "교육",
        title: "동기 부여",
        description: "대상: 청소년, 대학생, 성인, 기업체",
        duration: "시간: 4시간 (평일, 주말)",
        classtime: 4,
        content: [
            {
                title: "동기부여 이론",
                content: (
                    <ul>
                        <li>동기부여 이론</li>
                        <li>알파벳 퀴즈</li>
                    </ul>
                )
            },
            {
                title: "자신의 SWOT 분석",
                content: (
                    <ul>
                        <li>SWOT 분석</li>
                        <li>ST, SO, WT, WO 전략</li>
                    </ul>
                )
            },
            {
                title: "자신감과 열정",
                content: (
                    <ul>
                        <li>긍정 인생 로드맵 작성</li>
                    </ul>
                )
            },
            {
                title: "실행 계획 작성 및 선언",
                content: (
                    <ul>
                        <li>실행 계획 작성</li>
                        <li>선언식</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/edu_2.jpg'
    },
    {
        type: "교육",
        title: "비즈니스 모델",
        description: "대상: 청소년, 대학생, 성인, 기업체",
        duration: "시간: 8시간 (평일, 주말)",
        classtime: 8,
        content: [
            {
                title: "비즈니스 모델",
                content: (
                    <ul>
                        <li>비즈니스 모델 유형</li>
                    </ul>
                )
            },
            {
                title: "SWOT 분석",
                content: (
                    <ul>
                        <li>SWOT 분석</li>
                    </ul>
                )
            },
            {
                title: "비즈니스 모델 전략",
                content: (
                    <ul>
                        <li>BMC 컨셉 및 전략</li>
                        <li>사례 연구</li>
                    </ul>
                )
            },
            {
                title: "BMC 작성 실습",
                content: (
                    <ul>
                        <li>BMC 작성</li>
                        <li>발표</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/edu_3.jpg'
    }
];

const AccordionItem = ({ title, content, isOpen, onClick }) => (
    <div className="accordion-item">
        <div className="accordion-title" onClick={onClick}>
            {title}
            <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && <div className="accordion-content">{content}</div>}
    </div>
);

const O_Reservation_Edu = () => {
    const [openIndices, setOpenIndices] = useState(Array(sections.length).fill(null));
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        document.title = "집단 상담 예약";
    }, []);

    const handleToggle = (sectionIndex, contentIndex) => {
        setOpenIndices(prevIndices => {
            const newIndices = [...prevIndices];
            newIndices[sectionIndex] = newIndices[sectionIndex] === contentIndex ? null : contentIndex;
            return newIndices;
        });
    };

    const handleReservation = (type, title, classtime) => {
        if (!isLoggedIn) {
            navigate('/Login');
            return;
        }

        navigate('/O_Reservation_detail', {
            state: {
                type: type,
                title: title,
                classtime: classtime
            }
        });
    };

    return (
        <div className='O_Res'>
            <ToastContainer />
            {sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="O_Res_Container">
                    <h1 className='O_Res_Container_h1'>{section.title}</h1>
                    <p className='O_Res_Container_p'>{section.description}</p>
                    <p className='O_Res_Container_p'>{section.duration}</p>
                    <div className='O_Res_Content'>
                        <div className="accordion">
                            {section.content.map((item, contentIndex) => (
                                <AccordionItem
                                    key={contentIndex}
                                    title={item.title}
                                    content={item.content}
                                    isOpen={openIndices[sectionIndex] === contentIndex}
                                    onClick={() => handleToggle(sectionIndex, contentIndex)}
                                />
                            ))}
                        </div>
                        <div className='contentimg'>
                            <img src={section.img} alt={section.title} />
                        </div>
                    </div>
                    <button
                        className="O_reservation-button"
                        onClick={() => handleReservation(section.type, section.title, section.classtime)}
                    >
                        예약하기
                    </button>
                </div>
            ))}
        </div>
    );
};

export default O_Reservation_Edu;
