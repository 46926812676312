import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import infoContent from "./info";
import infoclient from "./Client_info";
import { Typography, Card, Button } from '@mui/material';
import PageContainer from '../../bootstrap/container/PageContainer';
import axios from 'axios';
import moment from "moment-timezone";

export default function Kakao_join(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [accessToken, setAccessToken] = useState(null); // accessToken 상태 추가
  const currentDate = new Date();

  const formattedDateForID = currentDate
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, "");
  const userid = `${formattedDateForID}21${Math.floor(Math.random() * 100)}`;

  const koreanDate = moment().tz("Asia/Seoul");
  const registereddate = koreanDate.format("YYYY-MM-DD HH:mm:ss");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("access_token");
    setAccessToken(token);
  }, [location.search]);

  const handleCheckboxChange = (checkboxNum) => {
    if (checkboxNum === 1) {
      setIsChecked1(!isChecked1);
    } else if (checkboxNum === 2) {
      setIsChecked2(!isChecked2);
    }
  };

  const handleJoin = async () => {
    if (!isChecked1 || !isChecked2) {
      toast.error("필수 약관 확인 후 동의해 주세요!");
      return;
    }

    try {
      const response = await axios.post(`/api/user/kakao-login`, {
        user_id: userid,
        registered_date: registereddate,
        access_token: accessToken,
      }, {
        withCredentials: true
      });

      navigate("/Success");
    } catch (error) {
      console.error("Error during Kakao registration:", error);
      toast.error("입력하신 정보를 다시 한번 확인해 주세요.");
    }
  };

  return (
    <PageContainer title="이용자 회원가입">
      <ToastContainer />
      <br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card elevation={20} sx={{ p: 4, zIndex: 1, width: '1300px',  minHeight:'400px ' }}>
          <br/><br/>
          <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
            이용자 회원가입
          </Typography> 
          <br/><br/><br/><br/><br/>

          <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: "left", marginLeft: '50px' }}>
            약관 동의{" "}
          </Typography>

          <br/>
          <div className="join_p" style={{ fontSize: '12px' }}>
            <label>
              <Span />
              <input
                type="checkbox"
                checked={isChecked1}
                onChange={() => handleCheckboxChange(1)}
              />
            </label>
            &nbsp;[필수] 개인정보 처리방침
          </div>

          <div className="signup-re-agree-box" style = {{textAlign: 'left', paddingLeft: '10px' }} >{infoContent}</div>

          <div className="join_p" style={{ fontSize: '12px' }}>
            <label>
              <Span />
              <input
                type="checkbox"
                checked={isChecked2}
                onChange={() => handleCheckboxChange(2)}
              />
            </label>
            &nbsp;[필수] 서비스 이용약관
          </div>

          <div className="signup-re-agree-box" style = {{textAlign: 'left', paddingLeft: '10px' }} >{infoclient}</div>

          <br/><br/><br/><br/>

          <Button
            variant="contained"
            color="primary"
            target="_blank"
            sx={{ height: 40, width: 180, fontSize: '1.5rem' }}
            onClick={handleJoin}
          >
            가입 완료
          </Button>
          <br/><br/><br/><br/>
        </Card>
      </div>
      <br/><br/><br/><br/>
    </PageContainer>
  );
}

function Span({ space = 80 }) {
  return <span style={{ paddingRight: space }}></span>;
}
