
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { parseISO, format } from 'date-fns';
import { ko } from 'date-fns/locale';

import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import { Typography, Grid, CardContent, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import BlankCard from '../shared/BlankCard';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { pink } from '@mui/material/colors';
import { blue } from '@mui/material/colors';

import meta from "../assets/images/logos/meta.png";

import { Select, MenuItem } from '@mui/material';

const CliReservationManage = () => {
  const navigate = useNavigate();
  const [checkRoomyn, setCheckRoomyn] = useState(null);
  const [userData, setUserData] = useState({}); // 사용자 데이터

  const [isOpen1, setIsOpen1] = useState(false); // 개인 예약 상세 보기 팝업
  const [selectedReservationIndex, setSelectedReservationIndex] = useState(null); // 상세보기 Modal
  const [isOpen2, setIsOpen2] = useState(false); // 그룹 예약 상세 보기 팝업
  const [selectedReservationIndex2, setSelectedReservationIndex2] = useState(null); // 상세보기 Modal
  const [isOpen8, setIsOpen8] = useState(false); // 집단 프로그램 상세 보기 팝업
  const [selectedReservationIndex3, setSelectedReservationIndex3] = useState(null); // 상세보기 Modal

  const [isOpen3, setIsOpen3] = useState(false); // 개인 예약 취소 팝업
  const [cancelmodalIndex, setCancelModalIndex] = useState(null); // 취소 Modal의 상태
  const [isOpen4, setIsOpen4] = useState(false); // 그룹 예약 취소 팝업
  const [cancelmodalIndex2, setCancelModalIndex2] = useState(null); // 취소 Modal의 상태

  const [isOpen5, setIsOpen5] = useState(false); // 개인 메타버스 입장 팝업
  const [metamodalIndex, setMetaModalIndex] = useState(null); // 메타 Modal의 상태
  const [isOpen6, setIsOpen6] = useState(false); // 그룹 메타버스 입장 팝업
  const [metamodalIndex2, setMetaModalIndex2] = useState(null); // 메타 Modal의 상태

  const [isOpen7, setIsOpen7] = useState(false); // 취소 상세 보기 팝업
  const [CancelIndex, setCancelIndex] = useState(null); // 메타 Modal의 상태



  const requestDescriptions = [
    "- 상담시 불러주었으면 하는 호칭이 있습니까?",
    "- 현재 하고 있는 일에 대해 소개해 주시겠어요?",
    "- 현재 나의 상태를 점수로 표현한다면? (1~3 : 부정, 4~6 : 긍정)",
    "- 어떤 주제로 상담을 원하시는지 구체적으로 알려주세요.",
    "- 상담을 통해 얻고싶거나 기대하는 사항은?",
    "- 상담 전문가에게 추가로 전달하고 싶은 사항은?",
    "- 상담을 신청하게 된 동기?",
    "- 심리상담이나 이와 비슷한 경험이 있다면 알려주세요/"
  ];

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const years = Array.from({ length: 11 }, (v, i) => new Date().getFullYear() - i);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value); // 월 선택
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // 년도 선택
  };

  const [resdata, setResdata] = useState({
    personalReservations: [],
    groupReservations: [],
    oReservations: [],
    canceloReservations: [],
    cancelReservations: []
  }); // 예약 정보를 저장할 상태

  Modal.setAppElement('#root'); // 또는 모달이 열릴 최상위 DOM 요소의 ID를 전달, 모달 오류 해결

  const fetchReservations = async () => {
    try {
      const queryParams = new URLSearchParams({
        selectedMonth,
        selectedYear,
      }).toString();
      const response = await axios.get(`/api/ResData?${queryParams}`);
      const personalReservations = response.data.personalReservations;
      const groupReservations = response.data.groupReservations;
      const cancelReservations = response.data.cancelReservations;
      const canceloReservations = response.data.canceloReservations;
      const oReservations = response.data.oReservations;

      setResdata({
        personalReservations,
        groupReservations,
        oReservations,
        cancelReservations,
        canceloReservations,
      }); // 응답으로 받은 예약 정보를 상태에 저장

    } catch (error) {
      console.error('예약 정보 가져오기 오류:', error);
    }
  };



  useEffect(() => {
    fetchReservations(selectedMonth, selectedYear); // 페이지가 로드될 때 예약 정보를 가져오는 함수 호출
  }, [selectedMonth, selectedYear]); // useEffect의 두 번째 인자에 빈 배열을 전달하여 한 번만 실행되도록 설정


  //개인 상세 보기 Modal
  const openPersonalDetailModal = (index) => {
    setSelectedReservationIndex(index);
    setIsOpen1(true);
  };

  const closePersonalDetailModal = () => {
    setSelectedReservationIndex(null);
    setIsOpen1(false);
  };

  //그룹 상세 보기 Modal
  const openGroupDetailModal = (index) => {
    setSelectedReservationIndex2(index);
    setIsOpen2(true);
  };

  const closeGroupDetailModal = () => {
    setSelectedReservationIndex2(null);
    setIsOpen2(false);
  };

  // 집단 프로그램 상세 보기 Modal
  const openOrganDetailModal = (index) => {
    setSelectedReservationIndex3(index);
    setIsOpen8(true);
  };

  const closeOrganDetailModal = () => {
    setSelectedReservationIndex3(null);
    setIsOpen8(false);
  };

  // 개인 예약 취소 Modal
  const openPersonalCancelModal = (index) => {
    setCancelModalIndex(index);
    setIsOpen3(true);
  };

  const closePersonalCancelModal = () => {
    setCancelModalIndex(null);
    setIsOpen3(false);
  };

  // 그룹 예약 취소 Modal
  const openGroupCancelModal = (index) => {
    setCancelModalIndex2(index);
    setIsOpen4(true);
  };

  const closeGroupCancelModal = () => {
    setCancelModalIndex2(null);
    setIsOpen4(false);
  };

  // 개인 메타버스 입장 Modal
  const openPersonalMetaModal = (index) => {
    setMetaModalIndex(index);
    setIsOpen5(true);
  };

  const closePersonalMetaModal = () => {
    setMetaModalIndex(null);
    setIsOpen5(false);
  };

  // 그룹 메타버스 입장 Modal
  const openGroupMetaModal = (index) => {
    setMetaModalIndex2(index);
    setIsOpen6(true);
  };

  const closeGroupMetalModal = () => {
    setMetaModalIndex2(null);
    setIsOpen6(false);
  };

  //취소 상세 보기 Modal
  const openCancelDetailModal = (index) => {
    setCancelIndex(index);
    setIsOpen7(true);
  };

  const closeCancelDetailModal = () => {
    setCancelIndex(null);
    setIsOpen7(false);
  };


  const customStyles = {
    // Modal 스타일
    overlay: {
      backgroundcolor: "rgba(0,0,0,0.5)",
    },
    content: {
      width: "90%", // 화면 너비의 90%로 조정
      maxWidth: "400px", // 최대 너비 설정
      height: "auto", // 높이 자동 조정
      margin: "auto",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      padding: "20px",
    },
  };

  const o_customStyles = {
    // Modal 스타일
    overlay: {
      backgroundcolor: "rgba(0,0,0,0.5)",
    },
    content: {
      width: "90%", // 화면 너비의 90%로 조정
      maxWidth: "400px", // 최대 너비 설정
      height: "65vh", // 높이 자동 조정
      margin: "auto",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      padding: "20px",
    },
  };


  const cnacel_customStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',
      height: '180px',
      maxWidth: '800px',
      maxHeight: '300px',
      overflow: 'hidden', // 스크롤 바를 숨김
      textAlign: 'center'
    },
  };

  const meta_customStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',
      height: '180px',
      maxWidth: '800px',
      maxHeight: '300px',
      overflow: 'hidden', // 스크롤 바를 숨김
      textAlign: 'center'
    },
  };


  const handleConfirmCancel = (resid) => { // 예약 취소 '네' 버튼
    const res_id = resid;

    // Cancel_request 페이지로 이동
    navigate(`/Cancel_request`, {
      state: {
        reservationNumber: res_id, // 예약 번호 전달
      }
    });
  };

  // 메타버스 클릭 시
  const handleMetaModalOpen = async (res_id) => {
    try {
      let check_roomyn;
      const response = await axios.get(`/api/meta?res_id=${res_id}`);
      // 여기에 요청 성공 시 수행할 동작 추가
      if (response.status === 200) { // HTTP 상태 코드가 200이면
        check_roomyn = 1; // check_roomyn 값을 1로 설정
      } else if (response.status === 201) { // HTTP 상태 코드가 201이면
        check_roomyn = 0; // check_roomyn 값을 0으로 설정
      }

      return check_roomyn;
    } catch (error) {
      // 요청이 실패하면 에러를 콘솔에 출력
      console.error('메타버스 정보 요청 오류:', error);
    }
  };

  const entermeta = async (user_id, res_id) => {
    try {
      const response = await axios.get(`/api/entermeta?user_id=${user_id}&res_id=${res_id}&authority=2`);
    } catch (error) {
      console.error('메타버스 입장 요청 오류:', error);
    }
  };

  const handleButtonClick = (token) => {
    const url = `meetingroom://launch?token=${encodeURIComponent(token)}`;
    window.location.href = url;
  };

  /* 개인 - 메타버스 입장 */
  const handleCombinedClick = () => {
    entermeta(resdata.personalReservations[metamodalIndex].user_id, resdata.personalReservations[metamodalIndex].res_id);
    handleButtonClick();
  };

  /* 그룹 - 메타버스 입장 */
  const handleCombinedClick2 = () => {
    entermeta(resdata.personalReservations[metamodalIndex].user_id, resdata.groupReservations[metamodalIndex2].res_id);
    handleButtonClick();
  };

  // 날짜 문자열을 원하는 형식으로 변환하는 함수
  const formatDate = (dateString) => {
    console.log(dateString);
    try {
      // 날짜 문자열을 ISO 형식으로 변환
      const date = parseISO(dateString);
      // 원하는 형식으로 포맷팅
      return format(date, 'yyyy년 MM월 dd일 HH시 mm분 ss초', { locale: ko });
    } catch (error) {
      console.error('날짜 포맷팅 오류:', error);
      return '기한 없음';
    }
  };

  return (
    <PageContainer title="예약 내역 확인">
      <Grid container spacing={3}>


        <Grid item sm={12}>
          <DashboardCard title="개인 예약">
            <Grid container spacing={3} >
              <Grid item sm={12}>
                <Paper elevation={10}>
                  <BlankCard>
                    <CardContent>


                      <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

                        <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                                  예약 ID
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                                  상담 유형
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                                  상담 분야
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                                  상담 일시
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                                  상담 시간
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                                  상담사
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                                  결제 상태
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                                  상담 상태
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {resdata.personalReservations && resdata.personalReservations.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                              .map((personalReservations, personalIndex) => (
                                <TableRow key={personalIndex}>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{personalReservations.res_id}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{personalReservations.coun_type}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{personalReservations.reservationType}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(personalReservations.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{personalReservations.selectedTime}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{personalReservations.counselor_name}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{personalReservations.deposit_stat}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{personalReservations.counselor_stat}</TableCell>

                                  <TableCell>
                                    <Button variant="contained"
                                      style={{
                                        backgroundColor: "#e8e8e8",
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: '9px',
                                        fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                      }}
                                      onClick={() => openPersonalDetailModal(personalIndex)}>
                                      상세보기 </Button>
                                  </TableCell>

                                  <TableCell>
                                    {resdata.personalReservations[personalIndex].counselor_stat !== "상담 완료" && (
                                      <Button variant="contained"
                                        style={{
                                          backgroundColor: pink[100],
                                          color: 'black',
                                          fontWeight: 'bold',
                                          fontSize: '9px',
                                          fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                        }}
                                        onClick={() => openPersonalCancelModal(personalIndex)}>
                                        예약취소 </Button>
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    {resdata.personalReservations[personalIndex].counselor_stat !== "상담 완료" && (
                                      <Button
                                        variant="contained"
                                        href="#contained-buttons"
                                        style={{
                                          backgroundColor: blue[100],
                                          color: 'black',
                                          fontWeight: 'bold',
                                          fontSize: '9px',
                                          display: 'flex', // 이미지와 텍스트를 가로로 정렬하기 위해 flex 속성 추가
                                          alignItems: 'center' // 이미지와 텍스트를 수직으로 정렬하기 위해 center 정렬
                                        }}
                                        onClick={() => {
                                          openPersonalMetaModal(personalIndex);
                                        }}
                                        startIcon={<img src={meta} alt="Meta Logo" style={{ width: '20px', marginRight: '2px' }} />} // 버튼의 시작 부분에 이미지를 추가
                                      >
                                        메타버스
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}

                            {/*예약취소 Modal(개인) */}
                            <Modal
                              isOpen={isOpen3 && cancelmodalIndex !== null}
                              onRequestClose={closePersonalCancelModal}
                              style={{
                                ...cnacel_customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...cnacel_customStyles.content,
                                  zIndex: 10000 // 모달 내용의 z-index
                                }
                              }}
                            >
                              {cancelmodalIndex !== null && resdata.personalReservations && (
                                <div>
                                  <h5>예약 번호 : {resdata.personalReservations[cancelmodalIndex].res_id}</h5>
                                  <br />
                                  <div>예약을 취소하시겠습니까?</div>
                                  <br />
                                  <br />
                                  <Button
                                    onClick={() => handleConfirmCancel(resdata.personalReservations[cancelmodalIndex].res_id
                                    )}
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      fontSize: '10px',
                                    }}>
                                    네
                                  </Button>&nbsp;
                                  <Button
                                    onClick={closePersonalCancelModal}
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      fontSize: '10px',
                                    }}>
                                    아니요
                                  </Button>&nbsp;

                                </div>
                              )}
                            </Modal>

                            {/*메타버스 Modal(개인) */}
                            <Modal
                              isOpen={isOpen5 && metamodalIndex !== null}
                              onRequestClose={closePersonalMetaModal}
                              onAfterOpen={() => {
                                handleMetaModalOpen(resdata.personalReservations[metamodalIndex].res_id)
                                  .then((result) => {
                                    setCheckRoomyn(result);
                                  });
                              }} style={{
                                ...meta_customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...meta_customStyles.content,
                                  zIndex: 10000, // 모달 내용의 z-index
                                  overflow: 'auto',  // 추가된 스타일
                                  maxHeight: '80vh', // 추가된 스타일
                                }
                              }}>
                              {metamodalIndex !== null && resdata.personalReservations && (
                                <div>
                                  {checkRoomyn === 0 && (
                                    <div>
                                      <h5>예약 번호 : {resdata.personalReservations[metamodalIndex].res_id}</h5>
                                      <br />
                                      <div> 현재 상담 시간이 아닙니다.</div>
                                      <br />
                                      <br />
                                      <Button
                                        onClick={closePersonalMetaModal}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          fontSize: '10px',
                                        }}>
                                        닫기
                                      </Button>&nbsp;
                                    </div>
                                  )}
                                  {checkRoomyn === 1 && (
                                    <div>
                                      <h5>예약 번호 : {resdata.personalReservations[metamodalIndex].res_id}</h5>
                                      <br />
                                      <div> 메타버스 입장이 가능합니다. </div>
                                      <br />
                                      <br />
                                      <Button
                                        onClick={handleCombinedClick}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          fontSize: '10px',
                                        }}>
                                        입장
                                      </Button>&nbsp;
                                      <Button
                                        onClick={closePersonalMetaModal}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          fontSize: '10px',
                                        }}>
                                        닫기
                                      </Button>&nbsp;
                                    </div>
                                  )}
                                </div>
                              )}
                            </Modal>

                            {/*개인 상세보기 Modal */}
                            <Modal
                              isOpen={isOpen1 && selectedReservationIndex !== null}
                              onRequestClose={closePersonalDetailModal}
                              style={{
                                ...customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...customStyles.content,
                                  zIndex: 10000 // 모달 내용의 z-index
                                }
                              }}>
                              {selectedReservationIndex !== null && resdata.personalReservations && (
                                <div>
                                  <div className="modal-p-bold">
                                    <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                      {resdata.personalReservations[selectedReservationIndex].res_id}
                                    </div>

                                    <Button
                                      onClick={closePersonalDetailModal}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        marginLeft: "280px", // 좌측 여백
                                        backgroundColor: "#5D87FF", // 파란색 배경
                                        color: "#fff", // 흰색 글자
                                        fontWeight: "bold", // 굵게 설정
                                        fontSize: "12px", // 글자 크기
                                        padding: "3px 16px", // 내부 여백
                                        borderRadius: "6px", // 약간 둥근 모서리
                                        textTransform: "uppercase", // 대문자 변환
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                        transition: "all 0.3s ease", // 변화 효과 추가
                                        "&:hover": {
                                          backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                          transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                        },
                                      }}
                                    >
                                      닫기
                                    </Button>

                                  </div>
                                  <hr style={{
                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                  }} />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담일시 : {new Date(resdata.personalReservations[selectedReservationIndex].selectedDate).toLocaleDateString('ko-KR')}</Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담 상태 : {resdata.personalReservations[selectedReservationIndex].counselor_stat || '상태 없음'}</Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담사 : {resdata.personalReservations[selectedReservationIndex].counselor_name || '상담사 없음'}</Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담유형 : {resdata.personalReservations[selectedReservationIndex].reservationType || '유형 없음'} </Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담 시간: {resdata.personalReservations[selectedReservationIndex].selectedTime || '시간 없음'}</Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >결제 상태 : {resdata.personalReservations[selectedReservationIndex].deposit_stat || '상태 없음'} </Typography>
                                  <br />
                                  {resdata.personalReservations[selectedReservationIndex].deposit_type === "가상계좌" && resdata.personalReservations[selectedReservationIndex].deposit_stat === "결제 전" && (
                                    <>
                                      <br /><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        총 입금 금액 : {resdata.personalReservations[selectedReservationIndex].totalamount || '금액 없음'}원
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        가상 계좌 은행 : {resdata.personalReservations[selectedReservationIndex].vbankname || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        가상 계좌 번호 : {resdata.personalReservations[selectedReservationIndex].vbank_num || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        가상 계좌 입금자명 : {resdata.personalReservations[selectedReservationIndex].vbank_name || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        가상 계좌 입금 기한 : {formatDate(resdata.personalReservations[selectedReservationIndex].vbank_date) || '기한 없음'}
                                      </Typography>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px", marginLeft: '20px' }}>
                                        * 입금 기한을 꼭 확인 후 입금해 주세요.
                                      </Typography>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px", marginLeft: '20px' }}>
                                        * 입금 기한이 지난 가상 계좌에는 입금이 불가능합니다.
                                      </Typography><br />
                                    </>
                                  )}
                                  <hr style={{
                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                  }} />
                                  {resdata.personalReservations[selectedReservationIndex].deposit_type === "가상계좌" && resdata.personalReservations[selectedReservationIndex].deposit_stat === "결제 완료" && (
                                    <>
                                      <br /><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        환불 시 계좌 은행 : {resdata.personalReservations[selectedReservationIndex].vrefbankname || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        환불 시 계좌 번호 : {resdata.personalReservations[selectedReservationIndex].vref_num || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        환불 시 계좌 입금자명 : {resdata.personalReservations[selectedReservationIndex].vref_name || '계좌 없음'}
                                      </Typography><br />
                                    </>
                                  )}
                                  <br /> <br />
                                  <Typography fontWeight="bold" color="textSecondary" sx={{ fontSize: "13.5px", marginLeft: '20px' }} >상담 요청사항</Typography>
                                  <br />
                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>

                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담시 불러주었으면 하는 호칭이 있습니까?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>
                                            {resdata.personalReservations[selectedReservationIndex].request_1 || '요청사항 없음'}
                                          </pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 현재 하고 있는 일에 대해 소개해 주시겠어요?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>
                                            {resdata.personalReservations[selectedReservationIndex].request_2 || '요청사항 없음'}
                                          </pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 현재 나의 상태를 점수로 표현한다면? <br /> &nbsp; (1~3 : 부정, 4~6 : 긍정)
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.personalReservations[selectedReservationIndex].request_3 ? `${resdata.personalReservations[selectedReservationIndex].request_3} 점` : '요청사항 없음'}
                                          </pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 어떤 주제로 상담을 원하시는지 구체적으로 알려주세요.
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.personalReservations[selectedReservationIndex].request_4 || '요청사항 없음'} </pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담을 통해 얻고싶거나 기대하는 사항은?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.personalReservations[selectedReservationIndex].request_5 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담 전문가에게 추가로 전달하고 싶은 사항은?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.personalReservations[selectedReservationIndex].request_6 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>

                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담을 신청하게 된 동기?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.personalReservations[selectedReservationIndex].request_7 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>

                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 심리상담이나 이와 비슷한 경험을 알려주세요.
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.personalReservations[selectedReservationIndex].request_8 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                </div>

                              )}
                            </Modal >


                          </TableBody>
                        </Table>
                      </Box>


                    </CardContent>

                  </BlankCard>
                </Paper>
              </Grid>
            </Grid>
          </DashboardCard >
        </Grid >

        <Grid item sm={12}>
          <DashboardCard title="그룹 예약">
            <Grid container spacing={3} >
              <Grid item sm={12}>
                <Paper elevation={10}>
                  <BlankCard>
                    <CardContent>


                      <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

                        <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  예약 ID
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  상담 유형
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  상담 일시
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  상담 시간
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  인원 수
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  상담사
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  결제 상태
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  상담 상태
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {resdata.groupReservations && resdata.groupReservations.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                              .map((groupReservations, groupIndex) => (

                                <TableRow key={groupIndex}>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{groupReservations.res_id}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{groupReservations.coun_type}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(groupReservations.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{groupReservations.selectedTime}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{groupReservations.group_number}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{groupReservations.counselor_name}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{groupReservations.deposit_stat}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{groupReservations.counselor_stat}</TableCell>

                                  <TableCell>
                                    <Button variant="contained"
                                      style={{
                                        backgroundColor: "#e8e8e8",
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: '9px',
                                        fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                      }}
                                      onClick={() => openGroupDetailModal(groupIndex)}>
                                      상세보기 </Button>
                                  </TableCell>

                                  <TableCell>
                                    {resdata.groupReservations[groupIndex].counselor_stat !== "상담 완료" && (
                                      <Button variant="contained"
                                        style={{
                                          backgroundColor: pink[100],
                                          color: 'black',
                                          fontWeight: 'bold',
                                          fontSize: '9px',
                                          fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                        }}
                                        onClick={() => openGroupCancelModal(groupIndex)}> 예약취소 </Button>
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    {resdata.groupReservations[groupIndex].counselor_stat !== "상담 완료" && (
                                      <Button
                                        variant="contained"
                                        href="#contained-buttons"
                                        style={{
                                          backgroundColor: blue[100],
                                          color: 'black',
                                          fontWeight: 'bold',
                                          fontSize: '9px',
                                          display: 'flex', // 이미지와 텍스트를 가로로 정렬하기 위해 flex 속성 추가
                                          alignItems: 'center' // 이미지와 텍스트를 수직으로 정렬하기 위해 center 정렬
                                        }}
                                        onClick={() => openGroupMetaModal(groupIndex)}
                                        startIcon={<img src={meta} alt="Meta Logo" style={{ width: '20px', marginRight: '2px' }} />} // 버튼의 시작 부분에 이미지를 추가
                                      >
                                        메타버스
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}

                            {/* 그룹 예약 취소 */}
                            <Modal
                              isOpen={isOpen4 && cancelmodalIndex2 !== null}
                              onRequestClose={closeGroupCancelModal}
                              style={{
                                ...cnacel_customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...cnacel_customStyles.content,
                                  zIndex: 10000 // 모달 내용의 z-index
                                }
                              }}
                            >
                              {cancelmodalIndex2 !== null && resdata.groupReservations && (
                                <div>
                                  <h5>예약 번호 : {resdata.groupReservations[cancelmodalIndex2].res_id}</h5>
                                  <br />
                                  <div>예약을 취소하시겠습니까?</div>
                                  <br />
                                  <br />
                                  <Button
                                    onClick={() => handleConfirmCancel(resdata.groupReservations[cancelmodalIndex2].res_id)}
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      fontSize: '10px',
                                    }}>
                                    네
                                  </Button>&nbsp;
                                  <Button
                                    onClick={closeGroupCancelModal}
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      fontSize: '10px',
                                    }}>
                                    아니요
                                  </Button>&nbsp;

                                </div>
                              )}
                            </Modal>

                            {/*메타버스 Modal(그룹) */}
                            <Modal
                              isOpen={isOpen6 && metamodalIndex2 !== null}
                              onRequestClose={closeGroupMetalModal}
                              onAfterOpen={() => {
                                handleMetaModalOpen(resdata.groupReservations[metamodalIndex2].res_id)
                                  .then((result) => {
                                    setCheckRoomyn(result);
                                  });
                              }} style={{
                                ...meta_customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...meta_customStyles.content,
                                  zIndex: 10000 // 모달 내용의 z-index
                                }
                              }}>
                              {metamodalIndex2 !== null && resdata.groupReservations && (
                                <div>
                                  {checkRoomyn === 0 && (
                                    <div>
                                      <h5>예약 번호 : {resdata.groupReservations[metamodalIndex2].res_id}</h5>
                                      <br />
                                      <div> 현재 상담 시간이 아닙니다.</div>
                                      <br />
                                      <br />
                                      <Button
                                        onClick={closeGroupMetalModal}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          fontSize: '10px',
                                        }}>
                                        닫기
                                      </Button>&nbsp;
                                    </div>
                                  )}
                                  {checkRoomyn === 1 && (
                                    <div>
                                      <h5>예약 번호 : {resdata.groupReservations[metamodalIndex2].res_id}</h5>
                                      <br />
                                      <div> 메타버스 입장이 가능합니다. </div>
                                      <br />
                                      <br />
                                      <Button
                                        onClick={handleCombinedClick2}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          fontSize: '10px',
                                        }}>
                                        입장
                                      </Button>&nbsp;
                                      <Button
                                        onClick={closeGroupMetalModal}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                          fontSize: '10px',
                                        }}>
                                        닫기
                                      </Button>&nbsp;
                                    </div>
                                  )}
                                </div>
                              )}
                            </Modal>

                            {/*그룹 상세보기 Modal */}
                            <Modal isOpen={isOpen2 && selectedReservationIndex2 !== null}
                              onRequestClose={closeGroupDetailModal}
                              style={{
                                ...customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...customStyles.content,
                                  zIndex: 10000 // 모달 내용의 z-index
                                }
                              }}>
                              {selectedReservationIndex2 !== null && resdata.groupReservations && (
                                <div>
                                  <div className="modal-p-bold">
                                    <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                      {resdata.groupReservations[selectedReservationIndex2].res_id}
                                    </div>

                                    <Button onClick={closeGroupDetailModal}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        marginLeft: "280px", // 좌측 여백
                                        backgroundColor: "#5D87FF", // 파란색 배경
                                        color: "#fff", // 흰색 글자
                                        fontWeight: "bold", // 굵게 설정
                                        fontSize: "12px", // 글자 크기
                                        padding: "3px 16px", // 내부 여백
                                        borderRadius: "6px", // 약간 둥근 모서리
                                        textTransform: "uppercase", // 대문자 변환
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                        transition: "all 0.3s ease", // 변화 효과 추가
                                        "&:hover": {
                                          backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                          transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                        },
                                      }}
                                    >
                                      닫기
                                    </Button>


                                  </div>
                                  <hr style={{
                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                  }} />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담일시 : {new Date(resdata.groupReservations[selectedReservationIndex2].selectedDate).toLocaleDateString('ko-KR')}</Typography><br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담 상태 : {resdata.groupReservations[selectedReservationIndex2].counselor_stat || '상태 없음'}</Typography><br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담사 : {resdata.groupReservations[selectedReservationIndex2].counselor_name || '상담사 없음'}</Typography><br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담유형 : {resdata.groupReservations[selectedReservationIndex2].reservationType || '유형 없음'} </Typography><br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담 시간: {resdata.groupReservations[selectedReservationIndex2].selectedTime || '시간 없음'}</Typography><br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >결제 상태 : {resdata.groupReservations[selectedReservationIndex2].deposit_stat || '상태 없음'} </Typography><br />
                                  {resdata.groupReservations[selectedReservationIndex2].deposit_type === "가상계좌" && resdata.groupReservations[selectedReservationIndex2].deposit_stat === "결제 전" && (
                                    <>
                                      <br /><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        총 입금 금액 : {resdata.groupReservations[selectedReservationIndex2].totalamount || '금액 없음'}원
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        가상 계좌 은행 : {resdata.groupReservations[selectedReservationIndex2].vbankname || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        가상 계좌 번호 : {resdata.groupReservations[selectedReservationIndex2].vbank_num || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        가상 계좌 입금자명 : {resdata.groupReservations[selectedReservationIndex2].vbank_name || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        가상 계좌 입금 기한 : {formatDate(resdata.groupReservations[selectedReservationIndex2].vbank_date) || '기한 없음'}
                                      </Typography>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px", marginLeft: '20px' }}>
                                        * 입금 기한을 꼭 확인 후 입금해 주세요.
                                      </Typography>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px", marginLeft: '20px' }}>
                                        * 입금 기한이 지난 가상 계좌에는 입금이 불가능합니다.
                                      </Typography><br />
                                    </>
                                  )}
                                  <hr style={{
                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                  }} />
                                  {resdata.groupReservations[selectedReservationIndex2].deposit_type === "가상계좌" && resdata.groupReservations[selectedReservationIndex2].deposit_stat === "결제 완료" && (
                                    <>
                                      <br /><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        환불 시 계좌 은행 : {resdata.groupReservations[selectedReservationIndex2].vrefbankname || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        환불 시 계좌 번호 : {resdata.groupReservations[selectedReservationIndex2].vref_num || '계좌 없음'}
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                        환불 시 계좌 입금자명 : {resdata.groupReservations[selectedReservationIndex2].vref_name || '계좌 없음'}
                                      </Typography><br />
                                    </>
                                  )}
                                  <br /><br />
                                  <Typography fontWeight="bold" color="textSecondary" sx={{ fontSize: "13.5px", marginLeft: '20px' }} >상담 요청사항</Typography>
                                  <br />
                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담시 불러주었으면 하는 호칭이 있습니까?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.groupReservations[selectedReservationIndex2].request_1 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 현재 하고 있는 일에 대해 소개해 주시겠어요?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.groupReservations[selectedReservationIndex2].request_2 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 현재 나의 상태를 점수로 표현한다면?<br /> &nbsp;  (1~3 : 부정, 4~6 : 긍정)
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{`${resdata.groupReservations[selectedReservationIndex2].request_3} 점` || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 어떤 주제로 상담을 원하시는지 구체적으로 알려주세요.
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.groupReservations[selectedReservationIndex2].request_4 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담을 통해 얻고싶거나 기대하는 사항은?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.groupReservations[selectedReservationIndex2].request_5 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담 전문가에게 추가로 전달하고 싶은 사항은?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.groupReservations[selectedReservationIndex2].request_6 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>

                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담을 신청하게 된 동기?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.groupReservations[selectedReservationIndex2].request_7 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>

                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 심리상담이나 이와 비슷한 경험을 알려주세요.
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{resdata.groupReservations[selectedReservationIndex2].request_8 || '요청사항 없음'}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                </div>

                              )}
                            </Modal >
                          </TableBody>
                        </Table>
                      </Box>

                    </CardContent>

                  </BlankCard>
                </Paper>
              </Grid>
            </Grid>
          </DashboardCard >
        </Grid >

        <Grid item sm={12}>
          <DashboardCard title="집단 프로그램 예약">
            <Grid container spacing={3} >
              <Grid item sm={12}>
                <Paper elevation={10}>
                  <BlankCard>
                    <CardContent>


                      <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

                        <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  예약 ID
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  선택 타입
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  선택 강좌
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  상담 일시
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  상담 시간
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  인원 수
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  예약 상태
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  결제 상태
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                  상담 상태
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {resdata.oReservations && resdata.oReservations.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                              .map((oReservations, organIndex) => (
                                <TableRow key={organIndex}>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{oReservations.res_id}</TableCell>

                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.coun_type}</TableCell>

                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.coun_title}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(oReservations.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.selectedTime}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.totalclient}명</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.res_stat}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.deposit_stat}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.counselor_stat}</TableCell>
                                  <TableCell>
                                    <Button variant="contained"
                                      style={{
                                        backgroundColor: "#e8e8e8",
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: '9px',
                                        fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                      }}
                                      onClick={() => openOrganDetailModal(organIndex)}>
                                      상세보기 </Button>
                                  </TableCell>




                                </TableRow>
                              ))}

                            {/*집단 프로그램 상세보기 Modal */}
                            <Modal isOpen={isOpen8 && selectedReservationIndex3 !== null}
                              onRequestClose={closeOrganDetailModal}
                              style={{
                                ...o_customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...o_customStyles.content,
                                  zIndex: 10000 // 모달 내용의 z-index
                                }
                              }}>
                              {selectedReservationIndex3 !== null && resdata.oReservations && (
                                <div>
                                  <div className="modal-p-bold">
                                    <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                      {resdata.oReservations[selectedReservationIndex3].res_id}
                                    </div>

                                    <Button onClick={closeOrganDetailModal}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        marginLeft: "280px", // 좌측 여백
                                        backgroundColor: "#5D87FF", // 파란색 배경
                                        color: "#fff", // 흰색 글자
                                        fontWeight: "bold", // 굵게 설정
                                        fontSize: "12px", // 글자 크기
                                        padding: "3px 16px", // 내부 여백
                                        borderRadius: "6px", // 약간 둥근 모서리
                                        textTransform: "uppercase", // 대문자 변환
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                        transition: "all 0.3s ease", // 변화 효과 추가
                                        "&:hover": {
                                          backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                          transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                        },
                                      }}>
                                      닫기
                                    </Button>


                                  </div>
                                  <hr style={{
                                    backgroundColor: "#5D87FF", // 파란색으로 변경

                                  }} />

                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >선택 타입 : {resdata.oReservations[selectedReservationIndex3].coun_type || '상태 없음'}</Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >선택 강좌 : {resdata.oReservations[selectedReservationIndex3].coun_title || '상담사 없음'}</Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >상담 일시 : {new Date(resdata.oReservations[selectedReservationIndex3].selectedDate).toLocaleDateString('ko-KR')} </Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >상담 시작 시간 : {resdata.oReservations[selectedReservationIndex3].selectedTime || '시간 없음'}</Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >상담 총 시간 : {resdata.oReservations[selectedReservationIndex3].totalTime || '상태 없음'}시간 </Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >상담 참여 인원 수 : {`${resdata.oReservations[selectedReservationIndex3].totalclient}명` || '상태 없음'}</Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >예약 상태 : {resdata.oReservations[selectedReservationIndex3].res_stat || '상태 없음'} </Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >결제 상태 : {resdata.oReservations[selectedReservationIndex3].deposit_stat || '상태 없음'} </Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px' }} >상담 상태 : {resdata.oReservations[selectedReservationIndex3].counselor_stat || '상태 없음'} </Typography>
                                  <br />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', lineHeight: '1.5' }} > ** 집단 프로그램 결제와 예약 취소는 관리자에게 문의 남겨 주시면 안내 도와드리겠습니다.</Typography>
                                </div >
                              )}
                            </Modal >

                          </TableBody>
                        </Table>
                      </Box>

                    </CardContent>

                  </BlankCard>
                </Paper>
              </Grid>
            </Grid>
          </DashboardCard >
        </Grid >





        <Grid item sm={4}>
          <BlankCard>
            <CardContent>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: 'left' }}>
                          조회 기간 설정
                        </Typography>
                        <Typography sx={{ fontSize: "10px", fontWeight: "500", textAlign: 'left' }}>
                          * 상담 일시 기준
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Select
                          labelId="year-dd"
                          id="year-dd"
                          value={selectedYear}
                          size="small"
                          onChange={handleYearChange}
                        >
                          {years.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}년
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item>
                        <Select
                          labelId="month-dd" ㅁ
                          id="month-dd"
                          value={selectedMonth}
                          size="small"
                          onChange={handleMonthChange}
                        >
                          <MenuItem value={1}>1월</MenuItem>
                          <MenuItem value={2}>2월</MenuItem>
                          <MenuItem value={3}>3월</MenuItem>
                          <MenuItem value={4}>4월</MenuItem>
                          <MenuItem value={5}>5월</MenuItem>
                          <MenuItem value={6}>6월</MenuItem>
                          <MenuItem value={7}>7월</MenuItem>
                          <MenuItem value={8}>8월</MenuItem>
                          <MenuItem value={9}>9월</MenuItem>
                          <MenuItem value={10}>10월</MenuItem>
                          <MenuItem value={11}>11월</MenuItem>
                          <MenuItem value={12}>12월</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
            </CardContent>
          </BlankCard>
        </Grid>

      </Grid >
      <br />

      <Grid item sm={12}>
        <DashboardCard title="취소된 예약">
          <Grid container spacing={3} >
            <Grid item sm={12}>
              <Paper elevation={10}>
                <BlankCard>
                  <CardContent>


                    <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

                      <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                예약 ID
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                상담 유형
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                상담 일시
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                상담 시간
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                상담사
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                결제 상태
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                상담 상태
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {resdata.cancelReservations && resdata.canceloReservations && (
                            [...resdata.cancelReservations, ...resdata.canceloReservations]
                              .sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                              .map((reservation, index) => (
                                <TableRow key={index}>

                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{reservation.res_id}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{reservation.coun_type}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(reservation.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{reservation.selectedTime}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{reservation.counselor_name}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{reservation.deposit_stat}</TableCell>
                                  <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{reservation.counselor_stat}</TableCell>

                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#e8e8e8",
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: '9px',
                                        fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                      }}
                                      onClick={() => openCancelDetailModal(index)}
                                    >
                                      상세보기
                                    </Button>
                                  </TableCell>



                                </TableRow>
                              ))
                          )}

                          {/*취소 예약 상세보기 Modal */}
                          <Modal
                            isOpen={isOpen7 && CancelIndex !== null}
                            onRequestClose={closeCancelDetailModal}
                            style={{
                              ...customStyles,
                              overlay: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                zIndex: 9999 // 오버레이의 z-index
                              },
                              content: {
                                ...customStyles.content,
                                zIndex: 10000 // 모달 내용의 z-index
                              }
                            }}
                          >

                            {CancelIndex !== null && (resdata.cancelReservations || resdata.canceloReservations) && (
                              <div>
                                {([...resdata.cancelReservations, ...resdata.canceloReservations]
                                  .sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                                  .map((reservation, index) => (
                                    index === CancelIndex && ( // 선택한 예약의 인덱스와 일치하는 경우에만 상세 정보 표시
                                      <div key={index}>
                                        <div className="modal-p-bold">
                                          <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                            {reservation.res_id}
                                          </div>

                                          <Button
                                            onClick={closeCancelDetailModal}
                                            variant="contained"
                                            size="small"
                                            style={{
                                              marginLeft: "280px", // 좌측 여백
                                              backgroundColor: "#5D87FF", // 파란색 배경
                                              color: "#fff", // 흰색 글자
                                              fontWeight: "bold", // 굵게 설정
                                              fontSize: "12px", // 글자 크기
                                              padding: "3px 16px", // 내부 여백
                                              borderRadius: "6px", // 약간 둥근 모서리
                                              textTransform: "uppercase", // 대문자 변환
                                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                              transition: "all 0.3s ease", // 변화 효과 추가
                                              "&:hover": {
                                                backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                                transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                              },
                                            }}>
                                            닫기
                                          </Button>
                                        </div>
                                        <hr style={{
                                          backgroundColor: "#5D87FF", // 파란색으로 변경

                                        }} />
                                        {resdata.canceloReservations && resdata.canceloReservations.includes(reservation) && (
                                          <div>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >선택 타입 : {reservation.coun_type || '상태 없음'}</Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >선택 강좌 : {reservation.coun_title || '상담사 없음'}</Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 일시 : {new Date(reservation.selectedDate).toLocaleDateString('ko-KR')} </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 시작 시간 : {reservation.selectedTime || '시간 없음'}</Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 총 시간 : {reservation.totalTime || '상태 없음'}시간 </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 참여 인원 수 : {reservation.totalclient || '상태 없음'} </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >예약 상태 : {reservation.res_stat || '상태 없음'} </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >결제 상태 : {reservation.deposit_stat || '상태 없음'} </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 상태 : {reservation.counselor_stat || '상태 없음'} </Typography>
                                            <br /><br />
                                          </div>
                                        )}
                                        {/* 아래 코드는 resdata.cancelReservations인 경우에만 표시됩니다 */}
                                        {resdata.cancelReservations && resdata.cancelReservations.includes(reservation) && (
                                          <div>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담일시 : {new Date(reservation.selectedDate).toLocaleDateString('ko-KR')} </Typography><br />
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담 상태 : {reservation.counselor_stat || '상태 없음'} </Typography><br />
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담사 : {reservation.counselor_name || '상담사 없음'} </Typography><br />
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담유형 : {reservation.reservationType || '유형 없음'}  </Typography><br />
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >상담 시간: {reservation.selectedTime || '시간 없음'} </Typography><br />
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >결제 상태 : {reservation.deposit_stat || '상태 없음'}  </Typography><br />
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }} >취소 사유 : {reservation.cancelreason || '상태 없음'}  </Typography><br />


                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px' }}>
                                              * 환불 규정
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px", marginLeft: '20px' }}>
                                              - 상담 시작 시간 기준 36시간 전 취소 시 : 100% 환불
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px", marginLeft: '20px' }}>
                                              - 상담 시작 시간 기준 36시간 ~ 12시간 전 취소 시 : 50% 환불
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px", marginLeft: '20px' }}>
                                              - 상담 시작 시간 기준 12시간 이내 취소 시 : 0% 환불
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px", marginLeft: '20px' }}>
                                              - 내담자의 노쇼로 인한 상담 불이행은 환불이 불가능합니다.
                                            </Typography>
                                            <hr style={{
                                              backgroundColor: "#5D87FF", // 파란색으로 변경
                                            }} /><br />
                                            <Typography fontWeight="bold" color="textSecondary" sx={{ fontSize: "13.5px", marginLeft: '20px' }} >상담 요청사항 </Typography>
                                            <br />
                                            {[1, 2, 3, 4, 5, 6, 7, 8].map((requestNum) => (
                                              <Grid item sm={12} key={requestNum}>
                                                <BlankCard>
                                                  <CardContent>
                                                    <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }}>
                                                      {requestDescriptions[requestNum - 1]}
                                                    </Typography><br />
                                                    <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                                      <pre> {reservation[`request_${requestNum}`] || '요청사항 없음'}</pre>
                                                    </Typography>
                                                  </CardContent>
                                                </BlankCard>
                                                <br />
                                              </Grid>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  ))
                                )}
                              </div>
                            )}
                          </Modal>


                        </TableBody>
                      </Table>
                    </Box>



                  </CardContent>

                </BlankCard>
              </Paper>
            </Grid>
          </Grid>
        </DashboardCard >
      </Grid >



    </PageContainer>
  );
};

function MSpan({ space = 510 }) {
  return (
    <span style={{ paddingRight: space }}></span>
  );
}


export default CliReservationManage;