import "./css/App.css";
import { useNavigate, useLocation, BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect } from "react";
import { useAuth } from './AuthContext';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { baselightTheme } from "./bootstrap/theme/DefaultColors.js"

/* 메인화면, 메인 헤더, 하단 정보*/
import Header from "./component/Header.js";
import Header2 from "./component/Header2.js";
import Home from "./component/Home"; // 메인화면
import Footer from "./component/Footer.js"; // 하단 정보

/* 회원가입, 로그인 */
import Signup from "./component/Signup"; // 회원가입 
import Client from "./component/Sign_up/Client_join.js"; // 내담자_회원가입
import Counselor_join from "./component/Sign_up/Counselor_join.js"; // 전문가_회원가입
import Success from "./component/Sign_up/Success.js"; // 내담자_ 회원가입 성공 화면
import Wsuccess from "./component/Sign_up/Wsuccess.js"; // 전문가_ 회원가입 대기 화면

/* 아이디/비밀번호 찾기 */
import Login from "./component/Login/Login.js";
import Find_pw from "./component/Login/Find_pw.js";
import Find_id from "./component/Login/Find_id.js";
import Check_id from "./component/Login/check_id.js";
import Reset_pw from "./component/Login/Reset_pw.js";
import Success_pw from "./component/Login/Success_pw.js";


/* SNS_로그인, 회원가입 */
import Naver_join from "./component/Sign_up/Naver_join.js";
import Kakao_join from "./component/Sign_up/Kakao_join.js";
import Naver_loading from "./component/Sign_up/Naver_loading.js";
import Kakao_loading from "./component/Sign_up/Kakao_loading.js";

/* 전문가 소개 페이지 */
import Search from "./component/Search/Search.js";
import Counselor from "./component/Search/Counselor.js";

/* 상담 예약 페이지 - 개인, 그룹, 집단 순 */
import Reservation from "./component/Reservation/Reservation.js";
import Reservation_request from "./component/Reservation/Reservation_request.js"; // 개인상담_상담신청서
import Cancel_request from "./component/Reservation/Cancel_request.js"; // 상담 취소 신청서

import Group_reservation from "./component/Reservation/Group_reservation.js";
import G_Reservation_request from "./component/Reservation/G_Reservation_request.js";

import O_Reservation from "./component/Reservation/O_Reservation.js";
import O_Reservation_Edu from "./component/Reservation/O_Reservation_Edu.js";
import O_Reservation_Con from "./component/Reservation/O_Reservation_Con.js";
import O_Reservation_Heal from "./component/Reservation/O_Reservation_Heal.js";
import O_Reservation_detail from "./component/Reservation/O_Reservation_detail.js";
import O_Reservation_sus from "./component/Reservation/O_Reservation_sus.js";

/* 결제 - 개인, 그룹 순*/
import Payment from "./component/Reservation/Payment.js";
import Paybank from "./component/Reservation/Paybank.jsx";

import G_Payment from "./component/Reservation/G_Payment.js";
import G_Paybank from "./component/Reservation/G_Paybank.jsx";

import PaybankFail from "./component/Reservation/fail.jsx"; // 결제 실패 화면

/* 커뮤니티 */
import PostMain from "./component/Community/Post/PostMain.js";
import PostView from "./component/Community/Post/PostView.js";
import PostCreate from "./component/Community/Post/PostCreate.js";
import PostChange from "./component/Community/Post/PostChange.js";



/* 내담자 마이페이지 */
import Client_Mypage from "./client/dashboard/Cli_Dashboard.js"; // 내담자_메인 화면
import Client_FullLayout from "./client/layouts/full/FullLayout.js";
import Client_Payment from "./client/cli_component/Cli_Mypage_payment.js";// 결제내역
import Cli_Reservation_manamge1 from "./client/cli_component/Cli_reservation_manage.js"; // 예약 관리
import Cli_con_detail1 from "./client/cli_component/Cli_con_detail.js"; // 상담 내역
import Cli_consultation_list from "./client/cli_component/Cli_consultation_list.js"; // 상담 결과
import Cli_meta from "./client/cli_component/meta.js"; // 내담자_메타버스 설치파일 다운

// 내담자_회원정보
import Client_edit_profile from "./client/cli_component/Edit_Client.js";
import Client_update_profile from "./client/cli_component/Update_Client.js";
import Cli_Check from "./client/cli_component/Cli_Check.js"; // 회원 정보 수정 전 비번 확인
import Sns_client from "./client/cli_component/Sns_client.js"; // sns 회원 정보

// 내담자_커뮤니티 활동 
import Client_community_post from "./client/cli_component/Cli_act_Community.js";
import Client_community_comment from "./client/cli_component/Cli_act_Community_comment.js";

// 내담자_리뷰
import Client_review from "./client/cli_component/Cli_review.js";
import Client_add_review from "./client/cli_component/Add_review.js";
import Cli_review_detail from "./client/cli_component/review_detail.js";
import Cli_change_review from "./client/cli_component/Change _reveiw.js";

// 내담자_ 회원탈퇴
import Client_Leave from "./client/cli_component/Client_Leave.js"; // 자체 회원 탈퇴
import Sns_Client_Leave from "./client/cli_component/Sns_client_leave.js"; // SNS 회원 탈퇴

// 내담자 상담결과
import Client_consultation_info from "./client/cli_component/Consultation_info.js";


/* 전문가 마이페이지 */
import Counselor_Mypage from "./counselor/dashboard/Cou_Dashboard.js"; // 전문가_메인화면
import Counselor_FullLayout from "./counselor/layouts/full/FullLayout.js";
import Con_calculate from "./counselor/counselor_component/Con_calculate.js"; // 정산
import Con_calender from "./counselor/counselor_component/Con_calender.js"; // 상담 일정 관리
import Con_request from "./counselor/counselor_component/Con_request.js"; // 결제 완료 상담 관리
import Cou_meta from "./counselor/counselor_component/meta.js"; // 전문가_메타버스 설치파일 다운
import Con_Check from "./counselor/counselor_component/Con_Check.js" // 회원 정보 수정 전 비번 확인
import Con_time_calendar from "./counselor/counselor_component/Con_time_calendar1.js" // 전문가 상담시간 설정

// 전문가_회원정보
import Counselor_edit_profile from "./counselor/counselor_component/Edit_Counselor.js";
import Counselor_update_profile from "./counselor/counselor_component/Update_Counselor.js";

// 전문가_커뮤니티 활동 
import Counselor_community_post from "./counselor/counselor_component/Cou_act_Community.js";
import Counselor_community_comment from "./counselor/counselor_component/Cou_act_Community__comment.js";

// 전문가_리뷰
import Counselor_review from "./counselor/counselor_component/Cou_review.js"
import Counselor_review_detail from "./counselor/counselor_component/Cou_reveiw_detail.js"





/* 관리자 마이페이지 */
import Admin_Mypage from "./admin/dashboard/Adm_Dashboard.js"; // 관리자_메인화면
import Admin_FullLayout from "./admin/layouts/full/FullLayout.js";
import Admin_calculate from "./admin/admin_component/Admin_calculate.js"; // 정산
import O_Program_Reservation from "./admin/admin_component/O_Program_Reservation.js"; // 집단 프로그램 예약 관리

// 전문가/내담자 관리 
import Client_Manage from "./admin/admin_component/Client_Manage.js"
import Counselor_Manage from "./admin/admin_component/Counselor_Manage.js";

// 전문가 동적 생성 + 전문가 분야 추가, 삭제
import Add_counselor from "./admin/admin_component/Add_counselor.js"; // 등록 된 전문가 
import A_counselor_detail from "./admin/admin_component/A_counselor_detail.js"; // 등록 된 전문가 - 전문가 상세화면
import A_edit_counselor_detail from "./admin/admin_component/A_edit_counselor_detail.js"; // 등록 된 전문가 - 수정

import New_counselor from "./admin/admin_component/New_counselor.js"; // 등록 전 전문가
import New_edit_counselor from "./admin/admin_component/New_edit_counselor.js"; // 등록 전 전문가 - 수정
import New_counselor_detail from "./admin/admin_component/New_counselor_detail.js"; // 등록 전 전문가 - 전문가 상세화면

// 전문가/내담자 강제 탈퇴
import Counselor_Leave from "./admin/admin_component/Counselor_Leave.js";
import Client_Leave2 from "./admin/admin_component/Client_Leave.js";



function App() {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const theme = baselightTheme;
  const navigate = useNavigate();


  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      // 경로가 변경될 때마다 스크롤을 상단으로 이동
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  // 페이지 로드 시 저장된 세션 쿠키 확인
  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch('/api/check-session', {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        if (data.isLoggedIn === true) {
          setIsLoggedIn(data.isLoggedIn);
        } else {
          //navigate("/");
          setIsLoggedIn(data.isLoggedIn);
        }
      } catch (error) {
        navigate("/");
        setIsLoggedIn(false);
      }
    };

    checkSession();
  }, []);

  const location = useLocation();

  const showFooter = !location.pathname.startsWith('/Mypage/client')
    && !location.pathname.startsWith('/Mypage/counselor')
    && !location.pathname.startsWith('/Mypage/admin')
    && !location.pathname.startsWith('/Signup')
    && !location.pathname.startsWith('/Client_join')
    && !location.pathname.startsWith('/Counselor_join')
    && !location.pathname.startsWith('/Login');

  const ProtectedRoute = ({ element, isLoggedIn }) => {
    return isLoggedIn ? element : <Navigate to="/Login" />;
  };


  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <CssBaseline />
      <div className="App">
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route element={<Header />}>
            <Route path="/" element={<Home />} />
          </Route>

          {/* 내담자 마이페이지 */}
          <Route element={<Client_FullLayout />}>
            <Route
              path="/Mypage/client"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_Mypage />} />}
            />
            <Route
              path="/Mypage/client/payment"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_Payment />} />}
            />
            <Route
              path="/Mypage/client/reservation_manage"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Cli_Reservation_manamge1 />} />}
            />
            <Route
              path="/Mypage/client/con_detail"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Cli_con_detail1 />} />}
            />
            <Route
              path="/Mypage/client/Cli_consultation_list"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Cli_consultation_list />} />}
            />
            <Route
              path="/Mypage/client/meta"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Cli_meta />} />}
            />
            <Route
              path="/Mypage/client/community"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_community_post />} />}
            />
            <Route
              path="/Mypage/client/community/comment"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_community_comment />} />}
            />
            <Route
              path="/Mypage/client/review"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_review />} />}
            />
            <Route
              path="/Mypage/client/add_review"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_add_review />} />}
            />
            <Route
              path="/Mypage/client/review_detail/:no"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Cli_review_detail />} />}
            />
            <Route
              path="/Mypage/client/review_change/:no"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Cli_change_review />} />}
            />
            <Route
              path="/Mypage/client/Check"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Cli_Check />} />}
            />
            <Route
              path="/Mypage/client/Client_Leave"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_Leave />} />}
            />
            <Route
              path="/Mypage/client/Sns_Client_Leave"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Sns_Client_Leave />} />}
            />
            <Route
              path="/Mypage/client/edit_Client"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_edit_profile />} />}
            />
            <Route
              path="/Mypage/client/update_Client"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_update_profile />} />}
            />
            <Route
              path="/Mypage/client/sns_client"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Sns_client />} />}
            />
            <Route
              path="/Mypage/client/consultation_info/:no"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_consultation_info />} />}
            />
          </Route>

          {/* 관리자 마이페이지 */}
          <Route element={<Admin_FullLayout />}>
            <Route
              path="/Mypage/admin"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Admin_Mypage />} />}
            />
            <Route
              path="/Mypage/admin/client_manage"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_Manage />} />}
            />
            <Route
              path="/Mypage/admin/counselor_manage"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_Manage />} />}
            />
            <Route
              path="/Mypage/admin/admin_calculate"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Admin_calculate />} />}
            />
            <Route
              path="/Mypage/admin/O_Program_Reservation"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<O_Program_Reservation />} />}
            />
            <Route
              path="/Mypage/admin/add_counselor"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Add_counselor />} />}
            />
            <Route
              path="/Mypage/admin/new_counselor"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<New_counselor />} />}
            />
            <Route
              path="/Mypage/admin/counselor_detail/:id"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<A_counselor_detail />} />}
            />
            <Route
              path="/Mypage/admin/counselor_detail_edit/:id"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<A_edit_counselor_detail />} />}
            />
            <Route
              path="/Mypage/admin/new_counselor_detail/:id"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<New_counselor_detail />} />}
            />
            <Route
              path="/Mypage/admin/new_edit_counselor/:id"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<New_edit_counselor />} />}
            />
            <Route
              path="/Mypage/admin/Counselor_Leave"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_Leave />} />}
            />
            <Route
              path="/Mypage/admin/Client_Leave"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Client_Leave2 />} />}
            />
          </Route>

          {/* 전문가 마이페이지 */}
          <Route element={<Counselor_FullLayout />}>
            <Route
              path="/Mypage/counselor"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_Mypage />} />}
            />
            <Route
              path="/Mypage/counselor/con_calculate"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Con_calculate />} />}
            />
            <Route
              path="/Mypage/counselor/con_calender"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Con_calender />} />}
            />
            <Route
              path="/Mypage/counselor/con_request"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Con_request />} />}
            />
            <Route
              path="/Mypage/counselor/meta"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Cou_meta />} />}
            />
            <Route
              path="/Mypage/counselor/community"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_community_post />} />}
            />
            <Route
              path="/Mypage/counselor/community/comment"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_community_comment />} />}
            />
            <Route
              path="/Mypage/counselor/review"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_review />} />}
            />
            <Route
              path="/Mypage/counselor/review_detail/:no"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_review_detail />} />}
            />
            <Route
              path="/Mypage/counselor/con_time_calendar"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Con_time_calendar />} />}
            />
            <Route
              path="/Mypage/counselor/Check"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Con_Check />} />}
            />
            <Route
              path="/Mypage/counselor/edit_counselor"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_edit_profile />} />}
            />
            <Route
              path="/Mypage/counselor/update_counselor"
              element={<ProtectedRoute isLoggedIn={isLoggedIn} element={<Counselor_update_profile />} />}
            />
          </Route>

          <Route element={<Header2 />}>
            {/* 회원가입, 로그인, 아이디/비밀번호 찾기 */}
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Client_join" element={<Client />} />
            <Route path="/Counselor_join" element={<Counselor_join />} />

            <Route path="/Find_pw" element={<Find_pw />} />
            <Route path="/Find_id" element={<Find_id />} />
            <Route path="/Check_id" element={<Check_id />} />
            <Route path="/Reset_pw" element={<Reset_pw />} />
            <Route path="/Success_pw" element={<Success_pw />} />
            <Route path="/Success" element={<Success />} />
            <Route path="/Wsuccess" element={<Wsuccess />} />

            <Route path="/naver_join" element={<Naver_join />} />
            <Route path="/kakao_join" element={<Kakao_join />} />

            <Route path="/Naver_loading" element={<Naver_loading />} />
            <Route path="/Kakao_loading" element={<Kakao_loading />} />


            {/* 상담사 소개 페이지 */}
            <Route path="/Search" element={<Search />} />
            <Route path="/Counselor/:id" element={<Counselor />} />


            {/* 상담 예약 페이지 - 개인, 그룹, 집단 순 */}
            <Route path="/Counselor/:id/Reservation" element={<Reservation />} />
            <Route path="/Counselor/:id/Reservation_request" element={<Reservation_request />} />
            <Route path="/Cancel_request" element={<Cancel_request />} />
            <Route path="/Counselor/:id/Payment" element={<Payment />} />
            <Route path="/Paybank" element={<Paybank />} />
            <Route path="/G_Paybank" element={<G_Paybank />} />
            <Route path="/PaybankFail" element={<PaybankFail />} />

            <Route path="/Counselor/:id/Group_reservation" element={<Group_reservation />} />
            <Route path="/Counselor/:id/G_Reservation_request" element={<G_Reservation_request />} />
            <Route path="/Counselor/:id/G_payment" element={<G_Payment />} />

            <Route path="/O_Reservation" element={<O_Reservation />} />
            <Route path="/O_Reservation_Edu" element={<O_Reservation_Edu />} />
            <Route path="/O_Reservation_Con" element={<O_Reservation_Con />} />
            <Route path="/O_Reservation_Heal" element={<O_Reservation_Heal />} />
            <Route path="/O_Reservation_detail" element={<O_Reservation_detail />} />
            <Route path="/O_Reservation_sus" element={<O_Reservation_sus />} />


            {/* 커뮤니티 */}
            <Route path="/Community" element={<PostMain />} />
            <Route path="/postView/:no" element={<PostView />} />
            <Route path="/PostCreate" element={<PostCreate />} />
            <Route path="/PostChange/:no" element={<PostChange />} />
          </Route>
        </Routes>

        {showFooter && <Footer />}
      </div>
    </ThemeProvider>
  );
}

export default App;
