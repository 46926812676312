import React, { useState } from "react";
import './Find_pw.css';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Typography, Card, Button } from '@mui/material';
import PageContainer from '../../bootstrap/container/PageContainer';
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const email2s = ['@naver.com', '@google.com', '@hanmail.net', '@nate.com', '@kakao.com'];


export default function Find_id() {

  const navigate = useNavigate();

  const [email, setEmail] = useState(""); // 이메일 앞부분 
  const [email2, setEmail2] = useState(""); // 이메일 뒷부분 
  const user_email = email + email2; // 전체 이메일

  const [name, setName] = useState(""); // 이름


  const [enteredCode, setEnteredCode] = useState('');
  const [verificationResult, setVerificationResult] = useState('');




  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmail2Change = (event) => {
    setEmail2(event.target.value);
  };


  const _searchid = async function () {

    // 이메일 체크
    const email_check = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (name === "") {
      return toast.error('올바른 이름을 입력해주세요.');
    }

    if (email === "" || email2 === "") {
      return toast.error('이메일을 모두 입력해주세요.');
    } else if (!user_email.match(email_check)) {
      return toast.error('올바른 이메일 형식을 입력해주세요.');
    }

    if (!enteredCode) {
      toast.error("이메일이 인증 되지 않았습니다."); // toast로 오류 메시지 표시
      return; // 함수 종료
    }

    navigate(`/Check_id`,
      {
        state: {
          name,
          email: user_email
        }
      })

  };

  const sendEmailToServer = async (email) => { // 서버에서 보내는 인증번호
    try {
      const response = await axios.post('/api/sendEmail', {
        type: 'findid',
        sendEmail: user_email
      });
      toast.success('인증번호가 전송되었습니다. 이메일을 확인해주세요.');

    } catch (error) {
      console.error('Error sending email', error);
    }
  };

  const handleVerification = async () => { // 인증번호 확인
    try {
      const response = await axios.post('/api/verifyCode', { enteredCode: enteredCode });
      if (response.data.success) {
        setVerificationResult('인증 성공!');
        _searchid(name, user_email);
        sendToServer(name, user_email);
      } else {
        setVerificationResult('인증번호가 일치하지 않습니다.');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setVerificationResult('인증 중 오류가 발생했습니다.');
    }
  };


  const sendToServer = async (name, user_email) => { //서버로 이름, 이메일 전송
    try {
      const response = await axios.post('/api/find_id', { name, email: user_email });
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <PageContainer title="아이디 찾기">
      <ToastContainer style={{ zindex: 20 }} />

      <br /><br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>

        <Card elevation={20} sx={{ p: 4, zIndex: 1, width: '100%', maxWidth: '500px', minHeight: '500px ' }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
            아이디 찾기
          </Typography>
          <br /><br /><br /><br /><br />

          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="이름"
              variant="outlined"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}


            />
          </Box>
          <br />
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch', mt: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="email1"
              label="이메일"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              className="email-box1"
            />

            <Select
              value={email2}
              onChange={handleEmail2Change}
              displayEmpty
            >
              <MenuItem value="" disabled>
                선택해주세요
              </MenuItem>
              {email2s.map((email2, index) => (
                <MenuItem key={index}
                  value={email2}>
                  {email2}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch', ml: -5 },
            }}
            noValidate
            autoComplete="off"
          >

            <TextField
              type="text"
              onChange={(e) => setEnteredCode(e.target.value)}
              value={enteredCode}
              label="인증번호"
              variant="outlined"
              fullWidth
              sx={{ mt: 1, width: '100%' }}
            />
            <Button
              onClick={() => sendEmailToServer(`${email}${email2}`)}
              sx={{ width: '100%', mt: 1, py: 2, fontSize: '1.2rem' }} // 크기 조정
            >
              인증번호 전송
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Box>
          <br /><br /><br />


          <Button
            variant="contained"
            onClick={handleVerification}
            fullWidth
            mt={1}
            sx={{ fontSize: '1.4rem' }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleVerification();
              }
            }
            }
          >
            아이디 찾기
          </Button>


        </Card>

      </div>
      <br /><br /><br /><br /><br /><br /><br /><br />

    </PageContainer>



  );
}