import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { naver } = window;

const Naver = () => {
  const navigate = useNavigate();

  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: '0Y6PSDfUcWdAuNwsrp_U',
      callbackUrl: "https://naman.co.kr/Naver_loading",
      clientSecret: 'OSOfLbi_gJ',
      isPopup: false,
      loginButton: { color: 'green', type: 1, height: '35' },

    });


    naverLogin.init();
  };

  useEffect(() => {
    initializeNaverLogin();
  }, []);

  return <div id='naverIdLogin' />;
};

export default Naver;