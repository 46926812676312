import React from 'react';
import './Login.css';
import logo from "./kakao_login_m.png";
import logo2 from "./kakao.png";


const SocialKakao = () => {
    const Rest_api_key = 'e4c7b1d98744a7f982e5570a3ba1ff1c';
    const redirect_uri = 'https://naman.co.kr/Kakao_loading';
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

    const handleLogin = () => {
        window.location.href = kakaoURL;
    };

    return (
        <>
            <button onClick={handleLogin} className="kakao-login-button">
                <img src={logo2} alt="카카오 로그인" />
            </button>
        </>
    );
};

export default SocialKakao;