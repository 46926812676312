import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../AuthContext.js';

const Kakao_loading = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoggedIn } = useAuth();

  const getKakaoToken = async () => {
    const code = new URLSearchParams(location.search).get('code');

    if (!code) return;

    try {
      const response = await axios.post('/api/kakao_callback', { code }, { withCredentials: true });

      if (response.data.success === true && response.data.log === "로그인 성공") {
        setIsLoggedIn(response.data.isLoggedIn);
        localStorage.setItem('isLoggedIn', response.data.isLoggedIn);
        navigate(response.data.redirectUrl, { state: response.data });
      } else if (response.data.success === false && response.data.error === "정지된 계정") {
        setIsLoggedIn(response.data.isLoggedIn);
        localStorage.setItem('isLoggedIn', response.data.isLoggedIn);
        navigate(response.data.redirectUrl, { state: response.data });
      } else if (response.data.success === true && response.data.log === "회원가입 필요") {
        navigate(response.data.redirectUrl, { state: response.data });
      } else {
        toast.error("입력하신 정보를 다시 한번 확인해 주세요.");
      }
    } catch (error) {
      console.error("Error fetching Kakao user info:", error);
      toast.error("입력하신 정보를 다시 한번 확인해 주세요.");
    }
  };

  useEffect(() => {
    getKakaoToken();
  }, []);

  return null;
};

export default Kakao_loading;
