import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../AuthContext.js';

const Naver_loading = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoggedIn } = useAuth();

  const getNaverToken = async () => {
    if (!location.hash) return;

    const token = location.hash.split('=')[1].split('&')[0];

    try {
      const response = await axios.post('/api/naver_callback', { token }, { withCredentials: true });

      if (response.data.success === true && response.data.log === "로그인 성공") {
        setIsLoggedIn(response.data.isLoggedIn);
        localStorage.setItem('isLoggedIn', response.data.isLoggedIn);
        navigate(response.data.redirectUrl); // state는 필요하지 않음
      } else if (response.data.success === false && response.data.error === "정지된 계정") {
        setIsLoggedIn(response.data.isLoggedIn);
        localStorage.setItem('isLoggedIn', response.data.isLoggedIn);
        toast.error("계정이 정지되었습니다. 관리자에게 문의해주세요.");
        navigate(response.data.redirectUrl); // state는 필요하지 않음
      } else if (response.data.success === true && response.data.log === "회원가입 필요") {
        navigate(response.data.redirectUrl); // state는 필요하지 않음
      } else {
        toast.error("입력하신 정보를 다시 한번 확인해 주세요.");
      }

    } catch (error) {
      console.error("Error fetching Naver user info:", error);
      toast.error("입력하신 정보를 다시 한번 확인해 주세요.");
    }
  };

  useEffect(() => {
    getNaverToken();
  }, []);

  return null;
};

export default Naver_loading;
