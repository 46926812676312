import React, { useEffect, useState } from 'react';
import DashboardCard from '../../shared/DashboardCard';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import ProfileImg from '../../../counselor_img/unknown.png';

const Counselor_profile = () => {
  const [w_counselorData, setW_counselorData] = useState({}); // 사용자 데이터

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/Mypage", {
          withCredentials: true,
        });
        setW_counselorData(response.data);
      } catch (error) {
        console.error("마이페이지 데이터 요청 오류:", error);
      }
    }

    fetchUserData();
  }, []);

  return (
    <DashboardCard title="나의 정보">
      <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
        <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {w_counselorData.c_image ? (
                    <img
                      src={w_counselorData.c_image}
                      alt="Counselor"
                      style={{
                        width: '110px',
                        height: '110px',
                        marginRight: '20px',
                        marginTop: '-30px',
                        borderRadius: '5px',
                      }}
                    />
                  ) : (
                    <img
                      src={ProfileImg}
                      alt="Counselor"
                      style={{
                        width: '110px',
                        height: '110px',
                        marginRight: '20px',
                        marginTop: '-30px',
                        borderRadius: '5px',
                      }}
                    />
                  )}
                  <div>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                      이름 : {w_counselorData.couns_name}
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", marginLeft: '10px' }}>
                      아이디 : {w_counselorData.couns_loginid}
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", marginLeft: '10px' }}>
                      전화번호 :  {w_counselorData.couns_phonenum}
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", marginLeft: '10px' }}>
                      이메일 : {w_counselorData.couns_email}
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", marginLeft: '10px' }}>
                      생년월일 :{w_counselorData.birthday}
                    </Typography>
                  </div>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Box>
    </DashboardCard>
  );
};

export default Counselor_profile;
