import React, { useEffect, useState } from 'react';
import DashboardCard from '../../shared/DashboardCard';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import { Link, Typography } from '@mui/material';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const TodayReservation = () => {
  const [reservations, setReservations] = useState([]);
  const navigate = useNavigate();
  
  const today = new Date();
  const koreanOffset = 9 * 60;
  const koreanTime = new Date(today.getTime() + (koreanOffset * 60 * 1000));
  const koreanISOTimeString = koreanTime.toISOString();

  async function fetchReservationData() {
    try {
      const response = await axios.post('/api/Con_calendar', {
        convertDate: koreanISOTimeString
      });
      setReservations(response.data);
    } catch (error) {
      console.error('예약 데이터를 불러오는 중 오류 발생:', error);
    }
  }

  useEffect(() => {
    fetchReservationData();
  }, []);

  // TimelineDot 색상을 순환하여 반환하는 함수
  const getColor = (index) => {
    const colors = ["primary", "secondary", "error", "info", "success"]; // 색상 목록
    return colors[index % colors.length]; // 순환하여 색상 할당
  };

  return (
    <DashboardCard title="오늘의 예약">
      {reservations.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          예약이 없습니다.
        </Typography>
      ) : (
        <Timeline sx={{ p: 0, mb: '-40px' }}>
          {reservations.map((reservation, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent fontWeight="700"> {reservation.selectedTime}</TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color={getColor(index)} variant="outlined" />
                {index !== reservations.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography fontWeight="400">{reservation.res_id} , {reservation.user_name} </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                {reservation.coun_type}, {reservation.coun_type === '개인 상담' ? `${reservation.reservationType}` : `그룹 상담 - ${reservation.group_number} 명`}
                </Typography>

              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </DashboardCard>
  );
};

export default TodayReservation;