import { Outlet } from "react-router-dom";
import { Navigation2 } from './Home/navigation2';

const Header2 = () => {
    return (
        <>
            <Navigation2 />
            <Outlet />
        </>
    )
}
export default Header2;