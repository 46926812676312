import React, { useState, useEffect } from "react";
import './Find_pw.css';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import PageContainer from '../../bootstrap/container/PageContainer';
import { Typography, Card, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import naman_logo from "./naman.png";


export default function Check_id() {
  const [loginId, setLoginId] = useState(''); // 로그인 아이디
  const location = useLocation();
  const { name, email } = location.state;



  useEffect(() => {
    // 서버로부터 로그인 아이디를 받아옴
    axios.post('/api/find_id', { name, email })
      .then(response => {

        if (response.data.length > 0) {
          const loginId = response.data[0].loginid || response.data[0].couns_loginid; // loginid 또는 couns_loginid 사용
          setLoginId(loginId); // loginId 값을 설정
        } else {
          setLoginId(null); // 일치하는 아이디가 없는 경우 null로 설정
        }
      })
      .catch(error => {
        console.error('Error:', error);
        if (error.response && error.response.status === 404) {
          console.log("일치하는 아이디가 없습니다.");
        } else {
          console.log("서버 에러");
        }
      });
  }, [name, email]); // name 또는 email이 변경될 때마다 실행



  return (
    <PageContainer>

      <br /><br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card elevation={20} sx={{ mt: 10, p: 4, zIndex: 1, width: '100%', maxWidth: '480px', minHeight: '200px ' }}>
          <Link to="/">
                    <img
                      src={naman_logo}
                      alt="Logo"
                      style={{
                        height: '32px',
                        width: '140px',
                      
                        overflow: 'hidden',
                    
                        marginBottom: '25px',
                      }}
                    />
                    
                    <hr style={{
                              backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
                              height: '4px',
                              width: '115px',
                              top : '-38px',
                              left : '3px',
                              border: 'none' // 기본 테두리를 제거합니다
                          }} />
                  </Link>



          <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
            {loginId !== null && loginId !== undefined && loginId !== '' ? (
              <div>{name} 님의 아이디는 {loginId}입니다.</div>
            ) : (
              <div>일치하는 아이디가 없습니다.</div>
            )}
          </Typography>
          <br /><br />
          <Typography>
            <Link to="/Login">로그인</Link>
          </Typography>

          <br />
        </Card>

      </div>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </PageContainer>



  );
}
