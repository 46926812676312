import { Outlet } from "react-router-dom";
import { Navigation } from "./Home/navigation";

const Header = () => {
  return (
    <>
      <Navigation />
      <Outlet />
    </>
  );
};
export default Header;
