import React from "react";
import { Link } from 'react-router-dom';

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>나만 서비스 소개</h2>
          <p>
            나만 상담 운영 프로그램
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3 style={{ fontWeight: 600 }}>{d.name}</h3>
                    <p>
                      {d.text.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                </div>
              ))
            : "loading"}
            <Link to="/Search" 
                  className="btn btn-custom btn-lg page-scroll" 
                  style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
              상담 예약
            </Link>
        </div>
      </div>
    </div>
  );
};
