import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import { Box, Table, TableBody, TableCell, TableRow, Typography, TableHead } from '@mui/material';

import ProfileImg1 from '../assets/images/profile/user-1.jpg';
import ProfileImg2 from '../assets/images/profile/user-2.jpg';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const Update_Client = () => {
  const [userData, setUserData] = useState({}); // 사용자 데이터
  const navigate = useNavigate();
  const [pw, setPw] = useState("");
  const [checkpw, setCheckpw] = useState("");

  const location = useLocation();
  const state = location.state || {}; // null 대신 빈 객체로 초기화
  const { check } = state;

  if (!check || check !== 'OK') {
    navigate('/Mypage/client/Check');
    console.log("잘못된 접근입니다.");
  }

  // 로그인 상태가 아닐 시 로그인 페이지로 전환
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    navigate('/Login');
  }

  const goToEdit = () => {
    navigate(`/Mypage/client/edit_Client`, {
      state: {
        check: "OK"
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/UpdatedUserData', { withCredentials: true });
        setUserData(response.data);
      } catch (error) {
        console.error('마이페이지 데이터 요청 오류:', error);
      }
    };

    fetchData();
  }, []);

  const isPasswordValid = (pw) => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,16}$/;
    return passwordRegex.test(pw);
  };

  const handleUpdate = () => {
    if (pw !== checkpw) {
      toast.error("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return;
    }

    if (!isPasswordValid(pw)) {
      toast.error(
        "비밀번호는 (영문자/숫자/특수문자 조합 8자 ~ 16자)여야 합니다."
      );
      return;
    }

    axios.put('/api/updateClient', { pw: pw }, { withCredentials: true })
      .then(response => {
        console.log('사용자 정보 업데이트 성공');
      })
      .catch(error => {
        console.error('사용자 정보 업데이트 오류:', error);
      });

    goToEdit();
  };

  const handleLeave = () => {
    navigate("/Mypage/client/Client_Leave", {
      state: {
        loginid: userData.loginid,
        user_id: userData.user_id,
        authority: userData.authority,
        check: "OK"
      }
    });
  };

  return (
    <PageContainer title="프로필 수정" >
      <ToastContainer />
      <DashboardCard title="나의 정보 수정" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Paper elevation={10}>
          <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
            <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                      <img
                        src={userData.gender === 1 ? ProfileImg1 : ProfileImg2}
                        alt="ProfileImg"
                        style={{
                          width: '110px',
                          height: '110px',

                          marginTop: '-280px',
                          borderRadius: '5px',
                          marginLeft: '60px'
                        }}
                      />

                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#5D87FF",
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '11px',
                          top: "-230px",
                          left: "700px",
                          height: '30px',

                        }}
                        onClick={goToEdit}>
                        취소
                      </Button>



                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#5D87FF",
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '11px',
                          top: "-230px",
                          left: "710px",
                          height: '30px',
                        }}
                        onClick={handleUpdate}>
                        저장
                      </Button>



                      <div style={{ marginLeft: '-30px' }}>
                        <br /><br /><br />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                          이름 : {userData.name}
                        </Typography>
                        <br />
                        <br />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          아이디 : {userData.loginid}
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          <TextField
                            label="비밀번호 변경"
                            variant="filled"
                            color="primary"
                            focused
                            type="password"
                            onChange={(event) => {
                              setPw(event.target.value);
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: '45px', // 원하는 높이 값으로 설정
                              },
                            }} />
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          <TextField
                            label="비밀번호 변경 확인"
                            variant="filled"
                            color="primary"
                            focused
                            type="password"
                            onChange={(event) => {
                              setCheckpw(event.target.value);
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: '45px', // 원하는 높이 값으로 설정
                              },
                            }} />
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          이메일 : {userData.email}
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          전화번호 : {userData.phonenum}
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          생년월일 : {userData.birthday}
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          성별 : {userData.gender === 1 ? '남자' : '여자'}
                        </Typography>
                        <br /><br />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Button
                            onClick={handleLeave}
                            sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "3", border: 'none', background: 'none', cursor: 'pointer' }}
                          >
                            회원 탈퇴
                          </Button>
                        </Box>
                      </div>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>

          </Box>
        </Paper>

      </DashboardCard>


    </PageContainer>
  );
};

export default Update_Client;