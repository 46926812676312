import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Typography, Card, Button } from '@mui/material';
import PageContainer from '../../bootstrap/container/PageContainer';
import naman_logo from "../Login/naman.png";

export default function Success() {
  const location = useLocation();
  const { state } = location;


  return (
    <div className="login-title">

      <PageContainer title="가입 완료" sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>


        <br /><br /><br /><br /><br /><br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>

          <Card elevation={20} sx={{ p: 4, zIndex: 1, width: '500px', minHeight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>


            <Link to="/">
              <img
                src={naman_logo}
                alt="Logo"
                style={{
                  height: '32px',
                  width: '140px',
                  overflow: 'hidden',
                  display: 'block',
                  marginBottom: '25px',
                }}
              />
            </Link>



            <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
              가입 완료
            </Typography>
            <br />

            <Typography style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }} sx={{ fontSize: "15px", fontWeight: "400", textAlign: "left" }}>
              나만 회원이 되신걸 환영합니다.
            </Typography>

            <br />

          </Card>
        </div>
        <br /><br /><br /><br />
      </PageContainer>
    </div>
  );
}
