import React from "react";


export const Main = (props) => {
  const normalTitle = "메타버스 심리 상담소";
  const breakTitle = "메타버스<br/>심리<br/>상담소";

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? (
                    <>
                      <span className="normal-line" style={{ fontFamily: 'NotoSansKR-Black, sans-serif' }}>
                        {normalTitle}
                      </span>
                      <span
                        className="break-line"
                        dangerouslySetInnerHTML={{ __html: breakTitle }}
                        style={{ fontFamily: 'NotoSansKR-Black, sans-serif' }}
                      ></span>
                    </>
                  ) : (
                    "Loading"
                  )}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#about"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
