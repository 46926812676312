import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import N_DashboardCard from '../shared/DashboardCard_add';
import axios from 'axios';
import { Typography, Grid, Paper, Button, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Anew_counselor.css';
import ProfileImg from '../assets/images/profile/blank_profile.png';


const Add_counselor = () => {
  const navigate = useNavigate();
  const [cdetail, setCdetail] = useState([]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cdeatilResponse = await axios.get('/api/admin_counselorall', {});
        const cdetailData = cdeatilResponse.data.alldetaildata;
        setCdetail(cdetailData);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, []);



  return (
    <PageContainer title="등록전 전문가">
      <DashboardCard title="등록전 전문가"
        action = {
          <Typography sx={{ fontSize: "14.5px", fontWeight: "700" }}>
             <Link
              to="/Mypage/admin/add_counselor"
              style={{ fontWeight: 700 }}>등록된 전문가</Link>
          </Typography>
        }>


        <br/><br/>
        
        <Grid container spacing={3}>
          {cdetail.map((counselorItem) => (
            <Grid item xs={12} sm={6} lg={4} key={counselorItem.num_review}>
              <Paper elevation={5}>
                <N_DashboardCard>
                <TableContainer style={{ maxHeight: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                        <Typography sx={{ fontSize: "13.5px", fontWeight: "450" }}>
                        {counselorItem.c_image ? (
                          <img
                            src={counselorItem.c_image}
                            alt="Counselor Image"
                            className="acouns_img"
                          />
                        ) : (
                          <img
                            src={ProfileImg}
                            alt="Profile Image"
                            className="acouns_img"
                          />
                        )}

                          </Typography>
                          <br />
                          <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                            전문가 ID : {counselorItem.c_id}
                          </Typography>
                          <br/>
                          <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                            이름: {counselorItem.c_name}
                          </Typography>
                          <br />
                          <Typography sx={{ fontSize: "13.5px", fontWeight: "400", whiteSpace: 'pre-line', lineHeight: '1.5' }}>
                            소개: {counselorItem.c_description}
                          </Typography>
                          <br />

                          <Button variant="contained" 
                                  style={{ 
                                    backgroundColor: "#5D87FF", 
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '10.5px',
                                    marginLeft: '-2px',
                                    marginTop : '10px'
                                  }}  
                                  onClick={() => navigate(`/Mypage/admin/new_counselor_detail/${counselorItem.id}`, {
                                    state: {
                                      id: counselorItem.id,
                                    }
                                  })}
                                  
                              >
                            자세히 보기
                          </Button> &nbsp;

                      
                          
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                  </TableContainer>
                </N_DashboardCard>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
};

export default Add_counselor;