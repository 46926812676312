import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import Calendar from "react-calendar";
import Button from '@mui/material/Button';
import { Typography, Grid, Table, CardContent, TableHead, TableRow, TableCell } from '@mui/material';
import Paper from '@mui/material/Paper';
import Modal from "react-modal"
import BlankCard from '../shared/BlankCard';
import "./Con_calender.css";
import moment from 'moment';
import axios from 'axios';


import "react-datepicker/dist/react-datepicker.css"; // 스타일시트를 import
import DatePicker from "react-datepicker";


const Con_calender = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({}); // 사용자 데이터

  const today = new Date();

  const [selectedDate, setSelectedDate] = useState(today); // 초기값을 오늘 날짜로 설정
  const [reservationData, setReservationData] = useState([]);

  const [isOpen, setIsOpen] = useState(false); // 팝업
  const [isOpen2, setIsOpen2] = useState(false); // 팝업2

  const [selectedReservationIndex, setSelectedReservationIndex] = useState(null); // 상세보기 Modal
  const [metaIndex, setMetaModalIndex] = useState(null); // 메타버스 Modal의 상태

  useEffect(() => {
    fetchReservationData(selectedDate);
  }, [selectedDate]); // selectedDate가 변경될 때마다 예약 데이터를 가져오도록 설정

  const handleClickDate = async (date) => {
    if (!(date instanceof Date && !isNaN(date))) { // 선택된 날짜가 유효한지 검사
      console.error('유효하지 않은 날짜입니다.');
      return;
    }
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    setSelectedDate(newDate);
  };

  const fetchReservationData = async (date) => {
    try {
      const convertedDate = new Date(date); // 클라이언트에서 전송된 지역 시간을 Date 객체로 변환
      convertedDate.setHours(convertedDate.getHours() + 9); // UTC 기준에서 한국 시간대로 변환
      const convertDateISOString = convertedDate.toISOString(); // 변환된 시간을 ISO 8601 형식으로 변환

      const response = await fetch('/api/Con_calendar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ convertDate: convertDateISOString })
      });
      const data = await response.json();
      setReservationData(data);
    } catch (error) {
      console.error('데이터 가져오기 오류:', error);
    }
  };

  const entermeta = async (user_id, res_id) => {
    try {
      const response = await axios.get(`/api/entermeta?user_id=${user_id}&res_id=${res_id}&authority=1`);
    } catch (error) {
      console.error('메타버스 입장 요청 오류:', error);
    }
  };

  const handleCombinedClick = () => {
    window.location.href = "meetingroom://launch";
  };
  

  // 메타버스 실행
  const handleButtonClick = async (user_id, res_id) => {
    entermeta(user_id, res_id);
    handleCombinedClick();
  };



  /* 상담 보고서 다운 */
  const handleDownloadReport = async (item) => {
    try {
      const requestOptions = {
        method: 'GET', // 서버에서 GET 요청으로 처리하도록 설정
      };

      const response = await fetch(`/api/download/${item.res_id}`, requestOptions);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${item.res_id}_상담보고서.xlsx`; // 다운로드할 파일 이름 설정
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('상담보고서 다운로드 중 오류 발생:', error);
      // 오류 처리
    }
  };

  const customStyles = {
    overlay: {
      backgroundcolor: "rgba(0,0,0,0.5)",
    },
    content: {
      width: "90%", // 화면 너비의 90%로 조정
      maxWidth: "400px", // 최대 너비 설정
      height: "auto", // 높이 자동 조정
      margin: "auto",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      padding: "20px",
    },
  };


  const meta_customStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',
      height: '180px',
      maxWidth: '800px',
      maxHeight: '300px',
      overflow: 'hidden', // 스크롤 바를 숨김
      textAlign: 'center'
    },
  };


  //상세보기 Modal
  const openModal = (index) => {
    setSelectedReservationIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedReservationIndex(null);
    setIsOpen(false);
  };

  //메타 Moal
  const openMetaModal = (index) => {
    setMetaModalIndex(index);
    setIsOpen2(true);
  };

  const closeMetaModal = () => {
    setMetaModalIndex(null);
    setIsOpen2(false);
  };


  return (
    <PageContainer title="상담 일정" description="this is Sample page">
      <DashboardCard title="상담 일정">
        <Grid container spacing={2}>
          {/* 왼쪽 그리드에 캘린더 */}
          <Grid item xs={12} sm={7} lg={5} style={{ display: 'flex', paddingTop: '3.5rem', paddingLeft: '4rem' }}>
            <div>
              <Calendar
                className="calender-con"
                onChange={handleClickDate}
                value={selectedDate}
                formatDay={(locale, date) => moment(date).format("DD")} // 날'일' 제외하고 숫자만 보이도록 설정
                minDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
                maxDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
              />
            </div>
          </Grid>
          {/* 오른쪽 그리드에 데이터 값들 */}
          <Grid item xs={12} sm={7} lg={5} style={{ minHeight: '400px' }}>
            <div>
              <Paper style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                <Table>
                  <TableHead>
                    {reservationData.length === 0 ? (

                      <TableRow>
                        <Paper elevation={8}>
                          <TableCell colSpan={3} align="center">
                            <Typography variant="h3">예약이 없습니다.</Typography>
                          </TableCell>
                        </Paper>
                      </TableRow>

                    ) : (
                      reservationData.map((item, index) => (
                        <Paper elevation={5} style={{ marginTop: '30px' }}>
                          <TableRow key={item.res_id}>
                            <TableCell align="left">
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                예약 ID : {item.res_id}
                              </Typography>
                              <br />
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "4700" }}>
                                상담 일시 : {new Date(item.selectedDate).toLocaleDateString('ko-KR')}
                              </Typography>
                              <br />
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                상담 시간 : {item.selectedTime} ({item.totalTime}시간)
                              </Typography>
                              <br />
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                상담 상태 : {item.counselor_stat}
                              </Typography>
                              <br />
                              <br />
                              <Button variant="contained"
                                style={{
                                  backgroundColor: "#e8e8e8",
                                  color: 'black',
                                  fontWeight: 'bold',
                                  fontSize: '9px',
                                  marginLeft: '5px'
                                }}
                                onClick={() => openModal(index)}
                              >
                                상세보기
                              </Button> &nbsp;
                              {item.counselor_stat === "상담 완료" && (
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#f7cac9",
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '9px',
                                    marginLeft: '5px'

                                  }}
                                  onClick={() => handleDownloadReport(item)}
                                >
                                  상담보고서
                                </Button>
                              )}

                              {item.counselor_stat !== "상담 완료" && (
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#5D87FF",
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '9px',
                                    marginLeft: '5px'
                                  }}
                                  onClick={() => openMetaModal(index)} // 함수 호출을 래퍼 함수로 변경
                                >
                                  메타버스 입장
                                </Button>
                              )}

                              {/*상세보기 Modal */}
                              <Modal isOpen={isOpen && selectedReservationIndex === index}
                                onRequestClose={closeModal}
                                style={{
                                  ...customStyles,
                                  overlay: {
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                    zIndex: 9999 // 오버레이의 z-index
                                  },
                                  content: {
                                    ...customStyles.content,
                                    zIndex: 10000 // 모달 내용의 z-index
                                  }
                                }}>

                                <div>
                                  <div className="modal-p-bold">
                                    <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                      {item.res_id}
                                    </div>

                                    <Button onClick={closeModal}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        marginLeft: "280px", // 좌측 여백
                                        backgroundColor: "#5D87FF", // 파란색 배경
                                        color: "#fff", // 흰색 글자
                                        fontWeight: "bold", // 굵게 설정
                                        fontSize: "12px", // 글자 크기
                                        padding: "3px 16px", // 내부 여백
                                        borderRadius: "6px", // 약간 둥근 모서리
                                        textTransform: "uppercase", // 대문자 변환
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                        transition: "all 0.3s ease", // 변화 효과 추가
                                        "&:hover": {
                                          backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                          transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                        },
                                      }}>
                                      닫기
                                    </Button>


                                  </div>
                                  <hr style={{
                                    backgroundColor: "#5D87FF", // 파란색으로 변경

                                  }} />
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 유형 : {item.coun_type} </Typography>
                                  {item.coun_type == "개인 상담" && (
                                    <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 분야  : {item.reservationType} 상담 </Typography>
                                  )}

                                  {item.coun_type == "그룹 상담" && (
                                    <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >인원 수  : {item.group_number}명 </Typography>
                                  )}
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >내담자 이름 : {item.user_name} </Typography>
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >내담자 생년월일  : {item.user_birth} </Typography>
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >내담자 ID : {item.user_id} </Typography>

                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담일시 : {new Date(item.selectedDate).toLocaleDateString('ko-KR')} </Typography>
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 시간: {item.selectedTime} {"("}
                                    {item.totalTime}시간{")"}{" "} </Typography>
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '20px' }} >결제 상태 :  {item.deposit_stat}  </Typography>
                                  <Typography fontWeight="bold" color="textSecondary" sx={{ fontSize: "13.5px", marginLeft: '20px' }} >상담 요청사항</Typography>
                                  <br />
                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담시 불러주었으면 하는 호칭이 있습니까?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>
                                            {item.request_1}
                                          </pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 현재 하고 있는 일에 대해 소개해 주시겠어요?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{item.request_2}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 현재 나의 상태를 점수로 표현한다면? (1~3 : 부정, 4~6 : 긍정)
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{item.request_3}</pre>

                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 어떤 주제로 상담을 원하시는지 구체적으로 알려주시겠어요?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{item.request_4}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담을 통해 얻고싶거나 기대하는 사항은?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{item.request_5}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담 전문가에게 추가로 전달하고 싶은 사항은?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{item.request_6}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>

                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 상담을 신청하게 된 동기?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{item.request_7}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>

                                  <br />

                                  <Grid item sm={12}>
                                    <BlankCard>
                                      <CardContent>
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                          - 심리상담이나 이와 비슷한 경험이 있다면 알려주시겠어요?
                                        </Typography><br />
                                        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                          <pre>{item.request_8}</pre>
                                        </Typography>
                                      </CardContent>
                                    </BlankCard>
                                  </Grid>
                                </div>
                              </Modal>

                              {/*메타버스 Modal */}
                              <Modal
                                isOpen={isOpen2 && metaIndex === index} // metaModalIndex로 수정
                                onRequestClose={closeMetaModal}
                                style={{
                                  ...meta_customStyles,
                                  overlay: {
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                    zIndex: 9999 // 오버레이의 z-index
                                  },
                                  content: {
                                    ...meta_customStyles.content,
                                    zIndex: 10000 // 모달 내용의 z-index
                                  }
                                }}
                              >
                                <div>
                                  <h5>예약 번호 : {reservationData[metaIndex]?.res_id}</h5><br />
                                  <div>메타버스 입장이 가능합니다.</div>
                                  <br />
                                  <br />
                                  <Button
                                    onClick={() => handleButtonClick(reservationData[metaIndex]?.counselor_id, reservationData[metaIndex]?.res_id)} // 함수 호출을 래퍼 함수로 변경
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      fontSize: '10px',
                                    }}
                                  >
                                    입장
                                  </Button>&nbsp;
                                  <Button
                                    onClick={closeMetaModal}
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      fontSize: '10px',
                                    }}
                                  >
                                    아니요
                                  </Button>&nbsp;
                                </div>
                              </Modal>

                            </TableCell>
                          </TableRow>
                        </Paper>
                      ))
                    )}
                  </TableHead>
                </Table>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
};

function MSpan({ space = 500 }) {
  return <span style={{ paddingRight: space }}></span>;
}

export default Con_calender;