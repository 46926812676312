import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Select, MenuItem } from '@mui/material';

const Clicon_deatil = () => {
  const navigate = useNavigate();
  const [completedReservations, setCompletedReservations] = useState([]);
  const [b_review, setBreview] = useState([]);
  const [isOpen8, setIsOpen8] = useState(false); // 집단 프로그램 상세 보기 팝업
  const [selectedReservationIndex3, setSelectedReservationIndex3] = useState(null); // 상세보기 Modal

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const years = Array.from({ length: 11 }, (v, i) => new Date().getFullYear() - i);

  // 집단 프로그램 상세 보기 Modal
  const openOrganDetailModal = (index) => {
    setSelectedReservationIndex3(index);
    setIsOpen8(true);
  };

  const closeOrganDetailModal = () => {
    setSelectedReservationIndex3(null);
    setIsOpen8(false);
  };

  const fetchCompletedReservations = async () => {
    try {
      const queryParams = new URLSearchParams({
        selectedMonth,
        selectedYear,
      }).toString();
      const response = await axios.get(`/api/CompletedReservations?${queryParams}`);
      const completeddata = response.data.completeddata;
      setCompletedReservations(completeddata);
    } catch (error) {
      console.error('예약 정보 가져오기 오류:', error);
    }
  };

  const fetchBreviewData = async () => {
    try {
      const queryParams = new URLSearchParams({
        selectedMonth,
        selectedYear,
      }).toString();
      const response = await axios.get(`/api/Checkreview?${queryParams}`);
      const breviewData = response.data.reviewData;
      setBreview(breviewData);
    } catch (error) {
      console.error('후기 데이터 가져오기 오류:', error);
    }
  };

  useEffect(() => {
    fetchCompletedReservations(selectedMonth, selectedYear);
    fetchBreviewData(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  // 후기 작성 버튼을 렌더링할지 여부를 결정하는 함수
  const shouldRenderReviewButton = (reservation) => {
    // reservation의 res_id가 breviewData에 있는지 확인
    return !b_review.find(item => item.res_id === reservation.res_id);
  };

  /* 내담자 상담 보고서 다운 - 내담자 */

  const handleDownloadReport = async (reservation) => {
    try {
      const requestOptions = {
        method: 'GET', // 서버에서 GET 요청으로 처리하도록 설정
      };

      // 서버로부터 PNG 파일 다운로드 요청
      const response = await fetch(`/api/cli_download/${reservation.res_id}`, requestOptions);
      const blob = await response.blob(); // Blob 객체로 변환

      // Blob을 다운로드할 수 있는 URL로 변환
      const url = window.URL.createObjectURL(blob);

      // 가상의 링크 엘리먼트 생성하여 다운로드 진행
      const a = document.createElement('a');
      a.href = url;
      a.download = `${reservation.res_id}_상담보고서.png`; // 다운로드할 파일 이름 설정
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('파일 다운로드 중 오류 발생:', error);
      // 오류 처리
    }
  };

  /* 녹음파일 */
  const handleDownloadrecord = async (reservation, extension = 'm4a') => {
    try {
        const requestOptions = {
            method: 'GET', // 서버에서 GET 요청으로 처리하도록 설정
        };

        // 서버로부터 파일 다운로드 요청
        const response = await fetch(`/api/cli_record_download/${reservation.res_id}?extension=${extension}`, requestOptions);

        if (!response.ok) {
            throw new Error('파일 다운로드에 실패했습니다.');
        }

        const blob = await response.blob(); // Blob 객체로 변환

        // Blob을 다운로드할 수 있는 URL로 변환
        const url = window.URL.createObjectURL(blob);

        // 가상의 링크 엘리먼트 생성하여 다운로드 진행
        const a = document.createElement('a');
        a.href = url;
        a.download = `${reservation.res_id}_상담녹음.${extension}`; // 다운로드할 파일 이름
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // 메모리 누수 방지
    } catch (error) {
        console.error('파일 다운로드 중 오류 발생:', error);
        
    }
};



  

  const customStyles = { //Modal 스타일
    overlay: {
      backgroundcolor: "rgba(0,0,0,0.5)",
    },
    content: {
      width: "750px",
      height: "500px",
      margin: "auto",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      padding: "20px",
    },
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value); // 월 선택
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // 년도 선택
  };

  return (
    <PageContainer title="상담 내역">
      <DashboardCard title={
        <>

          <Typography style={{ fontWeight: "700", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '20px', whiteSpace: 'nowrap' }}>
            완료 상담
          </Typography>
          <hr style={{
            backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
            height: '4px',
            width: '69px',
            margin: '10px auto', // 중앙 정렬을 위한 마진 설정
            border: 'none',
            marginLeft: '4px', // 왼쪽 정렬
            marginTop: '14px',

          }} />
        </>

      }
        action={
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: window.innerWidth <= 560 ? 'column' : 'row',
            gap: '30px'
          }}>
            <Typography sx={{ fontSize: "14.5px", fontWeight: "700" }}>
              <Link
                to="/Mypage/client/review"
                style={{ fontWeight: 700 }}>내가 작성한 후기</Link>
            </Typography>
            <Grid item sm={4}>
              <Paper elevation={10}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: 'left' }}>
                        조회 기간 설정
                      </Typography>
                      <Typography sx={{ fontSize: "10px", fontWeight: "500", textAlign: 'left' }}>
                        * 상담 일시 기준
                      </Typography>
                      <br />
                      <Grid container spacing={1}>
                        <Grid item>
                          <Select
                            labelId="year-dd"
                            id="year-dd"
                            value={selectedYear}
                            size="small"
                            onChange={handleYearChange}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}년
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item>
                          <Select
                            labelId="month-dd"
                            id="month-dd"
                            value={selectedMonth}
                            size="small"
                            onChange={handleMonthChange}
                          >
                            <MenuItem value={1}>1월</MenuItem>
                            <MenuItem value={2}>2월</MenuItem>
                            <MenuItem value={3}>3월</MenuItem>
                            <MenuItem value={4}>4월</MenuItem>
                            <MenuItem value={5}>5월</MenuItem>
                            <MenuItem value={6}>6월</MenuItem>
                            <MenuItem value={7}>7월</MenuItem>
                            <MenuItem value={8}>8월</MenuItem>
                            <MenuItem value={9}>9월</MenuItem>
                            <MenuItem value={10}>10월</MenuItem>
                            <MenuItem value={11}>11월</MenuItem>
                            <MenuItem value={12}>12월</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Paper>
            </Grid>
          </div>
        }>

        <Grid container spacing={3}>
          {completedReservations.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate)).map((reservation, index) => (
            <Grid item xs={12} sm={6} lg={4} key={reservation.res_id}>
              <Paper elevation={5}> {/* 그림자를 추가합니다. */}
                <DashboardCard>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                            예약 ID : {reservation.res_id}
                          </Typography>
                          <br />
                          <Typography sx={{ fontSize: "13.5px", fontWeight: "4700" }}>
                            상담 일시 : {new Date(reservation.selectedDate).toLocaleDateString('ko-KR')}
                          </Typography>
                          <br />
                          <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                            상담 시간 : {reservation.selectedTime} ({reservation.totalTime}시간)
                          </Typography>
                          <br />
                          <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                            상담사 : {reservation.counselor_name} 상담사
                          </Typography>
                          <br />
                          {reservation.counselor_name === "집단 프로그램" ? (
                            <div>
                              <Button variant="contained"
                                style={{
                                  backgroundColor: "#e8e8e8",
                                  color: 'black',
                                  fontWeight: 'bold',
                                  fontSize: '9px',
                                  marginLeft: "5px",
                                }}
                                onClick={() => openOrganDetailModal(index)}>
                                상세보기 </Button></div>
                          ) : (
                            <div>
                              {shouldRenderReviewButton(reservation) && (
                                <Button variant="contained"
                                  style={{
                                    backgroundColor: "#5D87FF",
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '9px',
                                    marginLeft: '5px'
                                  }}
                                  onClick={() => navigate(`/Mypage/client/add_review`,
                                    {
                                      state: {
                                        res_id: reservation.res_id,
                                        counselor_name: reservation.counselor_name,
                                        counselor_id: reservation.counselor_id,
                                        time: reservation.selectedTime,
                                        total_time: reservation.totalTime,
                                        date: new Date(reservation.selectedDate).toLocaleDateString('ko-KR'),
                                      }
                                    })}
                                >
                                  후기 작성
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#e8e8e8",
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "9px",
                                  marginLeft: "5px",
                                }}
                                onClick={() => handleDownloadReport(reservation)}
                              >
                                상담 보고서
                              </Button>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#e8e8e8",
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "9px",
                                  marginLeft: "5px",
                                }}
                                onClick={() => handleDownloadrecord(reservation)}
                              >
                                녹음 파일
                              </Button>
                            </div>
                          )}
                          {/*집단 프로그램 상세보기 Modal */}
                          <Modal isOpen={isOpen8 && selectedReservationIndex3 !== null}
                            onRequestClose={closeOrganDetailModal}
                            style={{
                              ...customStyles,
                              overlay: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                zIndex: 9999 // 오버레이의 z-index
                              },
                              content: {
                                ...customStyles.content,
                                zIndex: 10000 // 모달 내용의 z-index
                              }
                            }}>
                            {completedReservations[selectedReservationIndex3] && (
                              <div>
                                <div className="modal-p-bold">{completedReservations[selectedReservationIndex3].res_id}
                                  <MSpan />
                                  <Button onClick={closeOrganDetailModal}
                                    variant="contained"
                                    size="small"
                                    style={{
                                      backgroundColor: "#80CBC4",
                                      color: "#000",
                                      fontWeight: "bold", // 굵게 설정
                                      fontSize: "10px" // 글자 크기 설정
                                    }}>
                                    닫기
                                  </Button>
                                </div>
                                <hr />
                                <div className="modal-p">선택 타입 : {completedReservations[selectedReservationIndex3].coun_type || '상태 없음'}</div>
                                <div className="modal-p">선택 강좌 : {completedReservations[selectedReservationIndex3].coun_title || '상담사 없음'}</div>
                                <div className="modal-p">상담 일시 : {new Date(completedReservations[selectedReservationIndex3].selectedDate).toLocaleDateString('ko-KR')} </div>
                                <div className="modal-p">상담 시작 시간 : {completedReservations[selectedReservationIndex3].selectedTime || '시간 없음'}</div>
                                <div className="modal-p">상담 총 시간 : {completedReservations[selectedReservationIndex3].totalTime || '상태 없음'}시간 </div>
                                <div className="modal-p">상담 참여 인원 수 : {completedReservations[selectedReservationIndex3].totalclient || '상태 없음'} </div>
                                <div className="modal-p">예약 상태 : {completedReservations[selectedReservationIndex3].res_stat || '상태 없음'} </div>
                                <div className="modal-p">결제 상태 : {completedReservations[selectedReservationIndex3].deposit_stat || '상태 없음'} </div>
                                <div className="modal-p">상담 상태 : {completedReservations[selectedReservationIndex3].counselor_stat || '상태 없음'} </div>
                              </div>
                            )}

                          </Modal >
                        </TableCell>
                      </TableRow>
                    </TableHead>

                  </Table>
                </DashboardCard>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
};


function MSpan({ space = 510 }) {
  return (
    <span style={{ paddingRight: space }}></span>
  );
}
export default Clicon_deatil;
