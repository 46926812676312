import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography, IconButton } from '@mui/material';
import DashboardCard from '../../shared/DashboardCard';
import { IconArrowRight } from '@tabler/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Cli_community = () => {
  const navigate = useNavigate();

  const [postCount, setPostCount] = useState(0); // 글 작성 수
  const [commentCount, setCommentCount] = useState(0); // 댓글 작성 수
  const [isButtonClicked, setIsButtonClicked] = useState(false); // 버튼 클릭 여부 상태 추가

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postResponse = await axios.get('/api/Checkpost');
        const commentResponse = await axios.get('/api/Checkcomment');
        const postData = postResponse.data.postdata;
        const commentData = commentResponse.data.commentdata;
        setPostCount(postData.length);
        setCommentCount(commentData.length);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, []);

  const handleIconClick = () => {
    navigate('/Mypage/client/community');
    setIsButtonClicked(true); // 버튼이 클릭되었음을 표시
    setTimeout(() => setIsButtonClicked(false), 300); // 300ms 후에 버튼 클릭 상태 초기화
  };

  return (
    <DashboardCard title="커뮤니티 활동"
      action={
        <IconButton
          onClick={handleIconClick}
          sx={{
            '&:hover': {
              bgcolor: isButtonClicked ? '#b3b3b3' : '#e0e0e0', // 클릭되었을 때와 아닐 때 배경색 변경
              boxShadow: isButtonClicked ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.2)', // 클릭되었을 때 그림자 효과 변경
            },
            bgcolor: isButtonClicked ? '#b3b3b3' : '#d4eaf7', // 초기 상태의 배경색 지정
            boxShadow: isButtonClicked ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.2)', // 초기 상태의 그림자 효과 지정
          }}
        >
          <IconArrowRight width={20} color="#000" />
        </IconButton>
      }>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={16} sm={6}>
          <Stack direction="row" spacing={1} mt={1} alignItems="center">
            <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '13px', fontWeight: '400', textAlign: 'left', whiteSpace: 'pre-line' }}>
              {postCount === 0 && commentCount === 0 ? '커뮤니티 활동이 없습니다.' :
                `작성 글: ${postCount}개\n\n 작성 댓글: ${commentCount}개`
              }
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

export default Cli_community;
