import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, Paper, CardContent, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { Link } from 'react-router-dom';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import BlankCard from '../shared/BlankCard';
import { Rating, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';

// 별점 스타일
const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#FFD700',
  },
  '& .MuiRating-iconHover': {
    color: '#FFEA00',
  },
});


const Cou_review_detail = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  const { no } = useParams();


  const [review, setReview] = useState({
    counselor_name: '',
    date: '',
    rating: 0,
    content: ''
  });

  const { // 예약 데이터 전달받기
    reviewnum
  } = useLocation().state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reviewResponse = await axios.get('/api/reviewdetail', {
          params: {
            reviewnum: reviewnum
          }
        });
        const reviewData = reviewResponse.data.reviewdetail[0];
        setReview(reviewData);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }

    };

    fetchData();
  }, [reviewnum]);


  return (
    <PageContainer title="후기">
      <DashboardCard title="후기2">
        <Grid container spacing={3}>
          <Grid item sm={4} sx={{ textAlign: 'left', marginTop: '10px' }}>
            <Paper elevation={10}>
              <BlankCard>
                <CardContent>
                  <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                    <span style={{ fontWeight: 700 }}>예약 ID :</span> {reviewnum}
                  </Typography>
                  <br />
                  <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                    <span style={{ fontWeight: 700 }}>상담사 :</span> {review.counselor_name} 상담사
                  </Typography>
                  <br />
                  <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                    <span style={{ fontWeight: 700 }}>상담일시 :</span> {review.date}
                  </Typography>
                  <br />
                  <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                    <span style={{ fontWeight: 700 }}>별점 :</span> {review.rating}점
                    <StyledRating
                      name="read-only"
                      value={review.rating}
                      readOnly
                      precision={0.5}
                      sx={{ marginLeft: '10px', color: '#FFD700' }}
                    />
                  </Typography>

                  <br />
                </CardContent>
              </BlankCard>
            </Paper>
          </Grid>
        </Grid>

        <Grid item sm={4} sx={{ textAlign: 'left', marginTop: '10px' }}>
          <Paper elevation={10}>
            <BlankCard>
              <CardContent>
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px', whiteSpace: 'pre-line', lineHeight: '1.5' }}>
                  <span style={{ fontWeight: 700 }}>내용 :</span> {review.content}
                </Typography>
              </CardContent>
            </BlankCard>
          </Paper>
        </Grid>

        <br /><br />

      </DashboardCard>
    </PageContainer>
  );
};

export default Cou_review_detail;
