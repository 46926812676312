import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import axios from 'axios';
import "./O_Reservation.css";


import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const OReservationdetail = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { type, title, classtime } = location.state || {};

    const [semiselectedDate, setSelectedDate] = useState(new Date()); //날짜 선택
    const [selectedTime, setSelectedTime] = useState(''); //시간 선택
    const [availableTimes, setAvailableTimes] = useState([]); //예약 가능한 날짜
    const [timeRange, settimeRange] = useState([]);
    const [userData, setUserData] = useState({});
    const [checktime, setchecktime] = useState(null);

    const [totalclientcount, setTotalclientcount] = useState('0');

    // 로그인 상태가 아닐 시 로그인 페이지로 전환
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (!isLoggedIn) {
        navigate('/Login');
    }

    // 올바른 접근인지 확인
    if (location.state === null) {
        navigate('/O_Reservation');
    }

    useEffect(() => {
        document.title = "집단 상담 예약";
    }, []);

    useEffect(() => {
        // 서버에서 사용자 정보를 가져오는 API 호출
        axios.get('/api/Mypage', { withCredentials: true })
            .then(response => {
                setUserData(response.data);
            })
            .catch(error => {
                console.error('마이페이지 데이터 요청 오류:', error);
            });
    }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행되도록 설정

    // 예약 당시 날짜로 예약 번호 만들기
    const currentDate = new Date();
    const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
    const res_id = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
    const reservationDateTime = currentDate.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });

    // 날짜 선택
    useEffect(() => {
        handleDateChange(semiselectedDate);
    }, []);

    const handleDateChange = async (newDate) => {
        const currentDate = new Date(); // 현재시간

        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        const currentHour = currentDate.getHours();
        const currentMinute = currentDate.getMinutes();

        const selectedYear = newDate.getFullYear();
        const selectedMonth = newDate.getMonth();
        const selectedDay = newDate.getDate();


        // 현재 시간 이후의 시간을 필터링하는 함수
        const filterTimesAfterCurrentTime = (time) => {
            const [hour, minute] = time.split(':').map(Number); // 시간과 분을 분리하고 숫자로 변환
            if (
                (selectedYear === currentYear && selectedMonth === currentMonth && selectedDay === currentDay) &&
                (hour > currentHour || (hour === currentHour && minute >= currentMinute))
            ) {
                // 현재 날짜와 선택된 날짜가 같으면서, 시간이 현재 시간 이후인 경우
                return true;
            } else if (selectedYear > currentYear || (selectedYear === currentYear && selectedMonth > currentMonth) || (selectedYear === currentYear && selectedMonth === currentMonth && selectedDay > currentDay)) {
                // 선택된 날짜가 현재 날짜보다 미래인 경우
                return true;
            } else {
                // 나머지 경우
                return false;
            }
        };

        setSelectedDate(newDate);
        setSelectedTime([]); // 날짜가 변경될 때마다 선택된 시간 초기화


        try {
            const selectedDate2 = `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, '0')}-${selectedDay.toString().padStart(2, '0')}`;
            const response = await fetch(`/api/O_AvailableTimes?selected_date=${selectedDate2}`);
            const data = await response.json();

            const filteredTimes = data.availableTimes.filter(filterTimesAfterCurrentTime);
            settimeRange(data.timeRange);
            setAvailableTimes(filteredTimes);
        } catch (error) {
            console.error("예약 가능 시간 가져오기 실패:", error);
        }
    };

    // 서버로 보내는 date 형식
    const selectedDate = semiselectedDate.toLocaleDateString('ko-KR');
    // 예약 선택 날짜
    // 문자열을 Date 객체로 변환
    const selectedDateObj = new Date(selectedDate);
    // UTC 시간을 계산하고 한국 시간대로 변환
    const selectedDateUTC = Date.UTC(selectedDateObj.getFullYear(), selectedDateObj.getMonth(), selectedDateObj.getDate(), selectedDateObj.getHours(), selectedDateObj.getMinutes(), selectedDateObj.getSeconds());
    const selectedDateSeoul = new Date(selectedDateUTC + (9 * 60 * 60 * 1000));
    // 한국 시간대를 고려하여 MySQL에 저장할 형식으로 변환
    const mysqlDate = selectedDateSeoul.toISOString().slice(0, 10);

    const handleTimeSelect = (time) => {
        setSelectedTime(String(time)); // 문자열로 변환하여 setSelectedTime 호출
    };


    let endTime = '';
    // 종료 시간 계산
    if (typeof selectedTime === 'string' && selectedTime !== '') {
        const [startTimeHour, startTimeMinute] = selectedTime.split(":").map(Number);
        let endHour = startTimeHour + classtime;
        let endMinute = startTimeMinute

        // 시간이 24를 넘어가면 다음 날로 넘어감 (예외 처리)
        if (endHour >= 24) {
            endHour %= 24;
        }

        endTime = `${endHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")}`;
    }

    const handleClick = (time) => {
        if (availableTimes.includes(time)) {
            setchecktime(time);
            handleTimeSelect(time);
        }
    };

    const handleTotalclientcount = (event) => {
        setTotalclientcount(event.target.value);
    };

    const onClickOreservation = async () => {
        const response = await fetch(`/api/checkAvailableTimes/?countype=${2}&day=${mysqlDate}&startTime=${selectedTime}&endTime=${endTime}`);
        if (response.status === 200) {
            const resData = {
                res_id: res_id,
                reservationDateTime: reservationDateTime,
                coun_type: type,
                coun_title: title,
                selectedDate: mysqlDate,
                selectedTime: selectedTime,
                totalTime: classtime,
                totalclient: totalclientcount,
                user_id: userData.user_id,
                user_name: userData.name,
                user_email: userData.email,
                user_phonenum: userData.phonenum,
                counselor_name: "집단 프로그램",
                res_stat: "예약 확정 전",
                deposit_stat: "결제 전",
                counselor_stat: "상담 전",
            }

            const timeData = {
                type: type,
                title: title,
                res_id: res_id,
                user_id: userData.user_id,
                day: mysqlDate,
                start_time: selectedTime,
                end_time: endTime,
                availability_status: 'available'
            }

            axios.post(`/api/O_Reservation`, resData)
                .then(response => {
                    axios.post('/api/O_Restime', timeData)
                        .then(response => {
                            navigate(`/O_reservation_sus`, {
                                state: {
                                    status: "success"
                                }
                            })
                        })
                        .catch(error => {
                            console.log('시간 데이터 전송 실패');
                        });
                })
                .catch(reservationError => {
                    console.log('예약 데이터 전송 실패');
                    alert(`예약 오류입니다. 반복되는 경우 관리자에게 문의해 주세요.`);
                });
        } else {
            if (response.status === 400) {
                toast.error("예약 시간이 겹칩니다. 다른 시간을 선택해 주세요!");
                return;
            } else {
                toast.error("예약 시간 확인 오류입니다. 반복되면 관리자에게 문의 주세요.");
                return;
            }
        }
    }
    return (
        <div className='OReservationdetailContainer'>
            <ToastContainer />
            <div className='OResDetailTitle'>
                <h3>  예약 상세 정보</h3>
            </div>
            <div className='Oreservationdetail'>
                <div className='ODetailchoice'>
                    <div className='OResCal'>
                        <div className='OResdate'>
                            <Calendar
                                onChange={handleDateChange}
                                value={semiselectedDate}
                                tileDisabled={({ date, view }) => {
                                    if (view === 'month' && date < new Date(new Date().setHours(0, 0, 0, 0))) {
                                        return true; // 오늘 이전의 날짜는 선택할 수 없도록 함
                                    }
                                    return false;
                                }}
                                formatDay={(locale, date) => moment(date).format("DD")}
                                minDetail="month"
                                maxDetail="month"
                            />
                        </div>
                        <div className='ORestime'>
                            {timeRange.length > 0 ? (
                                <div className="O-time-buttons">
                                    {timeRange.map((time) => (
                                        <button
                                            key={time}
                                            onClick={() => handleClick(time)}
                                            className={
                                                availableTimes.includes(time)
                                                    ? `O-time-button ${selectedTime === time ? 'selected' : ''}`
                                                    : 'O-time-button disabled'
                                            }
                                        >
                                            {time}
                                        </button>
                                    ))}
                                </div>
                            ) : (
                                <p>해당 날짜에는 예약 가능한 시간이 없습니다.</p>
                            )}
                        </div>
                        <div className='Oresclientcountdiv'>
                            <p className='OresP'> 예상 수강 인원 </p>
                            <input className='Orescount_input' value={totalclientcount} onChange={handleTotalclientcount} />
                        </div>
                    </div>
                    <div className='Oresdetailinfo'>
                        <table className='Oresdetailinfo-table'>
                            <tbody>
                                <tr>
                                    <td>선택 타입</td>
                                    <td>{type}</td>
                                </tr>
                                <tr>
                                    <td>선택 강좌</td>
                                    <td>{title}</td>
                                </tr>
                                <tr>
                                    <td>선택된 날짜</td>
                                    <td>{selectedDate}</td>
                                </tr>
                                {selectedTime &&
                                    <tr>
                                        <td>선택된 시간</td>
                                        <td>{selectedTime} - {endTime}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>수강 인원</td>
                                    <td>{totalclientcount}명</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='Oresnotice'>
                            <p>- 프로그램에 관련하여 상담이 필요한 경우 예약 전 문의 주세요.</p>
                            <p>- 집단 프로그램은 관리자의 확인 후 예약이 확정됩니다.</p>
                            <p>- 예약 후 관리자에게 문의 메일 남겨 주세요.</p>
                        </div>
                    </div>
                </div>
                <div className='Oresnextbuttondiv'>
                    <button
                        className={totalclientcount !== '0' && (!selectedTime && selectedTime.length === 0) ? "Oresnextbuton" : "Oresnextbuton disabled"}
                        onClick={onClickOreservation}
                        disabled={totalclientcount === '0' || (!selectedTime || selectedTime.length === 0)}
                    >
                        예약 신청
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OReservationdetail;
