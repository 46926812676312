import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Signup.css';
import {Typography, Card, Button} from '@mui/material';
import DashboardCard from '../bootstrap/shared/DashboardCard';
import PageContainer from '../bootstrap/container/PageContainer';

function Signup() {
    return (
        
        <PageContainer title="회원가입">
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                
            <Card elevation={20} sx={{ p: 4, zIndex: 1, width: '100%', maxWidth: '500px', minHeight:'400px ' }}>
                <Typography sx={{ marginLeft : '8px', fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "22px", fontWeight: "700", marginTop : '10px' }}>
                   회원가입
                </Typography> 
                <br/><br/><br/><br/><br/>

                <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "400", marginTop : '-20px' }}>
                    서비스를 이용하고 싶다면?
                </Typography> 
                <br/>
                <Link to="/Client_join">
                    <Button 
                        variant="contained" 
                        color="primary"  
                        target="_blank" 
                        sx = {{fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", height : 40, width : 180, fontSize: '1.3rem'}} >
                        이용자 회원가입
                    </Button>
                </Link>
                <br/><br/><br/><br/>
                <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "400" }}>
                    전문가로 활동하고 싶다면?
                </Typography> 
                <br/>
                <Link to="/Counselor_join">
                    <Button 
                        variant="contained" 
                        color="primary"  
                        target="_blank" 
                        sx = {{fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", height : 40, width : 180, fontSize: '1.3rem'}} >
                        전문가 회원가입
                    </Button>
                </Link>
                <br/><br/><br/><br/>

            </Card>
        </div>
        <br/><br/><br/><br/>
        
        </PageContainer>
        

    );
}

export default Signup;