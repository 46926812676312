import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';

import axios from 'axios';
import Calendar from 'react-calendar';
import { Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Button from '@mui/material/Button';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Con_time_calendar.css";

const Cou_time_calendar = () => {
    const navigate = useNavigate();
    const [w_counselorData, setW_counselorData] = useState({}); // 사용자 데이터

    // 예약
    const [semiselectedDate, setSelectedDate] = useState(new Date()); // 날짜 선택
    const [checktime, setchecktime] = useState(null);
    const [availableTimes, setAvailableTimes] = useState([]); // 지난 시간 필터링
    const [yesreservationTime, setyesreservationTime] = useState([]); // 이미 예약이 된 시간
    const [blockreservationTime, setblockreservationTime] = useState([]); // 예약을 임의로 막은 시간
    const [selectedTime, setSelectedTime] = useState([]); // 시간 선택
    const [timeRange, settimeRange] = useState([]);
    const [btntype, setbtntype] = useState("cantblock");

    const selectedDate = semiselectedDate.toLocaleDateString('ko-KR');
    const selectedDate2 = `${semiselectedDate.getFullYear()}-${(semiselectedDate.getMonth() + 1).toString().padStart(2, '0')}-${semiselectedDate.getDate().toString().padStart(2, '0')}`;

    useEffect(() => {
        // 서버에서 사용자 정보를 가져오는 API 호출
        axios
            .get("/api/Mypage", { withCredentials: true })
            .then((response) => {
                setW_counselorData(response.data);
            })
            .catch((error) => {
                console.error("마이페이지 데이터 요청 오류:", error);
            });
    }, [setW_counselorData]);

    const handleDateChange = async (newDate) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        const currentHour = currentDate.getHours();
        const currentMinute = currentDate.getMinutes();

        const selectedYear = newDate.getFullYear();
        const selectedMonth = newDate.getMonth();
        const selectedDay = newDate.getDate();

        const filterTimesAfterCurrentTime = (time) => {
            const [hour, minute] = time.split(':').map(Number);
            if (
                (selectedYear === currentYear && selectedMonth === currentMonth && selectedDay === currentDay) &&
                (hour > currentHour || (hour === currentHour && minute > currentMinute))
            ) {
                return true;
            } else if (selectedYear > currentYear || (selectedYear === currentYear && selectedMonth > currentMonth) || (selectedYear === currentYear && selectedMonth === currentMonth && selectedDay > currentDay)) {
                return true;
            } else {
                return false;
            }
        };

        setSelectedDate(newDate);
        setSelectedTime([]); // 날짜가 변경될 때마다 선택된 시간 초기화
        setbtntype("cantblock");

        try {
            const selectedDate2 = `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, '0')}-${selectedDay.toString().padStart(2, '0')}`;
            const response = await fetch(`/api/AvailableTimes/${w_counselorData.user_id}?c_id=${w_counselorData.user_id}&selected_date=${selectedDate2}`);
            const data = await response.json();

            const filteredTimes = data.timeRange.filter(filterTimesAfterCurrentTime);
            const yesres = data.yesreservationTimes;
            const blockres = data.blockreservationTimes;
            settimeRange(data.timeRange);

            setAvailableTimes(filteredTimes);
            setyesreservationTime(yesres);
            setblockreservationTime(blockres);

        } catch (error) {
            console.error("예약 가능 시간 가져오기 실패:", error);
        }
    };

    const handleClick = (time) => {
        if (!availableTimes.includes(time) || yesreservationTime.some(reservation => time >= reservation.start && time < reservation.end)) {
            return;
        } else {
            setchecktime(time);
            handleTimeSelect(time);
        }
    };

    const handleTimeSelect = (time) => {
        setSelectedTime(time);
        if (blockreservationTime.some(reservation => time >= reservation.start && time < reservation.end)) {
            setbtntype("block");
        } else if (availableTimes.includes(time) && !yesreservationTime.some(reservation => time >= reservation.start && time < reservation.end)) {
            setbtntype("notblock");
        }
    };

    useEffect(() => {
        handleDateChange(semiselectedDate);
    }, [w_counselorData]);

    let endTime = '';
    // 종료 시간 계산
    if (typeof selectedTime === 'string' && selectedTime !== '') {
        const [startTimeHour, startTimeMinute] = selectedTime.split(":").map(Number);
        let endHour = startTimeHour + 1;
        let endMinute = startTimeMinute

        // 시간이 24를 넘어가면 다음 날로 넘어감 (예외 처리)
        if (endHour >= 24) {
            endHour %= 24;
        }

        endTime = `${endHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")}`;
    }

    const handleadmintime = async (time, state) => {
        try {
            const response = await fetch('/api/admincontime', {
                method: 'POST', // POST 메소드로 요청을 보냄
                headers: {
                    'Content-Type': 'application/json' // JSON 형식의 데이터를 전송
                },
                body: JSON.stringify({ // 데이터를 문자열로 변환하여 전송
                    counselor_id: w_counselorData.user_id,
                    day: selectedDate2,
                    start_time: time,
                    end_time: endTime,
                    counselor_name: w_counselorData.couns_name,
                    availability_status: "available",
                    state: state
                })
            });

            if (response.ok) {
                window.location.reload();
                toast.error('예약 시간 변경이 완료되었습니다.');
            } else {
                toast.error('예약 시간 변경 중 오류가 발생했습니다.');
            }
        } catch (error) {
            console.error("예약 시간 변경 오류:", error);
        }
    }


    return (
        <PageContainer title="상담 가능 시간 관리">
            <ToastContainer />
            <DashboardCard title="상담 가능 시간 관리">
                <Paper>
                    <DashboardCard
                        sx={{
                            width: '100%'
                        }}>
                        <div className='Counselor_time_calendar_container'>
                            <div className='Counselor_res_cal'>
                                <div className='Counselor_res_date'>
                                    <Calendar
                                        onChange={handleDateChange}
                                        value={semiselectedDate}
                                        tileDisabled={({ date, view }) => {
                                            if (view === 'month' && date < new Date(new Date().setHours(0, 0, 0, 0))) {
                                                return true;
                                            }
                                            return false;
                                        }}
                                        formatDay={(locale, date) => moment(date).format("DD")}
                                        minDetail="month"
                                        maxDetail="month"
                                    />
                                </div>
                                <div className='Counselor_res_time'>
                                    <div className='Counselor_time_info'>
                                        <div className='Counselor_p1'>* 이미 지난/예약된 시간은 변경이 불가능합니다.</div>
                                    </div>
                                    {timeRange.length > 0 ? (
                                        <div className="Counselor_time_buttons">
                                            {timeRange.map((time) => {
                                                const isDisabled = !availableTimes.includes(time) || yesreservationTime.some(reservation => time >= reservation.start && time < reservation.end);
                                                const isBlocked = blockreservationTime.some(reservation => time >= reservation.start && time < reservation.end);
                                                const isSelected = selectedTime === time;
                                                return (
                                                    <button
                                                        key={time}
                                                        onClick={() => handleClick(time)}
                                                        className={`Counselor_time_button 
                                                        ${isDisabled ? 'disabled' : ''} 
                                                        ${isBlocked ? 'block' : ''} 
                                                        ${isSelected ? 'selected' : ''}`}
                                                    >
                                                        {time}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <p>해당 날짜에는 예약 가능한 시간이 없습니다.</p>
                                    )}
                                </div>
                            </div>
                            <div className='Counselor_res_info'>
                                <div className='Counselor_res_admin'>
                                    <table className='Counselor_res_info_table'>
                                        <tbody>
                                            <tr>
                                                <td>선택된 날짜</td>
                                                <td>{selectedDate}</td>
                                            </tr>
                                            {selectedTime &&
                                                <tr>
                                                    <td>선택된 시간</td>
                                                    <td>{selectedTime}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className='Con_time_calendar_button'>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                borderRadius: "20px",
                                                fontSize: "1.2rem"
                                            }}
                                            disabled={btntype === "cantblock" || btntype === "notblock"}
                                            onClick={() => handleadmintime(selectedTime, "block")}
                                        >
                                            예약 가능으로 변경
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                borderRadius: "20px",
                                                fontSize: "1.2rem"
                                            }}
                                            disabled={btntype === "cantblock" || btntype === "block"}
                                            onClick={() => handleadmintime(selectedTime, "notblock")}
                                        >
                                            예약 불가능으로 변경
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DashboardCard>
                </Paper>
            </DashboardCard>
        </PageContainer>
    );
};

export default Cou_time_calendar;
