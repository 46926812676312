import "./Client_join.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageContainer from '../../bootstrap/container/PageContainer';
import infoContent from "./info";
import infoclient from "./Client_info";
import moment from "moment-timezone";
import axios from 'axios';
import { Typography, Card, Button, Box, Grid, CardContent } from '@mui/material';
import { Input } from '@mui/base/Input';
import { Link } from 'react-router-dom';

import DashboardCard from '../../bootstrap/shared/DashboardCard';
import Paper from '@mui/material/Paper';
import BlankCard from '../../bootstrap/shared/BlankCard';


export default function Client(props) {
  const [gender, setGender] = useState(""); // 라디오 버튼(성별)

  const [loginid, setId] = useState("");
  const [idMessage, setIdMessage] = useState("");

  const [passwd, setPasswd] = useState("");
  const [passwd2, setPasswd2] = useState("");
  const [name, setName] = useState("");

  const [prefix, setPrefix] = useState("010"); // 국번 상태 변수 선언 및 초기화
  const [middle, setMiddle] = useState(""); // 중간 번호 상태 변수 선언 및 초기화
  const [end, setEnd] = useState(""); // 끝 번호 상태 변수 선언 및 초기화

  const [year, setYear] = useState(""); // 년 상태 변수 선언 및 초기화
  const [month, setMonth] = useState(""); // 월 상태 변수 선언 및 초기화
  const [day, setDay] = useState(""); // 일 상태 변수 선언 및 초기화

  const [email, setEmail] = useState(""); // 이메일 앞부분 상태 변수 선언 및 초기화
  const [email2, setEmail2] = useState("naver.com"); // 이메일 뒷부분 상태 변수 선언 및 초기화

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const [idCheckValue, setIdCheck] = useState(false);

  const [enteredCode, setEnteredCode] = useState('');
  const [verificationResult, setVerificationResult] = useState('');

  useEffect(() => {
    document.title = "이용자 회원가입";
  }, []);

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleCheckboxChange = (checkboxNum) => {
    if (checkboxNum === 1) {
      setIsChecked1(!isChecked1);
    } else if (checkboxNum === 2) {
      setIsChecked2(!isChecked2);
    } else if (checkboxNum === 3) {
      setIsChecked3(!isChecked3);
    }
  };

  // 국번 선택 이벤트 핸들러
  const handlePrefixChange = (event) => {
    setPrefix(event.target.value); // 선택된 국번 설정
  };

  // 중간 번호 입력 이벤트 핸들러
  const handleMiddleChange = (event) => {
    setMiddle(event.target.value); // 입력된 중간 번호 설정
  };

  // 끝 번호 입력 이벤트 핸들러
  const handleEndChange = (event) => {
    setEnd(event.target.value); // 입력된 끝 번호 설정
  };

  // 전체 전화번호 계산
  const phonenum = prefix + "-" + middle + "-" + end;

  // 년 입력 이벤트 핸들러
  const handleYearChange = (event) => {
    setYear(event.target.value); // 입력된 년 설정
  };

  // 월 입력 이벤트 핸들러
  const handleMonthChange = (event) => {
    setMonth(event.target.value); // 입력된 월 설정
  };

  // 일 입력 이벤트 핸들러
  const handleDayChange = (event) => {
    setDay(event.target.value); // 입력된 일 설정
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmail2Change = (event) => {
    setEmail2(event.target.value);
  };

  const totalmail = email + "@" + email2;

  const navigate = useNavigate();

  const currentDate = new Date();
  const koreanDate = moment().tz("Asia/Seoul");
  // MySQL DATETIME 형식으로 변환
  const mysqlDatetime = koreanDate.format("YYYY-MM-DD HH:mm:ss");

  // 전체 생년월일 계산(하이픈 사용)
  const birthday = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;

  const formattedDateForID = currentDate
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, "");
  const user_id = `${formattedDateForID}21${Math.floor(Math.random() * 100)}`;

  const idcheck = async () => {
    try {
      // 데이터를 전송할 API 엔드포인트 URL
      const url = "/api/idCheck";

      // POST 요청을 보냄
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ loginid: loginid }),
      });

      if (response.ok) {
        setIdCheck(true);
        setIdMessage("사용 가능한 아이디입니다.");
      } else {
        setIdMessage("사용 불가능한 아이디입니다.");
      }
    } catch (error) {
      console.error("오류 발생:", error);
    }
  };

  const clientjoin = async () => {
    if (!enteredCode || !verificationResult || verificationResult !== '인증 성공!') {
      toast.error("이메일이 인증 되지 않았습니다."); // toast로 오류 메시지 표시
      return; // 함수 종료
    }
    if (
      !gender ||
      !loginid ||
      !name ||
      !passwd ||
      !passwd2 ||
      !prefix ||
      !middle ||
      !end ||
      !year ||
      !month ||
      !day ||
      !email ||
      !email2

    ) {
      // 모든 필수 입력 필드가 입력되지 않은 경우
      toast.error("모든 항목을 입력해 주세요!"); // toast로 오류 메시지 표시
      return; // 함수 종료
    }

    if (!isChecked1 || !isChecked2) {
      toast.error("필수 약관 확인 후 동의해 주세요!"); // toast로 오류 메시지 표시
      return;
    }

    if (passwd !== passwd2) {
      // 비밀번호 확인 일치
      toast.error("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return;
    }

    if (!isPasswordValid(passwd)) {
      toast.error(
        "비밀번호는 (영문자/숫자/특수문자 조합 8자 ~ 16자)여야 합니다."
      );
      return;
    }

    if (idCheckValue === false) {
      toast.error("아이디 중복 확인해 주세요.");
      return;
    }

    const data = {
      user_id: user_id,
      loginid: loginid,
      passwd: passwd,
      name: name,
      phonenum: phonenum,
      birthday: birthday,
      email: totalmail,
      gender: gender,
      path: 1,
      authority: 2,
      registered_date: mysqlDatetime, // 현재 날짜와 시간을 가입 날짜로 설정
      activity: 1,
    };
    try {
      // 데이터를 전송할 API 엔드포인트 URL
      const url = "/api/Client_join";

      // POST 요청을 보냄
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      // 응답 확인
      if (response.ok) {
        // 가입 성공 시 리다이렉트 등의 작업 수행
        navigate("/Success");
      } else {
        // 가입 실패 시 적절한 처리 수행
        toast.error("입력하신 정보를 다시 한번 확인해 주세요.");
        return;
      }
    } catch (error) {
      console.error("오류 발생:", error);
    }
  };

  const isPasswordValid = (password) => {
    // 비밀번호 유효성 검사 로직을 추가
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,16}$/;
    return passwordRegex.test(password);
  };

  const sendEmailToServer = async (email) => { // 서버에서 보내는 인증번호
    try {
      const response = await axios.post('/api/sendEmail', {
        type: 'signup',
        sendEmail: totalmail
      });

      toast.success('인증번호가 전송되었습니다. 이메일을 확인해주세요.');

    } catch (error) {
      console.error('Error sending email', error);
    }
  };

  const handleVerification = async () => { // 인증번호 확인
    try {
      const response = await axios.post('/api/verifyCode', { enteredCode: enteredCode });
      if (response.data.success) {
        setVerificationResult('인증 성공!');
      } else {
        setVerificationResult('인증번호가 일치하지 않습니다.');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setVerificationResult('인증 중 오류가 발생했습니다.');
    }
  };



  return (
    <div className="aragraphStyle">
      <ToastContainer />
      <br />
      <br />
      <br />
      <br />

      <div className="join_title">
        <h4 style={{ whiteSpace: 'nowrap', fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '20px' }}>
          이용자 회원가입
        </h4>
      </div>
      <div />
      <hr className='hr-join' />


      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Paper
          elevation={10}
          sx={{
            p: 4,
            textAlign: 'left',
            maxWidth: '1360px', // 필요한 경우 이 값을 조정하세요.
            width: '100%' // 반응형 디자인을 위해 너비를 퍼센트로 설정
          }}
        >

          <div style={{ whiteSpace: 'nowrap', fontSize: '12px', marginBottom: '20px' }}>
            *는 필수로 입력하셔야 합니다.
          </div>


          <div className="joinfield" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="join_p">
              아이디<span style={{ color: 'blue' }}>*</span>
              <input
                type="text"
                style={{ marginLeft: '50px' }}
                onChange={(event) => {
                  setId(event.target.value);
                }}
              /><br />
              <button onClick={idcheck} style={{ marginLeft: '90px', marginTop: '5px' }}>아이디 중복 확인</button>
              <div style={{ marginLeft: '70px', marginTop: '5px', marginLeft: '90px' }}><span>{idMessage}</span></div>
            </div>
          </div>

          <div className="joinfield" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="join_p">
              비밀번호 <span style={{ color: 'blue' }}>*</span>
              <input
                type="password"
                style={{ marginLeft: '35px' }}
                onChange={(event) => {
                  setPasswd(event.target.value);
                }}
              />
              <div style={{ marginTop: '5px', fontSize: '10px' }}>(영문대소문자/숫자/특수문자 조합 8자 ~ 16자)</div>
            </div>
          </div>

          <div className="joinfield" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="join_p">
              비밀번호 확인<span style={{ color: 'blue' }}>*</span>
              <input
                style={{ marginLeft: '10px' }}
                type="password"
                onChange={(event) => {
                  setPasswd2(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="joinfield" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="join_p">
              이름 <span style={{ color: 'blue' }}>*</span>
              <input
                type="text"
                style={{ marginLeft: '58px' }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="joinfield" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="join_p">
              휴대전화 <span style={{ color: 'blue' }}>*</span>
              <select
                className="box"
                style={{ marginLeft: '35px' }}
                id="phone-number-prefix"
                onChange={handlePrefixChange}
                value={prefix}
              >
                <option value="010">010</option>
                <option value="011">011</option>
                <option value="016">016</option>
                <option value="017">017</option>
              </select>
              &nbsp; -{" "}
              <input
                className="join-input"
                id="phone-number-middle"
                type="text"
                onChange={handleMiddleChange}
                maxLength="4"
                value={middle}
                style={{ width: '50px', height: '20px' }}
              />
              &nbsp; -{" "}
              <input
                className="join-input"
                id="phone-number-end"
                type="text"
                onChange={handleEndChange}
                maxLength="4"
                value={end}
                style={{ width: '50px', height: '20px' }}
              />
            </div>
          </div>

          <div className="joinfield" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="join_p">
              생년월일 <span style={{ color: 'blue' }}>*</span>
              <input
                className="join-input"
                type="text"
                placeholder="년"
                onChange={handleYearChange}
                value={year}
                maxLength="4"
                style={{ width: '50px', height: '20px', marginLeft: '35px' }}
              />{" "}
              - &nbsp;
              <input
                className="join-input"
                type="text"
                placeholder="월"
                onChange={handleMonthChange}
                value={month}
                maxLength="2"
                style={{ width: '50px', height: '20px' }}
              />{" "}
              - &nbsp;
              <input
                className="join-input"
                type="text"
                placeholder="일"
                onChange={handleDayChange}
                value={day}
                maxLength="2"
                style={{ width: '50px', height: '20px' }}
              />
            </div>
          </div>

          <div className="joinfield" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="join_p">
              이메일 <span style={{ color: 'blue' }}>*</span>
              <input
                className="join-input2"
                id="domain-txt"
                type="text"
                onChange={handleEmailChange}
                style={{ marginLeft: '46px', width: '80px', height: '22px' }}
                value={email}
              />
              &nbsp;
              <select
                className="box"
                id="domain-list"
                onChange={handleEmail2Change}
                value={email2}
              >
                <option value="naver.com">@naver.com</option>
                <option value="google.com">@google.com</option>
                <option value="hanmail.net">@hanmail.net</option>
                <option value="nate.com">@nate.com</option>
                <option value="kakao.com">@kakao.com</option>
              </select>
              <br />
              <button onClick={sendEmailToServer} style={{ marginTop: '5px', marginLeft: '195px' }}>인증번호 전송</button>
              <div style={{ marginTop: '10px', marginLeft: '92px' }}>
                <input
                  type="text"
                  onChange={(e) => setEnteredCode(e.target.value)}
                  value={enteredCode}
                  style={{ width: '150px' }}
                /> &nbsp;
                <button onClick={handleVerification}>완료</button>
                <div style={{ marginTop: '5px' }}>{verificationResult}</div>
              </div>
            </div>
          </div>

          <div className="joinfield" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="join_p">
              성별 <span style={{ color: 'blue' }}>*</span>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  checked={gender === "1"}
                  style={{ marginLeft: '60px' }}
                  onChange={handleGenderChange}
                />&nbsp;
                남성
              </label>
              &nbsp;&nbsp;
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="2"
                  checked={gender === "2"}
                  onChange={handleGenderChange}
                />&nbsp;
                여성
              </label>
            </div>
          </div>





        </Paper>
      </Grid>






      <br />

      <div className="joinfiled">


      </div>
      <hr className='hr-join' />

      <h5>
        <Span1 />
        약관 동의{" "}
      </h5>
      <div className="join_p">
        <label>
          <Span />
          <input
            type="checkbox"
            checked={isChecked1}
            onChange={() => handleCheckboxChange(1)}
          />
        </label>
        &nbsp;[필수] 개인정보 처리방침
      </div>

      <div className="signup-re-agree-box" style={{ textAlign: 'left', paddingLeft: '10px' }} >
        {infoContent}
      </div>

      <div className="join_p">
        <label>
          <Span />
          <input
            type="checkbox"
            checked={isChecked2}
            onChange={() => handleCheckboxChange(2)}
          />
        </label>
        &nbsp;[필수] 서비스 이용약관
      </div>

      <div className="signup-re-agree-box" style={{ textAlign: 'left', paddingLeft: '10px' }} >
        {infoclient}
      </div>





      <div />
      <br />


      <Button
        variant="contained"
        color="primary"
        target="_blank"
        sx={{ height: 40, width: 180, fontSize: '1.5rem', fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}
        onClick={clientjoin}>
        가입 완료
      </Button>
      <br /><br /><br />
    </div>
  );
}

function Span0({ space = 85 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span({ space = 80 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span1({ space = 70 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span2({ space = 50 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span4({ space = 85 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span5({ space = 195 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span6({ space = 44 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span7({ space = 70 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span10({ space = 142 }) {
  return <span style={{ paddingRight: space }}></span>;
}