import React, { useState, useEffect, useRef } from "react";
import './Paybank.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Grid, Box, Card, Stack, Typography } from '@mui/material';


import PageContainer from '../../bootstrap/container/PageContainer';
import Logo from '../../bootstrap/layouts/full/shared/logo/Logo';

import naman_logo from "../Login/naman.png";

export default function G_Paybank() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  

  const [responseData, setResponseData] = useState(null);
  const requestSentRef = useRef(false); // useRef를 사용하여 상태 관리
  const [isMounted, setIsMounted] = useState(true);

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    navigate('/Login');
  }

  useEffect(() => {
    if (!isMounted || requestSentRef.current) {
      return;
    }
    
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: Number(searchParams.get("amount")),
      paymentKey: searchParams.get("paymentKey"),
    };

    const resData = {
      res_id: decodeURIComponent(searchParams.get("res_id")),
      reservationDateTime: decodeURIComponent(searchParams.get("reservationDateTime")),
      coun_type: decodeURIComponent(searchParams.get("coun_type")),
      selectedDate: decodeURIComponent(searchParams.get("selectedDate")),
      selectedTime: decodeURIComponent(searchParams.get("selectedTime")),
      totalTime: decodeURIComponent(parseInt(searchParams.get("totalTime"), 10)),
      counselor_id: decodeURIComponent(searchParams.get("counselor_id")),
      counselor_name: decodeURIComponent(searchParams.get("counselor_name")),
      group_number: decodeURIComponent(searchParams.get("group_number")),
      user_id: decodeURIComponent(searchParams.get("user_id")),
      user_name: decodeURIComponent(searchParams.get("user_name")),
      user_birth: decodeURIComponent(searchParams.get("user_birth")),
      totalAmount: decodeURIComponent(parseInt(searchParams.get("totalAmount"), 10)),
      deposit_stat: decodeURIComponent(searchParams.get("deposit_stat")),
      deposit_name: decodeURIComponent(searchParams.get("deposit_name")),
      counselor_stat: decodeURIComponent(searchParams.get("counselor_stat")),
      request_1: decodeURIComponent(searchParams.get("request_1")),
      request_2: decodeURIComponent(searchParams.get("request_2")),
      request_3: decodeURIComponent(searchParams.get("request_3")),
      request_4: decodeURIComponent(searchParams.get("request_4")),
      request_5: decodeURIComponent(searchParams.get("request_5")),
      request_6: decodeURIComponent(searchParams.get("request_6")),
      request_7: decodeURIComponent(searchParams.get("request_7")),
      request_8: decodeURIComponent(searchParams.get("request_8")),
    };

    const timeData = {
      res_id: decodeURIComponent(searchParams.get("res_id")),
      counselor_id: decodeURIComponent(searchParams.get("counselor_id")),
      day: decodeURIComponent(searchParams.get("selectedDate")),
      start_time: decodeURIComponent(searchParams.get("start_time")),
      end_time: decodeURIComponent(searchParams.get("end_time")),
      counselor_name: decodeURIComponent(searchParams.get("counselor_name")),
      availability_status: "available",
    };

    const payData = {
      res_id: decodeURIComponent(searchParams.get("res_id")),
      coun_type: decodeURIComponent(searchParams.get("coun_type")),
      user_id: decodeURIComponent(searchParams.get("user_id")),
      user_name: decodeURIComponent(searchParams.get("user_name")),
      selectedDate: decodeURIComponent(searchParams.get("selectedDate")),
      pay_name: decodeURIComponent(searchParams.get("user_name")),
      pay_time: decodeURIComponent(searchParams.get("pay_time")),
      deposit_stat: decodeURIComponent(searchParams.get("deposit_stat")),
      price: decodeURIComponent(parseInt(searchParams.get("totalAmount"), 10)),
    };

    async function confirm() {
      try {
        const response3 = await axios.post('/api/G_Reservation', resData);
        if (!response3.data.success) {
          throw new Error('Reservation API failed');
        }

        const response2 = await axios.post('/api/Pay', payData);
        if (!response2.data.success) {
          throw new Error('Payment API failed');
        }

        const response1 = await axios.post('/api/restime', timeData);
        if (!response1.data.success) {
          throw new Error('Time reservation API failed');
        }

        const response = await fetch("/api/resconfirm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        const json = await response.json();

        if (!response.ok) {
          navigate(`/PaybankFail?message=${json.message}&code=${json.code}`);
          return;
        }

        setResponseData(json);
        requestSentRef.current = true; // 요청이 한 번만 보내지도록 설정
      } catch (error) {
        console.error("Error during payment processing:", error);
        navigate(`/PaybankFail?message=Payment%20processing%20failed&code=500`);
      }
    }

    confirm();

    return () => {
      setIsMounted(false); // 컴포넌트가 언마운트될 때 상태를 업데이트
    };
  }, [searchParams, navigate, isMounted]);

  return (
    <div className="login-title">

          <PageContainer title="예약 완료" sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>


            <br/><br/><br/><br/><br/><br/>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            
            <Card elevation={20} sx={{ p: 4, zIndex: 1, width: '500px', minHeight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              

              <Link to="/">
                <img
                  src={naman_logo}
                  alt="Logo"
                  style={{
                    height: '32px',
                    width: '140px',
                    overflow: 'hidden',
                    display: 'block',
                    
                  }}
                />
                   <hr style={{
                          backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
                          height: '4px',
                          width: '110px',
                          top : '-10px',
                          border: 'none' // 기본 테두리를 제거합니다
                      }} />

              </Link>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  예약 완료
                </Typography> 
                <br/>
                <Typography style = {{fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}  sx={{fontSize: "15px", justifyContent: "left", fontWeight: "400", textAlign: "left" }}>
                  {`주문번호: ${searchParams.get("orderId")}`}
                  <br/>
                  <br/>
                  {`결제 금액: ${Number(searchParams.get("amount")).toLocaleString()}원`}
                  <br/>
                  <br/>
                  {`자세한 사항은 마이페이지에서 확인해 주세요.`}
                </Typography>
                <br/>
            </Card>
        </div>
        <br/><br/><br/><br/>
    </PageContainer>
    </div>
  );
}
