import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import N_DashboardCard from '../shared/DashboardCard_add';
import axios from 'axios';
import { Typography, Grid, Paper, Button, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import { useParams, useNavigate } from 'react-router-dom';
import './Anew_counselor.css';
import ProfileImg from '../assets/images/profile/blank_profile.png';


const A_counselor_detail = () => {
  const navigate = useNavigate();
  const [cdetail, setCdetail] = useState([]);
  const { id } = useParams();

  const [specializations, setSpecializations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cDetailResponse = await axios.get('/api/counselordetail', {
          headers: {
            'Cache-Control': 'no-store'
          },
          params: { id }
        });

        const cDetailData = cDetailResponse.data.cdetaildata;
        setCdetail(cDetailData[0]); // cdetail 상태 업데이트



        try {
          const specializationsResponse = await axios.post('/api/view_specializations', {
            couns_id: cDetailData[0].c_id // cdetail 상태를 참조하여 couns_id 전달
          }, {
            headers: {
              'Cache-Control': 'no-store'
            }
          });

          const specialData = specializationsResponse.data.data;
          setSpecializations(specialData); // specializations 상태 업데이트

        } catch (error) {
          console.error('specializations 데이터 가져오기 오류:', error);
        }
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }

    };

    fetchData();
  }, [id]);


  /* 전문가 정보 삭제 */
  const deletecounselor = async () => {
    try {
      await axios.post('/api/delete_counselor', { c_id: cdetail.c_id });
      toast.success('전문가 정보가 성공적으로 삭제되었습니다!');
      navigate(`/Mypage/admin/add_counselor`);
    } catch (error) {
      toast.error('전문가 정보 삭제 중 오류가 발생하였습니다.');
    }
  };



  return (
    <PageContainer title="프로필 확인">
      <ToastContainer />
      <DashboardCard title="전문가"
        action={
          <Typography sx={{ fontSize: "14.5px", fontWeight: "700" }}>
            <Button variant="contained"
              style={{
                backgroundColor: "#5D87FF",
                color: 'white',
                fontWeight: 'bold',
                fontSize: '10px',
                marginLeft: '-2px',
                marginTop: '10px'
              }}
              onClick={() => navigate(`/Mypage/admin/counselor_detail_edit/${id}`)}>
              수정
            </Button>
            &nbsp;&nbsp;
            <Button variant="contained"
              style={{
                backgroundColor: "#5D87FF",
                color: 'white',
                fontWeight: 'bold',
                fontSize: '10px',
                marginLeft: '-2px',
                marginTop: '10px'
              }}
              onClick={deletecounselor}>
              삭제
            </Button>
          </Typography>
        }>


        <div className="Counselor_sub_container">
          <div className="Counselor_info">
            <div className="Counselor_img_class">
              {cdetail.c_image ? (
                <img src={`${cdetail.c_image}?${Date.now()}`}
                  alt="Updated Image"
                  className="Counselor_img" />
              ) : (
                <img
                  src={ProfileImg}
                  alt="Profile Image"
                  className="Counselor_img"

                />
              )}
            </div>
            <div className="couns-header-content col-md-9">
              <h3>상담사</h3>
              <h2>{cdetail.c_name}</h2>
              <div className="counselor_p">{cdetail.c_description}</div>
            </div>
          </div>

          <div className="Counselor_info_detail">
            <div className="Counselor_career">
              <h4 className="Counselor_h4">경력사항</h4>
              <div className="Counselor_career_content" style={{ whiteSpace: 'pre-line' }}>
                {cdetail.c_career && cdetail.c_career.split('\\r\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="Counselor_edu">
              <h4 className="Counselor_h4">최종학력</h4>
              <div className="Counselor_edu_content" style={{ whiteSpace: 'pre-line' }}>
                {cdetail.c_education && cdetail.c_education.split('\\r\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="Counselor_license">
              <h4 className="Counselor_h4">자격/수료사항</h4>
              <div className="Counselor_license_content" style={{ whiteSpace: 'pre-line' }}>
                {cdetail.c_license && cdetail.c_license.split('\\r\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="Counselor_info_detail5">
          <div className="Counselor_career">
            <h4 className="Counselor_h4">전문분야</h4>
            <br />
            {specializations.length > 0 ? (
              <div className="Counselor_info_detail_row">
                {specializations.map((specialization, index) => (
                  <div key={index} className={`Counselor_info_detail3 ${index % 2 === 0 ? 'left' : 'right'}`}>
                    <p>
                      ✔ {specialization.f_specializations} &gt; {specialization.s_specializations} &gt; {specialization.t_specializations}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <p>선택된 전문분야가 없습니다.</p>
            )}
          </div>
        </div>
      </DashboardCard>
    </PageContainer>
  );
};

export default A_counselor_detail;