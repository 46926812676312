import React, { useEffect, useRef, useState } from 'react';
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";
import inforefund from "./info_refund";

import './Payment.css';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { Typography, Grid, CardContent, TableHead, TableRow, TableCell } from '@mui/material';
import BlankCard from '../../bootstrap/shared/BlankCard';
import Paper from '@mui/material/Paper';
import PageContainer from '../../bootstrap/container/PageContainer';
import DashboardCard from '../../bootstrap/shared/DashboardCard';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import queryString from 'query-string';

/* 결제위젯 초기화 */
const widgetClientKey = "live_gck_24xLea5zVAljZ6n14Z0x3QAMYNwW";
const customerKey = nanoid();

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [userData, setUserData] = useState({});
  const [paymethod, setpaygender] = useState('1'); // 라디오 버튼(결제수단)

  const [isChecked1, setIsChecked1] = useState(false);

  const [paymentWidget, setPaymentWidget] = useState(null);
  const paymentMethodsWidgetRef = useRef(null);

  // 로그인 상태가 아닐 시 로그인 페이지로 전환
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    navigate('/Login');
  }
  // 올바른 접근인지 확인
  if (location.state === null) {
    navigate('/Search');
    console.log("잘못된 접근입니다.");
  }

  const {
    counselorName,
    counselorId,
    selectedDate2,
    selectedTime,
    totalAmount,
    countgroup,
    totalTime,
    request_1, request_2, request_3, request_4, request_5, request_6, request_7, request_8 } = useLocation().state;

  // 예약 당시 날짜로 예약 번호 만들기
  const currentDate = new Date();
  const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
  const res_id = `${formattedDateForID}21${Math.floor(Math.random() * 100)}`;
  const reservationDateTime = currentDate.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });

  // 예약 선택 날짜
  // 문자열을 Date 객체로 변환
  const selectedDateObj = new Date(selectedDate2);

  // UTC 시간을 계산하고 한국 시간대로 변환
  const selectedDateUTC = Date.UTC(selectedDateObj.getFullYear(), selectedDateObj.getMonth(), selectedDateObj.getDate(), selectedDateObj.getHours(), selectedDateObj.getMinutes(), selectedDateObj.getSeconds());
  const selectedDateSeoul = new Date(selectedDateUTC + (9 * 60 * 60 * 1000));


  const mysqlDate = selectedDateSeoul.toISOString().slice(0, 10);


  // 선택한 시간 및 총 상담 시간을 시간과 분으d로 분리
  const [startTimeHour, startTimeMinute] = selectedTime.split(":").map(Number);
  const totalTimeHour = Math.floor(countgroup);
  const totalTimeMinute = Math.round((countgroup - totalTimeHour) * 60);


  // 종료 시간 계산
  let endHour = startTimeHour + totalTimeHour;
  let endMinute = startTimeMinute + totalTimeMinute;


  // 분이 60을 넘어가면 시간에 반영
  if (endMinute >= 60) {
    endHour += Math.floor(endMinute / 60);
    endMinute %= 60;
  }

  // 시간이 24를 넘어가면 다음 날로 넘어감 (예외 처리)
  if (endHour >= 24) {
    endHour %= 24;

  }

  const endTime = `${endHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")}`;

  useEffect(() => {
    // 서버에서 사용자 정보를 가져오는 API 호출
    axios.get('/api/Mypage', { withCredentials: true })
      .then(response => {
        setUserData(response.data);
      })
      .catch(error => {
        console.error('마이페이지 데이터 요청 오류:', error);
      });
  }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행되도록 설정


  const handleCheckboxChange = (checkboxNum) => {
    if (checkboxNum === 1) {
      setIsChecked1(!isChecked1);
    }
  };

  const currencyFormat = (amount) => {
    return new Intl.NumberFormat('ko-KR', { style: 'decimal', currency: 'KRW' }).format(amount);
  };

  /* 결제 관련 UI */
  useEffect(() => {
    const fetchPaymentWidget = async () => {
      try {
        const loadedWidget = await loadPaymentWidget(widgetClientKey, customerKey);
        setPaymentWidget(loadedWidget);
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    };

    fetchPaymentWidget();
  }, []);

  useEffect(() => {
    if (!paymentWidget) return;

    const initializePaymentWidget = async () => {
      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        "#payment-widget",
        { value: totalAmount }, // 여기서는 고정된 값을 사용하거나 totalAmount를 사용합니다.
        { variantKey: "resang" }
      );
      paymentMethodsWidgetRef.current = paymentMethodsWidget;

      // 이용 약관 렌더링
      paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });
    };

    initializePaymentWidget();
  }, [paymentWidget, totalAmount]);

  const onClickPayment = async () => {
    if (!paymentWidget || !paymentMethodsWidgetRef.current) {
      console.error("Payment widget is not initialized.");
      return;
    }
    try {
      const resData = {
        res_id: res_id, // 예약 번호
        reservationDateTime: reservationDateTime, // 상담을 예약한 날짜, 시간
        coun_type: "그룹 상담", // 상담 타입
        selectedDate: mysqlDate,// 선택한 날짜
        selectedTime: selectedTime,  // 선택한 시간
        totalTime: totalTime, // 총 상담 시간
        counselor_id: counselorId, // 상담자 회원번호
        counselor_name: counselorName, // 상담자 이름
        group_number: countgroup,
        user_id: userData.user_id, // 내담자 회원번호
        user_name: userData.name,  // 내담자 이름
        user_birth: userData.birthday, // 내담자 생년월일
        totalAmount: totalAmount, // 총 결제 금액
        deposit_stat: "결제 전", // 결제 상태 (입금/결제 전, 입금/결제 완료, 환불 전, 환불 완료)
        deposit_name: userData.name,
        counselor_stat: "상담 전",
        request_1: request_1, // 상담 신청서 첫 번째 질문
        request_2: request_2, // 두 번째 질문
        request_3: request_3, // 세 번째 질문
        request_4: request_4, // 네 번째
        request_5: request_5, // 다섯 번째
        request_6: request_6,  // 여섯 번째
        request_7: request_7, // 일곱 번째
        request_8: request_8, // 여덟 번째
        start_time: selectedTime, // 선택한 시간
        end_time: endTime,
        pay_time: "결제 전",  // 결제 시간
        availability_status: "available",
      };

      const resParams = queryString.stringify(resData);

      try {
        // 전화번호에서 하이픈 제거
        const phonenumber = userData.phonenum.replace(/-/g, '');

        await paymentWidget?.requestPayment({
          orderId: res_id,
          orderName: "나만 그룹 상담 예약",
          customerName: userData.name,
          customerEmail: userData.email,
          customerMobilePhone: phonenumber,
          successUrl: `${window.location.origin}/G_Paybank?resData=${encodeURIComponent(resParams)}`,
          failUrl: `${window.location.origin}/PaybankFail`,
        });
      } catch (error) {
        console.error('Error during the payment process:', error);
        alert('결제에 실패하였습니다. 에러 내용: ' + error.message);
      }
    } catch (error) {
      console.error('Error during the payment process:', error);
      alert('결제에 실패하였습니다. 에러 내용: ' + error.message);
    }
  };

  if (!state || !state.selectedDate2) {
    // 유효한 데이터가 없는 경우 예외 처리 또는 리디렉션 등을 수행
    return <div className="payment_p2">유효한 데이터가 없습니다.</div>;
  }


  return (
    <div className="paymentdiv">
      <PageContainer title="예약 결제" className="page-container">
        <Grid container spacing={3} className="grid-container">
          <Grid item xs={12} sm={10}>
            <Paper elevation={3}>
              <DashboardCard title="결제">
                <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12} className="left-grid-item">
                    <Paper elevation={10} sx={{ width: '100%' }}>
                      <BlankCard>
                        <CardContent>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography className="card-content"
                                  style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}
                                  sx={{ fontSize: '17px', fontWeight: 'bold' }}>
                                  예약 정보
                                </Typography>
                                <br /><br />
                                <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                  상담사 : {counselorName} 상담사
                                </Typography>
                                <br /><br />
                                <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                  일정 : {selectedDate2} / {selectedTime}
                                </Typography>
                                <br /><br />
                                <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                  총 상담 시간 : {totalTime}시간
                                </Typography>
                                <br /><br />
                                <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                  상담 인원 : {countgroup}명
                                </Typography>
                                <br />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </CardContent>
                      </BlankCard>
                    </Paper>
                  </Grid>
                  <Grid container spacing={0} sx={{ display: 'flex', marginLeft: '2.1rem', textAlign: 'center', alignItems: 'center', justifyContent: 'center', paddingTop: '2rem' }}>
                    <Grid item xs={12}>
                      <Paper elevation={10}>
                        <BlankCard className="inner-card">
                          <CardContent>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography className="card-content" sx={{ fontWeight: 'bold', fontSize: '17px' }} style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                    최종결제 금액
                                  </Typography>
                                  <br /><br />
                                  <Typography className="card-subcontent" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                    총 금액: {currencyFormat(totalAmount)}원
                                  </Typography>
                                  <br /><br />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {/* 결제 UI, 이용약관 UI*/}
                            <div id="payment-widget" className="payment-widget" />
                            <div id="agreement" className="agreement" />
                          </CardContent>
                        </BlankCard>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="left-grid-item">
                    <Paper elevation={10} sx={{ width: '100%' }}>
                      <BlankCard>
                        <CardContent>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography className="card-content" sx={{ fontWeight: 'bold', fontSize: '17px' }} style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>
                                  환불규정
                                </Typography>
                                <br /><br />

                                <div className="join_p" style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '12px' }}>

                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={isChecked1}
                                      onChange={() => handleCheckboxChange(1)}
                                      style={{ marginRight: '5px' }}
                                    />
                                  </label>
                                  아래 내용을 확인했습니다.
                                </div>

                                <div className="signup-re-agree-box"
                                  style={{
                                    fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
                                    textAlign: 'left', width: '100%', paddingLeft: '10px'
                                  }}
                                >
                                  {inforefund}
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </CardContent>
                      </BlankCard>
                    </Paper>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  className="payment-button"
                  onClick={onClickPayment}
                  sx={{
                    fontSize: '13.5px',
                    marginTop: '2.2rem',
                    width: '185px',
                    height: '35px'
                  }}
                  style={{
                    marginBottom: '20px',
                    fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
                    fontSize: '14px'
                  }}
                >
                  결제하기
                </Button>
              </DashboardCard>
            </Paper>
          </Grid>
        </Grid>
      </PageContainer>
    </div>
  );

};

export default Payment;