import React, { useEffect, useState } from "react";
import "./Counselor.css";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Typography, Grid, Card, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../AuthContext";
import axios from 'axios';
import { Rating, styled } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import ProfileImg from './blank_profile.png';

const Counselor = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const { id } = useParams();

  const [cdetail, setcDeatildata] = useState({
    c_id: '',
    c_name: '',
    c_description: '',
    c_career: '',
    c_education: '',
    c_license: '',
    image: '', // 추가된 부분
  });

  const [reviewData, setReviewData] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#FFD700',
    },
    '& .MuiRating-iconHover': {
      color: '#FFEA00',
    },
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    document.title = "전문가 상세 페이지";
  }, []);

  useEffect(() => {
    scrollToTop();
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/counselordetail', {
          headers: {
            'Cache-Control': 'no-store'
          },
          params: { id }
        });
        const cDetailData = response.data.cdetaildata;
        const reviewData = response.data.reviewdata;

        setcDeatildata(cDetailData[0]);
        setReviewData(reviewData);

        const totalRating = reviewData.reduce((sum, review) => sum + review.rating, 0);
        const avgRating = reviewData.length ? (totalRating / reviewData.length).toFixed(1) : 0;
        setAverageRating(avgRating);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, [id]);

  const goToReservation = () => {
    if (!isLoggedIn) {
      navigate('/Login');
    } else {
      navigate(`/Counselor/${cdetail.id}/Reservation`, {
        state: {
          counselorid: cdetail.c_id,
          check: true
        }
      });
    }
  };

  const goToGroup = () => {
    if (!isLoggedIn) {
      navigate('/Login');
    } else {
      navigate(`/Counselor/${cdetail.id}/Group_reservation`, {
        state: {
          counselorid: cdetail.c_id,
          check: true
        }
      });
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // 모달을 열 때 인덱스를 설정하는 함수
  const openModal2 = (index) => {
    setOpenModalIndex(index);
    setIsOpen2(true);
  };

  // 모달을 닫을 때 인덱스를 초기화하는 함수
  const closeModal2 = () => {
    setOpenModalIndex(null);
    setIsOpen2(false);
  };


  if (!cdetail.c_name) {
    return <div>상담사를 찾을 수 없습니다.</div>;
  }

  // Sort reviews by writedate in descending order
  const sortedReviews = [...reviewData].sort((a, b) => {
    const [aDateString, aTimeString] = a.writedate.split('오');
    const [aYear, aMonth, aDay] = aDateString.trim().split('.');
    const [aTime, aTimeMeridiem] = aTimeString.trim().split(':');

    const [bDateString, bTimeString] = b.writedate.split('오');
    const [bYear, bMonth, bDay] = bDateString.trim().split('.');
    const [bTime, bTimeMeridiem] = bTimeString.trim().split(':');

    if (aYear === bYear && aMonth === bMonth && aDay === bDay) {
      if (aTimeMeridiem === bTimeMeridiem) {
        const dateA = new Date(aYear, aMonth - 1, aDay, parseMeridiemHour(aTime));
        const dateB = new Date(bYear, bMonth - 1, bDay, parseMeridiemHour(bTime));
        return dateB - dateA;
      } else {
        // 오전과 오후가 다르면 오전이 먼저 오도록 합니다.
        const [aStringTime, a_aTime] = aTime.split(' ');
        return aStringTime === '전' ? -1 : 1;
      }
    } else {
      // 다른 날짜인 경우 두 날짜를 비교합니다.
      return new Date(bYear, bMonth - 1, bDay) - new Date(aYear, aMonth - 1, aDay);
    }
  });

  function parseMeridiemHour(hourString) {
    const [stringhour, hour] = hourString.split(' ');
    let inthour = parseInt(hour);
    if (stringhour === '후' && inthour !== 12) {
      inthour += 12;
    } else if (stringhour === '전' && inthour === 12) {
      inthour = 0;
    }
    return inthour;
  }


  // Calculate the current reviews to display
  let itemsPerPage = 0
  if (window.innerWidth < 599) {
    itemsPerPage = 3
  } else {
    itemsPerPage = 6
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastReview = currentPage * itemsPerPage;
  const indexOfFirstReview = indexOfLastReview - itemsPerPage;
  const currentReviews = sortedReviews.slice(indexOfFirstReview, indexOfLastReview);

  return (
    <div className="Counselorrootdiv">
      <ToastContainer />
      <div className="Counselor_main_container">
        <div className="Counselor_sub_container">
          <div className="Counselor_info">
            <div className="Counselor_img_class">
              {cdetail.c_image ? (
                <img src={`${cdetail.c_image}?${Date.now()}`}
                  alt="Updated Image"
                  className="Counselor_img" />
              ) : (
                <img
                  src={ProfileImg}
                  alt="Profile Image"
                  className="Counselor_img"

                />
              )}
            </div>
            <div className="couns-header-content col-md-9">
              <h3>상담사</h3>
              <h2>{cdetail.c_name}</h2>
              <div className="counselor_p">{cdetail.c_description}</div>
            </div>
          </div>

          <div className="Counselor_info_detail">
            <div className="Counselor_career">
              <h4 className="Counselor_h4">경력사항</h4>
              <div className="Counselor_career_content" style={{ whiteSpace: 'pre-line' }}>
                {cdetail.c_career && cdetail.c_career.split('\\r\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="Counselor_edu">
              <h4 className="Counselor_h4">최종학력</h4>
              <div className="Counselor_edu_content" style={{ whiteSpace: 'pre-line' }}>
                {cdetail.c_education && cdetail.c_education.split('\\r\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="Counselor_license">
              <h4 className="Counselor_h4">자격/수료사항</h4>
              <div className="Counselor_license_content" style={{ whiteSpace: 'pre-line' }}>
                {cdetail.c_license && cdetail.c_license.split('\\r\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="Counselor_review_detail">
          <div className="Counselor_review_title">
            <h4 className="Counselor_h4">후기</h4>
            <div className="Counselor_stars_container">
              <StyledRating
                name="read-only"
                value={averageRating}
                readOnly
                precision={0.5}
              /> &nbsp;
              {averageRating} / 5.0
            </div>
          </div>
          <div className="Counselor_review_board">
            <Grid container spacing={3}>
              {currentReviews.length === 0 ? (
                <Typography variant="body1" style={{ marginTop: '15px', marginLeft: '30px' }}>후기가 없습니다.</Typography>
              ) : (
                currentReviews.map((review, index) => (
                  <Grid item xs={12} sm={6} lg={4} key={index}>
                    <Card className="review-card" elevation={5}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                          {review.writedate}
                        </Typography>
                        <div>
                          <Typography variant="body2" color="textSecondary" style={{ textAlign: 'right' }}>
                            <StyledRating
                              name="read-only"
                              value={review.rating}
                              readOnly
                              precision={0.5}
                            />
                          </Typography>
                        </div>
                      </div>
                      <div className="Counselor_review_content">
                        {review.content}
                      </div>
                      <div className="Counselor_review_button">
                        <button className="review_detail_button" onClick={() => openModal2(index)}>후기 전체 보기</button>
                        <Dialog open={isOpen2 && openModalIndex === index} onClose={closeModal2} className="review_dialog_container">
                          <DialogTitle className="review_dialog_title">후기 전체 보기</DialogTitle>
                          <DialogContent
                            className="review_dialog_content"
                            style={{
                              whiteSpace: 'pre-line',
                              lineHeight: '1.5'
                            }}
                          >
                            {currentReviews[openModalIndex]?.content}
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeModal2} className="review_dialog_close">닫기</Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
            {sortedReviews.length > itemsPerPage && (
              <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                  count={Math.ceil(sortedReviews.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
          </div>
        </div>


        <div className="Counselor_Reservation_button">
          <Button
            variant="contained"
            onClick={() => navigate('/Search')}
            mt={1}
            sx={{ fontSize: '1.6rem', width: 150 }}
            className="Counselor_button"
            style={{
              fontSize: '15px',
              fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
            }}
          >
            목록
          </Button>

          <Button
            variant="contained"
            onClick={openModal}
            mt={1}
            sx={{ fontSize: '1.6rem', width: 150 }}
            className="Counselor_button"
            style={{
              fontSize: '15px',
              fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
            }}
          >
            예약하기
          </Button>
        </div>
      </div>

      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="Modal__Content">
          <Button
            variant="contained"
            onClick={goToReservation}
            mt={1}
            sx={{ fontSize: '1.4rem', width: 220 }}
            style={{
              fontSize: '15px',
              fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
            }}
          >
            개인상담
          </Button>
          <br /><br /><br />
          <Button
            variant="contained"
            onClick={goToGroup}
            mt={1}
            sx={{ fontSize: '1.4rem', width: 220 }}
            style={{
              fontSize: '15px',
              fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
            }}
          >
            그룹상담
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Counselor;