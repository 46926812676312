import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Table, TableBody, TableCell, TableRow, Typography, Paper, TextField } from '@mui/material';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Con_Check = () => {
    const [userData, setUserData] = useState({}); // 사용자 데이터 배열
    const [passwd, setPasswd] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get("/api/Mypage", {
                    withCredentials: true,
                });
                setUserData(response.data);
            } catch (error) {
                console.error("마이페이지 데이터 요청 오류:", error);
            }
        }

        fetchUserData();
    }, []);

    const nextbuttonhandle = () => {
        axios.post('/api/checkuser', {
            loginid: userData.couns_loginid,
            passwd: passwd,
            authority: userData.authority
        }, {
            withCredentials: true
        })
            .then(response => {
                if (response.status === 200) {
                    navigate(`/Mypage/counselor/edit_counselor`, {
                        state: {
                            check: "OK"
                        }
                    });
                }
            })
            .catch(error => {
                toast.error("비밀번호가 일치하지 않습니다.");
            });
    };


    return (
        <PageContainer title="비밀번호 확인">
            <ToastContainer />
            <DashboardCard
                title="비밀번호 확인"
                sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                }}
            >
                <Paper
                    elevation={10}
                    sx={{
                        display: 'flex',
                        whiteSpace: 'nowrap',
                        flexGrow: 1,
                    }}
                >
                    <Box sx={{
                        height: '40rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <Table sx={{ width: '100%' }}>
                                <TableBody>
                                    <TableRow
                                        sx={{
                                            display: 'flex',
                                            width: '100%'
                                        }}
                                    >
                                        <TableCell sx={{ textAlign: 'left', width: '30%' }}>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    fontSize: '14px',
                                                    paddingRight: '2rem',
                                                    fontWeight: 400,
                                                    lineHeight: 3,
                                                }}
                                            >
                                                아이디
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'left', width: '70%' }}>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    lineHeight: 3,
                                                }}
                                            >
                                                {Object.keys(userData).length > 0 ? userData.couns_loginid : 'Loading...'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%'
                                        }}
                                    >
                                        <TableCell sx={{ textAlign: 'left', width: '30%' }}>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    fontSize: '14px',
                                                    paddingRight: '2rem',
                                                    fontWeight: 400,
                                                    lineHeight: 3,
                                                }}
                                            >
                                                비밀번호
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'left', width: '70%' }}>
                                            <TextField
                                                type="password"
                                                variant="outlined"
                                                onInput={(e) => setPasswd(e.target.value)}
                                                sx={{
                                                    display: 'flex',
                                                    width: '100%'
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: 'left', width: '70%' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={nextbuttonhandle}
                                                sx={{
                                                    width: '100%',
                                                    height: '3.5rem',
                                                    fontSize: '1.2rem',
                                                    borderRadius: '10px'
                                                }}>
                                                확인
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Paper>
            </DashboardCard >
        </PageContainer >
    );
};

export default Con_Check;
