import React, { useEffect, useState, useRef } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, Paper, Button, TableContainer, IconButton, InputAdornment } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import './Anew_counselor.css';
import { toast, ToastContainer } from "react-toastify";
import TextField from '@mui/material/TextField'
import ProfileImg from '../assets/images/profile/blank_profile.png';

import closeIcon from './close.png';



const A_edit_counselor_detail = () => {
  const navigate = useNavigate();
  const [cdetail, setCdetail] = useState([]);
  const { id } = useParams();

  const [name, setName] = useState(cdetail.c_name || '');
  const [description, setDescription] = useState(cdetail.c_description || '');
  const [career, setCareer] = useState(cdetail.c_career || '');
  const [education, setEducation] = useState(cdetail.c_education || '');
  const [license, setLicense] = useState(cdetail.c_license || '');
  const [image, setImage] = useState(cdetail.c_image);

  const [file, setFile] = useState(null); // 프로필 사진
  const fileInputRef = useRef(null);

  const [fSpecializations, setFSpecializations] = useState('');
  const [sSpecializations, setSSpecializations] = useState('');
  const [tSpecializations, setTSpecializations] = useState('');


  const [specializations, setSpecializations] = useState([]);


  // 각 체크박스의 선택 상태를 관리할 상태 변수

  /* 1차 전문분야*/
  const [lifeChecked, setLifeChecked] = useState(false);
  const [businessChecked, setBusinessChecked] = useState(false);
  const [careerChecked, setCareerChecked] = useState(false);

  /* 2차 전문분야 */
  const [s_life1, setsecondlife1] = useState(false);
  const [s_life2, setsecondlife2] = useState(false);
  const [s_life3, setsecondlife3] = useState(false);
  const [s_business1, setsecondbusiness1] = useState(false);
  const [s_business2, setsecondbusiness2] = useState(false);
  const [s_business3, setsecondbusiness3] = useState(false);
  const [s_career1, setcareer1] = useState(false);
  const [s_career2, setcareer2] = useState(false);
  const [s_career3, setcareer3] = useState(false);
  const [s_career4, setcareer4] = useState(false);

  /* 3차 전문분야 */
  const [t_family1, setthirdfamily1] = useState(false);
  const [t_family2, setthirdfamily2] = useState(false);
  const [t_family3, setthirdfamily3] = useState(false);
  const [t_family4, setthirdfamily4] = useState(false);
  const [t_relation1, setthirdrelation1] = useState(false);
  const [t_relation2, setthirdrelation2] = useState(false);
  const [t_relation3, setthirdbrelation3] = useState(false);
  const [t_personal1, setpersonal1] = useState(false);
  const [t_personal2, setpersonal2] = useState(false);
  const [t_personal3, setpersonal3] = useState(false);
  const [t_personal4, setpersonal4] = useState(false);
  const [t_personal5, setpersonal5] = useState(false);
  const [t_personal6, setpersonal6] = useState(false);
  const [t_personal7, setpersonal7] = useState(false);
  const [t_personal8, setpersonal8] = useState(false);
  const [t_personal9, setpersonal9] = useState(false);
  const [t_personal10, setpersonal10] = useState(false);
  const [t_personal11, setpersonal11] = useState(false);
  const [t_personal12, setpersonal12] = useState(false);
  const [t_personal13, setpersonal13] = useState(false);
  const [t_personal14, setpersonal14] = useState(false);
  const [t_money1, setmoney1] = useState(false);
  const [t_money2, setmoney2] = useState(false);
  const [t_money3, setmoney3] = useState(false);
  const [t_money4, setmoney4] = useState(false);
  const [t_money5, setmoney5] = useState(false);
  const [t_money6, setmoney6] = useState(false);






  /* 1차 전문분야 */
  const handleCheckBoxClick = (category) => {
    let specialization1 = '';
    switch (category) {
      case 'life':
        setLifeChecked(true);
        setBusinessChecked(false);
        setCareerChecked(false);
        specialization1 = '라이프';
        break;
      case 'business':
        setLifeChecked(false);
        setBusinessChecked(true);
        setCareerChecked(false);
        specialization1 = '비즈니스';
        break;
      case 'career':
        setLifeChecked(false);
        setBusinessChecked(false);
        setCareerChecked(true);
        specialization1 = '커리어';
        break;
      default:
        break;
    }
    setFSpecializations(specialization1);
  };

  /* 2차 전문분야 */
  const handleCheckBoxClick2 = (category) => {
    let specialization2 = '';
    switch (category) {
      case 's_life1':
        setsecondlife1(true);
        setsecondlife2(false);
        setsecondlife3(false);
        setsecondbusiness1(false);
        setsecondbusiness2(false);
        setsecondbusiness3(false);
        setcareer1(false);
        setcareer2(false);
        setcareer3(false);
        setcareer4(false);
        specialization2 = '가족관계';
        break;
      case 's_life2':
        setsecondlife1(false);
        setsecondlife2(true);
        setsecondlife3(false);
        setsecondbusiness1(false);
        setsecondbusiness2(false);
        setsecondbusiness3(false);
        setcareer1(false);
        setcareer2(false);
        setcareer3(false);
        setcareer4(false);
        specialization2 = '대인관계';
        break;
      case 's_life3':
        setsecondlife1(false);
        setsecondlife2(false);
        setsecondlife3(true);
        setsecondbusiness1(false);
        setsecondbusiness2(false);
        setsecondbusiness3(false);
        setcareer1(false);
        setcareer2(false);
        setcareer3(false);
        setcareer4(false);
        specialization2 = '개인이슈';
        break;
      case 's_business1':
        setsecondlife1(false);
        setsecondlife2(false);
        setsecondlife3(false);
        setsecondbusiness1(true);
        setsecondbusiness2(false);
        setsecondbusiness3(false);
        setcareer1(false);
        setcareer2(false);
        setcareer3(false);
        setcareer4(false);
        specialization2 = '창업';
        break;

      case 's_business2':
        setsecondlife1(false);
        setsecondlife2(false);
        setsecondlife3(false);
        setsecondbusiness1(false);
        setsecondbusiness2(true);
        setsecondbusiness3(false);
        setcareer1(false);
        setcareer2(false);
        setcareer3(false);
        setcareer4(false);
        specialization2 = '경영';
        break;

      case 's_business3':
        setsecondlife1(false);
        setsecondlife2(false);
        setsecondlife3(false);
        setsecondbusiness1(false);
        setsecondbusiness2(false);
        setsecondbusiness3(true);
        setcareer1(false);
        setcareer2(false);
        setcareer3(false);
        setcareer4(false);
        specialization2 = '폐업';
        break;

      case 's_career1':
        setsecondlife1(false);
        setsecondlife2(false);
        setsecondlife3(false);
        setsecondbusiness1(false);
        setsecondbusiness2(false);
        setsecondbusiness3(false);
        setcareer1(true);
        setcareer2(false);
        setcareer3(false);
        setcareer4(false);
        specialization2 = '학업';
        break;

      case 's_career2':
        setsecondlife1(false);
        setsecondlife2(false);
        setsecondlife3(false);
        setsecondbusiness1(false);
        setsecondbusiness2(false);
        setsecondbusiness3(false);
        setcareer1(false);
        setcareer2(true);
        setcareer3(false);
        setcareer4(false);
        specialization2 = '진로';
        break;

      case 's_career3':
        setsecondlife1(false);
        setsecondlife2(false);
        setsecondlife3(false);
        setsecondbusiness1(false);
        setsecondbusiness2(false);
        setsecondbusiness3(false);
        setcareer1(false);
        setcareer2(false);
        setcareer3(true);
        setcareer4(false);
        specialization2 = '취업';
        break;

      case 's_career4':
        setsecondlife1(false);
        setsecondlife2(false);
        setsecondlife3(false);
        setsecondbusiness1(false);
        setsecondbusiness2(false);
        setsecondbusiness3(false);
        setcareer1(false);
        setcareer2(false);
        setcareer3(false);
        setcareer4(true);
        specialization2 = '경력개발';
        break;
      default:
        break;
    }
    setSSpecializations(specialization2);
  };


  /* 3차 전문분야 */
  const handleCheckBoxClick3 = (category) => {
    let specialization3 = '';
    switch (category) {
      case 't_family1':
        setthirdfamily1(true);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '부모, 자녀관계';
        break;
      case 't_family2':
        setthirdfamily1(false);
        setthirdfamily2(true);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '부부관계';

        break;
      case 't_family3':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(true);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '가족과의 불화';

        break;
      case 't_family4':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(true);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '육아 스트레스';

        break;
      case 't_relation1':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(true);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '이성관계';

        break;
      case 't_relation2':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(true);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '친구관계';

        break;
      case 't_relation3':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(true);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '직장 내 대인관계';

        break;
      case 't_personal1':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(true);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '건강관리';

        break;
      case 't_personal2':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(true);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '습관/태도';

        break;
      case 't_personal3':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(true);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '성격/외모';

        break;
      case 't_personal4':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(true);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '자신감';

        break;
      case 't_personal5':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(true);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '우울감';

        break;
      case 't_personal6':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(true);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '공황';

        break;
      case 't_personal7':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(true);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '자살충동';

        break;
      case 't_personal8':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(true);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '공포';

        break;
      case 't_personal9':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(true);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '강박';

        break;
      case 't_personal10':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(true);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '열등감';

        break;
      case 't_personal11':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(true);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '중독/집착';

        break;
      case 't_personal12':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(true);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '트라우마';

        break;
      case 't_personal13':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(true);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '불면';

        break;
      case 't_personal14':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(true);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '불안';

        break;
      case 't_money1':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(true);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '재무';

        break;
      case 't_money2':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(true);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '리더십';

        break;
      case 't_money3':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(true);
        setmoney4(false);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '조직문화';

        break;
      case 't_money4':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(true);
        setmoney5(false);
        setmoney6(false);
        specialization3 = '역량';

        break;
      case 't_money5':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(true);
        setmoney6(false);
        specialization3 = '성과';

        break;
      case 't_money6':
        setthirdfamily1(false);
        setthirdfamily2(false);
        setthirdfamily3(false);
        setthirdfamily4(false);
        setthirdrelation1(false);
        setthirdrelation2(false);
        setthirdbrelation3(false);
        setpersonal1(false);
        setpersonal2(false);
        setpersonal3(false);
        setpersonal4(false);
        setpersonal5(false);
        setpersonal6(false);
        setpersonal7(false);
        setpersonal8(false);
        setpersonal9(false);
        setpersonal10(false);
        setpersonal11(false);
        setpersonal12(false);
        setpersonal13(false);
        setpersonal14(false);
        setmoney1(false);
        setmoney2(false);
        setmoney3(false);
        setmoney4(false);
        setmoney5(false);
        setmoney6(true);
        specialization3 = '전략';

        break;


      default:
        break;
    }
    setTSpecializations(specialization3);
  };



  const handleSaveSpecializations = () => {
    const data = {
      couns_id: cdetail.c_id,
      f_specializations: fSpecializations,
      s_specializations: sSpecializations,
      t_specializations: tSpecializations,
    };

    axios.post('/api/add_specializations', data)
      .then(response => {
        console.log('전문분야 데이터 저장 성공');
      })
      .catch(error => {
        console.error('전문분야 데이터 저장 실패:', error);

      });
    window.location.reload();
  };



  const handleFileChange = (e) => {
    // 첨부파일_사진
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
      const ext = fileName.split('.').pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedExtensions.includes(ext)) {
        toast.error("파일 확장자가 jpg, jpeg, png 인 파일만 업로드 가능합니다.");
        setFile(null);
        return;
      }

      if (selectedFile.size > maxSize) {
        toast.error("파일 크기는 5MB 이하만 업로드 가능합니다.");
        setFile(null);
        return;
      }

      setFile(selectedFile);
    } else {
      console.error("파일이 선택되지 않았습니다.");
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };


  const uploadImage = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('c_id', cdetail.c_id); // c_id 추가

    try {
      const response = await axios.post('/api/img_upload', formData, {
        headers: {
          'Cache-Control': 'no-store',
          'Content-Type': 'multipart/form-data',
          'c_id': cdetail.c_id
        }
      });

      setImage(response.data.imageUrl);  // 업데이트된 이미지 URL 설정
      setCdetail((prevDetail) => ({ ...prevDetail, c_image: response.data.imageUrl })); // cdetail 업데이트


    } catch (error) {
      console.error('이미지 업로드 실패:', error);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const cDetailResponse = await axios.get('/api/counselordetail', {
          headers: {
            'Cache-Control': 'no-store'
          },
          params: { id }
        });

        const cDetailData = cDetailResponse.data.cdetaildata;
        setCdetail(cDetailData[0]); // cdetail 상태 업데이트

        try {
          const specializationsResponse = await axios.post('/api/view_specializations', {
            couns_id: cDetailData[0].c_id // cdetail 상태를 참조하여 couns_id 전달
          }, {
            headers: {
              'Cache-Control': 'no-store'
            }
          });

          const specialData = specializationsResponse.data.data;
          setSpecializations(specialData); // specializations 상태 업데이트

        } catch (error) {
          console.error('specializations 데이터 가져오기 오류:', error);
        }

      } catch (error) {
        console.error('counselordetail 데이터 가져오기 오류:', error);
      }
    };

    fetchData();

  }, [id]);

  /* 전문분야 삭제 */
  const handleDeleteSpecialization = async (num) => {
    try {
      await axios.post('/api/delete_specializations', { num });
      console.log(`번호가 ${num}인 전문분야가 삭제되었습니다.`);
    } catch (error) {
      console.error('전문분야 삭제 오류:', error);
    }
    window.location.reload();
  };




  useEffect(() => {
    setName(cdetail.c_name ? cdetail.c_name.replace(/\\r\\n/g, '\n') : '');
    setDescription(cdetail.c_description ? cdetail.c_description.replace(/\\r\\n/g, '\n') : '');
    setCareer(cdetail.c_career ? cdetail.c_career.replace(/\\r\\n/g, '\n') : '');
    setEducation(cdetail.c_education ? cdetail.c_education.replace(/\\r\\n/g, '\n') : '');
    setLicense(cdetail.c_license ? cdetail.c_license.replace(/\\r\\n/g, '\n') : '');



  }, [cdetail.c_name, cdetail.c_description, cdetail.c_career, cdetail.c_education, cdetail.c_license, cdetail.c_image]);


  const handleaNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCareerChange = (e) => {
    setCareer(e.target.value);
  };

  const handleEducationChange = (e) => {
    setEducation(e.target.value);
  };

  const handleLicenseChange = (e) => {
    setLicense(e.target.value);
  };

  /* 전문가 정보 수정 */
  const changecounselor = async () => {
    try {
      const response = await axios.post('/api/new_counselor', {
        c_id: cdetail.c_id,
        name: name,
        description: description,
        career: career,
        education: education,
        license: license,
        view: 1,
      });
      if (response.data.success) {
        toast.success('전문가 수정이 성공적으로 되었습니다. ');
        navigate(`/Mypage/admin/counselor_detail/${id}`);
        window.location.reload();

      } else {
        toast.error('전문가 수정 중 오류가 발생하였습니다.');
      }
    } catch (error) {
      toast.error('전문가 수정 중 오류가 발생하였습니다.');
    }
  };



  return (
    <PageContainer title="프로필 수정">
      <ToastContainer />
      <DashboardCard title="전문가"
        action={
          <Typography sx={{ fontSize: "14.5px", fontWeight: "700" }}>

            <Button variant="contained"
              style={{
                backgroundColor: "#5D87FF",
                color: 'white',
                fontWeight: 'bold',
                fontSize: '10px',
                marginLeft: '-2px',
                marginTop: '10px'
              }}
              onClick={() => {
                navigate(`/Mypage/admin/counselor_detail/${id}`);

              }}>
              취소
            </Button>
            &nbsp;
            <Button variant="contained"
              style={{
                backgroundColor: "#5D87FF",
                color: 'white',
                fontWeight: 'bold',
                fontSize: '10px',
                marginLeft: '-2px',
                marginTop: '10px'
              }}
              onClick={() => {
                changecounselor();
                uploadImage();
              }}>
              완료
            </Button>



          </Typography>
        }>


        <div className="Counselor_sub_container">
          <div className="Counselor_info">
            <div className="Counselor_img_class" onClick={handleImageClick}>
              {file ? (
                <img src={URL.createObjectURL(file)} alt="My Image" className="Counselor_img" />
              ) : (
                <img src={`${cdetail.c_image}?${Date.now()}`} alt="Updated Image" className="Counselor_img" />

              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {file && <p>{file.name} 파일이 선택되었습니다.</p>}
            </div>
            <div className="couns-header-content col-md-9">
              <h3>상담사</h3>
              <br />


              <h2>
                <TextField
                  label="이름"
                  variant="filled"
                  color="primary"
                  focused
                  type="text"
                  value={name}
                  onChange={handleaNameChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '45px', // 원하는 높이 값으로 설정
                    },
                  }} />
              </h2>
              <div className="counselor_p">
                <TextField
                  label="소개"
                  variant="filled"
                  color="primary"
                  focused
                  multiline // 여러 줄 입력 필드 생성
                  rows={5} // 몇 줄 보여줄지 설정
                  type="text"
                  value={description}

                  onChange={handleDescriptionChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '120px', // 원하는 높이 값으로 설정
                      width: '250px'
                    },
                  }} />
              </div>

              <br />


            </div>
          </div>

          <div className="Counselor_info_detail">
            <div className="Counselor_career">
              <h4 className="Counselor_h4">경력사항</h4>
              <div className="Counselor_career_content">
                <TextField
                  variant="filled"
                  color="primary"
                  focused
                  multiline // 여러 줄 입력 필드 생성
                  rows={5} // 몇 줄 보여줄지 설정
                  type="text"
                  value={career}

                  onChange={handleCareerChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '120px', // 원하는 높이 값으로 설정
                      width: '450px',
                    },
                  }}
                />

              </div>
            </div>

            <div className="Counselor_edu">
              <h4 className="Counselor_h4">최종학력</h4>
              <div className="Counselor_edu_content">
                <TextField
                  variant="filled"
                  color="primary"
                  focused
                  multiline // 여러 줄 입력 필드 생성
                  rows={5} // 몇 줄 보여줄지 설정
                  type="text"
                  value={education}
                  onChange={handleEducationChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '120px', // 원하는 높이 값으로 설정
                      width: '450px',
                    },
                  }}
                />

              </div>
            </div>

            <div className="Counselor_license">
              <h4 className="Counselor_h4">자격/수료사항</h4>
              <div className="Counselor_license_content">
                <TextField
                  variant="filled"
                  color="primary"
                  focused
                  multiline // 여러 줄 입력 필드 생성
                  rows={5} // 몇 줄 보여줄지 설정
                  type="text"
                  value={license}
                  onChange={handleLicenseChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '120px', // 원하는 높이 값으로 설정
                      width: '450px',
                    },
                  }}
                />
              </div>
            </div>


          </div>

        </div>

        <div className="Counselor_sub_container">
          <div className="Counselor_info_detail4">
            <div className="Counselor_career">
              <h4 className="Counselor_h4">전문가 정보</h4>
              <br />
              <div className="pretty-checkbox">
                <Typography sx={{ fontSize: "15.5px", fontWeight: "700" }}>1차 전문분야</Typography> <br />
                <div className="checkbox-container">
                  <input type="checkbox" id="f_checkbox1" checked={lifeChecked} onChange={() => handleCheckBoxClick('life')} />
                  <label htmlFor="f_checkbox1">라이프</label>
                  <input type="checkbox" id="f_checkbox2" checked={businessChecked} onChange={() => handleCheckBoxClick('business')} />
                  <label htmlFor="f_checkbox2" style={{ marginLeft: '20px' }}>비즈니스</label>
                  <input type="checkbox" id="f_checkbox3" checked={careerChecked} onChange={() => handleCheckBoxClick('career')} />
                  <label htmlFor="f_checkbox3" style={{ marginLeft: '20px' }}>커리어</label>
                </div>
              </div>

            </div>

            <div class="pretty-checkbox">
              <Typography sx={{ fontSize: "15.5px", fontWeight: "700" }}> 2차 전문분야 </Typography> <br />

              <div id="life-group">
                <Typography sx={{ fontSize: "12.5px", fontWeight: "700" }}> &lt; 라이프 &gt; </Typography> <br />
                <input type="checkbox" id="f2_checkbox1" checked={s_life1} onChange={() => handleCheckBoxClick2('s_life1')} />
                <label for="f2_checkbox1" > 가족관계 </label>
                <input type="checkbox" id="f2_checkbox2" checked={s_life2} onChange={() => handleCheckBoxClick2('s_life2')} />
                <label for="f2_checkbox2" style={{ marginLeft: '20px' }} > 대인관계 </label>
                <input type="checkbox" id="f2_checkbox3" checked={s_life3} onChange={() => handleCheckBoxClick2('s_life3')} />
                <label for="f2_checkbox3" style={{ marginLeft: '20px' }} > 개인이슈 </label>

              </div>
              <br />

              <div id="business-group">
                <Typography sx={{ fontSize: "12.5px", fontWeight: "700" }}> &lt; 비즈니스 &gt; </Typography> <br />
                <input type="checkbox" id="s2_checkbox1" checked={s_business1} onChange={() => handleCheckBoxClick2('s_business1')} />
                <label for="s2_checkbox1" > 창업 </label>
                <input type="checkbox" id="s2_checkbox2" checked={s_business2} onChange={() => handleCheckBoxClick2('s_business2')} />
                <label for="s2_checkbox2" style={{ marginLeft: '20px' }}> 경영 </label>

                <input type="checkbox" id="s2_checkbox3" checked={s_business3} onChange={() => handleCheckBoxClick2('s_business3')} />
                <label for="s2_checkbox3" style={{ marginLeft: '20px' }}> 폐업 </label>
              </div>

              <br />

              <div id="career-group">
                <Typography sx={{ fontSize: "12.5px", fontWeight: "700" }}> &lt; 커리어 &gt; </Typography> <br />
                <input type="checkbox" id="t2_checkbox1" checked={s_career1} onChange={() => handleCheckBoxClick2('s_career1')} />
                <label for="t2_checkbox1" > 학업 </label>
                <input type="checkbox" id="t2_checkbox2" checked={s_career2} onChange={() => handleCheckBoxClick2('s_career2')} />
                <label for="t2_checkbox2" style={{ marginLeft: '20px' }}> 진로 </label>
                <input type="checkbox" id="t2_checkbox3" checked={s_career3} onChange={() => handleCheckBoxClick2('s_career3')} />
                <label for="t2_checkbox3" style={{ marginLeft: '20px' }}> 취업 </label>
                <input type="checkbox" id="t2_checkbox4" checked={s_career4} onChange={() => handleCheckBoxClick2('s_career4')} />
                <label for="t2_checkbox4" style={{ marginLeft: '20px' }}> 경력개발 </label>
              </div>
            </div>

            <div class="pretty-checkbox">
              <Typography sx={{ fontSize: "15.5px", fontWeight: "700" }}> 3차 전문분야 </Typography> <br />
              <Typography sx={{ fontSize: "12.5px", fontWeight: "700" }}> &lt; 가족관계 &gt; </Typography> <br />
              <input type="checkbox" id="f3_checkbox1" checked={t_family1} onChange={() => handleCheckBoxClick3('t_family1')} />
              <label for="f3_checkbox1" > 부모, 자녀 관계 </label>
              <input type="checkbox" id="f3_checkbox2" checked={t_family2} onChange={() => handleCheckBoxClick3('t_family2')} />
              <label for="f3_checkbox2" style={{ marginLeft: '20px' }}> 부부관계 </label>
              <input type="checkbox" id="f3_checkbox3" checked={t_family3} onChange={() => handleCheckBoxClick3('t_family3')} />
              <label for="f3_checkbox3" style={{ marginLeft: '20px' }}> 가족과의 불화 </label>
              <input type="checkbox" id="f3_checkbox4" checked={t_family4} onChange={() => handleCheckBoxClick3('t_family4')} />
              <label for="f3_checkbox4"> 육아 스트레스 </label>
            </div>

            <div class="pretty-checkbox">
              <Typography sx={{ fontSize: "12.5px", fontWeight: "700" }}> &lt; 대인관계 &gt; </Typography> <br />
              <input type="checkbox" id="f3_checkbox5" checked={t_relation1} onChange={() => handleCheckBoxClick3('t_relation1')} />
              <label for="f3_checkbox5" > 이성관계 </label>
              <input type="checkbox" id="f3_checkbox6" checked={t_relation2} onChange={() => handleCheckBoxClick3('t_relation2')} />
              <label for="f3_checkbox6" style={{ marginLeft: '20px' }}> 친구관계 </label>
              <input type="checkbox" id="f3_checkbox7" checked={t_relation3} onChange={() => handleCheckBoxClick3('t_relation3')} />
              <label for="f3_checkbox7" style={{ marginLeft: '20px' }}> 직장 내 대인관계 </label>
            </div>

            <div class="pretty-checkbox">
              <Typography sx={{ fontSize: "12.5px", fontWeight: "700" }}> &lt; 개인이슈 &gt; </Typography> <br />
              <input type="checkbox" id="f3_checkbox8" checked={t_personal1} onChange={() => handleCheckBoxClick3('t_personal1')} />
              <label for="f3_checkbox8" > 건강관리 </label>
              <input type="checkbox" id="f3_checkbox9" checked={t_personal2} onChange={() => handleCheckBoxClick3('t_personal2')} />
              <label for="f3_checkbox9" style={{ marginLeft: '20px' }}> 습관/태도 </label>
              <input type="checkbox" id="f3_checkbox10" checked={t_personal3} onChange={() => handleCheckBoxClick3('t_personal3')} />
              <label for="f3_checkbox10" style={{ marginLeft: '20px' }}> 성격/외모</label>
              <input type="checkbox" id="f3_checkbox11" checked={t_personal4} onChange={() => handleCheckBoxClick3('t_personal4')} />
              <label for="f3_checkbox11" style={{ marginLeft: '20px' }}> 자신감</label>
              <input type="checkbox" id="f3_checkbox12" checked={t_personal5} onChange={() => handleCheckBoxClick3('t_personal5')} />
              <label for="f3_checkbox12" > 우울감</label>

              <input type="checkbox" id="f3_checkbox13" checked={t_personal6} onChange={() => handleCheckBoxClick3('t_personal6')} />
              <label for="f3_checkbox13" style={{ marginLeft: '20px' }}>  공황  </label>
              <input type="checkbox" id="f3_checkbox14" checked={t_personal7} onChange={() => handleCheckBoxClick3('t_personal7')} />
              <label for="f3_checkbox14" style={{ marginLeft: '20px' }}> 자살충동</label>
              <input type="checkbox" id="f3_checkbox15" checked={t_personal8} onChange={() => handleCheckBoxClick3('t_personal8')} />
              <label for="f3_checkbox15" style={{ marginLeft: '20px' }}> 공포</label>
              <input type="checkbox" id="f3_checkbox16" checked={t_personal9} onChange={() => handleCheckBoxClick3('t_personal9')} />
              <label for="f3_checkbox16" style={{ marginLeft: '20px' }}> 강박</label>
              <input type="checkbox" id="f3_checkbox17" checked={t_personal10} onChange={() => handleCheckBoxClick3('t_personal10')} />
              <label for="f3_checkbox17"  > 열등감</label>

              <input type="checkbox" id="f3_checkbox18" checked={t_personal11} onChange={() => handleCheckBoxClick3('t_personal11')} />
              <label for="f3_checkbox18" style={{ marginLeft: '20px' }}> 중독/집착</label>
              <input type="checkbox" id="f3_checkbox19" checked={t_personal12} onChange={() => handleCheckBoxClick3('t_personal12')} />
              <label for="f3_checkbox19" style={{ marginLeft: '20px' }}> 트라우마</label>
              <input type="checkbox" id="f3_checkbox20" checked={t_personal13} onChange={() => handleCheckBoxClick3('t_personal13')} />
              <label for="f3_checkbox20" style={{ marginLeft: '20px' }}> 불면</label>
              <br />
              <input type="checkbox" id="f3_checkbox21" checked={t_personal14} onChange={() => handleCheckBoxClick3('t_personal14')} />
              <label for="f3_checkbox21"> 불안</label>

            </div>

            <div class="pretty-checkbox">
              <Typography sx={{ fontSize: "12.5px", fontWeight: "700" }}> &lt; 경영 &gt; </Typography> <br />
              <input type="checkbox" id="s3_checkbox1" checked={t_money1} onChange={() => handleCheckBoxClick3('t_money1')} />
              <label for="s3_checkbox1" > 재무 </label>
              <input type="checkbox" id="s3_checkbox2" checked={t_money2} onChange={() => handleCheckBoxClick3('t_money2')} />
              <label for="s3_checkbox2" style={{ marginLeft: '20px' }}> 리더십 </label>
              <input type="checkbox" id="s3_checkbox3" checked={t_money3} onChange={() => handleCheckBoxClick3('t_money3')} />
              <label for="s3_checkbox3" style={{ marginLeft: '20px' }}> 조직문화 </label>
              <input type="checkbox" id="s3_checkbox4" checked={t_money4} onChange={() => handleCheckBoxClick3('t_money4')} />
              <label for="s3_checkbox4" style={{ marginLeft: '20px' }}> 역량 </label>
              <input type="checkbox" id="s3_checkbox5" checked={t_money5} onChange={() => handleCheckBoxClick3('t_money5')} />
              <label for="s3_checkbox5" style={{ marginLeft: '20px' }}> 성과 </label>
              <input type="checkbox" id="s3_checkbox6" checked={t_money6} onChange={() => handleCheckBoxClick3('t_money6')} />
              <label for="s3_checkbox6" > 전략 </label>
            </div>

            <Button
              onClick={handleSaveSpecializations}
              variant="contained"
              color="primary"
              target="_blank"
              sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", height: 40, width: '100%', fontSize: '1.3rem' }} >
              전문가 정보 추가
            </Button>


          </div>

          <div className="Counselor_info_detail">
            <div className="Counselor_career">
              <h4 className="Counselor_h4">전문분야</h4>
              <br />
              {specializations.length > 0 ? (
                <div className="Counselor_info_detail_row">
                  {specializations.map((specialization, index) => (
                    <div key={index} className={`Counselor_info_detail3 ${index % 2 === 0 ? 'left' : 'right'}`}>
                      <div className="button-container">
                        <button className="icon-button" onClick={() => handleDeleteSpecialization(specialization.num)}>
                          <img src={closeIcon} alt="close icon" className="close-icon" />
                        </button>
                      </div>
                      <p>
                        ✔ {specialization.f_specializations} &gt; {specialization.s_specializations} &gt; {specialization.t_specializations}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p>선택된 전문분야가 없습니다.</p>
              )}
            </div>
          </div>
        </div>



      </DashboardCard>
    </PageContainer>
  );
};

export default A_edit_counselor_detail;