import { Image } from "./image";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Gallery = (props) => {
  const navigate = useNavigate();

  // 이미지 클릭 시 경로를 변경하는 함수
  const handleClick = (index) => {
    if ([0, 1, 2].includes(index)) {
      navigate("/O_Reservation_Edu");
    } else if ([3, 4].includes(index)) {
      navigate("/O_Reservation_Con");
    }
      else if ([5, 6, 7, 8].includes(index)) {
      navigate("/O_Reservation_Heal");
    }
  };



  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>집단프로그램</h2>
          <p>교육, 컨설팅, 힐링 프로그램 </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                    onClick={() => handleClick(i)}  // 클릭 이벤트 추가
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                      onClick={() => handleClick(i)}  // 클릭 이벤트 전달
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
