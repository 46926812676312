import React, { useState, useEffect } from 'react';
import { Box, Typography, FormGroup, Button, Stack } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../AuthContext.js';
import CustomTextField from '../../bootstrap/forms/theme-elements/CustomTextField';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SocialKakao from './SocialKakao.js';
import SocialNaver from './SocialNaver.js';

const AuthLogin = ({ title, subtitle, subtext }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAuth();
  const location = useLocation(); // 추가된 부분

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async () => {
    if (!username || !password) {
      toast.error("아이디와 비밀번호를 다시 입력해 주세요!");
      return;
    }

    const requestData = {
      username,
      password
    };

    try {
      const response = await fetch('/api/Login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include'
      });

      const responseData = await response.json(); // 서버에서 온 JSON 데이터 파싱

      if (response.status === 200 && responseData.isLoggedIn) {
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', true);
        navigate("/"); // 홈페이지로 이동
      } else if (response.status === 401 && responseData.error === "비밀번호 오류") {
        toast.error("아이디 또는 비밀번호가 잘못되었습니다.");
      } else if (response.status === 401 && responseData.error === "정지된 계정") {
        toast.error("계정이 정지되었습니다. 관리자에게 문의해주세요.");
      } else {
        toast.error("나중에 다시 시도해 주세요.");
      }
    } catch (error) {
      console.error('로그인 오류:', error);
      toast.error("서버와의 통신 중 오류가 발생했습니다. 나중에 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    if (location.state && location.state.error === "정지된 계정") {
      toast.error("계정이 정지되었습니다. 관리자에게 문의해주세요.");
    }
  }, [location.state]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };



  return (
    <>
      {title ? (
        <Typography fontWeight="700" variant="h2" mb={1}>
          {title}
        </Typography>
      ) : null}

      {subtext}

      <Stack>
        <ToastContainer style={{ zIndex: 99 }} />
        <Box>
          <label htmlFor="username">
            <div style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontWeight: 500, marginBottom: '5px', fontSize: '12px' }}>
              아이디
            </div>
          </label>
          <CustomTextField
            id="username"
            variant="outlined"
            fullWidth
            value={username}
            onChange={handleUsernameChange}
            onKeyPress={handleKeyPress}
          />
        </Box>
        <Box mt="25px">
          <label htmlFor="password">
            <Typography style={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", variant: "h4", marginBottom: '5px', fontWeight: 500, mb: "5px", fontSize: '12px' }}>
              비밀번호
            </Typography>
          </label>
          <CustomTextField
            id="password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            onKeyPress={handleKeyPress}
          />
        </Box>

        <Stack justifyContent="space-between" direction="row" alignItems="center" my={2}>
          <FormGroup></FormGroup>
          <Typography
            component={Link}
            to="/Find_pw"
            fontWeight="500"
            sx={{
              textDecoration: 'none',
              color: 'primary.main',
              fontSize: '11px'
            }}
          >
            비밀번호 찾기
          </Typography>
        </Stack>
      </Stack>
      <Box>
        <Button
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={handleSubmit}
          style={{
            fontSize: '13px',
            fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
          }}
        >
          로그인
        </Button>

      </Box>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '20px' }}>
        <SocialNaver />
        <SocialKakao />

      </div>


      {subtitle}
      <br />

      <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "11.5px", fontWeight: "400" }}>
        * 전문가 회원가입은 네이버, 카카오에서 불가능합니다.
      </Typography>
    </>
  );
};

export default AuthLogin;
