import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './search.css';
import axios from 'axios';
import { Rating } from '@mui/material';
import ProfileImg from './blank_profile.png';

export default function Search() {
  const [cdetail, setcDeatildata] = useState([]);
  const [reviewCounts, setReviewCounts] = useState({});
  const [averageRatings, setAverageRatings] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('전문분야');
  const [selectedGender, setSelectedGender] = useState('성별');
  const [selectedPrimaryField, setSelectedPrimaryField] = useState('1차 전문분야');
  const [selectedSecondaryField, setSelectedSecondaryField] = useState('2차 전문분야');
  const [selectedTertiaryField, setSelectedTertiaryField] = useState('3차 전문분야');
  const [cDetailData, setcDetailData] = useState([]);

  const secondaryFields = {
    '1차 전문분야': ['2차 전문분야'],
    '라이프': ['2차 전문분야', '가족관계', '대인관계', '개인이슈'],
    '비즈니스': ['2차 전문분야', '창업', '경영', '폐업'],
    '커리어': ['2차 전문분야', '학업', '진로', '취업', '경력개발']
  };

  const tertiaryFields = {
    '2차 전문분야': ['3차 전문분야'],
    '가족관계': ['3차 전문분야', '부모, 자녀 관계', '부부관계', '가족과의 불화', '육아 스트레스'],
    '대인관계': ['3차 전문분야', '이성관계', '친구관계', '직장 내 대인관계'],
    '개인이슈': ['3차 전문분야', '건강관리', '습관/태도', '성격/외모', '자신감', '우울감', '공황', '자살충동', '공포', '강박', '열등감', '중독/집착', '트라우마', '불면', '불안'],
    '경영': ['3차 전문분야', '재무', '리더십', '조직문화', '역량', '성과', '전략'],
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    scrollToTop();
    const fetchData = async () => {
      try {
        const cdetailResponse = await axios.get('/api/counselorall');
        const cdetailData = cdetailResponse.data.alldetaildata;
        setcDeatildata(cdetailData);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    document.title = "전문가";
  }, []);

  useEffect(() => {
    scrollToTop();
    const fetchReviewCountsAndAverageRatings = async () => {
      const counts = {};
      const ratings = {};
      for (const counselor of cdetail) {
        try {
          const response = await axios.get(`/api/counselordetail?id=${counselor.id}`, {
            headers: {
              'Cache-Control': 'no-store'
            }
          });
          const reviewData = response.data.reviewdata;
          const totalRating = reviewData.reduce((sum, review) => sum + review.rating, 0);
          const avgRating = reviewData.length ? (totalRating / reviewData.length).toFixed(1) : 0;
          counts[counselor.id] = reviewData.length;
          ratings[counselor.id] = avgRating;
        } catch (error) {
          console.error(`리뷰 수 및 별점 가져오기 오류 (전문가 ID: ${counselor.id}):`, error);
          counts[counselor.id] = 0;
          ratings[counselor.id] = 0;
        }
      }
      setReviewCounts(counts);
      setAverageRatings(ratings);
    };

    fetchReviewCountsAndAverageRatings();
  }, [cdetail]);

  useEffect(() => {
    // 페이지 로드 시 기본 필터값을 서버로 전송
    const initialFieldSelections = async () => {
      await sendFieldSelections(selectedPrimaryField, selectedSecondaryField, selectedTertiaryField);
    };

    initialFieldSelections();
  }, []);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
  };

  const handlePrimaryFieldChange = async (e) => {
    const value = e.target.value;
    setSelectedPrimaryField(value);
    setSelectedSecondaryField('2차 전문분야');
    setSelectedTertiaryField('3차 전문분야');
    await sendFieldSelections(value, '2차 전문분야', '3차 전문분야');
  };

  const handleSecondaryFieldChange = async (e) => {
    const value = e.target.value;
    setSelectedSecondaryField(value);
    setSelectedTertiaryField('3차 전문분야');
    await sendFieldSelections(selectedPrimaryField, value, '3차 전문분야');
  };

  const handleTertiaryFieldChange = async (e) => {
    const value = e.target.value;
    setSelectedTertiaryField(value);
    await sendFieldSelections(selectedPrimaryField, selectedSecondaryField, value);
  };

  const sendFieldSelections = async (primaryField, secondaryField, tertiaryField) => {
    try {
      const response = await axios.post('/api/filterCounselors', {
        primaryField,
        secondaryField,
        tertiaryField
      });
      const filteredCounselors = response.data.data; // 서버에서 받은 필터링된 전문가들의 couns_id 목록

      // 각 전문가의 리뷰 수 및 평균 별점 가져오기
      const counts = {};
      const ratings = {};
      let allDetailData = []; // 모든 전문가의 세부 데이터를 저장할 배열

      for (const counselorId of filteredCounselors) {
        try {
          const cdetailResponse = await axios.get(`/api/filter_counselorall?id=${counselorId}`, {
            headers: {
              'Cache-Control': 'no-store'
            }
          });
          const cdetailData = cdetailResponse.data.alldetaildata;

          const fetchReviewCountsAndAverageRatings = async () => {
            const counts = {};
            const ratings = {};
            for (const counselor of cdetail) {
              try {
                const response = await axios.get(`/api/counselordetail?id=${counselor.id}`, {
                  headers: {
                    'Cache-Control': 'no-store'
                  }
                });
                const reviewData = response.data.reviewdata;
                const totalRating = reviewData.reduce((sum, review) => sum + review.rating, 0);
                const avgRating = reviewData.length ? (totalRating / reviewData.length).toFixed(1) : 0;
                counts[counselor.id] = reviewData.length;
                ratings[counselor.id] = avgRating;
              } catch (error) {
                console.error(`리뷰 수 및 별점 가져오기 오류 (전문가 ID: ${counselor.id}):`, error);
                counts[counselor.id] = 0;
                ratings[counselor.id] = 0;
              }
            }
            setReviewCounts(counts);
            setAverageRatings(ratings);
          };

          // 기존 데이터에 새로운 데이터를 추가하여 상태를 업데이트합니다.
          allDetailData = [...allDetailData, ...cdetailData];

          setcDetailData(allDetailData);

          fetchReviewCountsAndAverageRatings();

        } catch (error) {
          console.error(`오류 (전문가 ID: ${counselorId}):`, error);
          counts[counselorId] = 0;
          ratings[counselorId] = 0;
        }
      }

      // 상태 업데이트
      setReviewCounts(counts);
      setAverageRatings(ratings);
    } catch (error) {
      console.error('전문분야 선택값 보내기 오류:', error);
      throw error;
    }
  };

  const filteredCounselors = cDetailData.filter(counselor => {
    const matchGender = selectedGender === '성별' || (selectedGender === '남' ? counselor.gender === 1 : counselor.gender === 2);
    return matchGender;
  });

  return (
    <div className="search container">
      <br />
      <div className="search-title">
        <h3>개인 및 그룹 상담 예약</h3>
      </div>
      <br />
      <div className="filters">
        <div className="filter">
          <select id="gender-select" onChange={handleGenderChange} value={selectedGender}>
            <option value="성별">성별</option>
            <option value="남">남</option>
            <option value="여">여</option>
          </select>
        </div>

        <div className="filter">
          <select id="primary-field-select" onChange={handlePrimaryFieldChange} value={selectedPrimaryField}>
            <option value="1차 전문분야">1차 전문분야</option>
            <option value="라이프">라이프</option>
            <option value="비즈니스">비즈니스</option>
            <option value="커리어">커리어</option>
          </select>
        </div>

        <div className="filter">
          <select id="secondary-field-select" onChange={handleSecondaryFieldChange} value={selectedSecondaryField}>
            {secondaryFields[selectedPrimaryField]?.map(field => (
              <option key={field} value={field}>{field}</option>
            ))}
          </select>
        </div>

        {!(selectedSecondaryField === '창업' || selectedSecondaryField === '폐업' || selectedSecondaryField === '학업' || selectedSecondaryField === '진로' || selectedSecondaryField === '취업' || selectedSecondaryField === '경력개발') &&
          <div className="filter">
            <select id="tertiary-field-select" onChange={handleTertiaryFieldChange} value={selectedTertiaryField}>
              {tertiaryFields[selectedSecondaryField]?.map(field => (
                <option key={field} value={field}>{field}</option>
              ))}
            </select>
          </div>
        }
      </div>

      <div className="search_row">
        {filteredCounselors.map((combinedCounselor) => (
          <div key={combinedCounselor.id} className="search-box">
            <Link to={`/Counselor/${combinedCounselor.id}`}>
              <div className="couns_img_div">
                <div className="couns_img_class">
                  {combinedCounselor.c_image ? (
                    <img src={`${combinedCounselor.c_image}?${Date.now()}`}
                      alt="Counselor Image"
                      className="couns_img" />
                  ) : (
                    <img
                      src={ProfileImg}
                      alt="Counselor Image"
                      className="couns_img" />
                  )}
                </div>
              </div>
              <div className="couns_content_div">
                <span className="search_overlay"></span>
                <div className="couns_content_class">
                  <div className="couns-name-ratings">
                    <h3 className="couns-name">
                      {combinedCounselor.c_name} ({reviewCounts[combinedCounselor.id]})
                    </h3>
                    <div className="ratings">
                      <Rating
                        name="read-only"
                        value={parseFloat(averageRatings[combinedCounselor.id])}
                        readOnly
                        precision={0.5}
                      />
                      <span style={{ fontFamily: 'Arial, sans-serif', marginLeft: '5px', fontSize: '12px' }}>
                        {parseFloat(averageRatings[combinedCounselor.id])} / 5.0
                      </span>
                    </div>
                  </div>
                  <div className="couns-script left-align">{combinedCounselor.c_description}</div>
                  <br />
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <br /><br /><br /><br /><br />
    </div>
  );
}
