import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Chip
} from '@mui/material';
import DashboardCard from '../../shared/DashboardCard';



const Wcounselor = () => {
    const navigate = useNavigate();
    const [waitingCounselors, setWaitingCounselors] = useState([]);


    useEffect(() => {
        // 서버에서 데이터 가져오는 함수
        async function fetchWaitingCounselors() {
          try {
            const response = await fetch("/api/Counselor_Manage");
            const data = await response.json();
            setWaitingCounselors(data);
          } catch (error) {
            console.error("데이터 가져오기 오류:", error);
          }
        }
      
        // Waiting Counselors 데이터 가져오기
        fetchWaitingCounselors();

    }, []); 

    return (

        <DashboardCard title="승인대기 회원">
            <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
                <Table
                    aria-label="simple table"
                    sx={{
                        whiteSpace: "nowrap",
                        mt: 2
                    }}
                >
                    <TableHead>
                    <TableRow>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                이름
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                생년월일
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                이메일
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                전화번호
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                성별
                              </Typography>
                            </TableCell>
                          </TableRow>
                    </TableHead>
                    <TableBody>
                        {waitingCounselors.map((item, index) => (
                          <TableRow key={index}>
                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} > {item.w_couns_name} </TableCell>
                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.w_couns_birthday}</TableCell>
                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.w_couns_email}</TableCell>
                                
                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.w_couns_phonenum}</TableCell>
                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.w_couns_gender === 1 ? "여" : "남"}</TableCell>
                                
                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </DashboardCard>
    );
};

export default Wcounselor;
