import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './O_Reservation.css';
import { useAuth } from "../../AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sections = [
    {
        type: "컨설팅",
        title: "성과 관리",
        description: "대상: 청소년, 대학생, 성인, 기업체",
        duration: "시간: 4시간 (평일, 주말)",
        classtime: 4,
        content: [
            {
                title: "성과 관리",
                content: (
                    <ul>
                        <li>성과 관리 이론</li>
                        <li>성과 측정 및 보상</li>
                    </ul>
                )
            },
            {
                title: "조직의 성과 관리",
                content: (
                    <ul>
                        <li>전략 수립</li>
                        <li>BSC</li>
                    </ul>
                )
            },
            {
                title: "개인의 성과 관리",
                content: (
                    <ul>
                        <li>목표 설정</li>
                        <li>MBO</li>
                        <li>성과 측정 및 평가</li>
                    </ul>
                )
            },
            {
                title: "핵심 인재(High Performer)",
                content: (
                    <ul>
                        <li>높은 성과를 위한 제언</li>
                        <li>고효율 업무 성과 관리</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/con_1.jpg'
    },
    {
        type: "컨설팅",
        title: "역량 관리",
        description: "대상: 청소년, 대학생, 성인, 기업체",
        duration: "시간: 4시간 (평일, 주말)",
        classtime: 4,
        content: [
            {
                title: "역량 개념",
                content: (
                    <ul>
                        <li>역량 개념</li>
                        <li>역량 정의</li>
                    </ul>
                )
            },
            {
                title: "역량 진단",
                content: (
                    <ul>
                        <li>역량 사전</li>
                        <li>역량 진단</li>
                        <li>역량 분석</li>
                    </ul>
                )
            },
            {
                title: "역량 관리",
                content: (
                    <ul>
                        <li>역량 모형</li>
                        <li>인지 모형</li>
                    </ul>
                )
            },
            {
                title: "역량 개발",
                content: (
                    <ul>
                        <li>필요 역량 (공통, 직무, 리더십)</li>
                        <li>역량 개발 계획</li>
                        <li>실행 및 점검</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/con_2.png'
    },
    {
        type: "컨설팅",
        title: "팀 워크",
        description: "대상: 청소년, 대학생, 성인, 기업체",
        duration: "시간: 8시간 (평일, 주말)",
        classtime: 8,
        content: [
            {
                title: "팀워크",
                content: (
                    <ul>
                        <li>팀워크의 중요성</li>
                        <li>동영상</li>
                    </ul>
                )
            },
            {
                title: "3D Puzzle 실습",
                content: (
                    <ul>
                        <li>3D 퍼즐</li>
                        <li>프로젝트 관리 (Lesson Learnt)</li>
                    </ul>
                )
            },
            {
                title: "리치 픽쳐 (Rich Picture)",
                content: (
                    <ul>
                        <li>미래 설계</li>
                        <li>리치 픽쳐 만들기</li>
                    </ul>
                )
            },
            {
                title: "팀 비전 / 미션",
                content: (
                    <ul>
                        <li>팀 비전 / 미션 수립</li>
                        <li>액션 플랜 시트 작성</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/con_3.jpg'
    },
    {
        type: "컨설팅",
        title: "리더십 역량",
        description: "대상: 청소년, 대학생, 성인, 기업체",
        duration: "시간: 8시간 (평일, 주말)",
        classtime: 8,
        content: [
            {
                title: "리더십 유형과 역량",
                content: (
                    <ul>
                        <li>리더십 유형</li>
                        <li>리더십 역량</li>
                    </ul>
                )
            },
            {
                title: "사례 연구",
                content: (
                    <ul>
                        <li>리더십의 중요성</li>
                        <li>Lesson Learnt</li>
                    </ul>
                )
            },
            {
                title: "셀프 리더십",
                content: (
                    <ul>
                        <li>리더십 진단</li>
                        <li>리더십 개발 계획</li>
                    </ul>
                )
            },
            {
                title: "진성 리더십",
                content: (
                    <ul>
                        <li>진성 리더십</li>
                        <li>선언식</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/con_4.jpg'
    }
];

const AccordionItem = ({ title, content, isOpen, onClick }) => (
    <div className="accordion-item">
        <div className="accordion-title" onClick={onClick}>
            {title}
            <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && <div className="accordion-content">{content}</div>}
    </div>
);

const O_Reservation_Con = () => {
    const [openIndices, setOpenIndices] = useState(Array(sections.length).fill(null));
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    useEffect(() => {
        scrollToTop();
    }, []);

    const handleToggle = (sectionIndex, contentIndex) => {
        setOpenIndices(prevIndices => {
            const newIndices = [...prevIndices];
            newIndices[sectionIndex] = newIndices[sectionIndex] === contentIndex ? null : contentIndex;
            return newIndices;
        });
    };

    useEffect(() => {
        document.title = "집단 상담 예약";
    }, []);

    const handleReservation = (type, title, classtime) => {
        if (!isLoggedIn) {
            navigate('/Login');
            return;
        }

        navigate('/O_Reservation_detail', {
            state: {
                type: type,
                title: title,
                classtime: classtime
            }
        });
    };

    return (
        <div className='O_Res'>
            <ToastContainer />
            {sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="O_Res_Container">
                    <h1 className='O_Res_Container_h1'>{section.title}</h1>
                    <p className='O_Res_Container_p'>{section.description}</p>
                    <p className='O_Res_Container_p'>{section.duration}</p>
                    <div className='O_Res_Content'>
                        <div className="accordion">
                            {section.content.map((item, contentIndex) => (
                                <AccordionItem
                                    key={contentIndex}
                                    title={item.title}
                                    content={item.content}
                                    isOpen={openIndices[sectionIndex] === contentIndex}
                                    onClick={() => handleToggle(sectionIndex, contentIndex)}
                                />
                            ))}
                        </div>
                        <div className='contentimg'>
                            <img src={section.img} alt={section.title} />
                        </div>
                    </div>
                    <button
                        className="O_reservation-button"
                        onClick={() => handleReservation(section.type, section.title, section.classtime)}
                    >
                        예약하기
                    </button>
                </div>
            ))}
        </div>
    );
};

export default O_Reservation_Con;
