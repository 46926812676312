
const info_refund = `
〔 환불 기준 〕

- 상담 시작 시간 기준 36시간 전 : 100% 환불
- 상담 시작 시간 기준 36시간 ~ 12시간 전 : 50% 환불
- 상담 시작 시간 기준 12시간 이내 : 0% 환불

〔 노쇼 기준 〕(노쇼: 오기로 한 사람이 예약이나 약속을 취소하지 않고 나타나지 않는 일)

- 상담 예약한 시간이 지났으나 접속하지 않을 경우, 상담 전문가는 필수 대기 시간 이후에는 상담을 진행한 것으로 간주함.
- 상담 전문가 필수 대기 시간(노쇼 기준) : 20분
- 내담자의 노쇼로 인한 상담 불이행은 환불 불가함.

`;


export default info_refund;