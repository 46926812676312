import React, { useState, useEffect } from "react";
import './Find_pw.css';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { Typography, Card, Button } from '@mui/material';
import PageContainer from '../../bootstrap/container/PageContainer';
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function Reset_pw() {
  const location = useLocation();
  const navigate = useNavigate();

  const [new_pw, setNewpw] = useState(""); // 새 비밀번호
  const [new_pw2, setNewpw2] = useState(""); // 새 비밀번호 확인

  const { id, email } = location.state;


  const handlepwChange = (event) => {
    setNewpw(event.target.value);
  };

  const handlepwChange2 = (event) => {
    setNewpw2(event.target.value);
  };

  const reset_pw = async () => {

    if (new_pw !== new_pw2) {
      toast.error("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return;
    }

    try {
      const response = await axios.post('/api/find_pw', {
        id: id,
        email: email,
        new_pw: new_pw // 새 비밀번호 서버에 전송
      });
    } catch (error) {
      console.error("비밀번호 재설정 오류:", error.response.data);
      toast.error("비밀번호 재설정에 실패했습니다. 다시 시도해주세요.");
    }

    navigate(`/Success_pw`)

  };

  return (
    <PageContainer title="비밀번호 재설정">
      <ToastContainer style={{ zindex: 20 }} />
      <br /><br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card elevation={20} sx={{ p: 4, zIndex: 1, width: '100%', maxWidth: '480px', minHeight: '500px ' }}>


          <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
            비밀번호 재설정
          </Typography>
          <br /><br /><br /><br /><br />

          <Typography sx={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}>
            아이디 : {id}
          </Typography>
          <br /><br />

          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >

            <TextField
              id="password"
              label="새 비밀번호"
              variant="outlined"
              type="password"
              value={new_pw}
              onChange={handlepwChange}
            />
            <br /><br /> &nbsp;(영문대소문자/숫자/특수문자 조합 8자 ~ 16자)
          </Box>
          <br />
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch', mt: 2 },
            }}
            noValidate
            autoComplete="off"
          >

            <TextField
              id="password"
              type="password"
              label="새 비밀번호 확인"
              variant="outlined"
              value={new_pw2}
              onChange={handlepwChange2}
              className="email-box1"
            />

          </Box>
          <br /><br /><br />
          <br />

          <Button
            variant="contained"
            onClick={reset_pw}
            fullWidth
            mt={1}
            sx={{ fontSize: '1.4rem' }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                reset_pw();
              }
            }
            }
          >
            비밀번호 변경
          </Button>


        </Card>

      </div>
      <br /><br /><br />
    </PageContainer>




  );
}
