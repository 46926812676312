import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Menu,
  Button,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { IconListCheck, IconMail, IconUser } from '@tabler/icons';

import ProfileImg from '../../../../counselor_img/unknown.png';

import { useAuth } from "../../../../AuthContext";
import axios from "axios";




const Profile = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [w_counselorData, setW_counselorData] = useState({}); // 사용자 데이터
  const [counsImg, setCounsImg] = useState({}); // 이미지 불러오기

  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  async function fetchCounsImg() {
    try {
      //서버에 상담사 이미지 가져오는 api 호출
      const counsResponse = await axios.post('/api/MypageCouns', {
        userId: w_counselorData.user_id,
        userName: w_counselorData.couns_name,
      }, { withCredentials: true });

    } catch (error) {
      console.log('데이터 요청 오류');
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/Mypage", {
          withCredentials: true,
        });

        setW_counselorData(response.data);
      } catch (error) {
        console.error("마이페이지 데이터 요청 오류:", error);
      }
    }

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch("/api/Logout", {
        method: "GET",
        credentials: "include",
      });

      const responseData = await response.json();
      if (responseData.isLoggedIn === false) {
        setIsLoggedIn(false);
        navigate("/");
      } else {
        console.error("로그아웃 실패:", response.statusText);
      }
    } catch (error) {
      console.error("로그아웃 요청 오류:", error);
    }
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === 'object' && {
            color: 'primary.main',
          }),
        }}
        onClick={handleClick2}
      >

        {w_counselorData.c_image ? (
          <Avatar
            src={w_counselorData.c_image}
            alt="Counselor"
            sx={{
              width: 35,
              height: 35,
            }}
          />
        ) : (
          <Avatar
            src={ProfileImg}
            alt="Counselor"
            sx={{
              width: 35,
              height: 35,
            }}
          />
        )}
      </IconButton>

      <Menu
        id="msgs-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{
          '& .MuiMenu-paper': {
            width: '200px',
          },
        }}
      >
        <MenuItem component={Link} to="/Mypage/counselor/Check">
          <ListItemIcon>
            <IconUser width={20} />
          </ListItemIcon>
          <ListItemText>프로필 세부정보</ListItemText>
        </MenuItem>
        <Box mt={1} py={1} px={2}>
          <Button to="/" variant="outlined" color="primary" component={Link} onClick={handleLogout} fullWidth>
            로그아웃
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};

export default Profile;
