// 내담자_예약 내역

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Chip
} from '@mui/material';
import DashboardCard from '../../shared/DashboardCard';


const ProductPerformance = () => {
    const [resdata, setResdata] = useState([]); // 예약 정보를 저장할 상태
    const [closestReservations, setClosestReservations] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        const fetchReservations = async () => {
            try {
                const response = await axios.get('/api/ResData');
                if (response.data && response.data.combinedResults) {
                    const combinedResults = response.data.combinedResults;

                    setResdata(combinedResults);

                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    const futureReservations = combinedResults.filter(reservation => {
                        const reservationDate = new Date(reservation.selectedDate);
                        return reservationDate >= today; 
                    });

                    const closestReservations = futureReservations
                        .sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                        .slice(0, 3); // 상위 3개의 예약을 선택

                    setClosestReservations(closestReservations);
                } else {
                    console.error('올바르지 않은 형식의 응답:', response.data);
                }
            } catch (error) {
                console.error('예약 정보 가져오기 오류:', error);
            }
        };

        fetchReservations();
    }, []);

    const counselorColors = {
        '김해은': '#ecd79b',
        '유용린': '#dedeff',
        '집단 프로그램':'#f7cac9',
        '강은주' : '#ecd79b'
        
    };


    return (

        <DashboardCard title="예약 내역">
            <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
                <Table
                    aria-label="simple table"
                    sx={{
                        whiteSpace: "nowrap",
                        mt: 2
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "13px", fontWeight: "700" }}>
                                    예약 ID
                                </Typography>
                            </TableCell>

                            <TableCell>
                                <Typography sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "13px", fontWeight: "700" }}>
                                    상담 일시
                                </Typography>
                            </TableCell>

                            <TableCell>
                                <Typography sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "13px", fontWeight: "700" }}>
                                    상담 시간
                                </Typography>
                            </TableCell>

                            <TableCell>
                                <Typography sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "13px", fontWeight: "700" }}>
                                    상담사
                                </Typography>
                            </TableCell>

                            <TableCell >
                                <Typography sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "13px", fontWeight: "700" }}>
                                    상담 상태
                                </Typography>
                            </TableCell>
                            
                            <TableCell>
                                <Typography sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "13px", fontWeight: "700" }}>
                                    결제 상태
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {closestReservations.map(reservation => (
                            <TableRow key={reservation.res_id}>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
                                        }}
                                    >
                                    {reservation.res_id}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Box>
                                        <Typography variant="subtitle2" fontWeight={600}>
                                                
                                        </Typography>
                                        <Typography
                                            color="textSecondary"
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                            }}
                                        >
                                            {new Date(reservation.selectedDate).toLocaleDateString('ko-KR')}
                                            </Typography>
                                      
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography 
                                    color="textSecondary" 
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                        }}
                                        >
                                        {reservation.selectedTime}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        sx={{
                                            px: "4px",
                                            backgroundColor: counselorColors[reservation.counselor_name], // 상담사 별 색상 지정
                                            color: "#000",
                                            fontSize: "10px",
                                            fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                        }}
                                        size="small"
                                        label={reservation.counselor_name}
                                    ></Chip>
                                </TableCell>
                                <TableCell>
                                    <Typography sx={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                        }}>
                                        {reservation.counselor_stat}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography sx={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                             fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif"
                                        }}>
                                        {reservation.deposit_stat}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </DashboardCard>
    );
};

export default ProductPerformance;
