// 관리자 마이페이지 메뉴

import {
  IconAperture, IconCalculator, IconLayoutDashboard,  IconUsers, IconCirclePlus
} from '@tabler/icons';
import { Typography } from '@mui/material';
import { uniqueId } from 'lodash';

const Menuitems = [
  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
      sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", 
              fontSize: '1.25rem',  
              marginTop: '24px', 
              marginBottom: '10px' }}>
        홈
      </Typography>
    ),
  },

  {
    id: uniqueId(),
    title: '관리자 페이지',
    icon: IconLayoutDashboard,
    href: '/Mypage/admin',
  },
  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
      sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '1.25rem',  marginTop: '24px', marginBottom: '10px' }}>
        회원관리
      </Typography>
    ),
  },
  {
    id: uniqueId(),
    title: '전문가', 
    icon: IconUsers,
    href: '/Mypage/admin/counselor_manage',
  },
  {
    id: uniqueId(),
    title: '내담자',
    icon: IconUsers,
    href: '/Mypage/admin/client_manage',
  },
  {
    navlabel: true,
    subheader: (
      <Typography variant="h6" 
      sx={{  fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '1.25rem',  marginTop: '24px', marginBottom: '10px' }}>
        서비스 관리
      </Typography>
    ),
  },
  {
    id: uniqueId(),
    title: '집단 프로그램 예약 관리 ',  
    icon: IconAperture,
    href: '/Mypage/admin/O_Program_Reservation',
  },
  {
    id: uniqueId(),
    title: '정산 ', 
    icon: IconCalculator,
    href: '/Mypage/admin/admin_calculate',
  },
  {
    id: uniqueId(),
    title: '전문가 등록 ',  
    icon: IconCirclePlus,
    href: '/Mypage/admin/add_counselor',
  },

];

export default Menuitems;
