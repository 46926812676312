import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { useTheme } from '@mui/material/styles';
import DashboardCard from '../../shared/DashboardCard';
import { Select, MenuItem } from '@mui/material';

const YearlyRes = () => {
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [selectedYear, setSelectedYear] = useState('2023'); 
  const today = new Date();
  const selectedMonth = today.getMonth() + 1;

  useEffect(() => {
    axios.post("/api/resDataChart")
      .then(response => {
        const serverData = response.data;
        const yearData = [];
        for (let i = 1; i <= 12; i++) {
          const monthData = serverData.find(item => item.month === i);
          if (monthData) {
            yearData.push(monthData.count);
          } else {
            yearData.push(0);
          }
        }
        setChartData(yearData);
      })
      .catch(error => {
        console.error('Error fetching monthly reservation data:', error);
      });
  }, []);

  useEffect(() => {
    if (chartRef.current && chartData.length) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      const ctx = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
          datasets: [{
            label: ' 예약건수',
            data: chartData,
            backgroundColor: 'rgba(54, 162, 235, 0.8)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
            borderRadius: 5,
            hoverBackgroundColor: 'rgba(54, 162, 235, 1)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)'
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'top',
              labels: {
                color: '#333',
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              titleColor: '#fff',
              bodyColor: '#fff',
              titleFont: {
                size: 16,
                weight: 'bold'
              },
              bodyFont: {
                size: 14
              },
              cornerRadius: 5,
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ' : ';
                  }
                  if (context.parsed.y !== null) {
                    label += `${context.parsed.y}건`;
                  }
                  return label;
                }
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                color: '#333',
                font: {
                  size: 12
                }
              }
            },
            y: {
              beginAtZero: true,
              grid: {
                color: 'rgba(54, 162, 235, 0.2)'
              },
              ticks: {
                color: '#333',
                font: {
                  size: 12
                }
              }
            }
          }
        }
      });
    }
  }, [chartData]);

  // select
  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <DashboardCard title={`${today.getFullYear()}년 예약 현황`}>
      <canvas ref={chartRef} />
    </DashboardCard>
  );
};

export default YearlyRes;
