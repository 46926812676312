import React from 'react';
import { Grid, Box } from '@mui/material';
import PageContainer from '../container/PageContainer.js';

// components
import Counselor_profile from './components/Counselor_profile.js';
import Cou_calculate from './components/Cou_calculate.js';
import Todayreservation from './components/Todayreservation.js';

import Cou_community from './components/Cou_community.js';


import { CssBaseline, ThemeProvider } from '@mui/material';
import { baselightTheme } from "../theme/DefaultColors.js"


const Cou_Dashboard = () => {
  const theme = baselightTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PageContainer title="전문가 마이페이지">
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={18}>
              <Counselor_profile />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={3}>

                <Grid item xs={12} >
                  <Cou_calculate />
                </Grid>
                <Grid item xs={12}>
                  <Cou_community />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Todayreservation />
            </Grid>


          </Grid>
        </Box>
      </PageContainer>
    </ThemeProvider>
  );
};

export default Cou_Dashboard;
