import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Typography, Grid, CardContent, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import BlankCard from '../../bootstrap/shared/BlankCard';
import PageContainer from '../../bootstrap/container/PageContainer';
import DashboardCard from '../../bootstrap/shared/DashboardCard';
import Paper from '@mui/material/Paper';
import inforefund from "./info_refund";


const Cancel_request = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { reservationNumber } = location.state; // 예약 번호 가져오기


  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);

  const [customText, setCustomText] = useState('');

  const [resdata, setResdata] = useState([]); // 예약 정보를 저장할 상태

  const [cancelreason, setcancelreason] = useState('');
  const [refundAmount, setRefundAmount] = useState(0); // 환불 금액

  const [cancelDateTime, setCancelDateTime] = useState(null);

  const [refundDepositor, setRefundDepositor] = useState(''); // 환불 예금주
  const [refundAccount, setRefundAccount] = useState(''); // 환불 계좌
  const [selectedBank, setSelectedBank] = useState(''); // 환불 은행

  const reservationId = reservationNumber;

  useEffect(() => {
    const fetchReservationDetails = async () => {
      try {
        const response = await axios.get(`/api/reservationDetails/${reservationId}`);
        const responseData = response.data.results[0];
        setResdata(responseData);
      } catch (error) {
        console.error('예약 상세 정보 가져오기 오류:', error);
      }
    };

    fetchReservationDetails();
  }, [reservationNumber]);

  useEffect(() => {
    const handleCancelReservation = () => {
      // 현재 시간 구하기
      const currentTime = new Date();

      // 예약된 시간과 현재 시간의 차이 계산 (밀리초 단위)
      const timeDiff = new Date(resdata.selectedDate) - currentTime;

      // 시간 차이를 시간 단위로 변환하여 계산
      const hoursDiff = Math.ceil(timeDiff / (1000 * 60 * 60));

      // 환불 비율 계산
      let refundPercentage;
      if (hoursDiff >= 36) {
        refundPercentage = 100;
      } else if (hoursDiff < 36 && hoursDiff >= 12) {
        refundPercentage = 50;
      } else {
        refundPercentage = 0;
      }

      // 환불 금액 계산
      const refundAmount = (resdata.totalamount * refundPercentage) / 100;
      setRefundAmount(refundAmount);
    };

    const cancelDateTime = new Date().toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });
    setCancelDateTime(cancelDateTime);

    handleCancelReservation();
  }, [resdata]);


  const handleCheckboxChange = (value) => {
    if (value === '단순변심') {
      setIsChecked1(true);
      setIsChecked2(false);
      setIsChecked3(false);
      setIsChecked4(false);
      setcancelreason('단순변심');
    } else if (value === '날짜, 시간 잘못 선택') {
      setIsChecked1(false);
      setIsChecked2(true);
      setIsChecked3(false);
      setIsChecked4(false);
      setcancelreason('날짜, 시간 잘못 선택');
    } else if (value === '상담사 잘못 선택') {
      setIsChecked1(false);
      setIsChecked2(false);
      setIsChecked3(true);
      setIsChecked4(false);
      setcancelreason('상담사 잘못 선택');
    } else if (value === '기타') {
      setIsChecked1(false);
      setIsChecked2(false);
      setIsChecked3(false);
      setIsChecked4(true);
      setcancelreason(customText);
    }
  };

  const setCustomTextValue = (value) => {
    setCustomText(value);
    if (isChecked4) {
      setcancelreason(value);
    }
  };

  const onClickCancel = () => {
    const cancelReservation = async (reservationId) => {
      try {
        const cancelResponse = await axios({
          url: "/api/Rescancel",
          method: "post",
          headers: { "Content-Type": "application/json" },
          data: {
            coun_type: resdata.coun_type,
            res_id: resdata.res_id, // 주문번호
            paymentKey: resdata.paymentKey,
            reason: cancelreason, // 환불사유
            deposit_type: resdata.deposit_type,
            deposit_stat: resdata.deposit_stat,
            refundAmount: refundAmount
          }
        });

        const responseData = cancelResponse.data;
        if (responseData.status === "success") {
          await axios.put(`/api/cancelReservation/${reservationId}?coun_type=${resdata.coun_type}`);

          const updatedResData = { ...resdata };
          updatedResData.counselor_stat = '예약 취소';
          setResdata(updatedResData);
          navigate('/Mypage/client/reservation_manage');
        } else {
          alert(`예약 취소에 실패하였습니다. (에러 메시지: ${responseData.message})`);
        }
      } catch (error) {
        console.error('예약 취소 오류:', error);
        alert(`예약 취소 요청에 실패하였습니다. (에러 메시지: ${error.message})`);
      }
    };

    if (resdata && resdata.res_id) {
      cancelReservation(resdata.res_id);
    } else {
      console.error('예약 정보가 유효하지 않습니다.');
      alert('예약 정보가 유효하지 않습니다.');
    }
  };


  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', marginTop: '8rem' }}>
      <ToastContainer />
      <PageContainer title="예약 취소" style={{ justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '1200px' }}>
        <Grid container spacing={3} style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          {/* 왼쪽 그리드 아이템 */}
          <Grid item sm={10}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <DashboardCard title="예약 취소">
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Paper elevation={10} style={{ padding: '20px' }}>
                      <BlankCard>
                        <CardContent>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography sx={{ fontSize: "14.5px", fontWeight: "700" }}>
                                  예약 번호 : {reservationNumber}
                                </Typography>
                                <br /><br />
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                  상담사 : {resdata.counselor_name} 상담사
                                </Typography>
                                <br /><br />
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                  예약 날짜 : {new Date(resdata.selectedDate).toLocaleDateString('ko-KR')}
                                </Typography>
                                <br /><br />
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                  예약 시간 : {resdata.selectedTime} {'('}{resdata.totalTime}시간{')'}
                                </Typography>
                                <br />
                              </TableCell>
                              {/* 나머지 TableCell들 */}
                            </TableRow>
                          </TableHead>
                          {/* 나머지 내용 */}
                        </CardContent>
                      </BlankCard>
                    </Paper>
                  </Grid>
                  {/* 오른쪽 그리드 아이템 */}
                  <Grid container spacing={0}>
                    <Grid item sm={11.58} style={{ marginLeft: '24px', marginTop: '10px' }}>
                      <Paper elevation={10} style={{ padding: '20px' }}>
                        <BlankCard>
                          <CardContent>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography sx={{ fontSize: "14.5px", fontWeight: "700" }}>
                                    취소 사유
                                  </Typography>
                                  <br /><br />
                                  <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={isChecked1}
                                        onChange={() => handleCheckboxChange('단순변심')}
                                      />
                                      단순변심
                                    </label>
                                  </Typography>
                                  <br /><br />
                                  <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={isChecked2}
                                        onChange={() => handleCheckboxChange('날짜, 시간 잘못 선택')}
                                      />
                                      날짜, 시간 잘못 선택
                                    </label>
                                  </Typography>
                                  <br /><br />
                                  <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={isChecked3}
                                        onChange={() => handleCheckboxChange('상담사 잘못 선택')}
                                      />
                                      상담사 잘못 선택
                                    </label>
                                  </Typography>
                                  <br /><br />
                                  <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={isChecked4}
                                        onChange={() => handleCheckboxChange('기타')}
                                      />
                                      기타(직접기재란, 최대 50자) <input value={customText} onChange={(e) => setCustomTextValue(e.target.value)} />
                                    </label>
                                  </Typography>
                                  <br />
                                </TableCell>
                                {/* 나머지 TableCell들 */}
                              </TableRow>
                            </TableHead>
                            {/* 나머지 내용 */}
                          </CardContent>
                        </BlankCard>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item sm={11.58} style={{ marginLeft: '24px', marginTop: '10px' }}>
                      <Paper elevation={10} style={{ padding: '20px' }}>
                        <BlankCard>
                          <CardContent>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography className="card-content" sx={{ fontWeight: 'bold' }}>
                                    환불규정
                                  </Typography>
                                  <br />
                                  <div className="signup-re-agree-box"
                                    style={{
                                      fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
                                      textAlign: 'left', width: '100%', paddingLeft: '10px',
                                      maxWidth: '100%',
                                      maxHeight: '100%'
                                    }}
                                  >
                                    {inforefund}
                                  </div>
                                  <br />
                                  <br />
                                  <Typography sx={{ fontSize: '14.5px', fontWeight: '700' }}>
                                    {resdata.deposit_stat === '결제 전' && (
                                      <div className="depositStatus_false">
                                        <div className="join_p4">결제상태 : 결제 전</div>
                                        <br />
                                        <div className="join_p4">환불금 : 0원</div>
                                        <br />
                                        <div className='Counselor_p1'>* 예약 취소 시, 전문가(상담가)는 예약 시 작성하신 상담 요청사항을 확인할 수 없습니다.</div>
                                      </div>
                                    )}
                                    {resdata.deposit_stat === '결제 완료' && (
                                      <div className="depositStatus_true">
                                        <div className="join_p4">결제금액 : {resdata.totalamount}원</div><br />
                                        <div className="join_p4">환불금 : {refundAmount}원</div><br />
                                        <div className="join_p4">결제수단 : {resdata.deposit_type}</div><br />
                                        {resdata.deposit_type === '가상계좌' && (
                                          <div className="join_p4">
                                            <div className="join_p4">환불 계좌: {resdata.vbankname} {resdata.vref_num} {resdata.vref_name}</div>
                                            <br />
                                          </div>
                                        )}
                                        <br />
                                        <div className='Counselor_p1'>* 예약 취소 시, 전문가(상담가)는 예약 시 작성하신 상담 요청사항을 확인할 수 없습니다.</div>
                                      </div>
                                    )}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </CardContent>
                        </BlankCard>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#5d87ff',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '13px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '185px',
                    height: '35px',
                    margin: '30px auto',
                  }}
                  onClick={onClickCancel}
                >
                  예약 취소
                </Button>
              </DashboardCard>
            </Paper>
          </Grid>
        </Grid>
      </PageContainer>
    </div>
  );

};

function Span({ space = 100 }) {
  return <span style={{ paddingRight: space }}></span>;
}

function Span1({ space = 5 }) {
  return <span style={{ paddingRight: space }}></span>;
}



export default Cancel_request;