import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import DashboardCard from '../../shared/DashboardCard';
import axios from 'axios';

const Completeres = () => {
  const [closestReservation, setClosestReservation] = useState(null);

  useEffect(() => {
    const fetchCompletedReservations = async () => {
      try {
        const response = await axios.get('/api/CompletedReservations');
        const completeddata = response.data.completeddata;
        const today = new Date();
        
        // 현재 날짜와 가장 가까운 예약 찾기
        let closest = null;
        let minDiff = Number.MAX_VALUE;
        completeddata.forEach(reservation => {
          const diff = Math.abs(new Date(reservation.selectedDate) - today);
          if (diff < minDiff) {
            minDiff = diff;
            closest = reservation;
          }
        });
        setClosestReservation(closest);
      } catch (error) {
        console.error('예약 정보 가져오기 오류:', error);
      }
    };

    fetchCompletedReservations();
  }, []);

  return (
    <DashboardCard title="완료된 예약">
      <Stack direction="row" spacing={1} my={1} alignItems="center">
        {closestReservation ? (
          <Typography variant="subtitle2" fontWeight="500" sx={{ textAlign: 'left', fontSize: 11, fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" ,fontSize :'13px' }}>
            예약 ID : {closestReservation.res_id}<br/>
            상담 일시 : {new Date(closestReservation.selectedDate).toLocaleDateString('ko-KR')}<br/>
            상담 시간 : {closestReservation.selectedTime}<br/>
            상담사 : {closestReservation.counselor_name} <br/>
          </Typography>
          
        ) : (
          <Typography variant="subtitle2" fontWeight="600" sx = {{fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" ,fontSize :'13px'}}>
            완료된 예약이 없습니다.
          </Typography>
        )}
      </Stack>
    </DashboardCard>
  );
};

export default Completeres;
