import React from "react";
import { useSearchParams, Link } from "react-router-dom";
import './Paybank.css';
import { Grid, Box, Card, Stack, Typography } from '@mui/material';
import PageContainer from '../../bootstrap/container/PageContainer';
import naman_logo from "../Login/naman.png";

const PaybankFail = () => {
  const [searchParams] = useSearchParams();

  return (
    <div className="login-title">
      <PageContainer title="결제 실패" sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15rem' }}>
          <Card elevation={20} sx={{ p: 4, zIndex: 1, width: '500px', minHeight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Link to="/">
              <img
                src={naman_logo}
                alt="Logo"
                style={{
                  height: '32px',
                  width: '140px',
                  overflow: 'hidden',
                  display: 'block',
                  marginBottom: '25px',
                }}
              />
              
              <hr style={{
                        backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
                        height: '4px',
                        width: '115px',
                        top : '-20px',
                        left : '3px',
                        border: 'none' // 기본 테두리를 제거합니다
                    }} />
            </Link>
            <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
              예약 실패
            </Typography> 
            <br/>
            <Typography style = {{fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}  sx={{fontSize: "15px", justifyContent: "left", fontWeight: "400", textAlign: "left" }}>
              {`예약을 다시 시도해 주시거나, 반복 시 관리자에게 문의 주세요.`}
            </Typography>
            <br/>
          </Card>
        </div>
      <br/><br/><br/><br/>
      </PageContainer>
    </div>
  );
}

export default PaybankFail;
