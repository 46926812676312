import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './O_Reservation.css';

function O_Reservation() {
    const navigate = useNavigate();

    const programs = [
        {
            id: 1,
            title: '교육',
            description: '최고의 교육 프로그램을 제공합니다.',
            imageUrl: 'https://ohiocapitaljournal.com/wp-content/uploads/2019/12/education-1959551_640.jpg',
            link: '/O_Reservation_Edu'
        },
        {
            id: 2,
            title: '컨설팅',
            description: '전문적인 컨설팅 서비스를 제공합니다.',
            imageUrl: 'https://t4.ftcdn.net/jpg/02/14/49/77/360_F_214497736_HB3R4BZr5wu0VsrifKjMVeiYD2UNiJWp.jpg',
            link: '/O_Reservation_Con'
        },
        {
            id: 3,
            title: '힐링',
            description: '힐링 프로그램으로 마음의 안정을 찾으세요.',
            imageUrl: 'https://images.unsplash.com/photo-1655361293379-01e6ebc966f7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            link: '/O_Reservation_Heal'
        }
    ];

    const ProgramCard = ({ title, description, imageUrl, link }) => (
        <div className="program-card" onClick={() => navigate(link)}>
            <img src={imageUrl} alt={title} />
            <div className="program-info">
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );

    useEffect(() => {
        document.title = "집단 프로그램";
    }, []);

    return (
        <div className="O_Reservation">
            <div className="programs">
                {programs.map(program => (
                    <ProgramCard key={program.id} {...program} />
                ))}
            </div>
        </div>
    );
}

export default O_Reservation;
