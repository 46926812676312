
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import { Typography, Grid, CardContent, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import BlankCard from '../shared/BlankCard';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { Select, MenuItem } from '@mui/material';
import { pink } from '@mui/material/colors';
import { blue } from '@mui/material/colors';
import './Oresbutton.css';

const O_Program_Reservation = () => {
    const [isOpen1, setIsOpen1] = useState(false); // 집단 프로그램 예약 확정 전 상세 보기 팝업
    const [selectedReservationIndex1, setSelectedReservationIndex1] = useState(null); // 상세보기 Modal
    const [isOpen2, setIsOpen2] = useState(false); // 집단 프로그램 예약 확정 후 상세 보기 팝업
    const [selectedReservationIndex2, setSelectedReservationIndex2] = useState(null); // 상세보기 Modal
    const [isOpen3, setIsOpen3] = useState(false); // 집단 프로그램 예약 확정 후 상세 보기 팝업
    const [selectedReservationIndex3, setSelectedReservationIndex3] = useState(null); // 상세보기 Modal
    const [isOpen4, setIsOpen4] = useState(false); // 집단 프로그램 예약 확정 후 상세 보기 팝업
    const [selectedReservationIndex4, setSelectedReservationIndex4] = useState(null); // 상세보기 Modal

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const years = Array.from({ length: 11 }, (v, i) => new Date().getFullYear() - i);

    const [resdata, setResdata] = useState({
        oReservations: [],
        oReservations2: [],
        oCompleteRes: [],
        cancelResults: []
    }); // 예약 정보를 저장할 상태


    async function fetchReservations() {
        try {
            const queryParams = new URLSearchParams({
                selectedMonth,
                selectedYear,
            }).toString();
            const response = await axios.get(`/api/admin_ResData?${queryParams}`);
            const oReservations = response.data.oReservations;
            const oReservations2 = response.data.oReservations2;
            const oCompleteRes = response.data.oCompleteRes;
            const cancelResults = response.data.cancelResults;

            setResdata({
                oReservations,
                oReservations2,
                oCompleteRes,
                cancelResults,
            }); // 예약 정보를 상태에 저장
        } catch (error) {
            console.error('예약 정보 가져오기 오류:', error);
        }
    }

    useEffect(() => {
        fetchReservations(selectedMonth, selectedYear);
    }, [selectedMonth, selectedYear]);


    // toast 메시지가 사라진 후에 페이지를 새로 고침하는 함수
    function reloadPageAfterToast() {
        window.location.reload();
    }

    // 집단 프로그램 예약 확정 전 상세 보기 모달
    const openOrganDetailModal = (index) => {
        setSelectedReservationIndex1(index);
        setIsOpen1(true);
    };

    const closeOrganDetailModal = () => {
        setSelectedReservationIndex1(null);
        setIsOpen1(false);
    };

    // 집단 프로그램 예약 확정 전 상세 보기 모달
    const openOrganDetailModal2 = (index) => {
        setSelectedReservationIndex2(index);
        setIsOpen2(true);
    };

    const closeOrganDetailModal2 = () => {
        setSelectedReservationIndex2(null);
        setIsOpen2(false);
    };

    // 집단 프로그램 상담 후 상세 보기 모달
    const openOrganDetailModal3 = (index) => {
        setSelectedReservationIndex3(index);
        setIsOpen3(true);
    };

    const closeOrganDetailModal3 = () => {
        setSelectedReservationIndex3(null);
        setIsOpen3(false);
    };

    // 집단 프로그램 취소 후 상세 보기 모달
    const openOrganDetailModal4 = (index) => {
        setSelectedReservationIndex4(index);
        setIsOpen4(true);
    };

    const closeOrganDetailModal4 = () => {
        setSelectedReservationIndex4(null);
        setIsOpen4(false);
    };

    const handleConfirmReservation = async (res_id) => {
        try {
            const response = await axios.get(`/api/OResConfirm?res_id=${res_id}`);
            // 여기에 요청 성공 시 수행할 동작 추가
            if (response.status === 200) { // HTTP 상태 코드가 200이면
                setSelectedReservationIndex1(null);
                setIsOpen1(false);
                window.location.reload();
            } else {
                toast.error('예약 확정에 실패했습니다.');
            }
        } catch (error) {
            // 요청이 실패하면 에러를 콘솔에 출력
            console.error('예약 확정 오류: ', error);
        }
    }

    const handleCancelReservation = async (res_id) => {
        try {
            const response = await axios.get(`/api/OResCancel?res_id=${res_id}`);
            // 여기에 요청 성공 시 수행할 동작 추가
            if (response.status === 200) { // HTTP 상태 코드가 200이면
                setSelectedReservationIndex1(null);
                setIsOpen1(false);
                setSelectedReservationIndex2(null);
                setIsOpen2(false);
                window.location.reload();
            } else {
                toast.error('예약 취소에 실패했습니다.');
            }
        } catch (error) {
            // 요청이 실패하면 에러를 콘솔에 출력
            console.error('예약 취소 오류: ', error);
        }
    }

    const handleDepositStat = async (res_id, state) => {
        try {
            const response = await axios.get(`/api/OresDeposit?res_id=${res_id}&state=${state}`);
            // 여기에 요청 성공 시 수행할 동작 추가
            if (response.status === 200) { // HTTP 상태 코드가 200이면
                setSelectedReservationIndex1(null);
                setIsOpen1(false);
                setSelectedReservationIndex2(null);
                setIsOpen2(false);
                window.location.reload();
            } else {
                toast.error('결제 상태 변경에 실패했습니다.');
            }
        } catch (error) {
            console.error('결제 상태 변경 오류: ', error.message);
            toast.error('요청 중 오류가 발생했습니다.');
        }
    }


    const customStyles = {
        // Modal 스타일
        overlay: {
            backgroundcolor: "rgba(0,0,0,0.5)",
        },
        content: {
            width: "90%", // 화면 너비의 90%로 조정
            maxWidth: "400px", // 최대 너비 설정
            height: "auto", // 높이 자동 조정
            margin: "auto",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            padding: "20px",
        },
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value); // 월 선택
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value); // 년도 선택
    };

    return (
        <PageContainer title="집단 프로그램" description="this is Typography">
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <DashboardCard title="집단 프로그램 예약 확정 전">
                        <ToastContainer onClose={reloadPageAfterToast} />

                        <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

                            <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                예약 ID


                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                선택 타입


                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                선택 강좌



                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 일시


                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 시간

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                인원 수

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                예약 상태

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                결제상태
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담상태
                                            </Typography>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resdata.oReservations && resdata.oReservations.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                                        .map((oReservations, organIndex) => (

                                            <TableRow key={organIndex}>

                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{oReservations.res_id}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.coun_type}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.coun_title}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(oReservations.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.selectedTime}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.totalclient}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.res_stat}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.deposit_stat}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.counselor_stat}</TableCell>
                                                <TableCell>
                                                    <Button variant="contained"
                                                        style={{
                                                            backgroundColor: "#e8e8e8",
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                            fontSize: '9px'
                                                        }}
                                                        onClick={() => openOrganDetailModal(organIndex)}>
                                                        상세보기 </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    {/*집단 프로그램 상세보기 Modal */}
                                    <Modal isOpen={isOpen1 && selectedReservationIndex1 !== null}
                                        onRequestClose={closeOrganDetailModal}
                                        style={{
                                            ...customStyles,
                                            overlay: {
                                                backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                                zIndex: 9999 // 오버레이의 z-index
                                            },
                                            content: {
                                                ...customStyles.content,
                                                zIndex: 10000 // 모달 내용의 z-index
                                            }
                                        }}>
                                        {selectedReservationIndex1 !== null && resdata.oReservations && (
                                            <div>
                                                <div className="modal-p-bold">
                                                    <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                                        {resdata.oReservations[selectedReservationIndex1].res_id}
                                                    </div>

                                                    <Button onClick={closeOrganDetailModal}
                                                        variant="contained"
                                                        size="small"
                                                        style={{
                                                            marginLeft: "280px", // 좌측 여백
                                                            backgroundColor: "#5D87FF", // 파란색 배경
                                                            color: "#fff", // 흰색 글자
                                                            fontWeight: "bold", // 굵게 설정
                                                            fontSize: "12px", // 글자 크기
                                                            padding: "3px 16px", // 내부 여백
                                                            borderRadius: "6px", // 약간 둥근 모서리
                                                            textTransform: "uppercase", // 대문자 변환
                                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                                            transition: "all 0.3s ease", // 변화 효과 추가
                                                            "&:hover": {
                                                                backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                                                transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                                            },
                                                        }}
                                                    >
                                                        닫기
                                                    </Button>


                                                </div>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 회원번호 : {resdata.oReservations[selectedReservationIndex1].user_id || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 성함 : {resdata.oReservations[selectedReservationIndex1].user_name || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 전화번호 : {resdata.oReservations[selectedReservationIndex1].user_phonenum || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 이메일 : {resdata.oReservations[selectedReservationIndex1].user_email || '상태 없음'}</Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >선택 타입 : {resdata.oReservations[selectedReservationIndex1].coun_type || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >선택 강좌 : {resdata.oReservations[selectedReservationIndex1].coun_title || '상담사 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 일시 : {new Date(resdata.oReservations[selectedReservationIndex1].selectedDate).toLocaleDateString('ko-KR')}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 시작 시간 : {resdata.oReservations[selectedReservationIndex1].selectedTime || '시간 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 총 시간 : {`${resdata.oReservations[selectedReservationIndex1].totalTime}시간` || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 참여 인원 수 :  {`${resdata.oReservations[selectedReservationIndex1].totalclient}명` || '상태 없음'} </Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약 상태 : {resdata.oReservations[selectedReservationIndex1].res_stat || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >결제 상태 : {resdata.oReservations[selectedReservationIndex1].deposit_stat || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 상태 : {resdata.oReservations[selectedReservationIndex1].counselor_stat || '상태 없음'} </Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <div className='Oresbuttondiv'>
                                                    <button className='Oresbutton'
                                                        onClick={() => handleConfirmReservation(resdata.oReservations[selectedReservationIndex1].res_id)}>
                                                        예약 확정
                                                    </button>
                                                    <button className='Oresbutton' onClick={() => handleCancelReservation(resdata.oReservations[selectedReservationIndex1].res_id)}>예약 취소</button>
                                                </div>
                                            </div >
                                        )}
                                    </Modal >





                                </TableBody>
                            </Table>
                        </Box>


                    </DashboardCard>
                </Grid>

                <Grid item sm={4}>
                    <BlankCard>
                        <CardContent>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                                            <Grid item>
                                                <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: 'left' }}>
                                                    조회 기간 설정
                                                </Typography>
                                                <Typography sx={{ fontSize: "10px", fontWeight: "500", textAlign: 'left' }}>
                                                    * 상담 일시 기준
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    labelId="year-dd"
                                                    id="year-dd"
                                                    value={selectedYear}
                                                    size="small"
                                                    onChange={handleYearChange}
                                                >
                                                    {years.map((year) => (
                                                        <MenuItem key={year} value={year}>
                                                            {year}년
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    labelId="month-dd"
                                                    id="month-dd"
                                                    value={selectedMonth}
                                                    size="small"
                                                    onChange={handleMonthChange}
                                                >
                                                    <MenuItem value={1}>1월</MenuItem>
                                                    <MenuItem value={2}>2월</MenuItem>
                                                    <MenuItem value={3}>3월</MenuItem>
                                                    <MenuItem value={4}>4월</MenuItem>
                                                    <MenuItem value={5}>5월</MenuItem>
                                                    <MenuItem value={6}>6월</MenuItem>
                                                    <MenuItem value={7}>7월</MenuItem>
                                                    <MenuItem value={8}>8월</MenuItem>
                                                    <MenuItem value={9}>9월</MenuItem>
                                                    <MenuItem value={10}>10월</MenuItem>
                                                    <MenuItem value={11}>11월</MenuItem>
                                                    <MenuItem value={12}>12월</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </CardContent>
                    </BlankCard>
                </Grid>

                <Grid item sm={12}>
                    <DashboardCard title="집단 프로그램 예약 확정 & 상담 전">
                        <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
                            <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                예약 ID


                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                선택 타입


                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                선택 강좌



                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 일시


                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 시간

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                인원 수

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                예약 상태

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                결제 상태
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 상태
                                            </Typography>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resdata.oReservations2 && resdata.oReservations2.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                                        .map((oReservations2, organIndex2) => (
                                            <TableRow key={organIndex2}>

                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{oReservations2.res_id}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations2.coun_type}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations2.coun_title}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(oReservations2.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations2.selectedTime}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations2.totalclient}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations2.res_stat}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations2.deposit_stat}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations2.counselor_stat}</TableCell>
                                                <TableCell>
                                                    <Button variant="contained"
                                                        style={{
                                                            backgroundColor: "#e8e8e8",
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                            fontSize: '9px'
                                                        }}
                                                        onClick={() => openOrganDetailModal2(organIndex2)}>
                                                        상세보기 </Button>
                                                </TableCell>


                                            </TableRow>
                                        ))}

                                    {/*집단 프로그램 상세보기 Modal */}
                                    <Modal isOpen={isOpen2 && selectedReservationIndex2 !== null}
                                        onRequestClose={closeOrganDetailModal2}
                                        style={{
                                            ...customStyles,
                                            overlay: {
                                                backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                                zIndex: 9999 // 오버레이의 z-index
                                            },
                                            content: {
                                                ...customStyles.content,
                                                zIndex: 10000 // 모달 내용의 z-index
                                            }
                                        }}>
                                        {selectedReservationIndex2 !== null && resdata.oReservations2 && (
                                            <div>
                                                <div className="modal-p-bold">
                                                    <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                                        {resdata.oReservations2[selectedReservationIndex2].res_id}
                                                    </div>

                                                    <Button onClick={closeOrganDetailModal2}
                                                        variant="contained"
                                                        size="small"

                                                        style={{
                                                            marginLeft: "280px", // 좌측 여백
                                                            backgroundColor: "#5D87FF", // 파란색 배경
                                                            color: "#fff", // 흰색 글자
                                                            fontWeight: "bold", // 굵게 설정
                                                            fontSize: "12px", // 글자 크기
                                                            padding: "3px 16px", // 내부 여백
                                                            borderRadius: "6px", // 약간 둥근 모서리
                                                            textTransform: "uppercase", // 대문자 변환
                                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                                            transition: "all 0.3s ease", // 변화 효과 추가
                                                            "&:hover": {
                                                                backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                                                transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                                            },
                                                        }}>
                                                        닫기
                                                    </Button>


                                                </div>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 회원번호 : {resdata.oReservations2[selectedReservationIndex2].user_id || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 성함 : {resdata.oReservations2[selectedReservationIndex2].user_name || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 전화번호 : {resdata.oReservations2[selectedReservationIndex2].user_phonenum || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 이메일 : {resdata.oReservations2[selectedReservationIndex2].user_email || '상태 없음'}</Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >선택 타입 : {resdata.oReservations2[selectedReservationIndex2].coun_type || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >선택 강좌 : {resdata.oReservations2[selectedReservationIndex2].coun_title || '상담사 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 일시 : {new Date(resdata.oReservations2[selectedReservationIndex2].selectedDate).toLocaleDateString('ko-KR')} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 시작 시간 : {resdata.oReservations2[selectedReservationIndex2].selectedTime || '시간 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 총 시간 : {`${resdata.oReservations2[selectedReservationIndex2].totalTime}시간` || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 참여 인원 수 :  {`${resdata.oReservations2[selectedReservationIndex2].totalclient}명` || '상태 없음'} </Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약 상태 : {resdata.oReservations2[selectedReservationIndex2].res_stat || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >결제 상태 : {resdata.oReservations2[selectedReservationIndex2].deposit_stat || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 상태 : {resdata.oReservations2[selectedReservationIndex2].counselor_stat || '상태 없음'} </Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <div className='Oresbuttondiv'>
                                                    {resdata.oReservations2[selectedReservationIndex2].deposit_stat === "결제 전" && (
                                                        <button className='Oresbutton' onClick={() => handleDepositStat(resdata.oReservations2[selectedReservationIndex2].res_id, "결제 완료")}>결제 확인</button>
                                                    )}
                                                    {resdata.oReservations2[selectedReservationIndex2].deposit_stat === "결제 완료" && (
                                                        <button className='Oresbutton' onClick={() => handleDepositStat(resdata.oReservations2[selectedReservationIndex2].res_id, "결제 전")}>결제 확인 취소</button>
                                                    )}
                                                    <button className='Oresbutton' onClick={() => handleCancelReservation(resdata.oReservations2[selectedReservationIndex2].res_id)}>예약 취소</button>
                                                </div>
                                            </div >
                                        )}
                                    </Modal >





                                </TableBody>
                            </Table>
                        </Box>

                    </DashboardCard>
                </Grid>

                <Grid item sm={12}>
                    <DashboardCard title="집단 프로그램 상담 완료">

                        <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

                            <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                예약 ID


                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                선택 타입


                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                선택 강좌



                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 유형

                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 일시

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 시간

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                인원 수
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                예약 상태

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                결제 상태

                                            </Typography>
                                        </TableCell>


                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 상태

                                            </Typography>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resdata.oCompleteRes && resdata.oCompleteRes.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                                        .map((oCompleteRes, organIndex3) => (
                                            <TableRow key={organIndex3}>

                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{oCompleteRes.res_id}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oCompleteRes.coun_type}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oCompleteRes.coun_title}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(oCompleteRes.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oCompleteRes.selectedTime}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oCompleteRes.totalclient}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oCompleteRes.res_stat}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oCompleteRes.deposit_stat}</TableCell>
                                                <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oCompleteRes.counselor_stat}</TableCell>
                                                <TableCell>
                                                    <Button variant="contained"
                                                        style={{
                                                            backgroundColor: "#e8e8e8",
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                            fontSize: '9px'
                                                        }}
                                                        onClick={() => openOrganDetailModal3(organIndex3)}>
                                                        상세보기 </Button>
                                                </TableCell>




                                            </TableRow>
                                        ))}

                                    {/*집단 프로그램 상세보기 Modal */}
                                    <Modal isOpen={isOpen3 && selectedReservationIndex3 !== null}
                                        onRequestClose={closeOrganDetailModal3}
                                        style={{
                                            ...customStyles,
                                            overlay: {
                                                backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                                zIndex: 9999 // 오버레이의 z-index
                                            },
                                            content: {
                                                ...customStyles.content,
                                                zIndex: 10000 // 모달 내용의 z-index
                                            }
                                        }}>
                                        {selectedReservationIndex3 !== null && resdata.oCompleteRes && (
                                            <div>
                                                <div className="modal-p-bold">
                                                    <div>
                                                        {resdata.oCompleteRes[selectedReservationIndex3].res_id}
                                                    </div>

                                                    <Button onClick={closeOrganDetailModal3}
                                                        variant="contained"
                                                        size="small"
                                                        style={{
                                                            marginLeft: "280px", // 좌측 여백
                                                            backgroundColor: "#5D87FF", // 파란색 배경
                                                            color: "#fff", // 흰색 글자
                                                            fontWeight: "bold", // 굵게 설정
                                                            fontSize: "12px", // 글자 크기
                                                            padding: "3px 16px", // 내부 여백
                                                            borderRadius: "6px", // 약간 둥근 모서리
                                                            textTransform: "uppercase", // 대문자 변환
                                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                                            transition: "all 0.3s ease", // 변화 효과 추가
                                                            "&:hover": {
                                                                backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                                                transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                                            },
                                                        }}>
                                                        닫기
                                                    </Button>


                                                </div>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 회원번호 : {resdata.oCompleteRes[selectedReservationIndex3].user_id || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 성함 : {resdata.oCompleteRes[selectedReservationIndex3].user_name || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 전화번호 : {resdata.oCompleteRes[selectedReservationIndex3].user_phonenum || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 이메일 : {resdata.oCompleteRes[selectedReservationIndex3].user_email || '상태 없음'}</Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >선택 타입 : {resdata.oCompleteRes[selectedReservationIndex3].coun_type || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >선택 강좌 : {resdata.oCompleteRes[selectedReservationIndex3].coun_title || '상담사 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 일시 : {new Date(resdata.oCompleteRes[selectedReservationIndex3].selectedDate).toLocaleDateString('ko-KR')} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 시작 시간 : {resdata.oCompleteRes[selectedReservationIndex3].selectedTime || '시간 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 총 시간 : {`${resdata.oCompleteRes[selectedReservationIndex3].totalTime}시간` || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 참여 인원 수 :  {`${resdata.oCompleteRes[selectedReservationIndex3].totalclient}명` || '상태 없음'} </Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약 상태 : {resdata.oCompleteRes[selectedReservationIndex3].res_stat || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >결제 상태 : {resdata.oCompleteRes[selectedReservationIndex3].deposit_stat || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 상태 : {resdata.oCompleteRes[selectedReservationIndex3].counselor_stat || '상태 없음'} </Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                            </div >
                                        )}
                                    </Modal >



                                </TableBody>
                            </Table>
                        </Box>
                    </DashboardCard>
                </Grid>

                <Grid item sm={12}>
                    <DashboardCard title="집단 프로그램 예약 취소">
                        <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
                            <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                예약 ID
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                선택 타입
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                선택 강좌
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 일시
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 시간

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                인원 수
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                예약 상태

                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                결제 상태

                                            </Typography>
                                        </TableCell>


                                        <TableCell>
                                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                                상담 상태

                                            </Typography>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resdata.cancelResults && resdata.cancelResults.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate)).map((cancelResults, organIndex4) => (
                                        <TableRow key={organIndex4}>

                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{cancelResults.res_id}</TableCell>
                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{cancelResults.coun_type}</TableCell>
                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{cancelResults.coun_title}</TableCell>
                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(cancelResults.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{cancelResults.selectedTime}</TableCell>
                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{cancelResults.totalclient}</TableCell>
                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{cancelResults.res_stat}</TableCell>
                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{cancelResults.deposit_stat}</TableCell>
                                            <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{cancelResults.counselor_stat}</TableCell>
                                            <TableCell>
                                                <Button variant="contained"
                                                    style={{
                                                        backgroundColor: "#e8e8e8",
                                                        color: 'black',
                                                        fontWeight: 'bold',
                                                        fontSize: '9px'
                                                    }}
                                                    onClick={() => openOrganDetailModal4(organIndex4)}>
                                                    상세보기 </Button>
                                            </TableCell>




                                        </TableRow>
                                    ))}

                                    {/*집단 프로그램 상세보기 Modal */}
                                    <Modal isOpen={isOpen4 && selectedReservationIndex4 !== null}
                                        onRequestClose={closeOrganDetailModal4}
                                        style={{
                                            ...customStyles,
                                            overlay: {
                                                backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                                zIndex: 9999 // 오버레이의 z-index
                                            },
                                            content: {
                                                ...customStyles.content,
                                                zIndex: 10000 // 모달 내용의 z-index
                                            }
                                        }}>
                                        {selectedReservationIndex4 !== null && resdata.cancelResults && (
                                            <div>
                                                <div className="modal-p-bold">
                                                    <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                                        {resdata.cancelResults[selectedReservationIndex4].res_id}
                                                    </div>
                                                    <Button onClick={closeOrganDetailModal4}
                                                        variant="contained"
                                                        size="small"
                                                        style={{
                                                            marginLeft: "280px", // 좌측 여백
                                                            backgroundColor: "#5D87FF", // 파란색 배경
                                                            color: "#fff", // 흰색 글자
                                                            fontWeight: "bold", // 굵게 설정
                                                            fontSize: "12px", // 글자 크기
                                                            padding: "3px 16px", // 내부 여백
                                                            borderRadius: "6px", // 약간 둥근 모서리
                                                            textTransform: "uppercase", // 대문자 변환
                                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                                            transition: "all 0.3s ease", // 변화 효과 추가
                                                            "&:hover": {
                                                                backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                                                transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                                            },
                                                        }}>
                                                        닫기
                                                    </Button>


                                                </div>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 회원번호 : {resdata.cancelResults[selectedReservationIndex4].user_id || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 성함 : {resdata.cancelResults[selectedReservationIndex4].user_name || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 전화번호 : {resdata.cancelResults[selectedReservationIndex4].user_phonenum || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약자 이메일 : {resdata.cancelResults[selectedReservationIndex4].user_email || '상태 없음'}</Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >선택 타입 : {resdata.cancelResults[selectedReservationIndex4].coun_type || '상태 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >선택 강좌 : {resdata.cancelResults[selectedReservationIndex4].coun_title || '상담사 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 일시 : {new Date(resdata.cancelResults[selectedReservationIndex4].selectedDate).toLocaleDateString('ko-KR')} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 시작 시간 : {resdata.cancelResults[selectedReservationIndex4].selectedTime || '시간 없음'}</Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 총 시간 : {`${resdata.cancelResults[selectedReservationIndex4].totalTime}시간` || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 참여 인원 수 :  {`${resdata.cancelResults[selectedReservationIndex4].totalclient}명` || '상태 없음'} </Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >예약 상태 : {resdata.cancelResults[selectedReservationIndex4].res_stat || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >결제 상태 : {resdata.cancelResults[selectedReservationIndex4].deposit_stat || '상태 없음'} </Typography>
                                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "13px", marginLeft: '20px', marginBottom: '5px' }} >상담 상태 : {resdata.cancelResults[selectedReservationIndex4].counselor_stat || '상태 없음'} </Typography>
                                                <hr style={{
                                                    backgroundColor: "#5D87FF", // 파란색으로 변경
                                                }} />
                                            </div >
                                        )}
                                    </Modal >



                                </TableBody>
                            </Table>
                        </Box>
                    </DashboardCard>
                </Grid>
            </Grid >
        </PageContainer>
    );
};

function MSpan({ space = 510 }) {
    return (
        <span style={{ paddingRight: space }}></span>
    );
}
export default O_Program_Reservation;
