import React from 'react';
import { Typography } from '@mui/material';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import Button from '@mui/material/Button';


const Cli_meta = () => {

  const handleDownload = () => {
    const url = 'https://resang-naman-s3.s3.ap-northeast-2.amazonaws.com/exe/Resang_MeetingRoom.exe';
    window.location.href = url;  // 현재 창에서 파일을 다운로드 받음
  };


  return (
    <PageContainer title="메타버스">

      <DashboardCard title="메타버스 다운로드">
        <Typography
          sx={{
            fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",

          }}>

          <Button
            onClick={handleDownload}
            variant="contained"
            size="small"
            sx={{
              fontSize: '20px',
              width: '300px',
              display: 'block',
              margin: 'auto',
              marginTop: '120px',
              marginBottom: '50px',
              fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
              borderRadius: '10px'
            }}
          >
            다운로드
          </Button>
          <br />

          * 다운로드 시 설치 경로를 c 드라이브로 지정해 주세요. 위치를 옮길 경우 실행이 정상적으로 되지 않습니다 *


          <br /><br /><br /><br /><br />
        </Typography>
      </DashboardCard>


    </PageContainer>
  );
};

export default Cli_meta;