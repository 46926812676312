
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardCard from '../../shared/DashboardCard';
import { Typography, Grid, CardContent, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Paper from '@mui/material/Paper';

const Oresconfirm = () => {
    const [resdata, setResdata] = useState({
        oReservations: [],
    }); // 예약 정보를 저장할 상태

    async function fetchReservations() {
        try {
            const response = await axios.get(`/api/admin_ResData2`);
            const oReservations = response.data.oReservations;

            setResdata({
                oReservations,
            }); // 예약 정보를 상태에 저장
        } catch (error) {
            console.error('예약 정보 가져오기 오류:', error);
        }
    }

    useEffect(() => {
        fetchReservations();
    }, []);


    return (
        <DashboardCard title="집단 프로그램 예약 확정 전">
            <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
                <Table
                    aria-label="simple table"
                    sx={{
                        whiteSpace: "nowrap",
                        mt: 2
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    예약 ID
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    선택 타입
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    선택 강좌
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    상담 일시
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    상담 시간
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    인원 수
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    예약 상태
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    결제 상태
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                    상담 상태
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {resdata.oReservations && resdata.oReservations.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                            .map((oReservations, organIndex) => (
                                <TableRow key={organIndex}>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{oReservations.res_id}</TableCell>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.coun_type}</TableCell>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.coun_title}</TableCell>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{new Date(oReservations.selectedDate).toLocaleDateString('ko-KR')}</TableCell>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.selectedTime}</TableCell>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.totalclient}</TableCell>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.res_stat}</TableCell>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.deposit_stat}</TableCell>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{oReservations.counselor_stat}</TableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Box>
        </DashboardCard>

    );
};

function MSpan({ space = 510 }) {
    return (
        <span style={{ paddingRight: space }}></span>
    );
}
export default Oresconfirm;
