
const agreereservation = 
` \n < ‘나만’ 상담서비스에 대한 이해와 협조 동의 >

“ ‘나만’ 상담 서비스는 고객님께서 원하시는 삶을 살아갈 수 있도록, 자신의 변화, 성장을 위한 통찰을 하고, 자기 주도적으로 해결책을 찾으며 실천해 나가는 과정의 상담 서비스를 진행합니다.
\n 상담전문가는 고객님의 파트너로써, 상담하는 동안 고객님께 온전히 집중할 것이며, 적극적으로 경청할 것입니다. 또한 새로운 통찰과 패러다임 변화를 불러 일으키기 위한 상담할 것을 약속드립니다. ” 

정신과 의사나 심리치료사가 행하는 약물 복용 등이 필요한 정신적 문제나 치료의 목적이 아님을 이해하고 동의합니다.

상담전문가에게서 조언이나 해답을 기대하기 보다 나 스스로 해답을 찾을 수 있도록, 상담에 몰입할 것을 약속합니다.
 \n 원하는 결과를 얻을 수 있도록, 있는 그대로의 나를 드러내고 상담 전문가의 질문에 신중하게 답하며 열린 마음으로 상담에 임할 것을 약속합니다.
\n 상담전문가와 나는 상호 합의한 상담 목표와 주제에 맞춰 상담을 원활히 진행하는 데에 책임이 있으며, 쌍방 중 어느 한쪽이라도 협의를 통해 상담 서비스를 중단할 수 있다는 것에 동의합니다.

모든 상담 내용은 비밀이 보장되며, 개인정보가 보호됩니다. 
\n 그러나 고객님의 생명이나 다른 사람들의 생명이 걱정되는 상황과 관련될 경우, 안전을 위해 외부에 도움을 요청할 수 있습니다. \n
이때 상황에 따라 관련 내용에 대한 비밀 보장이 되지 않을 수 있으며, 개인정보를 요청할 수 있습니다. 이 점 양해 부탁드립니다.

위 내용을 충분히 이해하셨고 이에 동의하신다면, '동의'에 체크 해 주세요.
                
`;


export default agreereservation;