import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, CardContent, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal"
import BlankCard from '../shared/BlankCard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Select, MenuItem } from '@mui/material';


const Con_request = () => {
  const navigate = useNavigate();
  const [completedReservations, setCompletedReservations] = useState([]);
  const [selectedReservationIndex, setSelectedReservationIndex] = useState(null); // 상세보기 Modal
  const [selectedReservationIndex2, setSelectedReservationIndex2] = useState(null); // 상세보기 Modal
  const [cancelmodalIndex, setCancelModalIndex] = useState(null); // 취소 Modal의 상태

  const [isOpen, setIsOpen] = useState(false); // 팝업
  const [isOpen2, setIsOpen2] = useState(false); // 팝업2
  const [isOpen3, setIsOpen3] = useState(false); // 팝업3

  const [C_resdata, setCResdata] = useState({ personalReservations: [], groupReservations: [], cancelpersonalReservations: [], cancelgroupReservations: [] }); // 예약 정보를 저장할 상태
  const allReservations = [...C_resdata.personalReservations, ...C_resdata.groupReservations];
  const allcancelReservations = [...C_resdata.cancelpersonalReservations, ...C_resdata.cancelgroupReservations];

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const years = Array.from({ length: 11 }, (v, i) => new Date().getFullYear() - i);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value); // 월 선택
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // 년도 선택
  };

  const fetchReservations = async () => {
    try {
      const queryParams = new URLSearchParams({
        selectedMonth,
        selectedYear,
      }).toString();
      const response = await axios.get(`/api/cResData?${queryParams}`);
      setCResdata(response.data);
    } catch (error) {
      console.error('예약 데이터 가져오기 오류:', error);
    }
  };

  useEffect(() => {
    fetchReservations(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);


  //결제 완료 상세보기 Modal
  const openModal = (index) => {
    setSelectedReservationIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedReservationIndex(null);
    setIsOpen(false);
  };

  //취소 예약 상세보기 Modal
  const openModal2 = (index) => {
    setSelectedReservationIndex2(index);
    setIsOpen3(true);
  };

  const closeModal2 = () => {
    setSelectedReservationIndex2(null);
    setIsOpen3(false);
  };

  //취소 Moal
  const openCancelModal = (index) => {
    setCancelModalIndex(index);
    setIsOpen2(true);
  };

  const closeCancelModal = () => {
    setCancelModalIndex(null);
    setIsOpen2(false);
  };

  const cancelReservation = async (index) => {
    try {
      if (allReservations[index]) {
        // 인덱스에 해당하는 예약 정보가 있을 때
        const reservationId = allReservations[index].res_id;
        const counselorType = allReservations[index].coun_type;

        // 서버로 예약 취소 요청 및 예약 유형 전송
        const cancelReservationRequest = async (reservationId) => {
          try {
            const cancelResponse = await axios({
              url: "/api/Rescancel",
              method: "post",
              headers: { "Content-Type": "application/json" },
              data: {
                coun_type: counselorType,
                res_id: reservationId, // 주문번호
                paymentKey: C_resdata.paymentKey,
                reason: "전문가 취소 요청", // 환불사유
                deposit_type: C_resdata.deposit_type,
                deposit_stat: C_resdata.deposit_stat,
                refundAmount: C_resdata.totalamount
              }
            });

            const responseData = cancelResponse.data;
            if (responseData.status === "success") {
              await axios.put(`/api/cancelReservation/${reservationId}?coun_type=${counselorType}`);

              const updatedResData = { ...C_resdata };
              updatedResData.counselor_stat = '예약 취소';
              setCResdata(updatedResData);
              navigate('/Mypage/counselor/con_request');
            } else {
              alert(`예약 취소에 실패하였습니다. (에러 메시지: ${responseData.message})`);
            }
          } catch (error) {
            console.error('예약 취소 오류:', error);
            alert(`예약 취소 요청에 실패하였습니다. (에러 메시지: ${error.message})`);
          }
        };

        closeCancelModal();
        await cancelReservationRequest(reservationId);

        // 예약 정보 업데이트
        allReservations[index].counselor_stat = "취소";
        toast.success("예약 취소 처리되었습니다.", { autoClose: 1500 });
      } else {
        console.error("예약 정보가 없습니다.");
      }
    } catch (error) {
      console.error("예약 취소 오류:", error);
    }
  };

  const customStyles = {
    overlay: {
      backgroundcolor: "rgba(0,0,0,0.5)",
    },
    content: {
      width: "90%", // 화면 너비의 90%로 조정
      maxWidth: "400px", // 최대 너비 설정
      height: "auto", // 높이 자동 조정
      margin: "auto",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      padding: "20px",
    },
  };

  const customStyles2 = {
    overlay: {
      backgroundcolor: "rgba(0,0,0,0.5)",
    },
    content: {
      width: "90%", // 화면 너비의 90%로 조정
      height: "45%",
      maxWidth: "400px", // 최대 너비 설정
      maxHeight: "450px",
      margin: "auto",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      padding: "20px",
    },
  };

  const cancel_customStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      height: '180px',
      maxWidth: '800px',
      maxHeight: '300px',
      overflow: 'hidden', // 스크롤 바를 숨김
      textAlign: 'center'
    },
  };

  // toast 메시지가 사라진 후에 페이지를 새로 고침하는 함수
  function reloadPageAfterToast() {
    window.location.reload();
  }

  return (
    <PageContainer title="결제 완료 상담 관리">
      <DashboardCard title="결제 완료 상담 관리"
        action={
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: window.innerWidth <= 560 ? 'column' : 'row',
            gap: '30px'
          }}>
            <Grid item sm={4}>
              <Paper elevation={10}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: 'left' }}>
                        조회 기간 설정
                      </Typography>
                      <Typography sx={{ fontSize: "10px", fontWeight: "500", textAlign: 'left' }}>
                        * 상담 일시 기준
                      </Typography>
                      <br />
                      <Grid container spacing={1}>
                        <Grid item>
                          <Select
                            labelId="year-dd"
                            id="year-dd"
                            value={selectedYear}
                            size="small"
                            onChange={handleYearChange}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}년
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item>
                          <Select
                            labelId="month-dd"
                            id="month-dd"
                            value={selectedMonth}
                            size="small"
                            onChange={handleMonthChange}
                          >
                            <MenuItem value={1}>1월</MenuItem>
                            <MenuItem value={2}>2월</MenuItem>
                            <MenuItem value={3}>3월</MenuItem>
                            <MenuItem value={4}>4월</MenuItem>
                            <MenuItem value={5}>5월</MenuItem>
                            <MenuItem value={6}>6월</MenuItem>
                            <MenuItem value={7}>7월</MenuItem>
                            <MenuItem value={8}>8월</MenuItem>
                            <MenuItem value={9}>9월</MenuItem>
                            <MenuItem value={10}>10월</MenuItem>
                            <MenuItem value={11}>11월</MenuItem>
                            <MenuItem value={12}>12월</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Paper>
            </Grid>
          </div>
        }>
        <ToastContainer onClose={reloadPageAfterToast} />
        <Grid container spacing={3}>
          {allReservations.sort( // 상담일시에 따라 오름차순으로 정렬
            (a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
            .map((reservation, index) => (
              <Grid item xs={12} sm={6} lg={4} key={reservation.res_id}>
                <Paper elevation={5}> {/* 그림자를 추가합니다. */}
                  <DashboardCard>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                              예약 ID : {reservation.res_id}
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "4700" }}>
                              상담 일시 : {new Date(reservation.selectedDate).toLocaleDateString('ko-KR')}
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                              상담 시간 : {reservation.selectedTime} ({reservation.totalTime}시간)
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                              상담유형 : {reservation.coun_type}
                            </Typography>
                            <br />
                            <Button variant="contained"
                              style={{
                                backgroundColor: "#5D87FF",
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '10px',
                                marginLeft: '5px'
                              }}
                              onClick={() => openModal(index)}
                            >
                              상세보기
                            </Button> &nbsp;


                            <Button variant="contained"
                              style={{
                                backgroundColor: "#e8e8e8",
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: '10px',
                                marginLeft: '5px'
                              }}
                              onClick={() => openCancelModal(index)}
                            >
                              예약 취소
                            </Button> &nbsp;

                            {/*상세보기 Modal */}
                            <Modal isOpen={isOpen && selectedReservationIndex === index}
                              onRequestClose={closeModal}
                              style={{
                                ...customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...customStyles.content,
                                  zIndex: 10000 // 모달 내용의 z-index
                                }
                              }}>

                              <div>
                                <div className="modal-p-bold">
                                  <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                    {reservation.res_id}
                                  </div>

                                  <Button onClick={closeModal}
                                    variant="contained"
                                    size="small"
                                    style={{
                                      marginLeft: "280px", // 좌측 여백
                                      backgroundColor: "#5D87FF", // 파란색 배경
                                      color: "#fff", // 흰색 글자
                                      fontWeight: "bold", // 굵게 설정
                                      fontSize: "12px", // 글자 크기
                                      padding: "3px 16px", // 내부 여백
                                      borderRadius: "6px", // 약간 둥근 모서리
                                      textTransform: "uppercase", // 대문자 변환
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                      transition: "all 0.3s ease", // 변화 효과 추가
                                      "&:hover": {
                                        backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                        transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                      },
                                    }}>
                                    닫기
                                  </Button>
                                </div>

                                <hr style={{
                                  backgroundColor: "#5D87FF", // 파란색으로 변경
                                }} />

                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 유형 : {reservation.coun_type} </Typography>
                                {reservation.coun_type == "개인 상담" && (
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 분야  : {reservation.reservationType} 상담 </Typography>
                                )}

                                {reservation.coun_type == "그룹 상담" && (
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >인원 수  : {reservation.group_number}명 </Typography>
                                )}
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >내담자 이름 : {reservation.user_name} </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >내담자 ID : {reservation.user_id}</Typography>

                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담일시 : {new Date(reservation.selectedDate).toLocaleDateString('ko-KR')} </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 시간: {reservation.selectedTime} {"("}
                                  {reservation.totalTime}시간{")"}{" "} </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '20px' }} >결제 상태 :  {reservation.deposit_stat}  </Typography>
                                <Typography fontWeight="bold" color="textSecondary" sx={{ fontSize: "13.5px", marginLeft: '20px' }} >상담 요청사항</Typography>
                                <br />
                                <Grid item sm={12}>
                                  <BlankCard>
                                    <CardContent>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                        - 상담시 불러주었으면 하는 호칭이 있습니까?
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                        <pre>{reservation.request_1}</pre>
                                      </Typography>
                                    </CardContent>
                                  </BlankCard>
                                </Grid>
                                <br />

                                <Grid item sm={12}>
                                  <BlankCard>
                                    <CardContent>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                        - 현재 하고 있는 일에 대해 소개해 주시겠어요?
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                        <pre>{reservation.request_2}</pre>
                                      </Typography>
                                    </CardContent>
                                  </BlankCard>
                                </Grid>
                                <br />

                                <Grid item sm={12}>
                                  <BlankCard>
                                    <CardContent>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                        - 현재 나의 상태를 점수로 표현한다면? (1~3 : 부정, 4~6 : 긍정)
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                        <pre>{reservation.request_3}</pre>

                                      </Typography>
                                    </CardContent>
                                  </BlankCard>
                                </Grid>
                                <br />

                                <Grid item sm={12}>
                                  <BlankCard>
                                    <CardContent>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                        - 어떤 주제로 상담을 원하시는지 구체적으로 알려주시겠어요?
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                        <pre>{reservation.request_4}</pre>
                                      </Typography>
                                    </CardContent>
                                  </BlankCard>
                                </Grid>
                                <br />

                                <Grid item sm={12}>
                                  <BlankCard>
                                    <CardContent>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                        - 상담을 통해 얻고싶거나 기대하는 사항은?
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                        <pre>{reservation.request_5}</pre>
                                      </Typography>
                                    </CardContent>
                                  </BlankCard>
                                </Grid>
                                <br />

                                <Grid item sm={12}>
                                  <BlankCard>
                                    <CardContent>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                        - 상담 전문가에게 추가로 전달하고 싶은 사항은?
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                        <pre>{reservation.request_6}</pre>
                                      </Typography>
                                    </CardContent>
                                  </BlankCard>
                                </Grid>

                                <br />

                                <Grid item sm={12}>
                                  <BlankCard>
                                    <CardContent>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                        - 상담을 신청하게 된 동기?
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                        <pre>{reservation.request_7}</pre>
                                      </Typography>
                                    </CardContent>
                                  </BlankCard>
                                </Grid>

                                <br />

                                <Grid item sm={12}>
                                  <BlankCard>
                                    <CardContent>
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px" }} >
                                        - 심리상담이나 이와 비슷한 경험이 있다면 알려주시겠어요?
                                      </Typography><br />
                                      <Typography variant="body1" color="textSecondary" sx={{ fontSize: "10px" }}>
                                        <pre>{reservation.request_8}</pre>
                                      </Typography>
                                    </CardContent>
                                  </BlankCard>
                                </Grid>
                              </div>
                            </Modal>
                            <Modal
                              isOpen={isOpen2 && cancelmodalIndex === index}
                              onRequestClose={closeCancelModal}
                              style={{
                                ...cancel_customStyles,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...cancel_customStyles.content,
                                  zIndex: 10000, // 모달 내용의 z-index
                                }
                              }}
                            >
                              <div>
                                <h5>예약 번호 : {reservation.res_id}</h5><br />
                                <div>예약을 취소하시겠습니까?</div>
                                <div className='Counselor_p1'>* 예약 취소 시, 내담자의 상담 요청사항은 확인할 수 없습니다.</div>
                                <br />
                                <br />
                                <Button
                                  onClick={() => cancelReservation(index)}
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    fontSize: '10px',
                                  }}>
                                  네
                                </Button>&nbsp;
                                <Button
                                  onClick={closeCancelModal}
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    fontSize: '10px',
                                  }}>
                                  아니요
                                </Button>&nbsp;
                              </div>
                            </Modal>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </DashboardCard>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </DashboardCard>

      <br /> <br />

      <DashboardCard title="취소된 상담 관리"
        action={
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: window.innerWidth <= 560 ? 'column' : 'row',
            gap: '30px'
          }}>
            <Grid item sm={4}>
              <Paper elevation={10}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: 'left' }}>
                        조회 기간 설정
                      </Typography>
                      <Typography sx={{ fontSize: "10px", fontWeight: "500", textAlign: 'left' }}>
                        * 상담 일시 기준
                      </Typography>
                      <br />
                      <Grid container spacing={1}>
                        <Grid item>
                          <Select
                            labelId="year-dd"
                            id="year-dd"
                            value={selectedYear}
                            size="small"
                            onChange={handleYearChange}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}년
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item>
                          <Select
                            labelId="month-dd"
                            id="month-dd"
                            value={selectedMonth}
                            size="small"
                            onChange={handleMonthChange}
                          >
                            <MenuItem value={1}>1월</MenuItem>
                            <MenuItem value={2}>2월</MenuItem>
                            <MenuItem value={3}>3월</MenuItem>
                            <MenuItem value={4}>4월</MenuItem>
                            <MenuItem value={5}>5월</MenuItem>
                            <MenuItem value={6}>6월</MenuItem>
                            <MenuItem value={7}>7월</MenuItem>
                            <MenuItem value={8}>8월</MenuItem>
                            <MenuItem value={9}>9월</MenuItem>
                            <MenuItem value={10}>10월</MenuItem>
                            <MenuItem value={11}>11월</MenuItem>
                            <MenuItem value={12}>12월</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Paper>
            </Grid>
          </div>
        }>
        <ToastContainer onClose={reloadPageAfterToast} />
        <Grid container spacing={3}>
          {allcancelReservations.sort( // 상담일시에 따라 오름차순으로 정렬
            (a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
            .map((reservation, index) => (
              <Grid item xs={12} sm={6} lg={4} key={reservation.res_id}>
                <Paper elevation={5}> {/* 그림자를 추가합니다. */}
                  <DashboardCard>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                              예약 ID : {reservation.res_id}
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "4700" }}>
                              상담 일시 : {new Date(reservation.selectedDate).toLocaleDateString('ko-KR')}
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                              상담 시간 : {reservation.selectedTime} ({reservation.totalTime}시간)
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400" }}>
                              상담유형 : {reservation.coun_type}
                            </Typography>
                            <br />
                            <Button variant="contained"
                              style={{
                                backgroundColor: "#5D87FF",
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '10px',
                                marginLeft: '5px'
                              }}
                              onClick={() => openModal2(index)}
                            >
                              상세보기
                            </Button> &nbsp;

                            {/*상세보기 Modal */}
                            <Modal isOpen={isOpen3 && selectedReservationIndex2 === index}
                              onRequestClose={closeModal2}
                              style={{
                                ...customStyles2,
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                                  zIndex: 9999 // 오버레이의 z-index
                                },
                                content: {
                                  ...customStyles2.content,
                                  zIndex: 10000 // 모달 내용의 z-index
                                }
                              }}>

                              <div>
                                <div className="modal-p-bold">
                                  <div style={{ position: 'relative', marginLeft: '20px', top: '20px' }}>
                                    {reservation.res_id}
                                  </div>

                                  <Button onClick={closeModal2}
                                    variant="contained"
                                    size="small"
                                    style={{
                                      marginLeft: "280px", // 좌측 여백
                                      backgroundColor: "#5D87FF", // 파란색 배경
                                      color: "#fff", // 흰색 글자
                                      fontWeight: "bold", // 굵게 설정
                                      fontSize: "12px", // 글자 크기
                                      padding: "3px 16px", // 내부 여백
                                      borderRadius: "6px", // 약간 둥근 모서리
                                      textTransform: "uppercase", // 대문자 변환
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // 그림자 효과
                                      transition: "all 0.3s ease", // 변화 효과 추가
                                      "&:hover": {
                                        backgroundColor: "#1E88E5", // 마우스 오버 시 약간 어두운 색상
                                        transform: "translateY(-2px)", // 약간의 Y축 이동 효과
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // 마우스 오버 시 그림자 증가
                                      },
                                    }}>
                                    닫기
                                  </Button>
                                </div>
                                <hr style={{
                                  backgroundColor: "#5D87FF", // 파란색으로 변경

                                }} />
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 유형 : {reservation.coun_type} </Typography>
                                {reservation.coun_type === "개인 상담" && (
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 분야  : {reservation.reservationType} 상담 </Typography>
                                )}
                                {reservation.coun_type === "그룹 상담" && (
                                  <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >인원 수  : {reservation.group_number}명 </Typography>
                                )}
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >내담자 이름 : {reservation.user_name} </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >내담자 ID : {reservation.user_id}</Typography>

                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담일시 : {new Date(reservation.selectedDate).toLocaleDateString('ko-KR')} </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >상담 시간: {reservation.selectedTime} {"("}{reservation.totalTime}시간{")"}{" "} </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >결제 상태 :  {reservation.deposit_stat} </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ fontSize: "12px", marginLeft: '20px', marginBottom: '8px' }} >취소 사유 :  {reservation.cancelreason} </Typography>
                              </div>
                            </Modal>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </DashboardCard>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
};

function MSpan({ space = 500 }) {
  return <span style={{ paddingRight: space }}></span>;
}

export default Con_request;