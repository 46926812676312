import React from 'react';
import PostList from './PostList';

const PostMain = props => {
  return (
    <>
      <PostList />
     
      
    </>
  )
}

export default PostMain;