import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

export const Testimonials = (props) => {
  const [cdetail, setcDeatildata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cdetailResponse = await axios.get('/api/counselorall', {});
        const cdetailData = cdetailResponse.data.alldetaildata;
        setcDeatildata(cdetailData);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>전문가들</h2>
        </div>
        <div className="row" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
          {cdetail.map((combinedCounselor) => (
            <div key={combinedCounselor.id} className="col-md-4" style={{display: 'flex', flexDirection:'column'}}>
              <Link to={`/Counselor/${combinedCounselor.id}`} style={{ color: 'black', textDecoration: 'none' }}>
                <div className="testimonial" style={{flex: 1}}>
                  <div className="testimonial-image">
                    <img
                      src={`${combinedCounselor.c_image}?${Date.now()}`}
                      alt=""
                    />
                  </div>
                  <div className="testimonial-content">
                    <p style={{ lineHeight: '1.5em', marginBottom: '1em', fontStyle: 'normal'}}>
                      <React.Fragment>
                        {combinedCounselor.c_description}
                        <br />
                      </React.Fragment>
                    </p>
                    <div className="testimonial-meta"> - {combinedCounselor.c_name} </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
