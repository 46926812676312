import React, { useEffect, useState, useRef } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, Paper, CardContent, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { Link } from 'react-router-dom';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import BlankCard from '../shared/BlankCard';
import { Rating, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import { Select, MenuItem } from '@mui/material';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

let chartData;


// Chart.js module
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};
const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
];

if (chartData !== undefined) {
  //alert('000');
} else {
  //alert('111');
}

  const label_title = [];
  const exArrAnger = [], exArrFear = [], exArrSadness = [], exArrPeace = [], exArrAmazement = [], exArrHappiness = [];
  const Consultation_info = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { no } = useParams();

  //exArrAnger = [];
  // exArrFear = [];
  // exArrSadness = [];
  // exArrPeace = [];
  // exArrAmazement = [];
  // exArrHappiness = [];

  const labels = label_title;
  //chartData.distroy();
  chartData = {
    type: 'bar',
    labels: labels,
    datasets: [
      {
        label: '분노',
        data: exArrAnger,
        backgroundColor: CHART_COLORS.red,
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: '두려움',
        data: exArrFear,
        backgroundColor: CHART_COLORS.blue,
      },
      {
        label: '슬픔',
        data: exArrSadness,
        backgroundColor: CHART_COLORS.yellow,
        borderWidth: 1,
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: '평안',
        data: exArrPeace,
        backgroundColor: CHART_COLORS.green,
      },
      {
        label: '놀람',
        data: exArrAmazement,
        backgroundColor: CHART_COLORS.purple,
      },
      {
        label: '행복',
        data: exArrHappiness,
        backgroundColor: CHART_COLORS.orange,
      }
    ],
  };

  const options = {
    plugins: {
      legend: { // 범례 스타일링      
        labels: {
          //범례 도형 모양과 관련된 속성으로, false일 경우엔 기본 직사각형
          usePointStyle: false,
          // 범례 간 가로 간격
          padding: 10,
          //범례의 폰트 스타일
          font: {
            family: "'Noto Sans KR', 'serif'",
            lineHeight: 1,
          },
        }
      },
      title: {
        display: true,
        text: '내담자 감정 분석 ',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  //--------------------------



  const [report, setReport] = useState({
    counselorName: '',
    counseleeName: '',
    consultationDate: '',
    consultationStartTime: '',
    consultationTime: '',
    nextConsultationDate: '', 
    checkPoint: '', 
    content: '', 
    skill: '',
    result: '', 
    note: '',
  });

  const { // 예약 데이터 전달받기
    consultationID
  } = useLocation().state;

  const chartRef = useRef(null);

  useEffect(() => {

    if (chartRef.current) {
      chartRef.current.destroy();
    }
    const fetchData = async () => {
      try {
        //상담 보고서 가져오기
        const reportResponse = await axios.get(`/api/ConsultationReportDetail?consultationID=${consultationID}`, {
          headers: {
            'Cache-Control': 'no-store'
          }
        });

        const reportData = reportResponse.data.completeddata[0];
        setReport(reportData);
        //감정 분석 가져오기
        const expressionResponse = await axios.get(`/api/ConsultationExpression?consultationID=${consultationID}`, {
          headers: {
            'Cache-Control': 'no-store'
          }
        });
        const expressionData = expressionResponse.data.resultData;
        if (expressionData.length > 0) {
          var i;
          for (i = 0; i < expressionData.length; ++i) {
            label_title.push(expressionData[i].recordDate);
            if (expressionData[i].expressionType == 'Anger')  {
              exArrAnger.push(expressionData[i].expressionValue);
              exArrFear.push(0);
              exArrSadness.push(0);
              exArrPeace.push(0);
              exArrAmazement.push(0);
              exArrHappiness.push(0);
            } else if (expressionData[i].expressionType == 'Fear') {
              exArrAnger.push(0);
              exArrFear.push(expressionData[i].expressionValue);
              exArrSadness.push(0);
              exArrPeace.push(0);
              exArrAmazement.push(0);
              exArrHappiness.push(0);
            } else if (expressionData[i].expressionType == 'Sadness') {
              exArrAnger.push(0);
              exArrFear.push(0);
              exArrSadness.push(expressionData[i].expressionValue);
              exArrPeace.push(0);
              exArrAmazement.push(0);
              exArrHappiness.push(0);
            } else if (expressionData[i].expressionType == 'Peace') {
              exArrAnger.push(0);
              exArrFear.push(0);
              exArrSadness.push(0);
              exArrPeace.push(expressionData[i].expressionValue);
              exArrAmazement.push(0);
              exArrHappiness.push(0);
            } else if (expressionData[i].expressionType == 'Amazement') {
              exArrAnger.push(0);
              exArrFear.push(0);
              exArrSadness.push(0);
              exArrPeace.push(0);
              exArrAmazement.push(expressionData[i].expressionValue);
              exArrHappiness.push(0);
            } else if (expressionData[i].expressionType == 'Happiness') {
              exArrAnger.push(0);
              exArrFear.push(0);
              exArrSadness.push(0);
              exArrPeace.push(0);
              exArrAmazement.push(0);
              exArrHappiness.push(expressionData[i].expressionValue);
            }
          }
        } else {
          // exArrAnger.destroy();
          // exArrFear.destroy();
          // exArrSadness.destroy();
          // exArrPeace.destroy();
          // exArrAmazement.destroy();
          // exArrHappiness.destroy();
          exArrAnger.removeAll();
          exArrFear.removeAll();
          exArrSadness.removeAll();
          exArrPeace.removeAll();
          exArrAmazement.removeAll();
          exArrHappiness.removeAll();
        }
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };
    fetchData();
  }, [consultationID]);

  return (
    <PageContainer title="상담 보고서">
      <DashboardCard title="상담 보고서">
        <Grid item sm={4} sx={{ textAlign: 'left', marginTop: '10px' }}>
          <Paper elevation={10}>
            <BlankCard>
              <CardContent>
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>상담 ID :</span> {consultationID}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>고객명 :</span> {report.client_name} <span style={{ fontWeight: 700 }}>님</span>
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>상담사 :</span> {report.couns_name} <span style={{ fontWeight: 700 }}>님</span>
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>상담일시 :</span> {report.consultationDate}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>상담 시작 시간 :</span> {report.consultationStartTime}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>상담 진행 시간 :</span> {report.consultationTime}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>다음 상담일 :</span> {report.nextConsultationDate}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>체크 포인트 :</span> {report.checkPoint}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>상담 내용 :</span> {report.content}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>접근전략 및 상담기법 :</span> {report.skill}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>결과 및 효과 :</span> {report.result}
                </Typography>
                <br />
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                  <span style={{ fontWeight: 700 }}>소견 및 기타사항 :</span> {report.note}
                </Typography>
                <br />
              </CardContent>
            </BlankCard>
          </Paper>
        </Grid>
      </DashboardCard>

      <Grid item sm={4} sx={{ textAlign: 'left', marginTop: '2px' }}>
        <Grid item xs={12} md={6}>
          <DashboardCard title="감정분석">
            <Bar data={chartData} options={options} />
          </DashboardCard>
        </Grid>
      </Grid>
      
    </PageContainer>
    
  );
};

export default Consultation_info;
