import React, { useEffect, useState } from 'react';
import { Typography, Grid, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import DashboardCard from '../shared/DashboardCard';
import PageContainer from '../container/PageContainer';
import axios from 'axios';
import { Select, MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';

const Cli_payment = () => {
  const [paydata, setPaydata] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const years = Array.from({ length: 11 }, (v, i) => new Date().getFullYear() - i);

  const fetchReservations = async () => {
    try {
      const queryParams = new URLSearchParams({
        selectedMonth,
        selectedYear,
      }).toString();
      const response = await axios.get(`/api/PayData?${queryParams}`);
      const paymentdata = response.data.paymentdata;
      setPaydata(paymentdata);
    } catch (error) {
      console.error('예약 정보 가져오기 오류:', error);
    }
  };

  useEffect(() => {
    fetchReservations(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value); // 월 선택
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // 년도 선택
  };

  return (
    <PageContainer title="결제 내역">
      <DashboardCard
        title={
          <>
            <Typography style={{ fontWeight: "700", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '20px', whiteSpace: 'nowrap' }}>
              결제 내역
            </Typography>
            <hr style={{
              backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
              height: '4px',
              width: '69px',
              margin: '10px auto', // 중앙 정렬을 위한 마진 설정
              border: 'none',
              marginLeft: '5px', // 왼쪽 정렬
              marginTop: '14px',

            }} />
          </>
        }
        action={


          <Grid item sm={4}>
            <Paper elevation={10}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: 'left' }}>
                      조회 기간 설정
                    </Typography>
                    <Typography sx={{ fontSize: "10px", fontWeight: "500", textAlign: 'left' }}>
                      * 상담 일시 기준
                    </Typography>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item>
                        <Select
                          labelId="year-dd"
                          id="year-dd"
                          value={selectedYear}
                          size="small"
                          onChange={handleYearChange}
                        >
                          {years.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}년
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item>
                        <Select
                          labelId="month-dd"
                          id="month-dd"
                          value={selectedMonth}
                          size="small"
                          onChange={handleMonthChange}
                        >
                          <MenuItem value={1}>1월</MenuItem>
                          <MenuItem value={2}>2월</MenuItem>
                          <MenuItem value={3}>3월</MenuItem>
                          <MenuItem value={4}>4월</MenuItem>
                          <MenuItem value={5}>5월</MenuItem>
                          <MenuItem value={6}>6월</MenuItem>
                          <MenuItem value={7}>7월</MenuItem>
                          <MenuItem value={8}>8월</MenuItem>
                          <MenuItem value={9}>9월</MenuItem>
                          <MenuItem value={10}>10월</MenuItem>
                          <MenuItem value={11}>11월</MenuItem>
                          <MenuItem value={12}>12월</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Paper>
          </Grid>
        }>

        <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

          <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    예약 ID
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    결제 일시
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    상담 유형
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    상담 일시
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    결제자
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    결제 금액
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    결제 수단
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    카드 결제 할부
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "14px", fontWeight: "700" }}>
                    결제 상태
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paydata.map((pay) => (
                <TableRow key={pay.res_id}>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "700", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>{pay.res_id}</Typography> {/* 예약 번호 */}
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>{pay.pay_time}</Typography> {/* 결제 일시 */}
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>{pay.coun_type}</Typography> {/* 상담 유형 */}
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>{new Date(pay.selectedDate).toLocaleDateString('ko-KR')}</Typography> {/* 상담 일시 */}
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>{pay.pay_name}</Typography> {/* 결제자 */}
                  </TableCell>

                  <TableCell>
                    <Chip sx={{ fontSize: "11px", px: "4px", backgroundColor: "#2196f3", color: "#fff", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}
                      size="small" label={pay.price} /> {/* 결제 금액 */}
                  </TableCell>

                  <TableCell sx={{ textAlign: 'left' }}>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>{pay.deposit_type}</Typography> {/* 결제 수단 */}
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>{pay.deposit_ins}</Typography> {/* 카드 결제 할부 */}
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>{pay.deposit_stat}</Typography> {/* 결제 상태 */}
                  </TableCell>

                  <TableCell>
                    {(pay.payment_method === "무통장 입금" || pay.payment_method === "무통장") && (
                      <Typography sx={{ fontSize: "11px", fontWeight: "400", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif" }}>  {/* 무통장일 경우 가상계좌 */}
                        가상 계좌: {pay.vbank_num} / {pay.vbank_name}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default Cli_payment;