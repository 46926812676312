import React, { useState, createContext, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(
        // 페이지 로드 시 로컬 스토리지에서 인증 상태 확인
        () => localStorage.getItem('isLoggedIn') === 'true'
    );

    useEffect(() => {
        // 인증 상태 변경 시 로컬 스토리지에 반영
        localStorage.setItem('isLoggedIn', isLoggedIn);
    }, [isLoggedIn]);

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);