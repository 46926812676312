import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './O_Reservation.css';
import { useAuth } from "../../AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sections = [
    {
        type: "힐링",
        title: "자존감 향상 프로그램",
        description: "대상: 청소년, 대학생, 성인",
        duration: "시간: 10시간 (평일, 주말)",
        classtime: 10,
        content: [
            {
                title: "나의 자존감",
                content: (
                    <ul>
                        <li>자존감 저하 요인 상담</li>
                        <li>향상 프로그램 10가지 선정</li>
                    </ul>
                )
            },
            {
                title: "향상 프로그램 1단계 (예)",
                content: (
                    <ul>
                        <li>나의 사명문</li>
                        <li>나의 장점 100가지</li>
                        <li>나의 인생 성적표</li>
                    </ul>
                )
            },
            {
                title: "향상 프로그램 2단계 (예)",
                content: (
                    <ul>
                        <li>나의 왕년</li>
                        <li>볼링 게임 & 다트 게임</li>
                        <li>나를 위한 보상과 상장</li>
                    </ul>
                )
            },
            {
                title: "향상 프로그램 3단계 (예)",
                content: (
                    <ul>
                        <li>나의 긍정 인생 로드맵</li>
                        <li>나의 Movie Poster</li>
                        <li>실행 계획 작성 및 선언</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/heal_1.jpg'
    },
    {
        type: "힐링",
        title: "대인 관계 향상",
        description: "대상: 청소년, 대학생, 성인",
        duration: "시간: 10시간 (평일, 주말)",
        classtime: 10,
        content: [
            {
                title: "나의 대인 관계",
                content: (
                    <ul>
                        <li>대인 관계 저하 요인 상담</li>
                        <li>향상 프로그램 10가지 선정</li>
                    </ul>
                )
            },
            {
                title: "향상 프로그램 1단계 (예)",
                content: (
                    <ul>
                        <li>30cm 훈련</li>
                        <li>내 이름 삼행시</li>
                        <li>나의 보물 창고</li>
                    </ul>
                )
            },
            {
                title: "향상 프로그램 2단계 (예)",
                content: (
                    <ul>
                        <li>나의 지식과 재능 10가지</li>
                        <li>나의 직업 10가지</li>
                        <li>나의 명함 디자인</li>
                    </ul>
                )
            },
            {
                title: "향상 프로그램 3단계 (예)",
                content: (
                    <ul>
                        <li>연사 소개하기</li>
                        <li>나의 미래 이력서</li>
                        <li>실행 계획 작성 및 선언</li>
                    </ul>
                )
            },],
        img: '/img/O_Res/heal_2.jpg'
    },
    {
        type: "힐링",
        title: "자기계발 / 인생 Roadmap",
        description: "대상: 청소년, 대학생, 성인",
        duration: "시간: 4시간 (평일, 주말)",
        classtime: 4,
        content: [
            {
                title: "자기계발",
                content: (
                    <ul>
                        <li>자기 정체성</li>
                        <li>명품 인생 설계</li>
                    </ul>
                )
            },
            {
                title: "나침반과 Navigation",
                content: (
                    <ul>
                        <li>나침반</li>
                        <li>Navigation</li>
                    </ul>
                )
            },
            {
                title: "인생 Roadmap",
                content: (
                    <ul>
                        <li>인생 곡선</li>
                        <li>Vision 수립</li>
                    </ul>
                )
            },
            {
                title: "실행 계획 작성 및 선언",
                content: (
                    <ul>
                        <li>Bucket List</li>
                        <li>실행계획</li>
                        <li>선언식</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/heal_3.jpg'
    },
    {
        type: "힐링",
        title: "스트레스 관리",
        description: "대상: 청소년, 대학생, 성인",
        duration: "시간: 4시간 (평일, 주말)",
        classtime: 4,
        content: [
            {
                title: "긍정 스트레스와 부정 스트레스",
                content: (
                    <ul>
                        <li>긍정 스트레스 (건강)</li>
                        <li>부정 스트레스 (불건강)</li>
                        <li>역장 분석 (FFA & Sum) </li>
                    </ul>
                )
            },
            {
                title: "긍정 스트레스 관리",
                content: (
                    <ul>
                        <li>긍정 스트레스 레벨 업 방안</li>
                        <li>실행 계획</li>
                    </ul>
                )
            },
            {
                title: "부정 스트레스 관리",
                content: (
                    <ul>
                        <li>원인 분석 (Fishbone Diagram)</li>
                        <li>해결 방안</li>
                        <li>실행 계획</li>
                    </ul>
                )
            },
            {
                title: "스트레스 관리 게임",
                content: (
                    <ul>
                        <li>부정 스트레스 척도 (High)</li>
                        <li>긍정 스트레스 척도 (Low)</li>
                        <li>스트레스 관리 게임 (척도 기준)</li>
                    </ul>
                )
            }
        ],
        img: '/img/O_Res/heal_4.jpg'
    }
];

const AccordionItem = ({ title, content, isOpen, onClick }) => (
    <div className="accordion-item">
        <div className="accordion-title" onClick={onClick}>
            {title}
            <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && <div className="accordion-content">{content}</div>}
    </div>
);

const O_Reservation_Heal = () => {
    const [openIndices, setOpenIndices] = useState(Array(sections.length).fill(null));
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        document.title = "집단 상담 예약";
    }, []);

    const handleToggle = (sectionIndex, contentIndex) => {
        setOpenIndices(prevIndices => {
            const newIndices = [...prevIndices];
            newIndices[sectionIndex] = newIndices[sectionIndex] === contentIndex ? null : contentIndex;
            return newIndices;
        });
    };

    const handleReservation = (type, title, classtime) => {
        if (!isLoggedIn) {
            navigate('/Login');
            return;
        }

        navigate('/O_Reservation_detail', {
            state: {
                type: type,
                title: title,
                classtime: classtime
            }
        });
    };

    return (
        <div className='O_Res'>
            <ToastContainer />
            {sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="O_Res_Container">
                    <h1 className='O_Res_Container_h1'>{section.title}</h1>
                    <p className='O_Res_Container_p'>{section.description}</p>
                    <p className='O_Res_Container_p'>{section.duration}</p>
                    <div className='O_Res_Content'>
                        <div className="accordion">
                            {section.content.map((item, contentIndex) => (
                                <AccordionItem
                                    key={contentIndex}
                                    title={item.title}
                                    content={item.content}
                                    isOpen={openIndices[sectionIndex] === contentIndex}
                                    onClick={() => handleToggle(sectionIndex, contentIndex)}
                                />
                            ))}
                        </div>
                        <div className='contentimg'>
                            <img src={section.img} alt={section.title} />
                        </div>
                    </div>
                    <button
                        className="O_reservation-button"
                        onClick={() => handleReservation(section.type, section.title, section.classtime)}
                    >
                        예약하기
                    </button>
                </div>
            ))}
        </div>
    );
};

export default O_Reservation_Heal;
