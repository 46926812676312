import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import moment from 'moment';
import "./Reservation.css";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  //const { counselorid } = useLocation().state;


  const counselorid = state.counselorid;
  const check = state.check;


  // Count
  const [countgroup, setCountgroup] = useState(0); //상담 인원 선택
  const [counttime, setCounttime] = useState(0); //상담 시간 선택

  // 예약
  const [semiselectedDate, setSelectedDate] = useState(new Date()); //날짜 선택
  const [availableTimes, setAvailableTimes] = useState([]); //예약 가능한 날짜
  const [selectedTime, setSelectedTime] = useState([]); //시간 선택
  const [timeRange, settimeRange] = useState([]);
  const [checktime, setchecktime] = useState(null);

  const { id } = useParams(); // URL에서 상담사 ID 추출

  const selectedDate = semiselectedDate.toLocaleDateString('ko-KR');
  const selectedDate2 = `${semiselectedDate.getFullYear()}-${(semiselectedDate.getMonth() + 1).toString().padStart(2, '0')}-${semiselectedDate.getDate().toString().padStart(2, '0')}`;
  //서버로 보내는 date 형식 


  // 로그인 상태가 아닐 시 로그인 페이지로 전환
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    navigate('/Login');
  }

  const [cdeatil, setcDeatildata] = useState({
    c_name: '',
    c_description: '',
    c_career: '',
    c_education: '',
    c_license: '',
    image: '', // 추가된 부분
  });

  useEffect(() => {
    if (state && check !== true) {
      navigate('/Search'); // 루트 페이지로 리디렉션
      toast.error("잘못된 접근입니다.");
    } else if (!state) {
      navigate('/Search');
      console.log("잘못된 접근입니다.");
    }
  }, [state, check, navigate]);



  useEffect(() => {
    if (cdeatil) { // cDeatildata가 설정되어 있을 때에만 실행
      handleDateChange(semiselectedDate);
    }
  }, [cdeatil]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/counselordetail', { params: { id } });
        const cDetailData = response.data.cdetaildata;
        setcDeatildata(cDetailData[0]);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    document.title = "그룹 예약";
  }, []);

  const handleDateChange = async (newDate) => {
    const currentDate = new Date(); // 현재시간

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    const selectedYear = newDate.getFullYear();
    const selectedMonth = newDate.getMonth();
    const selectedDay = newDate.getDate();


    // 현재 시간 이후의 시간을 필터링하는 함수
    const filterTimesAfterCurrentTime = (time) => {
      const [hour, minute] = time.split(':').map(Number); // 시간과 분을 분리하고 숫자로 변환
      if (
        (selectedYear === currentYear && selectedMonth === currentMonth && selectedDay === currentDay) &&
        (hour > currentHour || (hour === currentHour && minute > currentMinute))
      ) {
        // 현재 날짜와 선택된 날짜가 같으면서, 시간이 현재 시간 이후인 경우
        return true;
      } else if (selectedYear > currentYear || (selectedYear === currentYear && selectedMonth > currentMonth) || (selectedYear === currentYear && selectedMonth === currentMonth && selectedDay > currentDay)) {
        // 선택된 날짜가 현재 날짜보다 미래인 경우
        return true;
      } else {
        // 나머지 경우
        return false;
      }
    };

    setSelectedDate(newDate);
    setSelectedTime([]); // 날짜가 변경될 때마다 선택된 시간 초기화


    try {
      const selectedDate2 = `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, '0')}-${selectedDay.toString().padStart(2, '0')}`;
      const response = await fetch(`/api/AvailableTimes/${id}?c_id=${counselorid}&selected_date=${selectedDate2}`);
      const data = await response.json();

      const filteredTimes = data.availableTimes.filter(filterTimesAfterCurrentTime);
      settimeRange(data.timeRange);
      setAvailableTimes(filteredTimes);
    } catch (error) {
      console.error("예약 가능 시간 가져오기 실패:", error);
    }
  };

  let endTime = '';
  // 종료 시간 계산
  if (typeof selectedTime === 'string' && selectedTime !== '') {
    const [startTimeHour, startTimeMinute] = selectedTime.split(":").map(Number);
    let endHour = startTimeHour + counttime;
    let endMinute = startTimeMinute

    // 시간이 24를 넘어가면 다음 날로 넘어감 (예외 처리)
    if (endHour >= 24) {
      endHour %= 24;
    }

    endTime = `${endHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")}`;
  }

  const handleClick = (time) => {
    if (availableTimes.includes(time)) {
      setchecktime(time);
      handleTimeSelect(time);
    }
  };

  // 총 금액 계산
  const calculateTotalAmount = () => { // 가격 계산
    return (counttime * countgroup * 50000);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleIncrementgroup = () => {
    setCountgroup(countgroup + 1);
  };

  const handleDecrementgroup = () => {
    if (countgroup > 0) {
      setCountgroup(countgroup - 1);
    }
  };

  const handleIncrementtime = () => {
    setCounttime(counttime + 1);
  };

  const handleDecrementtime = () => {
    if (counttime > 0) {
      setCounttime(counttime - 1);
    }
  };

  /* 예약 시간 중복 확인 및 다음 페이지로 이동*/
  const handlenextstate = async () => {
    const response = await fetch(`/api/checkAvailableTimes/?countype=${1}&counselorId=${cdeatil.c_id}&day=${selectedDate2}&startTime=${selectedTime}&endTime=${endTime}`);
    if (response.status === 200) {
      navigate(`/Counselor/${cdeatil.id}/G_Reservation_request`,
        {
          state: {
            counselorName: cdeatil.c_name,
            counselorId: cdeatil.c_id,
            selectedDate2,
            selectedTime,
            countgroup,
            totalTime: counttime,
            totalAmount: calculateTotalAmount(),
          }
        })
    } else {
      if (response.status === 400) {
        toast.error("예약 시간이 겹칩니다. 다른 시간을 선택해 주세요!");
        return;
      } else {
        toast.error("예약 시간 확인 오류입니다. 반복되면 관리자에게 문의 주세요.");
        return;
      }
    }
  }

  return (
    <div className='G_Counselor_res_div'>
      <ToastContainer />
      <div className='G_Counselor_res_title'>
        <h3>그룹 예약 상세</h3>
      </div>
      <div className='G_Counselor_res_detail'>
        <div className='G_Counselor_res_choice'>
          <div className='G_Counselor_res_cal'>
            <div className='G_Counselor_res_date'>
              <Calendar
                onChange={handleDateChange}
                value={semiselectedDate}
                tileDisabled={({ date, view }) => {
                  if (view === 'month' && date < new Date(new Date().setHours(0, 0, 0, 0))) {
                    return true; // 오늘 이전의 날짜는 선택할 수 없도록 함
                  }
                  return false;
                }}
                formatDay={(locale, date) => moment(date).format("DD")}
                minDetail="month"
                maxDetail="month"
              />
            </div>
            <div className='G_Counselor_res_time'>
              {timeRange.length > 0 ? (
                <div className="G_Counselor_time_buttons">
                  {timeRange.map((time) => (
                    <button
                      key={time}
                      onClick={() => handleClick(time)}
                      className={
                        availableTimes.includes(time)
                          ? `G_Counselor_time_button ${selectedTime === time ? 'selected' : ''}`
                          : 'G_Counselor_time_button disabled'
                      }
                    >
                      {time}
                    </button>
                  ))}
                </div>
              ) : (
                <p>해당 날짜에는 예약 가능한 시간이 없습니다.</p>
              )}
            </div>
          </div>
          <div className='G_Counselor_res_info'>
            <div className='G_Counselor_res_transparent-box'>
              <div className="G_reservation_count">
                <div className='G_reervation_count_title'>
                  수강 인원 :
                </div>
                <button className="minusbutton" onClick={handleDecrementgroup}>-</button><Span3 />
                {countgroup}
                <Span3 /><button className="plusbutton" onClick={handleIncrementgroup}>+</button>
              </div>
              <div className="G_reservation_count">
                <div className='G_reervation_count_title'>
                  총 시간 (인당 1시간 50000원) :
                </div>
                <button className="minusbutton" onClick={handleDecrementtime}>-</button><Span3 />
                {counttime}
                <Span3 /><button className="plusbutton" onClick={handleIncrementtime}>+</button>
              </div>
            </div>
            <table className='G_Counselor_res_info_table'>
              <tbody>
                <tr>
                  <td>상담자명</td>
                  <td>{cdeatil.c_name}</td>
                </tr>
                <tr>
                  <td>선택된 날짜</td>
                  <td>{selectedDate}</td>
                </tr>
                {selectedTime &&
                  <tr>
                    <td>선택된 시간</td>
                    <td>{selectedTime} - {endTime}</td>
                  </tr>
                }
                <tr>
                  <td>상담 인원</td>
                  <td>{countgroup}</td>
                </tr>
                <tr>
                  <td>총 금액</td>
                  <td>{calculateTotalAmount()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='G_Counselor_res_button'>
          <button
            className={(counttime !== 0 && countgroup > 1 && selectedTime && selectedTime.length > 0) ? "G_Counselor_res_btn" : "G_Counselor_res_btn disabled"}
            onClick={handlenextstate}
            disabled={counttime === 0 || countgroup <= 1 || !selectedTime || selectedTime.length === 0}
          >
            다음 단계
          </button>
        </div>
      </div>
    </div>
  );
}


function Span1({ space = 50 }) {
  return (
    <span style={{ paddingRight: space }}></span>
  );
}

function Span2({ space = 50 }) {
  return (
    <span style={{ paddingRight: space }}></span>
  );
}

function Span3({ space = 5 }) {
  return (
    <span style={{ paddingRight: space }}></span>
  );
}

function Span4({ space = 28 }) {
  return (
    <span style={{ paddingRight: space }}></span>
  );
}



export default App;