import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, Paper, CardContent, TableContainer } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import BlankCard from '../shared/BlankCard';
import { Rating, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';

// 별점 스타일
const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#FFD700',
  },
  '& .MuiRating-iconHover': {
    color: '#FFEA00',
  },
});

// 삭제 날짜 번호 
const currentDate = new Date();
const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
const delete_review = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
const reviewdeleteDateTime = currentDate.toLocaleString('ko-KR', options);



const Cli_review = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  const { no } = useParams();


  const [review, setReview] = useState({ // 처음에 값 초기화
    counselor_name: '',
    date: '',
    rating: 0,
    content: ''
  });

  const { // 예약 데이터 전달받기
    reviewnum
  } = useLocation().state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reviewResponse = await axios.get('/api/reviewdetail', {
          params: {
            reviewnum: reviewnum
          }
        });
        const reviewData = reviewResponse.data.reviewdetail[0];
        setReview(reviewData);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }

    };

    fetchData();
  }, [reviewnum]);

  // 리뷰 삭제
  const deletereview = async () => {
    try {
      await axios.delete(`/api/deletereview?reviewnum=${reviewnum}&deletedate=${reviewdeleteDateTime}&delete_review=${delete_review}`);
      toast.success('리뷰가 삭제되었습니다!');
      navigate(`/Mypage/client/review`);
    } catch (error) {
      toast.error('리뷰 삭제 중 오류가 발생하였습니다.');
    }
  };







  return (
    <PageContainer title="작성한 후기">
      <DashboardCard title="작성한 후기"
        action={
          <div>
            <Button variant="contained"
              style={{
                backgroundColor: "#5D87FF",
                color: 'white',
                fontWeight: 'bold',
                fontSize: '10px',
                marginLeft: '5px'
              }}
              onClick={() => navigate(`/Mypage/client/review_change/${no}`, {
                state: {
                  reviewnum: reviewnum,
                  reviewdate: review.date, review,
                  date: review.date,
                  counselorname: review.counselor_name

                }
              })}
            >
              수정
            </Button>
            <Button variant="contained"
              style={{
                backgroundColor: "#5D87FF",
                color: 'white',
                fontWeight: 'bold',
                fontSize: '10px',
                marginLeft: '5px'
              }}
              onClick={deletereview}
            >
              삭제
            </Button>
          </div>

        }>
        <Grid container spacing={3}>
          <Grid item sm={4} sx={{ textAlign: 'left', marginTop: '10px' }}>
            <Paper elevation={10}>
              <BlankCard>
                <CardContent>
                  <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                    <span style={{ fontWeight: 700 }}>예약 ID :</span> {review.res_id}
                  </Typography>
                  <br />
                  <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                    <span style={{ fontWeight: 700 }}>상담사 :</span> {review.counselor_name} 상담사
                  </Typography>
                  <br />
                  <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                    <span style={{ fontWeight: 700 }}>상담일시 :</span> {review.date}
                  </Typography>
                  <br />
                  <Typography sx={{ fontSize: "13.5px", marginTop: '5px' }}>
                    <span style={{ fontWeight: 700 }}>별점 :</span> {review.rating}점
                    <StyledRating
                      name="read-only"
                      value={review.rating}
                      readOnly
                      precision={0.5}
                      sx={{ marginLeft: '10px', color: '#FFD700' }}
                    />
                  </Typography>

                  <br />
                </CardContent>
              </BlankCard>
            </Paper>
          </Grid>
        </Grid>

        <Grid item sm={4} sx={{ textAlign: 'left', marginTop: '10px' }}>
          <Paper elevation={10}>
            <BlankCard>
              <CardContent>
                <Typography sx={{ fontSize: "13.5px", marginTop: '5px', whiteSpace: 'pre-line', lineHeight: '1.5' }}>
                  <span style={{ fontWeight: 700 }}></span>

                  {review.content}
                </Typography>

              </CardContent>
            </BlankCard>
          </Paper>
        </Grid>

        <br /><br />




      </DashboardCard>
    </PageContainer>
  );
};

export default Cli_review;
