import React, { useState, useEffect } from "react";
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Fab } from '@mui/material';
import { IconCurrencyDollar } from '@tabler/icons';
import DashboardCard from '../../shared/DashboardCard';
import { useNavigate } from "react-router-dom";


const MonthlyEarnings = () => {
  const navigate = useNavigate();
  // chart color
  const theme = useTheme();
  const secondary = theme.palette.secondary.main;
  const secondarylight = '#f5fcff';
  const errorlight = '#fdede8';
  const today = new Date();
  const selectedMonth = today.getMonth() + 1;
  const [calCounsNum, setCalCounsNum] = useState([]); // 상담 건수
  const [adminCalculate, setAdminCalculate] = useState([]);
  const [cost, setCost] = useState([]);
  const [price, setPrice] = useState([]);


  // chart
  const optionscolumnchart = {
    chart: {
      type: 'area',
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      foreColor: '#adb0bb',
      toolbar: {
        show: false,
      },
      height: 60,
      sparkline: {
        enabled: true,
      },
      group: 'sparklines',
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    fill: {
      colors: [secondarylight],
      type: 'solid',
      opacity: 0.05,
    },
    markers: {
      size: 0,
    },
    tooltip: {
      theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
    },
  };
  const seriescolumnchart = [
    {
      name: '',
      color: secondary,
      data: [25, 66, 20, 40, 12, 58, 20],
    },
  ];

  const calCost = (item) => {
    var result;
    
    setCost(result);
  };


  async function fetchAdminCalc() {
    //메인페이지 정산 계산 함수
    try {
      const queryParams = new URLSearchParams({
        selectedMonth: selectedMonth,
      }).toString();
      const url = `/api/Home_Admin_calculate?${queryParams}`;
      const response = await fetch(url);
      const data = await response.json();

      // data의 각 항목에 대해 비용 계산
      data.forEach((item) => calCost(item));

      setAdminCalculate(data);
      setCalCounsNum(data.length); // 상담 건수

    } catch (error) {
      console.error("데이터 가져오기 오류:", error);
    }
  }

  useEffect(() => {

    fetchAdminCalc()
  }, []);

  useEffect(() => {
    // 모든 예약의 totalamount를 합친 값을 계산
    const totalPrice = adminCalculate.reduce((acc, item) => acc + item.totalamount, 0);
    // totalPrice를 setPrice로 설정
    setPrice(totalPrice);
  }, [adminCalculate]);

  return (
    <DashboardCard title={`${today.getMonth() + 1}월달 정산`}
      action={
        <Fab color="secondary" size="medium" sx={{ color: '#ffffff' }}>
          <IconCurrencyDollar width={24} />
        </Fab>
      }
      
    >
      <>
        <Typography fontWeight="700" mt="-20px" sx = {{fontSize: "14.5px", textAlign: 'left' }}>
          최종 금액 : {price}원
        </Typography>
        <br/>
        <br/>
        <br/>

        <Typography  fontWeight="700" mt="-20px" sx = {{fontSize: "14.5px", textAlign: 'left' }}>
          상담 건수 : {calCounsNum}건
        </Typography>
       
      </>
    </DashboardCard>
  );
};

export default MonthlyEarnings;