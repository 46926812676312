import React, { useEffect, useState } from 'react';
import { Button, Typography, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import DashboardCard from '../shared/DashboardCard';
import PageContainer from '../container/PageContainer';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/system';

const Client_Manage = () => {

  const [Clients, setClients] = useState([]);
  const navigate = useNavigate();

  const StyledSuspendButton = styled(Button)({ // 정지
    fontSize: '10px',
    fontWeight: 500,
    padding: '6px 12px',
    textTransform: 'none',
    border: '1px solid #FF4081', // 빨간색 외곽선
    color: '#FF4081', // 빨간색 텍스트
    '&:hover': {
      backgroundColor: '#FF4081', // 빨간색 배경색
      color: '#fff', // 흰색 텍스트
    },
  });

  const StyledReleaseButton = styled(Button)({ // 해제
    fontSize: '10px',
    fontWeight: 500,
    padding: '6px 12px',
    textTransform: 'none',
    border: '1px solid #1976D2', // 파란색 외곽선
    color: '#1976D2', // 파란색 텍스트
    '&:hover': {
      backgroundColor: '#1976D2', // 파란색 배경색
      color: '#fff', // 흰색 텍스트
    },
  });


  useEffect(() => {
    // 서버에서 데이터 가져오는 함수
    async function fetchClients() {
      try {
        const response = await fetch("/api/Client_Manage");
        const data = await response.json();
        setClients(data);
      } catch (error) {
        console.error("데이터 가져오기 오류:", error);
      }
    }
    fetchClients();
  }, []); // 컴포넌트가 처음 렌더링될 때 한 번만 실행

  async function suspendClient(client) {
    // 클라이언트 정지
    try {
      const response = await fetch('/api/suspendClient', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: client.user_id })
      });

      const data = await response.json();

      toast.error("계정 정지 처리되었습니다.", { autoClose: 1500 });
    } catch (error) {
      console.error('요청 실패:', error);
    }
  }

  async function removeSuspendClient(client) {
    // 클라이언트 정지 해제
    try {
      const response = await fetch('/api/removeSuspendClient', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: client.user_id })
      });

      const data = await response.json();

      toast.success("정지 해제 처리되었습니다.", { autoClose: 1500 });
    } catch (error) {
      console.error('요청 실패:', error);
    }
  }

  // toast 메시지가 사라진 후에 페이지를 새로 고침하는 함수
  function reloadPageAfterToast() {
    window.location.reload();
  }

  async function handleLeave(client) {
    if (client.activity === 2) {
      navigate("/Mypage/admin/Client_Leave", {
        state: {
          loginid: client.loginid,
          user_id: client.user_id,
          authority: client.authority,
          check: "OK"
        }
      });
    } else {
      toast.error("계정 정지된 사용자만 강제 회원 탈퇴가 가능합니다.");
    }
  }

  return (
    <PageContainer title="내담자 관리" >
      <DashboardCard title="내담자 관리">
        <ToastContainer onClose={reloadPageAfterToast} /> {/*  toast 메시지가 닫힌 후에 페이지를 새로 고침 */}
        <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
          <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontSize: "13px", fontWeight: "700" }}>
                    회원번호
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography sx={{ fontSize: "13px", fontWeight: "700" }}>
                    이름
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: "13px", fontWeight: "700" }}>
                    생년월일
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: "13px", fontWeight: "700" }}>
                    이메일
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: "13px", fontWeight: "700" }}>
                    전화번호
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: "13px", fontWeight: "700" }}>
                    성별
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: "13px", fontWeight: "700" }}>
                    가입경로
                  </Typography>
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {Clients.map((item, index) => (
                <TableRow key={index}>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "700" }}>{item.user_id}</Typography> {/* 예약 번호 */}
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400" }}>{item.name}</Typography> {/* 결제 일시 */}
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400" }}>{item.birthday}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400" }}>{item.email}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400" }}>{item.phonenum}</Typography>
                  </TableCell>



                  <TableCell sx={{ textAlign: 'left' }}>
                    <Typography sx={{ fontSize: "11px", fontWeight: "400" }}> {item.gender === 1 ? "남" : "여"} </Typography>
                  </TableCell>

                  <TableCell sx={{ textAlign: 'left' }}>
                    {item.path === 1 ? (
                      <Typography sx={{ fontSize: "11px", fontWeight: "400" }}>홈페이지</Typography>
                    ) : item.path === 2 ? (
                      <Typography sx={{ fontSize: "11px", fontWeight: "400" }}>네이버</Typography>
                    ) : item.path === 3 ? (
                      <Typography sx={{ fontSize: "11px", fontWeight: "400" }}>카카오</Typography>
                    ) : null}
                  </TableCell>

                  <TableCell sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: '11px', fontWeight: '400' }}>
                      {item.activity === 1 ? (
                        <StyledSuspendButton onClick={() => suspendClient(item)}>계정정지</StyledSuspendButton>
                      ) : (
                        <StyledReleaseButton onClick={() => removeSuspendClient(item)}>정지해제</StyledReleaseButton>
                      )}
                    </Typography>
                    <Typography sx={{ fontSize: "10px", fontWeight: "400" }}>
                      <StyledSuspendButton
                        onClick={() => handleLeave(item)}
                      >회원탈퇴</StyledSuspendButton></Typography>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default Client_Manage;
