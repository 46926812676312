import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import { Typography, Grid, CardContent, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import BlankCard from '../shared/BlankCard';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';

const Con_calculate = () => {
  const navigate = useNavigate();
  const [adminCalculate, setAdminCalculate] = useState([]);
  const [cost, setCost] = useState([]);
  const [price, setPrice] = useState([]);
  const [resdata, setresdata] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const years = Array.from({ length: 11 }, (v, i) => new Date().getFullYear() - i);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value); // 월 선택
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // 년도 선택
  };

  const [calCounsNum, setCalCounsNum] = useState([]); // 상담 건수

  const calCost = (item) => {
    var result;

    setCost(result);
  };

  async function fetchConCalculate() {
    try {
      const queryParams = new URLSearchParams({
        selectedMonth,
        selectedYear
      }).toString();
      const url = `/api/Con_calculate?${queryParams}`;
      const response = await fetch(url);
      const data = await response.json();

      // data의 각 항목에 대해 비용 계산
      data.forEach((item) => calCost(item));

      setAdminCalculate(data);
      setCalCounsNum(data.length); // 상담 건수
    } catch (error) {
      console.error("데이터 가져오기 오류:", error);
    }
  }

  useEffect(() => {
    fetchConCalculate(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);


  useEffect(() => {
    // 모든 예약의 totalamount를 합친 값을 계산
    const totalPrice = adminCalculate.reduce((acc, item) => acc + item.totalamount, 0);
    // totalPrice를 setPrice로 설정
    setPrice(totalPrice);
  }, [adminCalculate]);


  return (
    <PageContainer title="정산">
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <DashboardCard title="정산">
            <Grid container spacing={3} >
              <Grid item sm={4}>
                <Paper elevation={10}>
                  <BlankCard>
                    <CardContent>

                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: 'left' }}>
                              {selectedMonth}월
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700", textAlign: 'left' }}>
                              최종 금액 : {price}원
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "700", textAlign: 'left' }}>
                              상담 건수 : {calCounsNum}건
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                    </CardContent>

                  </BlankCard>
                </Paper>
              </Grid>

              <Grid item sm={4}>
                <Paper elevation={10}>
                  <BlankCard>
                    <CardContent>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: 'left' }}>
                              조회 기간 설정
                            </Typography>
                            <Typography sx={{ fontSize: "10px", fontWeight: "500", textAlign: 'left' }}>
                              * 상담 일시 기준
                            </Typography>
                            <br />
                            <Grid container spacing={1}>
                              <Grid item>
                                <Select
                                  labelId="year-dd"
                                  id="year-dd"
                                  value={selectedYear}
                                  size="small"
                                  onChange={handleYearChange}
                                >
                                  {years.map((year) => (
                                    <MenuItem key={year} value={year}>
                                      {year}년
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item>
                                <Select
                                  labelId="month-dd"
                                  id="month-dd"
                                  value={selectedMonth}
                                  size="small"
                                  onChange={handleMonthChange}
                                >
                                  <MenuItem value={1}>1월</MenuItem>
                                  <MenuItem value={2}>2월</MenuItem>
                                  <MenuItem value={3}>3월</MenuItem>
                                  <MenuItem value={4}>4월</MenuItem>
                                  <MenuItem value={5}>5월</MenuItem>
                                  <MenuItem value={6}>6월</MenuItem>
                                  <MenuItem value={7}>7월</MenuItem>
                                  <MenuItem value={8}>8월</MenuItem>
                                  <MenuItem value={9}>9월</MenuItem>
                                  <MenuItem value={10}>10월</MenuItem>
                                  <MenuItem value={11}>11월</MenuItem>
                                  <MenuItem value={12}>12월</MenuItem>
                                </Select>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </CardContent>
                  </BlankCard>
                </Paper>
              </Grid>

              <Grid item sm={12}>

                <Grid container spacing={3} >
                  <Grid item sm={12}>
                    <Paper elevation={10}>
                      <BlankCard>
                        <CardContent>


                          <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

                            <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                      예약 ID
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                      날짜
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                      상담 시간

                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                      상담 유형

                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                      상담사
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                                      최종금액

                                    </Typography>
                                  </TableCell>

                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {adminCalculate.map((item, index) => (
                                  <TableRow key={index}>
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} >{item.res_id}</TableCell> {/*예약 ID */}
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.selectedDate}</TableCell> {/*날짜 */}
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}> {item.selectedTime} ({item.totalTime}시간)</TableCell> {/*상담 시간 */}
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{
                                      item.coun_type}</TableCell> {/*상담 유형*/}

                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.counselor_name} 상담사 </TableCell> {/*상담사 */}
                                    <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.totalamount} </TableCell> {/*최종 금액 */}
                                  </TableRow>
                                ))}


                              </TableBody>
                            </Table>
                          </Box>



                        </CardContent>

                      </BlankCard>
                    </Paper>
                  </Grid>
                </Grid>

              </Grid >


            </Grid>
          </DashboardCard>
        </Grid>
      </Grid>
    </PageContainer>


  );
};

function MSpan({ space = 510 }) {
  return (
    <span style={{ paddingRight: space }}></span>
  );
}


export default Con_calculate;
