import React, { useEffect, useState, useRef } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ProfileImg from '../../counselor_img/unknown.png';

import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

import axios from 'axios';

const Edit_Counselor = () => {
  const [userData, setUserData] = useState({}); // 업데이트용 사용자 데이터

  const [counsImg, setCounsImg] = useState({}); // 이미지 불러오기
  const [w_counselorData, setW_counselorData] = useState({}); // 사용자 데이터

  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state || {};
  const { check } = state;

  if (!check || check !== 'OK') {
    navigate('/Mypage/counselor/Check');
    console.log("잘못된 접근입니다.");
  }

  // 로그인 상태가 아닐 시 로그인 페이지로 전환
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  if (!isLoggedIn) {
    navigate('/Login');
  }

  async function fetchCounsImg() {
    try {
      //서버에 상담사 이미지 가져오는 api 호출
      const counsResponse = await axios.post('/api/MypageCouns', {
        userId: w_counselorData.user_id,
        userName: w_counselorData.couns_name,
      }, { withCredentials: true });

    } catch (error) {
      console.log('데이터 요청 오류');
    }
  }

  useEffect(() => {
    // 서버에서 사용자 정보를 가져오는 API 호출
    axios
      .get("/api/Mypage", { withCredentials: true })
      .then((response) => {
        setW_counselorData(response.data);
      })
      .catch((error) => {
        console.error("마이페이지 데이터 요청 오류:", error);
      });
  }, []);

  useEffect(() => {
    axios.get('/api/UpdatedCounselorData', { withCredentials: true })
      .then(response => {
        setUserData(response.data); // 업데이트된 사용자 정보 설정
      })
      .catch(error => {
        console.error('업데이트된 사용자 데이터 요청 오류:', error);
      });

  }, []);

  useEffect(() => {
    if (w_counselorData && w_counselorData.user_id) {
      fetchCounsImg();

    }
  }, [w_counselorData]);

  return (
    <PageContainer title="프로필 확인" >

      <DashboardCard title="나의 정보" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Paper elevation={10}>
          <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
            <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>


                      {w_counselorData.c_image ? (
                        <img
                          src={w_counselorData.c_image}
                          alt="Counselor"
                          style={{
                            width: '110px',
                            height: '110px',
                            marginRight: '50px',
                            marginLeft: '120px',
                            marginTop: '-280px',
                            borderRadius: '5px',
                          }}
                        />
                      ) : (
                        <img
                          src={ProfileImg}
                          alt="Counselor"
                          style={{
                            width: '110px',
                            height: '110px',
                            marginRight: '50px',
                            marginLeft: '120px',
                            marginTop: '-280px',
                            borderRadius: '5px',
                          }}
                        />
                      )}
                      <div>
                        <br /><br /><br />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                          이름 : {userData.couns_name}
                        </Typography>
                        <br />
                        <br />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          아이디 : {userData.couns_loginid}
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          비밀번호 : ********
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          이메일 : {userData.couns_email}
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          전화번호 : {userData.couns_phonenum}
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          생년월일 : {userData.birthday}
                        </Typography>
                        <br />
                        <br />

                        <Typography sx={{ fontSize: "14px", fontWeight: "400", marginLeft: '10px' }}>
                          성별 : {userData.couns_gender === 1 ? '남자' : '여자'}
                        </Typography>
                        <br />
                        <br />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography
                            component={Link}
                            to={`/Mypage/counselor/update_counselor`}
                            state={{ check: "OK" }}
                            sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "3" }}
                          >
                            회원 정보 수정
                          </Typography>
                        </Box>
                        <br />
                      </div>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Box>
        </Paper>

      </DashboardCard>


    </PageContainer>
  );
};

export default Edit_Counselor;
