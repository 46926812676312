import React, { useEffect, useState } from 'react';
import DashboardCard from '../../shared/DashboardCard';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ProfileImg1 from '../../assets/images/profile/user-1.jpg';
import ProfileImg2 from '../../assets/images/profile/user-2.jpg';
import axios from 'axios';

const ClientProfile = () => {
    const [userData, setUserData] = useState({}); // 사용자 데이터

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/UpdatedUserData', { withCredentials: true });
                setUserData(response.data);
            } catch (error) {
                console.error('마이페이지 데이터 요청 오류:', error);
            }
        };
        fetchData();
    }, []);


    return (
        <DashboardCard title="나의 정보">
            <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>
                <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={userData.gender === 1 ? ProfileImg1 : ProfileImg2}
                                        alt="ProfileImg"
                                        style={{
                                            width: '110px',
                                            height: '110px',
                                            marginRight: '20px',
                                            marginTop: '-30px',
                                            borderRadius: '5px',
                                        }}
                                    />
                                    <div>
                                        <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "12.5px", fontWeight: "400", marginTop: '-30px', marginLeft: '10px' }}>
                                            이름 : {userData.name}
                                        </Typography>
                                        <br />
                                        <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "12.5px", fontWeight: "400", marginLeft: '10px' }}>
                                            아이디 : {userData.loginid}
                                        </Typography>
                                        <br />
                                        <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "12.5px", fontWeight: "400", marginLeft: '10px' }}>
                                            전화번호 : {userData.phonenum}
                                        </Typography>
                                        <br />
                                        <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "12.5px", fontWeight: "400", marginLeft: '10px' }}>
                                            이메일 : {userData.email}
                                        </Typography>
                                        <br />
                                        <Typography sx={{ fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: "12.5px", fontWeight: "400", marginLeft: '10px' }}>
                                            생년월일 : {userData.birthday}
                                        </Typography>
                                    </div>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </Box>
        </DashboardCard>
    );
};

export default ClientProfile;
