import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PostChange = () => {
    const navigate = useNavigate();
    const { no } = useParams();

    // 수정 당시 날짜로 게시글 번호 만들기
    const currentDate = new Date();
    const formattedDateForID = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
    const changepostnum = `${formattedDateForID}${Math.floor(Math.random() * 100)}`;
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    const postchangeDateTime = currentDate.toLocaleString('ko-KR', options);

    const [communityPost, setCommunityPost] = useState({
        title: '',
        nickname: '',
        content: ''
    });

    const [Titleinput, setTitleinput] = useState('');
    const [Contentinput, setContentinput] = useState('');

    useEffect(() => {
        document.title = "게시글 수정";
    }, []);

    const handletitlechange = (value) => {
        setTitleinput(value);
    }

    const handlecontentchange = (value) => {
        setContentinput(value);
    }


    useEffect(() => {
        const getPostCommunity = async () => {
            try {
                const response = await axios.get(`/api/communitypost?postnum=${no}`);
                const postData = response.data.postcontent[0];
                setCommunityPost({
                    title: postData.title,
                    nickname: postData.nickname,
                    content: postData.content
                });
                setTitleinput(postData.title);
                setContentinput(postData.content);
            } catch (error) {
                console.log('게시물 불러오기 중 오류가 발생하였습니다.');
            }
        }
        getPostCommunity();
    }, [no]);

    const sendPostCommunity = async () => {
        try {
            await axios.post('/api/Changepost', {
                changepostnum: changepostnum,
                postnum: no,
                title: Titleinput,
                content: Contentinput,
                changedate: postchangeDateTime
            });
            toast.success('게시물이 성공적으로 수정되었습니다!');
            navigate(`/postView/${no}`);
        } catch (error) {
            toast.error('게시물 수정 중 오류가 발생하였습니다.');
        }
    }

    return (
        <div style={{ backgroundColor: "#f4f7fa" }}>
            <div className='postviewcontainer'>
                <div className='post-view-wrapper'>
                    <div className='create-nickname'>
                        <label className='createnicknameinputlabel'>닉네임</label>
                        &nbsp;
                        <div className='createnicknameinputlabel2'>{communityPost.nickname}</div>
                    </div>
                    <div className='create-title'>
                        <label className='createtitleinputlabel'>제목</label>
                        <textarea type='text' value={Titleinput} className='createtitleinput' onChange={(e) => handletitlechange(e.target.value)}></textarea>
                    </div>
                    <textarea className='create-content-input' value={Contentinput} onChange={(e) => handlecontentchange(e.target.value)}></textarea>
                    <button className="create-post-button" onClick={sendPostCommunity}>수정</button>
                </div>
            </div>
            <br />
            <ToastContainer />
        </div>
    );
}



export default PostChange;