import React, { useEffect, useState } from 'react';
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import axios from 'axios';
import { Typography, Grid, Paper, Button, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Cli_review = () => {
  const navigate = useNavigate();
  const [review, setReview] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reviewResponse = await axios.get('/api/Checkreview');
        const reviewData = reviewResponse.data.reviewData;
        setReview(reviewData);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, []);





  return (
    <PageContainer title="작성한 후기">
     <DashboardCard title={
        <>

        <Typography style={{ fontWeight: "700", fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", fontSize: '20px', whiteSpace: 'nowrap' }}>
          작성한 후기
        </Typography>
        <hr style={{
            backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
            height: '4px',    
            width: '89px',
            margin: '10px auto', // 중앙 정렬을 위한 마진 설정
            border: 'none',
            marginLeft: '4px', // 왼쪽 정렬
            marginTop : '14px',
            
          }} />
          </>

      }
        action={
          <Typography sx={{ fontSize: "14.5px", fontWeight: "700" }}>
            <Link
              to="/Mypage/client/con_detail"
              style={{ fontWeight: 700 }}>완료된 상담</Link>
          </Typography>
        }>


        <br /><br />

        <Grid container spacing={3}>
          {review.map((reviewItem) => (
            <Grid item xs={12} sm={6} lg={4} key={reviewItem.num_review}>
              <Paper elevation={5}>
                <DashboardCard>
                  <TableContainer style={{ maxHeight: '35vh', overflowY: 'auto' }}>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400",fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", }}>
                              상담사 이름: {reviewItem.counselor_name}
                            </Typography>
                            <br />
                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400",fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", }}>
                              상담 일시: {reviewItem.date}
                            </Typography>
                            <br />
                            <Typography
                              sx={{
                                fontSize: "13.5px",
                                fontWeight: "400",
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
                                maxWidth: '100px' // 원하는 최대 너비 설정
                              }}
                            >
                              내용: {reviewItem.content.length > 200 ? reviewItem.content.slice(0, 200) + '...' : reviewItem.content}
                            </Typography>
                            <br />

                            <Typography sx={{ fontSize: "13.5px", fontWeight: "400",fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif", }}>
                              작성일시 : {reviewItem.writedate}
                            </Typography>
                            <br />

                            <Button variant="contained"
                              style={{
                                backgroundColor: "#5D87FF",
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '9px',
                                marginLeft: '-2px',
                                marginTop: '10px',
                                fontFamily: "'Noto Sans KR', 'Nanum Square', sans-serif",
                                fontSize: '10px'
                              }}
                              onClick={() => navigate(`/Mypage/client/review_detail/${reviewItem.num_review}`, {
                                state: {
                                  reviewnum: reviewItem.num_review,
                                }
                              })}

                            >
                              자세히 보기
                            </Button> &nbsp;


                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </DashboardCard>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DashboardCard>
    </PageContainer>
  );
};

export default Cli_review;