
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';

import Box1 from '@mui/material/Box';


import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { styled } from '@mui/material/styles';


import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import { Button, Typography, Grid, Chip, Box, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { functionsIn } from 'lodash';







const Counselor_Manage = () => {
  const navigate = useNavigate();


  const [waitingCounselors, setWaitingCounselors] = useState([]);
  const [Counselors, setCounselors] = useState([]); // 전체 전문가


  const [isOpen, setIsOpen] = useState(false);
  const [selectedCounsIndex, setSelectedCounsIndex] = useState(null); // 거절 Modal


  const [resdata, setResdata] = useState([]); // 예약 정보를 저장할 상태

  const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '600px',
    minHeight: '100px',
    maxHeight: '300px',
    resize: 'none',
    padding: '10px',  // 텍스트 여백
    fontSize: '16px',  // 글꼴 크기
    lineHeight: '1.5',  // 줄 간격
    borderRadius: '8px',  // 테두리 모서리 둥글게
    border: '1px solid #ccc',  // 테두리 색상
    backgroundColor: '#f9f9f9',  // 배경 색상
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',  // 그림자 효과
    transition: 'border-color 0.3s, box-shadow 0.3s',  // 호버 효과 전환
    '&:focus': {
      borderColor: '#66afe9',  // 포커스 시 테두리 색상
      boxShadow: '0 0 8px rgba(102, 175, 233, 0.6)',  // 포커스 시 그림자 효과
      outline: 'none',  // 기본 포커스 아웃라인 제거
    },
    '::placeholder': {
      color: '#aaa',  // 플레이스홀더 텍스트 색상
    },
  }));


  // 이메일 전송 버튼
  const StyledButton2 = styled(Button)({
    fontSize: '12px', // 폰트 크기 줄임
    fontWeight: 700,
    padding: '4px 8px', // 패딩 줄임
    textTransform: 'none',
    backgroundColor: '#80b3ff', // 파란색 배경색
    color: '#fff', // 흰색 텍스트
    '&:hover': {
      backgroundColor: '#99c2ff', // 어두운 파란색 배경색
    },
  });



  // 정지 버튼
  const StyledSuspendButton = styled(Button)({
    fontSize: '10px',
    fontWeight: 500,
    padding: '6px 12px',
    textTransform: 'none',
    border: '1px solid #FF4081', // 빨간색 외곽선
    color: '#FF4081', // 빨간색 텍스트
    '&:hover': {
      backgroundColor: '#FF4081', // 빨간색 배경색
      color: '#fff', // 흰색 텍스트
    },
  });

  // 정지 해제 버튼
  const StyledReleaseButton = styled(Button)({
    fontSize: '10px',
    fontWeight: 500,
    padding: '6px 12px',
    textTransform: 'none',
    border: '1px solid #1976D2', // 파란색 외곽선
    color: '#1976D2', // 파란색 텍스트
    '&:hover': {
      backgroundColor: '#1976D2', // 파란색 배경색
      color: '#fff', // 흰색 텍스트
    },
  });

  // 파일 다운로드 버튼
  const StyledButton = styled(Button)({
    backgroundColor: '#E0E0E0', // 회색 배경색
    color: '#424242', // 짙은 회색 텍스트
    padding: '4px 16px', // 내부 여백 설정 (세로 크기를 줄임)
    borderRadius: '5px', // 모서리를 둥글
    border: 'none', // 외곽선 없음
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    fontWeight: 'bold',
    fontSize: '9.5px',
    fontFamily: 'Arial, sans-serif',
    '&:hover': {
      backgroundColor: '#BDBDBD',
    },
  });

  // 거절 버튼
  const StyledSuspendButton1 = styled(Button)({
    fontSize: '10px',
    fontWeight: 700,
    padding: '4px 6px',
    textTransform: 'none',
    border: '1px solid #FF4081', // 빨간색 외곽선
    color: '#FF4081', // 빨간색 텍스트
    '&:hover': {
      backgroundColor: '#FF4081', // 빨간색 배경색
      color: '#fff', // 흰색 텍스트
    },
  });

  // 승인 버튼
  const StyledReleaseButton1 = styled(Button)({
    fontSize: '10px',
    fontWeight: 700,
    padding: '4px 6px',
    textTransform: 'none',
    border: '1px solid #1976D2', // 파란색 외곽선
    color: '#1976D2', // 파란색 텍스트
    '&:hover': {
      backgroundColor: '#1976D2', // 파란색 배경색
      color: '#fff', // 흰색 텍스트
    },
  });



  Modal.setAppElement('#root'); // 또는 모달이 열릴 최상위 DOM 요소의 ID를 전달, 모달 오류 해결

  useEffect(() => {
    // 서버에서 데이터 가져오는 함수
    async function fetchWaitingCounselors() {
      try {
        const response = await fetch("/api/Counselor_Manage");
        const data = await response.json();
        setWaitingCounselors(data);
      } catch (error) {
        console.error("데이터 가져오기 오류:", error);
      }
    }

    // Waiting Counselors 데이터 가져오기
    fetchWaitingCounselors();

    // 서버에서 데이터 가져오는 함수
    async function fetchCounselor() {
      try {
        const response = await fetch("/api/all_Counselor_Manage");
        const data = await response.json();
        setCounselors(data);
      } catch (error) {
        console.error("데이터 가져오기 오류:", error);
      }
    }

    // Counselor 데이터 가져오기
    fetchCounselor();
  }, []); // 컴포넌트가 처음 렌더링될 때 한 번만 실행

  async function suspendCounselor(counselor) {
    // 전문가 정지
    try {
      const response = await fetch('/api/suspendCounselor', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: counselor.user_id })
      });

      const data = await response.json();
      toast.error("계정 정지 처리되었습니다.", { autoClose: 1500 });
    } catch (error) {
      console.error('요청 실패:', error);
    }
  }

  async function removeSuspendCounselor(counselor) {
    // 전문가 정지 해제
    try {
      const response = await fetch('/api/removeSuspendCounselor', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: counselor.user_id })
      });

      const data = await response.json();

      toast.success("정지 해제 처리되었습니다.", { autoClose: 1500 });
    } catch (error) {
      console.error('요청 실패:', error);
    }
  }

  // toast 메시지가 사라진 후에 페이지를 새로 고침하는 함수
  function reloadPageAfterToast() {
    window.location.reload();
  }

  const mimeTypes = {
    pdf: 'application/pdf',
    txt: 'text/plain',
    // 다른 파일 형식에 대한 매핑 추가
  };


  /* 승인 전 전문가 파일 */
  const handleDownloadButtonClick = async (item) => {
    const url = item.w_couns_file; // 데이터베이스에 저장된 S3 객체 URL
    const fileName = url.split('/').pop(); // 파일명 추출
    const fileExtension = fileName.split('.').pop(); // 파일 확장자 추출

    const mimeType = mimeTypes[fileExtension] || 'application/octet-stream'; // MIME 유형 가져오기
    try {
      const response = await fetch(url);

      // 파일 다운로드를 위한 Blob 객체 생성
      const blob = await response.blob();
      // Blob 객체를 URL로 변환
      const downloadUrl = window.URL.createObjectURL(blob);
      // a 태그 생성
      const a = document.createElement("a");
      // a 태그에 다운로드 URL 설정
      a.href = downloadUrl;
      // 다운로드할 파일명 설정
      a.download = `${item.w_couns_id}_${item.w_couns_name}.${fileExtension}`;
      // 파일 형식 설정
      a.type = mimeType;
      // 클릭 이벤트 발생하여 파일 다운로드 시작
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("파일 다운로드 오류:", error);
    }
  };

  /* 승인 된 전문가 파일 */
  const handleDownloadButtonClick2 = async (item) => {
    const url = item.couns_file; // 데이터베이스에 저장된 S3 객체 URL
    const fileName = url.split('/').pop(); // 파일명 추출
    const fileExtension = fileName.split('.').pop(); // 파일 확장자 추출

    const mimeType = mimeTypes[fileExtension] || 'application/octet-stream'; // MIME 유형 가져오기
    try {
      const response = await fetch(url);

      // 파일 다운로드를 위한 Blob 객체 생성
      const blob = await response.blob();
      // Blob 객체를 URL로 변환
      const downloadUrl = window.URL.createObjectURL(blob);
      // a 태그 생성
      const a = document.createElement("a");
      // a 태그에 다운로드 URL 설정
      a.href = downloadUrl;
      // 다운로드할 파일명 설정
      a.download = `${item.user_id}_${item.couns_name}.${fileExtension}`;
      // 파일 형식 설정
      a.type = mimeType;
      // 클릭 이벤트 발생하여 파일 다운로드 시작
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("파일 다운로드 오류:", error);
    }
  };





  const approveCounselor = async (counselor) => {
    // 승인했을 때 데이터 처리
    try {
      const response = await fetch("/api/approveCounselor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(counselor),
      });

      if (!response.ok) {
        throw new Error("상담사 승인 요청 실패");
      }

      const data = await response.json();

      alert("승인 처리되었습니다.");
      window.location.reload();
    } catch (error) {
      console.error("승인 요청 오류:", error);
    }
  };

  const rejectCounselor = async (counselor) => {
    // 거절했을 때 처리
    const rejectionReason = document.getElementById("rejectionReason").value;

    if (rejectionReason === '') {
      alert("내용을 입력해 주세요.");
      return;
    }

    const confirmation = window.confirm("이메일을 전송하시겠습니까?");

    if (confirmation) {
      fetch('/api/sendRejectionEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          reason: rejectionReason,
          w_couns_email: counselor.w_couns_email,
          w_couns_id: counselor.w_couns_id,
        })
      })
        .then(response => {
          if (response.ok) {
            closeModal();
            window.location.reload();
          } else {
            console.error('이메일 전송 실패:', response.statusText);
          }
        })
        .catch(error => {
          console.error('이메일 전송 오류:', error);
        });
    }
  }

  const customStyles = {
    overlay: {
      backgroundcolor: "rgba(0,0,0,0.5)",
    },
    content: {
      width: "750px",
      height: "300px",
      margin: "auto",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      padding: "20px",
    },
  };

  const textareaStyle = {
    content: {
      width: "600px",
      height: "300px",
    }
  }



  const openModal = (index) => {
    setSelectedCounsIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedCounsIndex(null);
    setIsOpen(false);
  };



  // toast 메시지가 사라진 후에 페이지를 새로 고침하는 함수
  function reloadPageAfterToast() {
    window.location.reload();
  }

  async function handleLeave(counselor) {
    if (counselor.activity === 2) {
      navigate("/Mypage/admin/Counselor_Leave", {
        state: {
          loginid: counselor.loginid,
          user_id: counselor.user_id,
          authority: counselor.authority,
          check: "OK"
        }
      });
    } else {
      toast.error("계정 정지된 전문가만 강제 회원 탈퇴가 가능합니다.");
    }
  }

  return (
    <PageContainer title="승인 대기">
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <DashboardCard title="승인 대기">
            <ToastContainer onClose={reloadPageAfterToast} />
            <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

              <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        이름

                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        생년월일

                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        이메일


                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        전화번호
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        성별
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        첨부파일
                      </Typography>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {waitingCounselors.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} > {item.w_couns_name} </TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.w_couns_birthday}</TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.w_couns_email}</TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.w_couns_phonenum}</TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item.w_couns_gender === 1 ? "남" : "여"}</TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>
                        <StyledButton

                          onClick={() => handleDownloadButtonClick(item)}
                        >
                          파일 다운로드
                        </StyledButton>

                      </TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>
                        <StyledReleaseButton1
                          onClick={() => approveCounselor(item)}
                        >
                          승인
                        </StyledReleaseButton1> &nbsp;&nbsp;
                        <StyledSuspendButton1
                          onClick={() => openModal(index)}
                        >
                          거절
                        </StyledSuspendButton1 >
                        <Modal
                          isOpen={isOpen && selectedCounsIndex === index}
                          onRequestClose={closeModal}
                          style={{
                            ...customStyles,
                            overlay: {
                              backgroundColor: "rgba(0, 0, 0, 0.5)", // 오버레이 배경색
                              zIndex: 9999 // 오버레이의 z-index
                            },
                            content: {
                              ...customStyles.content,
                              zIndex: 10000 // 모달 내용의 z-index
                            }
                          }}
                        >
                          <div>
                            <h4>승인 거절 사유 작성</h4>
                            <br />
                            <form id="rejectionForm">
                              <Box1
                              >
                                <StyledTextarea
                                  id="rejectionReason"
                                  placeholder="거절 사유를 입력하세요"

                                />
                              </Box1>
                              <br />

                              <StyledButton2 onClick={() => rejectCounselor(item)}>이메일 전송</StyledButton2>
                            </form>
                          </div>
                        </Modal>

                      </TableCell>



                    </TableRow>
                  ))}


                </TableBody>
              </Table>
            </Box>
          </DashboardCard>
        </Grid>

        <Grid item sm={12}>
          <DashboardCard title="전문가 관리">
            <Box sx={{ overflow: 'auto', width: { xs: '280px', sm: 'auto' } }}>

              <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        전문가 번호


                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        이름


                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        생년월일



                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        이메일


                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        전화번호
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        성별
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontSize: "13.5px", fontWeight: "700" }}>
                        첨부파일
                      </Typography>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {Counselors.map((item2, index2) => (
                    <TableRow key={index2}>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }} > {item2.user_id} </TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item2.couns_name}</TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item2.birthday}</TableCell>

                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item2.couns_email}</TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item2.couns_phonenum}</TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>{item2.couns_gender === 1 ? "남" : "여"}</TableCell>
                      <TableCell sx={{ fontSize: "12px", fontWeight: "400" }}>
                        <StyledButton

                          onClick={() => handleDownloadButtonClick2(item2)}
                        >
                          파일 다운로드
                        </StyledButton>

                      </TableCell>
                      <TableCell sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontSize: "10px", fontWeight: "400" }}> {item2.activity === 1 ?
                          <StyledSuspendButton
                            onClick={() => suspendCounselor(item2)}
                          >계정정지</StyledSuspendButton>
                          : <StyledReleaseButton
                            onClick={() => removeSuspendCounselor(item2)}
                          >정지해제</StyledReleaseButton>}</Typography>
                        <Typography sx={{ fontSize: "10px", fontWeight: "400" }}>
                          <StyledSuspendButton
                            onClick={() => handleLeave(item2)}
                          >회원탈퇴</StyledSuspendButton></Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

          </DashboardCard>
        </Grid>


      </Grid >
    </PageContainer>
  );
};

function MSpan({ space = 510 }) {
  return (
    <span style={{ paddingRight: space }}></span>
  );
}



export default Counselor_Manage;
