import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

export const Navigation2 = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (isLoggedIn) {
          const response = await axios.get("/api/Mypage", {
            withCredentials: true,
          });
          if (Array.isArray(response.data) && response.data.length > 0) {
            setUserData(response.data[0]);
          }
          else if (!Array.isArray(response.data) && typeof response.data === 'object') {
            setUserData(response.data);
          }
        }
      } catch (error) {
        setIsLoggedIn(false);    
        setUserData(null);
      }
    };

    fetchUserData();
    const interval = setInterval(fetchUserData, 3600000);

    return () => clearInterval(interval);
  }, [isLoggedIn, setIsLoggedIn, setUserData]);

  const handleLogout = async () => {
    try {
      const response = await fetch("/api/Logout", {
        method: "GET",
        credentials: "include",
      });

      if (response.status === 200) {    
        setIsLoggedIn(false);
        localStorage.setItem('isLoggedIn', false);
        setUserData(null);
        navigate("/");
        window.location.reload();
      } else {
        console.error("로그아웃 실패:", response.statusText);
      }
    } catch (error) {
      console.error("로그아웃 요청 오류:", error);
    }
  };
  

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
        <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link to="/" className="navbar-brand page-scroll">
            N A M A N
          </Link>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
          <li>
              <Link to="/" className="page-scroll">
                나만서비스 소개
              </Link>
            </li>
            <li>
              <Link to="/O_Reservation" className="page-scroll">
                집단 프로그램 소개
              </Link>
            </li>
            <li>
              <Link to="/Search" className="page-scroll">
                상담예약
              </Link>
            </li>
            <li>
              <Link to="/Community" className="page-scroll">
                커뮤니티
              </Link>
            </li>
            <li>
              <Link to="https://blog.naver.com/resang79" className="page-scroll" target="_blank">
                블로그
              </Link>
            </li>
            <li>
              {isLoggedIn ? (
                <Link
                to={
                  userData.authority === 3
                    ? "/Mypage/admin"
                    : userData.authority === 1 ? "/Mypage/counselor" : "/Mypage/client"}
                    className="signup-button1"
                >
                마이페이지
                </Link>
              ) : (
                <Link to="/Signup" className="signup-button1">
                  회원가입
                </Link>
              )}
            </li>
            <li>
              {isLoggedIn ? (
                <Link to="/" className="signup-button1" onClick={handleLogout}>
                  로그아웃
                </Link>
              ) : (
                <Link to="/Login" className="signup-button1">
                  로그인
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};